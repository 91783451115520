<template>
    <validation-observer ref="fields">
        <v-row class="pb-2 mx-0 pt-4">
            <v-col cols="12" md="6">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>
        </v-row>
    </validation-observer>
</template>

<script>

export default {
	name: 'checklist-type-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
		info: Object,
	},

}
</script>
