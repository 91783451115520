<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Associazioni"
				:totalItems="totalItems"
				icon="mdi-home-city-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 160px; height: 100%">
				<v-data-iterator
					:options.sync="pagination"
					:server-items-length="totalItems"
					:items="organizations"
					:footer-props="{
						'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
						showFirstLastPage: true
					}">						
					<template v-slot:default="{ items }">

						<v-card class="rounded-lg pa-0 mb-2" max-height="200" style="overflow: hidden;" v-for="(organization, o) in items" :key="o">
							<v-row no-gutters class="pa-2">
								
								<v-col class="col-3 col-sm-3 col-md-2 col-lg-2" style="vertical-align: middle; margin: auto; margin-left: 0px">
									<v-avatar v-if="organization.avatar"
										class="custom-avatar ml-2 mr-4 align-self-center"
										:size="84" 
										v-ripple
										:style="{'background-image': 'url(' + organization.avatar + ')'}">
									</v-avatar>
									<v-avatar v-else size="84px" class="grey lighten-3 cursor-pointer">
										<v-icon x-large>mdi-home-city-outline</v-icon>
									</v-avatar>			
								</v-col>

								<v-col class="col-9 col-sm-9 col-md-3 col-lg-3 pb-xs-2 text-left" style="vertical-align: middle; margin: auto">
									<div class="px-6" style="display: flex; flex-direction: column;">
										<span class="">{{ organization.name }}</span>
										<div class="caption font-weight-thin grey--text text--darken-1 block-with-text">{{ organization.description }}</div>
									</div>								
								</v-col>


								<v-col class="col-12 col-sm-12 col-md-2 col-lg-2 text-center hidden-xs-only" style="vertical-align: middle; margin: auto;">
									<v-card-actions v-if="!organization.request_date" class="d-block">
										<v-spacer></v-spacer>
										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" color="success" outlined :loading="loadingButtonsSent[o]" @click="sendRequest(organization.id, true, o)">
													Invia Richiesta
												</v-btn>
											</template>
											<span>Invia Richiesta</span>
										</v-tooltip>
									</v-card-actions>

									<div v-else-if="!organization.approved && !organization.response_date" style="display: block">
										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" outlined color="error" text @click="deleteRequest(organization.organization_user_id, o)">
													Annulla Richiesta
												</v-btn>
											</template>
											<span>Annulla Richiesta</span>
										</v-tooltip>
										<span class="caption font-weight-bold text-center grey--text text--darken-1"> inviata il {{ organization.request_date | datetime }}</span>
									</div>

									<div v-else-if="!organization.approved && organization.response_date" style="display: flex; flex-direction: column;">
										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" color="success" text disabled>
													Richiesta Rifiutata
												</v-btn>
											</template>
											<span>Richiesta Rifiutata</span>
										</v-tooltip>							
										<span class="caption font-weight-bold text-center grey--text text--darken-1">il {{ organization.response_date | datetime }}</span>
									</div>

									<div v-else-if="organization.request_date" style="display: flex; flex-direction: column;">
										<v-tooltip top >
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" color="red" class="mt-4" outlined :loading="loadingButtonsRefused[o]"  @click="deleteRequest(organization.organization_user_id, o)">
													Disiscriviti
												</v-btn>
											</template>
											<span>Disiscriviti</span>
										</v-tooltip>							
										<span class="caption font-weight-bold text-center grey--text text--darken-1"> approvata il {{ organization.response_date | datetime }}</span>
									</div>
								</v-col>
							</v-row>

							<v-divider></v-divider>
							<v-row no-gutters>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-3 pb-xs-2 hidden-md-and-up pa-0" style="vertical-align: middle; margin: auto">

									<v-card-actions class="text-center pa-0">
										<v-tooltip top v-if="!organization.request_date">
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" color="success" block text :loading="loadingButtonsSent[o]" @click="sendRequest(organization.id, true, o)">
													Invia Richiesta
												</v-btn>
											</template>
											<span>Invia Richiesta</span>
										</v-tooltip>


										<v-tooltip top v-else-if="!organization.approved && !organization.response_date">
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" color="error" block text :loading="loadingButtonsRefused[o]"  @click="deleteRequest(organization.organization_user_id, o)">
													Annulla Richiesta
												</v-btn>
											</template>
											<span>Annulla Richiesta</span>
										</v-tooltip>


										<v-tooltip top v-else-if="!organization.approved && organization.response_date">
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" color="error" block text :loading="loadingButtonsRefused[o]" disabled @click="sendRequest(organization.id, false, o)">
													Rifiutata
												</v-btn>
											</template>
											<span>Richiesta Rifiutata</span>
										</v-tooltip>


										<v-tooltip top v-else-if="organization.approved && organization.response_date">
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" color="error" block text :loading="loadingButtonsRefused[o]" @click="deleteRequest(organization.organization_user_id, o)">
													Disiscriviti
												</v-btn>
											</template>
											<span>Richiesta Accettata</span>
										</v-tooltip>
									</v-card-actions>
								</v-col>						
							</v-row>

						</v-card>

					</template>

					<template v-slot:footer.page-text="{ pageStart, pageStop }">
						Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
					</template>					
				</v-data-iterator>

			</v-container>
		</v-col>
	</v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'

export default {
	name: 'organizations',
	components: {
		HeaderCrud,
	},
	mixins: [DataTableMixin],
	data() {
		return {
			search: '',
			loading: false,
			pagination: {
				'sortBy': ['id'],
				'sortDesc': [false],
				'page': 1,
				'itemsPerPage': 10,
				'totalItems': 0,
			},
			organizations: [],
			apiTimeout: null,
			totalItems: 0,
			loadingButtons: {},
			loadingButtonsSent: {},
			loadingButtonsRefused: {},
		}
	},
		

	created() {
		this.getData()
	},

	mounted() {
		this.$store.commit('SET_SIDEBAR', false)
	},


	methods: {

		getData() {
			if(this.apiTimeout) {
				clearTimeout(this.apiTimeout)
			}

			this.apiTimeout = setTimeout(() => {

				this.loading = true

				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					advancedFilters: this.advancedFilters,
					filter: this.search,
					subFilters: this.subFilters,
				}

				this.axios.get(this.$store.getters.getAuthUrl + '/organizations-list', {params: dataObject}).then(response => {
					this.organizations = response.data.data
					this.totalItems = response.data.meta.total
					this.pagination.totalItems = response.data.meta.total
					this.loading = false

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)			
		},

		sendRequest(organization_id, approved, index) {
			approved ? this.$set(this.loadingButtonsSent, [index], true) : this.$set(this.loadingButtonsRefused, [index], true)

			this.axios.post(this.$store.getters.getAuthUrl + '/requests', {user_id: this.$store.getters.getUserProfile.id, organization_id: organization_id, approved: approved}).then(response => {
					
				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: 'Richiesta Inviata',
					value: true,
					color: 'success'
				})

				this.getData()
				approved ? this.$set(this.loadingButtonsSent, [index], false) : this.$set(this.loadingButtonsRefused, [index], false)

			}, response => {
				// error callback
				this.loading = false
				approved ? this.$set(this.loadingButtonsSent, [index], false) : this.$set(this.loadingButtonsRefused, [index], false)
			})
		},

		deleteRequest(organization_user_id, index) {
			this.$set(this.loadingButtonsRefused, [index], true)

			this.axios.delete(this.$store.getters.getAuthUrl + '/requests/' + organization_user_id).then(response => {
					
				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: 'Iscrizione Annullata',
					value: true,
					color: 'success'
				})

				this.getData()
				this.$set(this.loadingButtonsRefused, [index], false)

			}, response => {
				// error callback
				this.loading = false
				this.$set(this.loadingButtonsRefused, [index], false)
			})
		}

	}
 	}
</script>

<style>
	.block-with-text {
		overflow: hidden;
		position: relative;
		line-height: 1.2em !important;
		max-height: 3.4em;
		text-align: unset;
		margin-right: 0;
		padding-right: 0;
	}

	.block-with-text::before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.block-with-text::after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}

</style>