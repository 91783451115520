export function trans(value) {
	value = value.toString().toLowerCase()
	let translations = {
		board: 'Bacheca',
		calendar: 'Calendario',
		courses: 'Corsi',
		analytics: 'Statistiche',
		attendances: 'Timbrature',
		compile_checklist: 'Compila Checklist',
		people: 'Persone',
		vehicles: 'Veicoli',
		handbook: 'Prontuario',
		checklists: 'Checklist',
		settings: 'Impostazioni',
		requests: 'Richieste',
		organizations: 'Associazioni',
		privacy: 'Privacy',
		standard: 'Standard',
		availability: 'Disponibilità',
		checklist: 'Checklist',
		compile_checklists: 'Compila Checklist',
		'invalid token provided': 'Token non valido',
		'invalid user': 'Utente non valido',
		'email already exists': 'Email già esistente',
		'no shift with checklist available': 'Nessuno turno con checklist disponibile',
		'already certified checklist': 'Checklist già certificata',
		'weekday': 'Feriale',
		'preholiday': 'Pre-festivo',
		'holiday': 'Festivo',
		'always': 'Sempre',
		'monday': 'Lunedì',
		'tuesday': 'Martedì',
		'wednesday': 'Mercoledì',
		'thursday': 'Giovedì',
		'friday': 'Venerdì',
		'saturday': 'Sabato',
		'sunday': 'Domenica',
		'subscribed': 'Iscritto',
		'not_subscribed': 'Non Iscritto',
		'pending': 'In Attesa'

	}

	return typeof translations[value] === 'undefined' ? value : translations[value]
}