<template>
    <validation-observer ref="fields">
        <v-container fluid>
            <v-row class="pb-2 mx-0 pt-4">
                <v-col class="col-12 col-md-6">
                    <validation-provider name="Strumento" rules="required" v-slot="{ errors }">
                        <v-autocomplete
                            label="Strumento*"
                            v-model="model['instrument']"
                            :error-messages="errors"
                            :loading="loading"
                            :items="instruments"
                            item-text="name"
                            item-value="id"
                            return-object
                            :search-input.sync="search"
                            flat
                            hide-no-data
                            cache-items
                            outlined
                            dense
                            ></v-autocomplete>
                    </validation-provider>
                </v-col>

                <template v-if="!model['is_boolean']">
                    <v-col class="col-12 col-md-3">
                        <validation-provider name="Min" rules="numeric|required" v-slot="{ errors }">
                            <v-text-field
                                name="Min*"
                                label="Min"
                                type="number"
                                v-model="model['min']"
                                :error-messages="errors"
                                outlined
                                dense                            
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col class="col-12 col-md-3">
                        <validation-provider name="Max" rules="numeric|required" v-slot="{ errors }">
                            <v-text-field
                                name="Max*"
                                label="Max"
                                type="number"
                                v-model="model['max']"
                                :error-messages="errors"
                                outlined
                                dense                            
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                </template>    

                <v-col class="col-12 col-md-6">
                    <v-switch label="Presente" inset v-model="model['is_boolean']"></v-switch>
                </v-col>
            </v-row>


        </v-container>
    </validation-observer>
</template>

<script>

export default {
	name: 'checklisttype-condition-fields',
	props: {
		model: Object,
	},
	data() {
		return {
			loading: false,
			instruments: [],
			search: null,
			select: null
		}
	},
	watch: {
		search (val) {
			val && val !== this.select && this.querySelections(val)
		},
		model: {
			immediate: true,
			handler: function (newVal, oldVal) {
				if(newVal.id) {
					this.instruments.push(newVal.instrument)
				}
			}
		}
	},
	methods: {
		querySelections (v) {
			this.loading = true
			this.axios.get('instruments', {params: {q: v}}).then(response => {
				this.instruments = response.data.data
				this.loading = false
			})
		},
	},

}
</script>
