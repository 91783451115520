<template>
	<v-row no-gutters class="pa-0 fill-height">
		<v-col class="mx-0">

            <v-container fluid class="pa-0" >
                <v-card :class="id ? 'pt-0' : ''" class="rounded-0 pa-6 pb-0 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters v-if="!loading">

                            <v-col class="col-10 col-sm-6 col-lg-11 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold">Turno: {{ checklist.shift.full_name }}</div>
                                <div v-if="checklist.shift.vehicle" class="pl-0 font-weight-bold" style="font-size: 20px">Veicolo: {{ checklist.shift.vehicle.full_name }}</div>
                                <div v-if="checklist.certified_at" class="pl-0 font-weight-bold" style="font-size: 20px">Certificata il: {{ checklist.certified_at | date }}</div>
                                <div v-if="checklist.expired" class="pl-0 font-weight-bold" style="font-size: 20px">Scaduta</div>
                                <div v-if="checklist.note" class="pl-0 font-weight-bold" style="font-size: 14px" v-html="checklist.note"></div>
                            </v-col>

                            <v-col class="col-2 col-lg-1 text-right ma-auto mr-0">
                                <v-icon :size="35" class="ma-auto mr-0" color="black">mdi-clipboard-check-outline</v-icon>
                            </v-col>

                        </v-row>
                    </v-card-title>
                    <v-card-actions class="hidden-md-and-up" >
                        <v-row no-gutters>
                            <v-col class="col-12 ma-auto ml-0">
                                <v-spacer></v-spacer>
                                <v-btn-toggle mandatory dense v-model="group" group>
                                    <v-btn  color="background-1" class="px-3" value="area">Area</v-btn>
                                    <v-btn v-if="hasVehicle" class="px-3" value="compartment">Scomparto</v-btn>  
                                </v-btn-toggle>
                            </v-col>
                            <v-col class="col-12 ma-auto ml-0">
                                <v-btn-toggle dense v-model="hideValid" group>
                                    <v-btn depressed class="px-3" :value="true">Nascondi Conformi</v-btn>
                                </v-btn-toggle>
                                <v-btn depressed class="px-3" @click="$eventBusUtility.openFile($store, '/checklists/' + id + '/print', 'Checklist_' + id)">Stampa</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>

                    <v-card-actions class="hidden-sm-and-down" style="">
                        <v-spacer></v-spacer>
                        <v-btn-toggle mandatory dense v-model="group" group>
                            <v-btn  color="background-1" class="px-3" value="area">Area</v-btn>
                            <v-btn v-if="hasVehicle" class="px-3" value="compartment">Scomparto</v-btn>  
                        </v-btn-toggle>
                        <v-btn-toggle dense v-model="hideValid" group>
                            <v-btn depressed class="px-3" :value="true">Nascondi Conformi</v-btn>
                        </v-btn-toggle>
                        <v-btn depressed class="px-3" @click="$eventBusUtility.openFile($store, '/checklists/' + id + '/print', 'Checklist_' + id)">Stampa</v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>

            <v-container fluid class="pa-6 -page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style=" height: 100%">
                <v-card :elevation="1" class="background rounded-lg" width="100%">
                    <template v-if="checklist.checklisttype">
                        <validation-observer ref="checklistFields">
                            <template v-for="(group, index) in grouppedCondition">
                                <v-card-title class="pa-0 rounded-lg" :key="'condition_'+index">
                                    <v-toolbar style="border-top-left-radius: 8px !important; border-top-right-radius: 8px !important;" dense flat color="primary" dark>
                                        <div class="pl-0 font-weight-bold" style="font-size: 20px">
                                            {{ group.name }}
                                        </div>
                                    </v-toolbar>
                                </v-card-title>
                                <v-divider :key="'divider_'+index"></v-divider>
                                    <template v-for="condition in group.checklist_conditions">
                                        <v-card-text :key="condition.id"  class="c-b-divider pt-4" v-if="checklistValueByChecklistconditionId[condition.id] && showCondition(condition)">
                                            <div class="d-flex">
                                                <div class="ma-auto">
                                                    <span class="font-weight-bold">{{ condition.instrument.name }}</span>
                                                    <span v-if="!condition.is_boolean" class="pl-2">[{{ condition.instrument.unit }}]</span>
                                                </div>
                                                <v-icon v-if="!checklistValueByChecklistconditionId[condition.id].compliant" color="error" class="pl-2 pb-1" size="20">mdi-alert-outline</v-icon>
                                                <v-spacer></v-spacer>
                                                <div>
                                                    <v-checkbox v-if="condition.is_boolean"
                                                        label="Presente"
                                                        v-model="checklistValueByChecklistconditionId[condition.id].value"
                                                        @change="updateCondition(condition.id)"
                                                        :true-value="1"
                                                        :false-value="0"
                                                        hide-details
                                                        :disabled="checklistCertified"
                                                        color="success"
                                                        class="ma-0 c-outlined"
                                                    ></v-checkbox>
                                                </div>
                                                <span icon class="pl-2" @click="$set(showNotes, condition.id, !showNotes[condition.id])">
                                                    <v-icon :color="checklistValueByChecklistconditionId[condition.id].note ? 'green' : 'red'" >mdi-note-plus-outline</v-icon>
                                                </span>
                                            </div>
                                            <template v-if="!condition.is_boolean">
                                                <div v-if="condition.min == condition.max">Quantità necessaria {{ condition.min }}</div>
                                                <div v-else-if="condition.max > 0" class="pt-1 pb-2">Quantità da {{ condition.min }} a {{ condition.max }}</div>
                                                <div v-else>Quantità minima {{ condition.min }}</div>
                                            </template>
                                            <div>{{ condition.instrument.description }}</div>


                                            <div v-if="!condition.is_boolean" class="d-flex">
                                                <v-chip label :ripple="false" color="error" class="mx-2 pa-5" @click="decrement(condition)" :disabled="checklistCertified">
                                                    <v-icon size="18">
                                                        mdi-window-minimize
                                                    </v-icon>
                                                </v-chip>
                                                <v-text-field
                                                    v-model.number="checklistValueByChecklistconditionId[condition.id].value"
                                                    type="number"
                                                    :class="{'checklistvalue': true, 'valid-condition': checklistValueByChecklistconditionId[condition.id].compliant}"
                                                    outlined
                                                    dense
                                                    :disabled="checklistCertified"
                                                    min="0"
                                                    @change="updateCondition(condition.id)">
                                                </v-text-field>
                                                <v-chip label :ripple="false" color="success" class="mx-2 pa-5" @click="increment(condition)" :disabled="checklistCertified">
                                                    <v-icon>
                                                        mdi-plus
                                                    </v-icon>
                                                </v-chip>
                                            </div>

                                            <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }" v-if="showNotes[condition.id]">
                                                <v-textarea
                                                    label="Note"
                                                    v-model="checklistValueByChecklistconditionId[condition.id].note"
                                                    :error-messages="errors"
                                                    class="pt-4"
                                                    textarea
                                                    dense
                                                    rows="2"
                                                    outlined
                                                    :disabled="checklistCertified"
                                                    @change="updateCondition(condition.id)"
                                                ></v-textarea>
                                            </validation-provider>
                                        </v-card-text>
                                    </template>

                            </template>
                        </validation-observer>
                    </template>
                </v-card>

                <v-btn
                    v-if="!checklistCertified"
                    :class="{'mb-16': $store.getters.showBottomNavigator}"
                    color="primary"
                    fixed
                    fab
                    bottom
                    right
                    @click.native.stop="closeChecklistDialog = true">
                    <v-icon>mdi-check</v-icon>
                </v-btn>

                <footer-logo></footer-logo>

            </v-container>
		</v-col>

        <v-dialog v-model="closeChecklistDialog" max-width="800" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Chiudi Checklist</div>                
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeChecklistDialog = false"><v-icon>mdi-close</v-icon></v-btn>                
                </v-card-title>            
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container fluid class="px-0 pt-6 mx-0">
                        <v-col class="col-12 px-6 py-0">
                            <v-alert icon="mdi-check"  outlined type="warning">
                                <v-row align="center">
                                    <v-col class="grow">
                                        Sei sicuro di voler chiudere questa Checklist?
                                    </v-col> 
                                </v-row>                        
                            </v-alert>
                        </v-col>
                        <v-col class="col-12 px-6 py-0" v-if="checklistSatisfied">
                            <v-alert icon="mdi-check" text outlined type="success">
                                <v-row align="center">
                                    <v-col class="grow">
                                        Tutte le condizioni sono verificate
                                    </v-col> 
                                </v-row>                        
                            </v-alert>
                        </v-col>
                        <v-col class="col-12 px-6 py-0" v-else>
                            <v-alert icon="mdi-alert-outline" text type="error">
                                <v-row align="center">
                                    <v-col class="grow">
                                        Sono presenti delle anomalie
                                    </v-col> 
                                </v-row>                        
                            </v-alert>
                        </v-col>
                        <v-row class="pb-2 mx-0">
                            <v-col class="col-12 py-0 px-6 pt-3">
                                <validation-observer ref="closeChecklistFields">
                                    <validation-provider name="Note" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                            label="Note"
                                            v-model="model['note']"
                                            :error-messages="errors"
                                            textarea
                                            dense
                                            rows="4"
                                            outlined
                                        ></v-textarea>
                                    </validation-provider>
                                </validation-observer>
                            </v-col>                       
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-btn color="background-1" fab small @click="closeChecklistDialog = false">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    <v-btn color="success" class="ml-4" :loading="loading" @click="certifyChecklist()">
                        Certifica
                    </v-btn>                    
                </v-card-actions>
            </v-card>
        </v-dialog>                    

	</v-row>
</template>

<script>
export default {
	name: 'checklist-editor',
	props: {
		id: Number,
	},
	data() {
		return {
			model: {},
			group: 'area',
			hideValid: false,
			timeouts: {},
			checklist: {},
			instrumentareas: {},
			compartments: {},
			showNotes: {},
			loading: true,
			fullScreen: false,
			closeChecklistDialog: false,
			checklistValueByChecklistconditionId: {}
		}
	},

	computed: {

		checklistSatisfied() {
			return this.checklist.checklist_values ? this.checklist.checklist_values.every(v => v.compliant == true) : false
		},

		areaById() {
			const initialValue = {}
			return this.instrumentareas.reduce((obj, item) => {
				return {
					...obj,
					[item['id']]: item,
				}
			}, initialValue)
		},

		checklistCertified() {
			return this.checklist.certified_at || this.checklist.expired ? true : false            
		},

		hasVehicle() {
			return (this.checklist.shift && this.checklist.shift.vehicle && this.checklist.shift.vehicle.id) ? true : false            
		},

		showCondition(condition) {
			return (condition) => {
				return !(this.hideValid && this.checklistValueByChecklistconditionId[condition.id].compliant)
			}
		},

		instrumentInCompartmentById() {
			let result = {}
			_.forEach(this.compartments, (compartment, key) => {
				_.forEach(compartment.instruments, (instrument, k) => {
					if(result[instrument.id]) {
						//result[instrument.id]
					} else {
						result[instrument.id] = {'compartment': compartment.name, 'id': compartment.id}
					}
				})
			})
			return result
		},

		grouppedCondition() {
			let result = {}
			if(this.group == 'area') {
				_.forEach(this.checklist.checklisttype.checklist_conditions, (condition, key) => {
					if(!this.areaById[condition.instrument.instrumentarea_id]) {
						if(result['no_area']) {
							result.no_area.checklist_conditions.push(condition)
						} else {
							result.no_area = {'name': 'Nessuna', 'checklist_conditions': [condition]}
						}
					} else {
						if(result[condition.instrument.instrumentarea_id]) {
							result[condition.instrument.instrumentarea_id].checklist_conditions.push(condition)
						} else {
							result[condition.instrument.instrumentarea_id] = {'name': this.areaById[condition.instrument.instrumentarea_id].name, 'checklist_conditions': [condition]}
						}
					}
				})
			} else if(this.group == 'compartment') {
				_.forEach(this.checklist.checklisttype.checklist_conditions, (condition, key) => {
					if(!this.instrumentInCompartmentById[condition.instrument_id]) {
						if(result['no_compartment']) {
							result.no_compartment.checklist_conditions.push(condition)
						} else {
							result.no_compartment = {'name': 'Nessuno', 'checklist_conditions': [condition]}
						}
					} else {
						if(result[this.instrumentInCompartmentById[condition.instrument_id].id]) {
							result[this.instrumentInCompartmentById[condition.instrument_id].id].checklist_conditions.push(condition)
						} else {
							result[this.instrumentInCompartmentById[condition.instrument_id].id] = {'name': this.instrumentInCompartmentById[condition.instrument_id].compartment, 'checklist_conditions': [condition]}
						}
					}
				})
			}
			return result
		},

	},

	mounted() {
		if(this.id) {
			this.axios.get('checklists/'+this.id).then(response => {
				this.checklist = response.data.data.checklist
				this.instrumentareas = response.data.data.instrumentareas
				this.compartments = response.data.data.compartments
				this.init()
				this.loading = false
			}).catch(error => {
				if(error.response.data.message) {
					this.$eventBusUtility.navigateTo('home')
				}
			})              
		} else {
			this.axios.get('current-checklist').then(response => {
				this.checklist = response.data.data.checklist
				this.instrumentareas = response.data.data.instrumentareas
				this.compartments = response.data.data.compartments
				this.init()
				this.loading = false
			}).catch(error => {
				if(error.response.data.message) {
					this.$eventBusUtility.navigateTo('home')
				}
			})
		}
	},

	beforeDestroy() {
		this.$echo.leave(`organization.${this.$store.getters.getCurrentOrganization.id}.checklist.${this.checklist.id}`)
	},

	methods: {

		init() {
			const initialValue = {}
			this.checklistValueByChecklistconditionId = this.checklist.checklist_values.reduce((obj, item) => {
				return {
					...obj,
					[item['checklist_condition_id']]: item,
				}
			}, initialValue)

			this.connectWS()
		},

		decrement(condition) {
			this.checklistValueByChecklistconditionId[condition.id].value--
			this.updateCondition(condition.id)
		},
        
		increment(condition) {
			this.checklistValueByChecklistconditionId[condition.id].value++
			this.updateCondition(condition.id)
		},

		certifyChecklist() {
			this.$refs.checklistFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				this.axios.post('certify-checklist/' + this.checklist.id, {...this.model}).then(response => {
					this.loading = false
					this.closeChecklistDialog = false
				}, response => {
					// error callback
					this.loading = false
				})

			}).catch(() => {

			})
		},

		connectWS() {
			// WS Listeners
			this.$echo.private(`organization.${this.$store.getters.getCurrentOrganization.id}.checklist.${this.checklist.id}`)
				.listen('.ChecklistValueUpdated', (event) => {
					this.checklistValueByChecklistconditionId[event.checklist_value.checklist_condition_id].note = event.checklist_value.note
					this.checklistValueByChecklistconditionId[event.checklist_value.checklist_condition_id].value = event.checklist_value.value
					this.checklistValueByChecklistconditionId[event.checklist_value.checklist_condition_id].compliant = event.checklist_value.compliant
				})
				.listen('.ChecklistCertified', (event) => {
					this.$set(this.checklist, 'compliant', event.checklist.compliant)
					this.$set(this.checklist, 'person_id', event.checklist.person_id)
					this.$set(this.checklist, 'certified_at', event.checklist.certified_at)
				})
		},

		updateCondition(condition_id) {
			if(this.timeouts[condition_id]) clearTimeout(this.timeouts[condition_id])

			this.timeouts[condition_id] = setTimeout(() => {
				this.axios.post('checklist_values', {
					checklist_id: this.checklist.id,
					checklist_condition_id: condition_id,
					value: this.checklistValueByChecklistconditionId[condition_id].value ?? null,
					note: this.checklistValueByChecklistconditionId[condition_id].note ?? null,
				})
			}, 900)
		},
	},
}
</script>

<style lang="scss">
@import 'src/scss/_variables.scss';
 .checklistvalue .v-text-field__slot input {
     text-align: center;
     font-weight: bold !important;
 }

 .checklistvalue.valid-condition input {
     color: $green-volontapp !important;
 }

 .checklistvalue input {
     color: $red-volontapp !important;
 }
</style>