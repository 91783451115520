<template>
    <v-col class="pa-0 px-1">
        <v-subheader>Calendari
			<v-spacer></v-spacer>
			<v-btn fab dark class="mb-n8" style="z-index: 2" small color="primary" @click="create()" v-if="calendars.length < $store.getters.getCurrentOrganization.calendars">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-subheader>
        <v-data-iterator
            :items="calendars"
            disable-pagination
            hide-default-footer>
            <template v-slot:default="{ items }">

                <template v-for="(item, o) in items">

                    <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o">
                        <div class="pa-2 d-inline-flex" style="width: 100%">
                            
                            <div style="width: 50px; vertical-align: middle; margin: auto;">
                                <v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
                                    <span class="font-weight-bold">{{ item.order }}</span>
                                </v-avatar>			
                            </div>

                            <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                                <div>
                                    <div class="body-1">{{ item.name }}</div>
                                    <div :class="$eventBusUtility.calendarType(item.type)" style="font-size: 13px">
                                        <v-icon :size="10" style="color: inherit; margin-bottom: 2px;">fiber_manual_record</v-icon><span> {{  item.type | trans }}</span><v-icon :size="16" class="pl-2" v-if="item.employees_only">mdi-briefcase-outline</v-icon>
                                    </div>
									<template v-if="$store.getters.checkSetting('calendar', 'calendar_group')">
										<div class="caption" v-if="item.calendargroup"><v-icon :size="16" class="pl-2">mdi-calendar-blank-multiple</v-icon> {{item.calendargroup.name}}</div>
										<div class="caption" v-else><v-icon :size="16" class="pl-2" color="error">mdi-close</v-icon> Nessun gruppo</div>
									</template>
                                </div>
                            </div>
                            

                            <actions-menu
                                :item="item"
                                :actions="actions"
                            ></actions-menu>
                        </div>

                    </v-card>
                </template>

            </template>
        </v-data-iterator>


      	<v-dialog
			v-model="editorDialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters class="d-block">

                        <template v-if="confirmDelete">
                            <div class="pl-0 font-weight-bold">Elimina Calendario</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold">Modifica Calendario</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Calendario</div>
                        </template>

					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
					
						<calendar-fields
							ref="calendarEditor"
							:model="model"
							:calendargroups="calendargroups"
						></calendar-fields>

					</v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption">Sei sicuro di voler eliminare questo Calendario?</div>
                        </v-row>
                    </v-container>
				</v-card-text>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
					<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
				</v-card-actions>
			</v-card>
		
		</v-dialog>
    </v-col>
</template>

<script>
import CalendarFields from '@/components/modules/settings/CalendarFields'

export default {
	name: 'calendar-crud',
	components: {
		CalendarFields
	},
	props: {
		calendargroups: Array
	},	
	data() {
		return {
			model: {},
			fab: null,
			calendars: [],
			loading: false,
			editorDialog: false,
			confirmDelete: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editCalendar'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyCalendar'
				},
			],

		}
	},

	methods: {

		getData() {
			this.loading = true
			this.axios.get('calendars').then(response => {
				this.calendars = response.data.data
				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		closeEditor() {
			this.editorDialog = false
			this.confirmDelete = false
			this.model = _.stubObject()
			this.$refs.calendarEditor ? this.$refs.calendarEditor.$refs.fields.reset() : null
		},

		edit(item) {
			this.model = {...item}
			this.loading = false
			this.editorDialog = true
		},

		create() {
			this.editorDialog = true
		},

		destroy() {
			this.loading = true
			this.axios.delete('calendars/'+ this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		save() {
			this.$refs.calendarEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('calendars/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('calendars', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		}
	},

	created() {
		this.getData()
	},

	mounted() {
		this.$eventBusUtility.$on('editCalendar', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyCalendar', (item) => {
			this.confirmDestroy(item)
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('editCalendar')
		this.$eventBusUtility.$off('destroy')
	},    
}
</script>