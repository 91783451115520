<template>
	<v-row no-gutters class="pa-0 fill-height">
        <v-tabs-items v-model="tab" touchless class="fill-height" style="width: 100%">
            <v-tab-item value="tab-1" class="fill-height">
                <attendances-crud>
                </attendances-crud>
            </v-tab-item>
        </v-tabs-items>
    </v-row>
</template>

<script>
import AttendancesCrud from '@/components/modules/attendances/AttendancesCrud'

export default {
	name: 'attendances-index',
	components: {
		AttendancesCrud
	},
	data() {
		return {
			tab: 'tab-1',
		}
	},
}
</script>