<template>
    <v-timeline-item fill-dot :small="$vuetify.breakpoint.smAndDown" class="pb-2">
        <template v-slot:icon>
            <v-avatar v-if="comment.person && comment.person.avatar" :size="$vuetify.breakpoint.smAndDown ? 24 : 30">
                <v-img :src="comment.person.avatar"></v-img>
            </v-avatar>
            <v-avatar v-else>
                <span class="white--text" style="line-height: 2">{{ comment.person ? comment.person.name[0] : '--' }}</span>
            </v-avatar>
        </template>
        <v-card elevation="2" shaped>
            <v-card-text class="px-0 py-3">
                <v-container fluid class="py-0">
                    <v-row>
                        <v-col cols="9" class="text-subtitle-2 font-weight-bold pb-0">
                            {{comment.person ? comment.person.full_name : '--'}} - 
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                    v-bind="attrs"
                                    v-on="on"
                                    >{{moment(comment.created_at).fromNow()}}</span>
                                </template>
                                <span>{{moment(comment.created_at).format('dddd d MMMM YYYY [ore] HH:mm')}}</span>
                            </v-tooltip>
                        </v-col>

                        <v-col cols="3" class="text-right py-0">
                            <v-btn v-if="$store.getters.isAuth && ((comment.person && comment.person.id == $store.getters.getUserPerson.id) || $store.getters.checkPermission('manage_other_comments'))" icon>
                                <v-menu
                                    left
                                    close-on-click
                                    bottom
                                    rounded="lg"
                                    :nudge-width="200"
                                    :nudge-bottom="35">                            
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        color="primary"
                                        dark
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        <v-icon color="grey lighten-1">mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense>
                                        <v-list-item @click="editComment = true">
                                            <v-list-item-icon>
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Modifica</v-list-item-title>
                                            </v-list-item-content>                                        
                                        </v-list-item>
                                        <v-list-item @click="confirmDeleteComment = true">
                                            <v-list-item-icon>
                                                <v-icon>mdi-delete</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Elimina</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0">
                            <template v-if="editComment">
                                <v-text-field 
                                    v-if="editComment"
                                    label="Modifica Commento"
                                    outlined
                                    v-model="comment.text"
                                    hide-details
                                    dense
                                    append-icon="mdi-send"
                                    @keyup.enter="updateComment"
                                    @click:append="updateComment"
                                ></v-text-field>
                            </template>
                            <p v-else>{{comment.text}}</p>
                            <p v-if="!isReply"><v-btn text small color="primary" @click="showRepliesBox()">{{comment.comments.length}} Risposta/e</v-btn></p>
                        </v-col>
                        
                    </v-row>
                    
                    <v-expand-transition>
                        <v-row>
                            <v-col cols="12" class="pa-0 pr-1">
                                <v-card v-show="showReplies" elevation="0">
                                    <v-divider class="mx-4"></v-divider>
                                    <v-card-text class="px-0">
                                        <post-comments v-if="!isReply && showReplies" :is-reply="true" :post-id="postId" :comment-id="comment.id" :comments="comment.comments"></post-comments>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                </v-container>
            </v-card-text>
        </v-card>

        <confirm-dialog
            v-model="confirmDeleteComment"
            mainTitle="Eliminare il commento?"
            secondTitle="Una volta eliminato non sarà possibile ripristinarlo."
            @confirm="deleteComment"
        ></confirm-dialog>
    </v-timeline-item>
</template>

<script>

export default {

	name: 'post-comment',

	components: {
		'post-comments': () => import('./PostComments')
	},

	props: {
		comment: Object,
		postId: Number,
		isReply: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			confirmDeleteComment: false,
			editComment: false,
			showReplies: false,
			newReply: false,
			newComment: ''
		}
	},

	methods: {

		deleteComment() {
			this.axios.delete('comments/'+this.comment.id).then(response => {
				if(response.data.success) {
					this.$store.commit('SET_SNACKBAR', {
						timeout: 6000,
						text: 'Commento eliminato con successo',
						value: true,
						color: 'success'
					})
				}
			})
		},

		updateComment() {
			if(this.comment.text) {
				this.axios.post('comments/'+this.comment.id, {_method: 'PUT', text: this.comment.text}).then(response => {
					this.editComment = false
				})
			}
		},
        
		showRepliesBox() {
			this.showReplies = !this.showReplies
			setTimeout(() => { 
				this.$eventBusUtility.$emit('redrawVueMasonry')
			}, 20)
		},
		
	},
}
</script>