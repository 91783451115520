import Vue from 'vue'
import Vuex from 'vuex'
import global from '@/store/modules/global'
import user from '@/store/modules/user'
import auth from '@/store/modules/auth'
// import network from './modules/network'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		user,
		auth,
		global,
		// network,
	},

	actions: {
		resetStore: ({commit}) => {
			commit('SET_AUTH_DEFAULT')
			commit('SET_GLOBAL_DEFAULT')
			commit('SET_USER_DEFAULT')
		}
	},
	strict: debug,
})