<template>
    <validation-observer ref="fields">

        <v-row class="pb-2 mx-0 pt-2">
            <v-col class="col-12 py-0 px-6 pt-4">
                <validation-provider name="Nome" rules="max:255" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Calendario" rules="required" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Calendario*"
                        v-model="model['calendar_id']"
                        item-value="id"
                        item-text="name"
                        :error-messages="errors"
                        clearable
                        small-chips
                        :items="info.calendars"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Tipo Turno" rules="required" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Tipo Turno*"
                        v-model="model['shifttype_id']"
                        item-value="id"
                        item-text="name"
                        :error-messages="errors"
                        clearable
                        small-chips
                        :items="info.shifttypes"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-menu
                    ref="started_at"
                    :close-on-content-click="false"
                    v-model="datepicker_menu['started_at']"
                    :return-value.sync="model['started_at']"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :nudge-right="40"
                    style="width: 100%">
                    <template v-slot:activator="{ on }">
                        <validation-provider name="started_at" rules="required" v-slot="{ errors }">
                            <v-text-field
                                v-on="on"
                                label="Data Inizio Turno*"
                                :error-messages="errors"
                                append-icon="event"
                                readonly
                                :value="$eventBusUtility.formatDate(model['started_at'])"
                                outlined
                                @click:clear="model['started_at'] = ''"
                                dense
                            ></v-text-field>
                        </validation-provider>
                    </template>
                    <v-date-picker 
                        v-model="model['started_at']"
                        color="primary"
                        locale="It"
                        scrollable
                        :first-day-of-week="1"
                        @input="$refs['started_at'].save(model['started_at'])">
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="start_time" rules="required|is_time" v-slot="{ errors }">
					<v-text-field
						label="Ora di Inizio*"
						v-model="model['start_time']"
                        :error-messages="errors"
						outlined
						dense
						v-mask="'##:##'"
						return-masked-value						
						ref="start_time"
					></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-menu
                    ref="ended_at"
                    :close-on-content-click="false"
                    v-model="datepicker_menu['ended_at']"
                    :return-value.sync="model['ended_at']"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :nudge-right="40"
                    style="width: 100%">
                    <template v-slot:activator="{ on }">
                        <validation-provider name="ended_at" rules="required|date_interval:@started_at,@start_time,@end_time" v-slot="{ errors }">
                            <v-text-field
                                v-on="on"
                                label="Data Fine Turno*"
                                :error-messages="errors"
                                append-icon="event"
                                readonly
                                :value="$eventBusUtility.formatDate(model['ended_at'])"
                                outlined
                                @click:clear="model['ended_at'] = ''"
                                dense
                            ></v-text-field>
                        </validation-provider>
                    </template>
                    <v-date-picker 
                        v-model="model['ended_at']"
                        color="primary"
                        locale="It"
                        scrollable
                        :min="model['started_at']"
                        :first-day-of-week="1"
                        @input="$refs['ended_at'].save(model['ended_at'])">
                    </v-date-picker>
                </v-menu>
            </v-col>



            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="end_time" rules="required|is_time" v-slot="{ errors }">
					<v-text-field
						label="Ora di Fine*"
						v-model="model['end_time']"
                        :error-messages="errors"
						outlined
						dense
						v-mask="'##:##'"
						return-masked-value						
						ref="end_time"
					></v-text-field>
                </validation-provider>
            </v-col>


            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="$store.getters.checkModulePermissions('vehicles')">
                <validation-provider name="Veicolo" :rules="{'contemporary_vehicle': [model]}" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Veicolo"
                        v-model="model['vehicle_id']"
                        item-value="id"
                        item-text="name"
                        :error-messages="errors"
                        clearable
                        small-chips
                        :items="info.vehicles"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>


            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Punteggio" rules="required|integer|min_value:0" v-slot="{ errors }">
                    <v-text-field
                        type="number"
                        label="Punteggio*"
                        v-model="model['score']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="score"
                        ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 py-0 px-6 pt-3">
                <v-text-field
                    type="text"
                    label="Luogo"
                    v-model="model['location']"
                    outlined
                    dense
                    ref="location"
                ></v-text-field>
            </v-col>
        
            <v-col class="col-12 py-0 pt-3 px-6">
                <validation-provider name="Note" rules="max:255" v-slot="{ errors }">
                    <v-textarea
                        label="Note"
                        name="note"
                        v-model="model['note']"
                        :error-messages="errors"
                        type="text"
                        outlined
                        dense
                        counter
                        ref="note"
                        ></v-textarea>
                </validation-provider>
            </v-col>

            <v-col class="col-12 py-0 pt-3 px-6" v-if="$store.getters.checkModulePermissions('handbook')">
                <v-autocomplete class="font-weight-regular"
                    label="Comuni"
                    v-model="municipalities"
                    @change="changeMunicipalities($event)"
                    clearable
                    small-chips
                    multiple
                    :items="veMunicipalities"
                    dense
                    outlined>
                </v-autocomplete>
            </v-col>

            <v-col class="col-12 px-6 py-0" v-if="!model.id">
                <v-checkbox
                    label="Invia notifica a tutte le persone che possono partecipare al turno"
                    v-model="model['send_notifications']"
                    color="primary"
                    :input-value="[true, false]"
                ></v-checkbox>
            </v-col>
            <v-col class="col-12 px-6 py-0" v-if="!model.id">
                <v-checkbox
                    label="Ripeti"
                    v-model="model['recurrence']"
                    color="primary"
                    :input-value="[true, false]"
                ></v-checkbox>
            </v-col>


            <v-fade-transition v-if="!model.id">
                <v-row v-if="model['recurrence']" class="pa-0 mx-0">
                    <v-col class="col-12 col-sm-12 col-lg-6 px-6">
                        <v-menu
                            ref="repeat_until"
                            :close-on-content-click="false"
                            v-model="datepicker_menu['repeat_until']"
                            :return-value.sync="model['repeat_until']"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :nudge-right="40"
                            style="width: 100%">
                            <template v-slot:activator="{ on }">
                                <validation-provider name="Ripeti fino al" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        v-on="on"
                                        label="Ripeti fino al*"
                                        :error-messages="errors"
                                        append-icon="event"
                                        readonly
                                        :value="$eventBusUtility.formatDate(model['repeat_until'])"
                                        outlined
                                        @click:clear="model['repeat_until'] = ''"
                                        dense
                                    ></v-text-field>
                                </validation-provider>
                            </template>
                            <v-date-picker 
                                v-model="model['repeat_until']"
                                color="primary"
                                locale="It"
                                scrollable
                                :min="model.started_at"
                                :max="setMaxRepeatUntil"
                                :first-day-of-week="1"
                                @input="$refs['repeat_until'].save(model['repeat_until'])">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col class="col-12 col-sm-12 col-lg-6 px-6">
                        <validation-provider name="Ripeti Ogni" rules="required|integer|min_value:1" v-slot="{ errors }">
                            <v-text-field
                                type="number"
                                placeholder="Ripeti Ogni*"
                                v-model="model['repeat_each']"
                                :error-messages="errors"
                                outlined
                                min="1"
                                suffix="Giorni"
                                dense>
                            </v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col class="col-12 px-6 py-0">
                        <v-subheader class="pl-1">Ripetere se</v-subheader>
                        <v-row class="mx-0">
                            <v-checkbox
                                label="Lunedì"
                                v-model="repeat['monday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Martedì"
                                v-model="repeat['tuesday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Mercoledì"
                                v-model="repeat['wednesday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Giovedì"
                                v-model="repeat['thursday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Venerdì"
                                v-model="repeat['friday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Sabato"
                                v-model="repeat['saturday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Domenica"
                                v-model="repeat['sunday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Feriale"
                                v-model="repeat['weekday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Pre-Festivo"
                                v-model="repeat['preholiday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Festivo"
                                v-model="repeat['holiday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Sempre"
                                v-model="repeat['always']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                        </v-row>
                    </v-col>
                    <v-col class="col-12 px-6 py-0">
                        <v-subheader class="pl-1">Non Ripetere se</v-subheader>
                        <v-row class="mx-0 mb-6">
                            <v-checkbox
                                label="Lunedì"
                                v-model="not_repeat['monday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Martedì"
                                v-model="not_repeat['tuesday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Mercoledì"
                                v-model="not_repeat['wednesday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Giovedì"
                                v-model="not_repeat['thursday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Venerdì"
                                v-model="not_repeat['friday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Sabato"
                                v-model="not_repeat['saturday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Domenica"
                                v-model="not_repeat['sunday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Feriale"
                                v-model="not_repeat['weekday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Pre-Festivo"
                                v-model="not_repeat['preholiday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                            <v-checkbox
                                label="Festivo"
                                v-model="not_repeat['holiday']"
                                color="primary"
                                class="pr-2"
                                :input-value="[true, false]"
                            ></v-checkbox>
                        </v-row>
                    </v-col>


                </v-row>
            </v-fade-transition>            
        </v-row>
        
    </validation-observer>    
</template>

<script>

import Vue from 'vue'

import veMunicipalities from '@/components/utilities/veMunicipalities'
export default {
	name: 'shift-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
		info: Object,
	},
        
	data() {
		return {
			dialog: false,
			datepicker_menu: {},
			repeat: {},
			not_repeat: {},
			veMunicipalities: veMunicipalities(),
			municipalities: [],
            
		}
	},

	computed: {
		setMaxRepeatUntil() {
			if(this.model.started_at) {
				return moment(this.model.started_at).add(6, 'months').format('YYYY-MM-DD')
			} else {
				return moment().add(1, 'years').format('YYYY-MM-DD')
			}
		}
	},

	watch: {

		'model.started_at': {
			handler(newValue, oldValue) {
				if(!oldValue) {
					Vue.set(this.model, 'ended_at', newValue)
				} else if(this.model.started_at > this.model.ended_at) {
					Vue.set(this.model, 'ended_at', this.model.started_at)
				}
			},
			deep: true,
		},

		repeat: {
			handler(newValue, oldValue) {
				Vue.set(this.model, 'repeat', newValue)
			},
			deep: true,
		},

		not_repeat: {
			handler(newValue, oldValue) {
				Vue.set(this.model, 'not_repeat', newValue)
			},
			deep: true,
		},

	},

	methods: {
		// federapp
		changeMunicipalities(item) {
			if(item.length > 0) {
				_.forEach(item, (municipy, key) => {
					if(key == 0) {
						this.$set(this.model, 'note', 'Comuni di ' + municipy)
					} else {
						this.$set(this.model, 'note', this.model['note'] + ', ' + municipy)
					}
				})
			} else {
				this.$set(this.model, 'note', '')
			}
		}        
	},

	mounted() {
		if(!this.model.id) {
			this.model.score = 1
		}
		// federapp
		if(this.$store.getters.checkModulePermissions('handbook') && this.model.note) {
			let note = this.model.note

			if (note.indexOf('Comuni di') !== -1) {
				this.municipalities = note.replace('Comuni di ', '').split(', ')
			}
		}
	}

}
</script>

<style>

</style>