<template>
    <v-dialog
        v-model="dialogEditor"
        fullscreen
        hide-overlay
        transition="scroll-x-reverse-transition"
        scrollable
        style="overflow-x: hidden">
        <v-card tile>
            <v-card-title class="pa-6" style="position: fixed">
                <v-row no-gutters class="d-block">
                    <template v-if="confirmDelete">
                        <div class="pl-0 font-weight-bold">Elimina Aula</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                    </template>
                    <template v-else-if="model.id">
                        <div class="pl-0 font-weight-bold">Modifica Aula</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                    </template>
                    <template v-else>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuova Aula</div>
                    </template>
                </v-row>
            </v-card-title>
            <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                    <validation-observer ref="fields">
                        <v-row no-gutters>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-4">
                                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        type="text"
                                        label="Nome"
                                        v-model="model['name']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        ref="name"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </validation-observer>
                </v-container>

                <v-container v-else>
                    <v-row class="mx-0 justify-center pt-6">
                    <div class="caption">Sei sicuro di voler eliminare questa Aula?</div>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                        </template>
                    <span>Chiudi</span>
                </v-tooltip>

                <v-btn v-if="model.id && confirmDelete == false" color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">Elimina</v-btn>

                <v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
                <v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
                <v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
	name: 'room-crud',
	data() {
		return {
			fab: null,
			model: {},
			search: '',
			totalItems: 0,
			dialog: false,
			loading: false,
			dialogEditor: false,
			confirmDelete: false,
		}
	},

	methods: {
		closeDialog() {
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(item) {
			this.dialogEditor = true
			this.model = {...item}
		},

		show(item) {					
			this.dialogEditor = true
			this.model = {...item}
		},

		create() {
			this.dialogEditor = true
		},

		confirmDestroy() {
			this.dialogEditor = true
			this.confirmDelete = true
		},        

		destroy() {
			this.axios.delete('rooms/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'room')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},

		save() {
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('rooms/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'room')
						this.closeDialog()
					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('rooms', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'room')
						this.closeDialog()

					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})                
		},
	},

	mounted() {
		this.$eventBusUtility.$on('editRoom', (item) => {
			this.edit(item)
		}),
               
		this.$eventBusUtility.$on('createRoom', () => {
			this.create()
		})
            
		this.$eventBusUtility.$on('destroyRoom', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('editRoom')
		this.$eventBusUtility.$off('createRoom')
		this.$eventBusUtility.$off('destroyRoom')
	},
}
</script>

<style>

</style>