<template>
	<v-row no-gutters class="pa-0 fill-height">
        <v-tabs-items v-model="tab" touchless class="fill-height" style="width: 100%">
            <v-tab-item value="tab-1" class="fill-height">

                <analytic-table 
                    v-if="info"
                    ref="analytics-count"
                    type="count"
                    :info="info">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="tab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Turni
                            </v-tab>

                            <v-tab href="#tab-2">
                                Punti
                            </v-tab>

                            <v-tab href="#tab-3">
                                Ore
                            </v-tab>

                        </v-tabs>
                    </template>
                </analytic-table>
            </v-tab-item>

            <v-tab-item value="tab-2" class="fill-height">
                <analytic-table
                    v-if="info"
                    ref="analytics-scores"
                    type="scores"
                    :info="info">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="tab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Turni
                            </v-tab>

                            <v-tab href="#tab-2">
                                Punti
                            </v-tab>

                            <v-tab href="#tab-3">
                                Ore
                            </v-tab>

                        </v-tabs>
                    </template>
                </analytic-table>
            </v-tab-item>

            <v-tab-item value="tab-3" class="fill-height">
                <analytic-table 
                    v-if="info"
                    ref="analytics-hours"
                    type="hours"
                    :info="info">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="tab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Turni
                            </v-tab>

                            <v-tab href="#tab-2">
                                Punti
                            </v-tab>

                            <v-tab href="#tab-3">
                                Ore
                            </v-tab>

                        </v-tabs>
                    </template>
                </analytic-table>
            </v-tab-item>

        </v-tabs-items>
    </v-row>
</template>

<script>

import AnalyticTable from '@/components/modules/analytics/AnalyticTable'

export default {
	name: 'analytic-index',
	components: {
		AnalyticTable,
	},

	data() {
		return {
			tab: 'tab-1',
			info: null
		}
	},

	created() {

		// Get info data for model
		this.axios.get('analytics-info').then(response => {
			// get info data
			this.info = response.data.info
			this.info.groups.unshift({'id':'-1', 'name': 'Nessun gruppo'})

			this.$store.commit('END_LOADING')

		}, response => {
			// error callback
			this.$store.commit('END_LOADING')
		})

	},    
}
</script>