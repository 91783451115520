<template>
    <validation-observer ref="fields">
        <v-row class="pb-2 mx-0 pt-4">
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 d-flex">
                <validation-provider tag="div" name="Colore" rules="required" v-slot="{ errors }" class="d-block" style="width: 100%">
                    <div class="d-flex" style="width: 100%">
                        <v-icon v-model="model.color" :style="{'color': model.color}">{{ model.color ? 'mdi-checkbox-blank' : 'mdi-checkbox-blank-off-outline' }}</v-icon>
                        <div class="ma-auto ml-2">Colore</div>

                        <v-btn icon @click="openDialog('color')">
                            <v-icon>mdi-palette</v-icon>
                        </v-btn>
                    </div>

                    <div class="v-text-field__details" v-show="errors[0]">
                        <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">{{ errors[0] }}</div>
                            </div>
                        </div>
                    </div>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0 d-flex pt-6">
                <validation-provider tag="div" name="Icona" rules="required" v-slot="{ errors }" class="d-block" style="width: 100%">
                    <div class="d-flex" style="width: 100%">
                        <v-text-field v-show="false" type="hidden" v-model="model.icon"></v-text-field>
                        <v-icon>{{ model.icon ? model.icon : 'mdi-checkbox-blank-off-outline' }}</v-icon>
                        <div class="ma-auto ml-2">Icona</div>
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">                             
                                <v-btn v-on="{ ...tooltip }" icon @click="openDialog('icon')">
                                    <v-icon>mdi-emoticon-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Selezione Icona</span>
                        </v-tooltip>
                    </div>

                    <div class="v-text-field__details" v-show="errors[0]">
                        <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">{{ errors[0] }}</div>
                            </div>
                        </div>
                    </div>
                </validation-provider>
            </v-col>
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-checkbox
                    label="Escludi da Statistiche"
                    v-model="model['exclude_statistics']"
                    color="primary"
                    :input-value="[true, false]"
                    ref="exclude_statistics"
                ></v-checkbox>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-autocomplete class="font-weight-regular"
                    label="Mansione Padre"
                    v-model="model['parent_id']"
                    clearable
                    small-chips
                    :items="tasks"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined>
                </v-autocomplete>
            </v-col>

            <v-expand-transition>
                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0" v-show="model['parent_id']">
                    <v-checkbox
                        label="Ereditabile"
                        v-model="model['heritable']"
                        color="primary"
                        :input-value="[true, false]"
                    ></v-checkbox>
                </v-col>
            </v-expand-transition>
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0">
                <v-checkbox
                    label="Mansione Virtuale"
                    v-model="model['virtual']"
                    color="primary"
                    :input-value="[true, false]"
                ></v-checkbox>
            </v-col>
            <v-expand-transition>
                <v-col v-show="model['virtual']" class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                    <v-autocomplete class="font-weight-regular"
                        label="Mansioni Richieste"
                        v-model="model['subtask_task_ids']"
                        clearable
                        small-chips
                        item-text="name"
                        item-value="id"
                        :items="tasks"
                        multiple
                        dense
                        outlined>
                    </v-autocomplete>
                </v-col>
            </v-expand-transition>
            <v-expand-transition>
                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0" v-show="model.subtask_task && model.subtask_task.length > 0">
                    <v-checkbox
                        label="Tutte Richieste"
                        v-model="model['required_all']"
                        color="primary"
                        :input-value="[true, false]"
                    ></v-checkbox>
                </v-col>
            </v-expand-transition>
            <v-col class="col-12 py-0 pt-3 px-6">
                <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                    <v-textarea
                        label="Descrizione"
                        name="description"
                        v-model="model['description']"
                        :error-messages="errors"
                        type="text"
                        outlined
                        dense
                        counter
                        ref="description"
                    ></v-textarea>
                </validation-provider>
            </v-col>
        </v-row>

        <v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters>

						<v-col class="col-12 ma-auto ml-0">
							<div v-if="dialogType == 'color'" class="pl-0 font-weight-bold" style="font-size: 30px">Seleziona Colore</div>
							<div v-else class="pl-0 font-weight-bold" style="font-size: 30px">Seleziona Icona</div>
							<div class="pl-0 font-weight-bold"></div>
						</v-col>
					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 80px" class="body-page">
					<v-container class="px-0 justify-center pt-6" fluid>

                        <v-item-group mandatory v-if="dialogType == 'color'">
                            <v-row>
                                <v-col class="col-4" v-for="(color, c) in info.colors" :key="c" @click="setColor(c)">
                                    <v-item>
                                        <v-icon
                                            :size="42"
                                            :color="color"
                                            class="d-flex align-center ma-auto">
                                            {{ model.color == c ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}
                                        </v-icon>
                                    </v-item>
                                </v-col>
                            </v-row>
                        </v-item-group>   

                        <div v-else>
                            <v-item-group mandatory>
                                <v-row>
                                    <v-col class="col-4" v-for="(icon, i) in info.icons" :key=i @click="setIcon(i)">
                                        <v-item>
                                            <v-icon
                                                :size="36"
                                                :color="model.icon == i ? 'primary' : ''"
                                                class="d-flex align-center ma-auto">
                                                {{ icon }}
                                            </v-icon>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </div>

					</v-container>
				</v-card-text>
                    
				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="secondary" min-width="200px"  outlined height="40" class="ml-4" @click="dialog = false">
								Chiudi
							</v-btn>
						</template>
						Crea
					</v-tooltip>
				</v-card-actions>
			</v-card>            

        </v-dialog>
    </validation-observer>    
</template>

<script>
import Vue from 'vue'

export default {
	name: 'task-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
		info: Object,
	},
        
	data() {
		return {
			dialog: false,
			dialogType: null,
		}
	},

	watch: {

		model: {
			handler: function(newValue, oldValue) {
				if(!newValue.subtask_task || newValue.subtask_task.length == 0) {
					this.model.required_all = false
				}
				if(!newValue.parent_id) {
					this.model.heritable = false
				}
			},
			deep: true,
		},

	},        

	computed: {
		tasks() {
			if(this.model.id) {
				return this.info.tasks ? this.info.tasks.filter((task) => {
					return task.id != this.model.id
				}) : []
			} else {
				return this.info.tasks
			}
		}
	},
        
	methods: {

		setIcon(i) {
			Vue.set(this.model, 'icon', this.info.icons[i])
		},

		setColor(c) {
			Vue.set(this.model, 'color', c)
		},

		openDialog(type) {
			this.dialog = true
			this.dialogType = type
		}

	}
}
</script>

<style>

</style>