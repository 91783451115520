<template>
	<div>
		<v-bottom-sheet v-model="sheet">
			<v-list class="pb-0">
				<v-list-item @click="launchFilePicker('camera')">
					<v-list-item-avatar>
						<v-avatar size="32px" item>
							<v-icon>camera</v-icon>
						</v-avatar>
					</v-list-item-avatar>
					<v-list-item-title>CAMERA</v-list-item-title>
				</v-list-item>
				<v-list-item @click="launchFilePicker('library')">
					<v-list-item-avatar>
						<v-avatar size="32px" item>
							<v-icon>image</v-icon>
						</v-avatar>
					</v-list-item-avatar>
					<v-list-item-title>GALLERIA</v-list-item-title>
				</v-list-item>
				<v-divider></v-divider>
				<v-list-item class="red white--text" @click="sheet = false">
					<v-list-item-avatar>
						<v-avatar size="32px" item>
							<v-icon color="white">close</v-icon>
						</v-avatar>
					</v-list-item-avatar>
				<v-list-item-title>CHIUDI</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-bottom-sheet>

	<!-- slot for parent component to activate the file changer -->
	<div @click="clickAvatar">
		<slot name="activator" :preview="preview"></slot>
	<!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
		<input type="file"
		accept="image/*"
		capture
		ref="file"
		@input="previewImage"
		style="display:none">
	</div>

	<!-- error dialog displays any potential error messages -->
	<v-dialog v-model="errorDialog" max-width="300">
		<v-card>
			<v-card-text class="subheading">{{errorText}}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="errorDialog = false" text>OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<div id="canvas"></div>

</div>
</template>

<script>
export default {
	name: 'image-input',
	data() {
		return {			
			errorDialog: null,
			errorText: '',
			sheet: false,
			preview: null,
			image: null,
		}
	},

	props: {
		value: File,
	},

	methods: {
		previewImage() {
			const input = this.$refs.file
			const files = input.files
			if (files && files[0]) {
				const reader = new FileReader
				reader.onload = e => {
					this.preview = e.target.result
				}
				reader.readAsDataURL(files[0])
				this.$emit('input', files[0])
			}
		},

		clickAvatar(){
			if(typeof(cordova) == 'object'){
				this.sheet = true
			} else {
				this.$refs.file.click()
			}
		},
		launchFilePicker(source){
			this.sheet = false
			let sourceType = ''
			if(source == 'camera')
				sourceType = Camera.PictureSourceType.CAMERA
			else if(source == 'library')
				sourceType = Camera.PictureSourceType.PHOTOLIBRARY
			else return false

			navigator.camera.getPicture((response) => {
				this.optimizeDataURL('data:image/png;base64,'+response)

			}, (error) => {
				this.errorText = error
				this.errorDialog = true
			}, {
				quality: 20,
				destinationType: Camera.DestinationType.DATA_URL,
				sourceType: sourceType,
				allowEdit: true,
				correctOrientation: true
			})
		},
	}
}
</script>