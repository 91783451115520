<template>
	<v-row no-gutters class="pa-0 fill-height">
        <v-tabs-items v-model="tab" touchless class="fill-height" style="width: 100%">
        	
			<v-tab-item value="tab-1" class="fill-height">
				<checklists-crud :element="element">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="tab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Checklist
                            </v-tab>

                            <v-tab href="#tab-2">
                                Tipi Checklist
                            </v-tab>

                        </v-tabs>
                    </template>
				</checklists-crud>
            </v-tab-item>

			<v-tab-item value="tab-2" class="fill-height">
				<checklisttypes-crud>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="tab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Checklist
                            </v-tab>

                            <v-tab href="#tab-2">
                                Tipi Checklist
                            </v-tab>

                        </v-tabs>
                    </template>
				</checklisttypes-crud>
            </v-tab-item>

		</v-tabs-items>
	</v-row>
</template>

<script>

import ChecklistsCrud from '@/components/modules/checklists/ChecklistsCrud'
import ChecklisttypesCrud from '@/components/modules/checklists/ChecklisttypesCrud'

export default {
	name: 'checklists-index',
	components: {
		ChecklistsCrud,
		ChecklisttypesCrud
	},
	props: {
		element: {
			type: Number,
			default: null
		},
	},
	data() {
		return {
			tab: 'tab-1',
		}
	},

}
</script>