<template>
	<div class="col-lg-4 col-md-4 col-12 pa-0 mx-0 col">
		<calendar-crud :calendargroups.sync="calendargroups"></calendar-crud>
		<calendargroup-crud v-if="$store.getters.checkSetting('calendar', 'calendar_group')" :calendargroups.sync="calendargroups"></calendargroup-crud>
	</div>
</template>

<script>
import CalendarCrud from './CalendarCrud'
import CalendargroupCrud from './CalendargroupCrud'

export default {
	name: 'calendars-crud',
	components: {
		CalendarCrud,
		CalendargroupCrud,
	},
	data() {
		return {
			calendargroups: [],
		}
	},	
}
</script>