<template>
    <validation-observer ref="fields">
        <v-row class="pb-2 mx-0 pt-3">
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome*"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Ordine" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Ordine*"
                        v-model="model['order']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="order"
                    ></v-text-field>
                </validation-provider>
            </v-col>


            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Tipo" rules="required" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Tipo*"
                        v-model="model['type']"
                        clearable
                        small-chips
                        :error-messages="errors"
                        :items="[{'text': 'Standard', 'value': 'standard'},{'text': 'Disponibilità', 'value': 'availability'}]"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>
            
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="$store.getters.checkSetting('calendar', 'calendar_group')">
                <validation-provider name="Tipo" rules="required" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Gruppo Calendaro*"
                        v-model="model['calendargroup_id']"
                        clearable
                        small-chips
                        :error-messages="errors"
                        :items="calendargroups"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6">
                <v-checkbox
                    label="Visibile solo ai dipendenti"
                    v-model="model['employees_only']"
                    color="primary"
                    :input-value="[true, false]"
                    ref="employees_only"
                ></v-checkbox>
            </v-col>

            <!-- <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6">
                <v-checkbox
                    label="Altezza righe fissa"
                    v-model="model['fixed_row_height']"
                    color="primary"
                    :input-value="[true, false]"
                    ref="fixed_row_height"
                ></v-checkbox>
            </v-col> -->

        </v-row>

    </validation-observer>    
</template>

<script>

export default {
	name: 'calendar-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
		calendargroups: Array
	},
        
	data() {
		return {
			dialog: false,
			dialogType: null,
		}
	},
       

	computed: {

	},
        
	methods: {

		openDialog(type) {
			this.dialog = true
			this.dialogType = type
		}

	}
}
</script>

<style>

</style>