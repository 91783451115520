<template>
	<v-row no-gutters class="pa-0 fill-height">
		<v-container fluid class="pa-0">

            <v-tabs-items v-model="tab" touchless class="fill-height">
                <v-tab-item value="tab-1" class="fill-height">

                    <violations-crud>
                        <template slot="tabs">
                            <v-tabs
                                class="pt-4"
                                v-model="tab"
                                background-color="white"
                                show-arrows
                                :centered="!$vuetify.breakpoint.mdAndUp">
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab href="#tab-1">
                                    Infrazioni
                                </v-tab>

                                <v-tab href="#tab-2">
                                    Sezioni
                                </v-tab>

                            </v-tabs>
                        </template>
                    </violations-crud>
                </v-tab-item>

                <v-tab-item value="tab-2" class="fill-height">
                    <sections-crud>                    
                        <template slot="tabs">
                            <v-tabs
                                class="pt-4"
                                v-model="tab"
                                background-color="white"
                                show-arrows
                                :centered="!$vuetify.breakpoint.mdAndUp">
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab href="#tab-1">
                                    Infrazioni
                                </v-tab>

                                <v-tab href="#tab-2">
                                    Sezioni
                                </v-tab>

                            </v-tabs>
                        </template>
                    </sections-crud>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-row>
</template>

<script>

import SectionsCrud from '@/components/modules/handbook/SectionsCrud'
import ViolationsCrud from '@/components/modules/handbook/ViolationsCrud'

export default {
	name: 'vehicles-index',
	components: {
		SectionsCrud,
		ViolationsCrud,
	},
	data() {
		return {
			tab: 'tab-1',
		}
	},
}
</script>

<style>

</style>