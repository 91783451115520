<template>
	<v-row no-gutters class="fill-height">
        <v-col class="mx-0">
            <v-container fluid class="pa-0" style="position: fixed;">
                <v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters>

                            <v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold" style="font-size: 30px">Reset Password</div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
            </v-container>

            <v-container fluid class="pa-6 body-page px-2'" style="margin-top: 110px; height: 100%">
                <v-card :elevation="1" class="background" width="100%">
                    <v-card-text class="pa-0">
                        <v-container fluid class="px-0 pt-6 mx-0">
                            <v-row align="center" class="px-4 pt-8">
                                <validation-observer tag="div" ref="passwordFields" class="row mx-0 pt-4">
                                    <v-col class="col-12 px-6 py-0">
                                        <validation-provider name="Email" rules="email|required" v-slot="{ errors }">
                                            <v-text-field
                                                label="Email"
                                                name="email"
                                                v-model="model['email']"
                                                :error-messages="errors"
                                                ref="email"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0">
                                        <validation-provider name="Password" rules="confirmed:password_confirmation|min:8" v-slot="{ errors }">
                                            <v-text-field
                                                label="Password"
                                                name="password"
                                                v-model="model['password']"
                                                :error-messages="errors"
                                                :append-icon="e1 ? 'mdi-eye-outline' : 'eye-off-outline'"
                                                @click:append="() => {e1 = !e1}"
                                                :type="!e1 ? 'password' : 'text'"
                                                ref="password"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0">
                                        <validation-provider name="Ripeti Password" rules="required|min:8" v-slot="{ errors }" vid="password_confirmation">
                                            <v-text-field
                                                label="Ripeti Password"
                                                name="password_confirmation"
                                                v-model="model['password_confirmation']"
                                                :error-messages="errors"
                                                :append-icon="e2 ? 'mdi-eye-outline' : 'eye-off-outline'"
                                                @click:append="() => {e2 = !e2}"
                                                :type="!e2 ? 'password' : 'text'"
                                                outlined
                                                dense
                                                ref="password_confirmation"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </validation-observer>
                            </v-row>
                            <v-row class="px-4 py-8">
                                <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0 caption">
                                    <li>La tua password non può essere troppo simile alle altre tue informazioni personali.</li>
                                    <li>La tua password deve contenere almeno 8 caratteri.</li>
                                    <li>La tua password non può essere una password comunemente usata.</li>
                                    <li>La tua password non può essere interamente numerica.</li>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-6 pb-10 justify-center elevation-0">
                        <v-btn  color="background-1" fab small @click="$router.push({name: 'home'})">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                        <v-btn color="success" min-width="100px" :loading=loading height="40" @click="savePassword()">Salva Password</v-btn>
                    </v-card-actions>

                </v-card>

                <footer-logo></footer-logo>

            </v-container>

        </v-col>
    </v-row>
</template>

<script>

export default {
	name: 'reset-password',

	props: {
	},

	data() {
		return {
			token: '',
			e1: false,
			e2: false,
			model: {},
			loading: false,
		}
	},

	methods: {
		savePassword() {
			this.loading = true
			this.$refs.passwordFields.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				}                
				this.axios.post(this.$store.getters.getAuthUrl + '/reset/password', {...this.model, token: this.token}).then((response) => {
					this.loading = false
					this.$store.commit('SET_SNACKBAR', {
						timeout: 6000,
						text: 'Password Aggiornata',
						value: true,
						color: 'success'
					})
					this.$store.dispatch('authLogin', {email: this.model.email, password: this.model.password}).then(() => {
						this.loading = false
						this.$eventBusUtility.navigateTo('home')
					}).catch((response) => {
						this.loading = false
					})                    
				}, response => {
					// error callback
					this.loading = false
				})
			}).catch(() => {
				this.loading = false
			})                
		}
	},

	mounted() {

		this.token = this.$route.query.reset_token
		if(!this.token) {
			this.$eventBusUtility.navigateTo('home')
		}
	}
}
</script>

<style>

</style>