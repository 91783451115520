<template>
    <v-col class="col-lg-8 col-md-8 col-12 pa-0 mx-0">
		<v-subheader>Tipi Turno
			<v-spacer></v-spacer>
			<v-btn fab dark class="mb-n8" style="z-index: 2" small color="primary" @click="create()">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-subheader>
        <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel v-for="(item,i) in shifttypes" class="rounded-lg mb-2" :key="i">
                <v-expansion-panel-header style="height: 80px" class="pa-4">

                    <div style="width: 50px; vertical-align: middle; margin: auto;" class="mr-2">
                        <v-avatar size="36px" class="grey lighten-3 cursor-pointer">
                            <span class="font-weight-bold">{{ item.order }}</span>
                        </v-avatar>			
                    </div>

                    <div style="width: 100%">{{ item.name }}
                    <div v-if="item.checklist" style="font-size: 13px" class="pt-1">{{ item.checklist.name }}</div>
					</div>

                    <actions-menu
                        :item="item"
                        :actions="actions"
                    ></actions-menu>

                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Mansione
                                    </th>
                                    <th class="text-left">
                                        Minimo
                                    </th>
                                    <th class="text-left">
                                        Massimo
                                    </th>
                                    <th class="text-left">
                                        Richiesto
                                    </th>
                                    <th class="text-left">
                                        Sufficiente
                                    </th>
                                    <th class="text-left">
                                        Nascosto
                                    </th>
                                    <th class="text-left">
                                        Extra
                                    </th>
                                    <th class="text-left">
                                        Ignora Gerarchia
                                    </th>
                                    <th class="text-left">
                                        Azioni
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, s) in item.shifttypetasks" :key="s">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.min }}</td>
                                    <td>{{ item.max }}</td>
                                    <td>
                                        <div>
                                            <v-icon>{{ item.required == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                        </div>                                        
                                    </td>
                                    <td>
                                        <div>
                                            <v-icon>{{ item.sufficient == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                        </div>                                             
                                    </td>
                                    <td>
                                        <div>
                                            <v-icon>{{ item.hidden == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <v-icon>{{ item.extra == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <v-icon>{{ item.ignore_hierarchies == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                        </div>                                        
                                    </td>
                                    <td>
                                    <actions-menu
                                        :item="item"
                                        :actions="shifttypeTaskactions"
                                    ></actions-menu>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                    </v-simple-table>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

      	<v-dialog
			v-model="editorDialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters class="d-block">

                        <template v-if="confirmDelete">
                            <div class="pl-0 font-weight-bold">Elimina Tipo Turno</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold">Modifica Tipo Turno</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Tipo Turno</div>
                        </template>

					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
					
						<shifttype-fields
							ref="shifttypeEditor"
							:model="model"
							:info="info"
						></shifttype-fields>

					</v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <strong>ATTENZIONE!</strong>
							<div class="caption">&nbsp;Eliminando questo Tipo Turno, verranno eliminati anche tutti i turni collegati e le relative statistiche di presenza. Sei sicuro di voler eliminare questo Tipo Turno?</div>
                        </v-row>
                    </v-container>                    
				</v-card-text>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
					<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
				</v-card-actions>
			</v-card>
		
		</v-dialog>

      	<v-dialog
			v-model="taskDialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters class="d-block">

                        <template v-if="confirmShifttypeTaskDelete">
                            <div class="pl-0 font-weight-bold">Elimina Requisito dal Tipo Turno</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold">Modifica Requisito del Tipo Turno</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Requisito del Tipo Turno</div>
                        </template>

					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid v-if="!confirmShifttypeTaskDelete">
					
						<shifttypetask-fields
							ref="shifttypetaskEditor"
							:model="model"
							:info="info"
						></shifttypetask-fields>

					</v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questo Requisito in questo Tipo Turno?</div>
                        </v-row>
                    </v-container>                    
				</v-card-text>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="confirmShifttypeTaskDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroyShifttypeTask()">Conferma</v-btn>
					<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveTask()">Salva</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveTask()">Crea</v-btn>
				</v-card-actions>
			</v-card>
		
		</v-dialog>
    </v-col>
</template>

<script>
import ShifttypeFields from '@/components/modules/settings/ShifttypeFields'
import ShifttypetaskFields from '@/components/modules/settings/ShifttypetaskFields'

export default {
	name: 'shifttype-crud',
	components: {
		ShifttypeFields,
		ShifttypetaskFields
	},    
	data() {
		return {
			fab: null,
			info: {},
			model: {},
			panel: [],
			loading: false,
			shifttypes: [],
			disabled: true,
			editorDialog: false,
			taskDialog: false,
			confirmDelete: false,            
			confirmShifttypeTaskDelete: false,            
			actions: [
				{
					'icon': 'mdi-plus',
					'text': 'Aggiungi Requisito',
					'event': 'addShifttypeTask'
				},
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editShifttype'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyShifttype'
				},
			],
			shifttypeTaskactions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editShifttypeTask'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyShifttypeTask'
				},
			],
		}
	},

	methods: {

		getData() {

			this.loading = true

			this.axios.get('shifttypes').then(response => {
				this.shifttypes = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		getInfo() {
			this.axios.get('shifttypes-info').then(response => {
				this.info = response.data.info
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		closeEditor() {
			this.editorDialog = false
			this.taskDialog = false
			this.confirmDelete = false
			this.confirmShifttypeTaskDelete = false
			this.model = _.stubObject()
		},        
        
		editShifttype(item) {
			this.model = {...item}
			this.loading = false
			this.editorDialog = true
		},

		create() {
			this.editorDialog = true
		},

		addShifttypeTask(item) {
			this.loading = false
			this.taskDialog = true
			this.model.shifttype_id = item.id
   
		},

		editShifttypeTask(item) {
			this.model = {...item}
			this.loading = false
			this.taskDialog = true
		},

		save() {
			this.$refs.shifttypeEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('shifttypes/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('shifttypes', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},
        
		saveTask() {
			this.$refs.shifttypetaskEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('shifttypetasks/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('shifttypetasks', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true                    
		},

		destroy() {
			this.loading = true
			this.axios.delete('shifttypes/' + this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},

		confirmDestroyShifttypeTask(item) {
			this.model = {...item}
			this.confirmShifttypeTaskDelete = true
			this.taskDialog = true
		},

		destroyShifttypeTask() {
			this.loading = true
			this.axios.delete('shifttypetasks/'+ this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},

	},

	created() {
		this.getData()
		this.getInfo()
	},

	mounted() {
		this.$eventBusUtility.$on('addShifttypeTask', (item) => {
			this.addShifttypeTask(item)
		})

		this.$eventBusUtility.$on('editShifttype', (item) => {
			this.editShifttype(item)
		})

		this.$eventBusUtility.$on('destroyShifttype', (item) => {
			this.confirmDestroy(item)
		})

		this.$eventBusUtility.$on('destroyShifttypeTask', (item) => {
			this.confirmDestroyShifttypeTask(item)
		})

		this.$eventBusUtility.$on('editShifttypeTask', (item) => {
			this.editShifttypeTask(item)
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('addShifttypeTask')
		this.$eventBusUtility.$off('editShifttype')
		this.$eventBusUtility.$off('destroyShifttype')
		this.$eventBusUtility.$off('destroyShifttypeTask')
		this.$eventBusUtility.$off('editShifttypeTask')
	},   
}
</script>