<template>
    <div>
        <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" :style="{'margin-left': (step * 25) + 'px!important', overflow: 'hidden'}" @click="$eventBusUtility.$emit('showTask', item)">
            <div class="pa-2 d-inline-flex" style="width: 100%">
                
                <div style="width: 50px; vertical-align: middle; margin: auto;">
                    <v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
                        <v-icon :style="{'color': item.color}">{{ item.icon }}</v-icon>
                    </v-avatar>			
                </div>


                <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                    <div>
                        <div class="body-1">{{ item.name }}</div>
                        <div class="caption">{{ item.description }}</div>
                    </div>
                </div>

                <div class="pl-4 pr-2" style="vertical-align: middle; margin: auto">

                    <actions-menu v-if="$store.getters.checkPermission('manage_tasks')"
                        :item="item"
                        :actions="actions"
                    ></actions-menu>

                </div>

            </div>

        </v-card>

        <template v-if="item.children.length > 0">
            <task-row v-for="(child, index) in item.children" :key="'child_'+index" :item="child" :step="step+1"></task-row>
        </template>
    </div>
</template>

<script>
export default {
	name: 'task-row',
	components: {
		TaskRow: () => import('./TaskRow.vue')
	},
	props: {
		item: Object,
		step: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editTask'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyTask'
				},
			],
		}
	},
}
</script>