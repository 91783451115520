<template>
    <div class="ma-auto">
        <v-menu v-if="$vuetify.breakpoint.mdAndUp"
            right
            bottom
            rounded="lg"
            :nudge-width="200"
            :nudge-bottom="35">
            <template v-slot:activator="{ on, attrs }">
                <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list dense>
                <v-subheader v-if="showAction" class="pl-4">AZIONI</v-subheader>

                <template v-for="(action, a) in actions">
                    <v-list-item link @click="emitEvent(item, action)" :key="action.event + '_' + a" v-if="checkCondition(action, item)">
                        <v-list-item-icon>
                            <v-icon>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ action.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

            </v-list>
        </v-menu>

        <div class="text-center" v-else>
            <v-bottom-sheet v-model="sheet[item.id]">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
                <v-list dense class="pb-12">
                    <v-subheader class="pl-4">AZIONI</v-subheader>

                    <template v-for="(action, a) in actions">
                        <v-list-item link @click="emitEvent(item, action)" :key="action.event + '_s_' + a" v-if="checkCondition(action, item)">
                            <v-list-item-icon>
                                <v-icon>{{ action.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ action.text }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                </v-list>
            </v-bottom-sheet>
        </div>
    </div>
</template>
<script>
export default {
	name: 'actions-menu',
	props: {
		item: Object,
		actions: Array,
		showAction: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			sheet: {},
		}
	},

	methods: {

		checkCondition(action, item) {
			if(action.permission) {
				return this.$store.getters.checkPermission(action.permission)
			}
			if(action.condition_key) {
				if(!action.condition_value) {
					return item[action.condition_key]
				} else {

					if(!action.condition_operator) {
						return (item[action.condition_key] && item[action.condition_key] == action.condition_value)
					} else {
						switch (action.condition_operator) {
						case '!=':
							return (item[action.condition_key] && item[action.condition_key] != action.condition_value)
                            
						case 'in':
							return (item[action.condition_key] && action.condition_value.includes(item[action.condition_key]))
						}                     
					}
				}
			} else return true
		},

		emitEvent(item, action) {
			this.$eventBusUtility.$emit(action.event, item)
		},
	},
   
}
</script>