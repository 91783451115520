<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Tipi Checklist"
				:totalItems="totalItems"
				icon="mdi-clipboard-text"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>


			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-data-iterator
					:items="checklisttypes"
					:search="search"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o" @click="openDialogCondition(item)">
								<div class="pa-2 d-inline-flex" style="width: 100%">

									<div style="width: 50px; vertical-align: middle; margin: auto;">
										<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
											<v-icon :style="{'color': item.color}">{{ item.icon }}</v-icon>
										</v-avatar>
									</div>


									<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
										<div>
											<div class="body-1">{{ item.name }}</div>
										</div>
									</div>
									<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto">
										<actions-menu
											:item="item"
											:actions="actions"
										></actions-menu>
									</div>

								</div>
							</v-card>
						</template>

					</template>
				</v-data-iterator>

				<v-btn
					:class="{'mb-16': $store.getters.showBottomNavigator}"
					color="primary"
					fixed
					fab
					bottom
					right
					@click.native.stop="create()">
					<v-icon>mdi-plus</v-icon>
				</v-btn>

				<footer-logo></footer-logo>

			</v-container>

			<checklisttype-conditions v-if="dialog"
				:id="model.id"
				:dialog.sync="dialog"
			></checklisttype-conditions>

			<v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
        		<v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters class="d-block">
							<template v-if="confirmDelete">
								<div class="pl-0 font-weight-bold">Elimina Checklist</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else-if="model.id">
								<div class="pl-0 font-weight-bold">Modifica Checklist</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Checklist</div>
							</template>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
						<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
							<checklisttype-fields
								ref="checklisttypeEditor"
								:model="model"
								:info="info"
							></checklisttype-fields>
						</v-container>

						<v-container v-else>
							<v-row class="mx-0 justify-center pt-6">
								<div class="caption">Sei sicuro di voler eliminare questo Checklist?</div>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="confirmDelete" color="success" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
						<v-btn v-else-if="model.id" color="success" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
						<v-btn v-else color="success" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>
  	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import ChecklisttypeFields from '@/components/modules/checklists/ChecklisttypeFields'
import ChecklisttypeConditions from '@/components/modules/checklists/ChecklisttypeConditions'

export default {
	name: 'checklisttypes-crud',
	components: {
		HeaderCrud,
		ChecklisttypeFields,
		ChecklisttypeConditions
	},
	data() {
		return {
			info: {},
			fab: null,
			model: {},
			checklisttypes: [],
			search: '',
			totalItems: 0,
			dialog: false,
			loading: false,
			editorDialog: false,
			confirmDelete: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editChecklisttype'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyChecklisttype'
				},
			],
		}
	},

	watch: {},


	created() {
		this.getData()
	},


	mounted() {
		this.$eventBusUtility.$on('editChecklisttype', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyChecklisttype', (item) => {
			this.confirmDestroy(item)
		})
	},


	methods: {

		closeEditor() {
			this.dialog = false
			this.editorDialog = false
			this.confirmDelete = false
			this.model = {}
			this.$refs.checklisttypeEditor ? this.$refs.checklisttypeEditor.$refs.fields.reset() : null
		},

		openDialogCondition(item) {
			this.model = {...item}
			this.dialog = true
			this.loading = false
		},

		create() {
			this.editorDialog = true
		},

		save() {
			this.$refs.checklisttypeEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				this.loading = true
				if (this.model.id) {
					this.axios.put('checklisttypes/' + this.model.id, {'name': this.model.name}).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('checklisttypes', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		edit(item) {
			this.editorDialog = true
			this.dialog = false
			this.model = {...item}
			this.loading = false
		},

		destroy() {
			this.axios.delete('/checklisttypes/' +this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},


		getData() {

			this.loading = true

			this.axios.get('checklisttypes').then(response => {
				this.checklisttypes = response.data.data
				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('editChecklisttype')
		this.$eventBusUtility.$off('destroyChecklisttype')
	},	
}
</script>

<style>

</style>