<template>
	<v-row no-gutters class="pa-0 fill-height">
		<v-container fluid class="pa-0">
	  		<v-tabs-items v-model="mutableTab" touchless class="fill-height">
				<v-tab-item value="courses" class="fill-height">

		  			<courses-crud :element="element" :tab="mutableTab" :started_at="started_at" :course_id="course_id" :subtab="mutableSubTab">
						<template slot="tabs">
							<v-tabs
								class="pt-4"
								v-model="mutableTab"
								background-color="white"
								show-arrows
								:centered="!$vuetify.breakpoint.mdAndUp">
								<v-tabs-slider></v-tabs-slider>

								<v-tab href="#courses">
									Corsi
								</v-tab>

								<v-tab href="#patents" v-if="$store.getters.checkPermission('manage_courses')">
									Brevetti
								</v-tab>

								<v-tab href="#rooms" v-if="$store.getters.checkPermission('manage_courses')">
									Aule
								</v-tab>
							</v-tabs>
						</template>
					</courses-crud>
				</v-tab-item>

				<v-tab-item value="patents" class="fill-height">
					<patents-crud>
						<template slot="tabs">
							<v-tabs
								class="pt-4"
								v-model="mutableTab"
								background-color="white"
								show-arrows
								:centered="!$vuetify.breakpoint.mdAndUp">
								<v-tabs-slider></v-tabs-slider>

								<v-tab href="#courses">
									Corsi
								</v-tab>

								<v-tab href="#patents">
									Brevetti
								</v-tab>

								<v-tab href="#rooms">
									Aule
								</v-tab>
							</v-tabs>
						</template>
					</patents-crud>
				</v-tab-item>

				<v-tab-item value="rooms" class="fill-height">
					<rooms-crud>
						<template slot="tabs">
							<v-tabs
								class="pt-4"
								v-model="mutableTab"
								background-color="white"
								show-arrows
								:centered="!$vuetify.breakpoint.mdAndUp">
								<v-tabs-slider></v-tabs-slider>

								<v-tab href="#courses">
									Corsi
								</v-tab>

								<v-tab href="#patents">
									Brevetti
								</v-tab>

								<v-tab href="#rooms">
									Aule
								</v-tab>
							</v-tabs>
						</template>
					</rooms-crud>
				</v-tab-item>

	  		</v-tabs-items>
		</v-container>



  	</v-row>




</template>

<script>

import RoomsCrud from '@/components/modules/courses/RoomsCrud'
import CoursesCrud from '@/components/modules/courses/CoursesCrud'
import PatentsCrud from '@/components/modules/courses/PatentsCrud'

export default {
	name: 'courses-index',
	components: {
		RoomsCrud,
		CoursesCrud,
		PatentsCrud,
  	},

	props: {
		element: Number,
		course_id: Number,
		started_at: String,
		tab: {
			type: String,
			default: 'courses'
		},
		subtab: {
			type: String,
			default: 'info'
		},
	},

	data() {
		return {
			mutableTab: this.tab,
			mutableSubTab: this.subtab,
		}
	},



}
</script>