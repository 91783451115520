<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Corsi"
				:totalItems="totalItems"
				icon="mdi-book-open-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-card :elevation="1" class="background" width="100%">

					<v-data-table class="background-2"
						:options.sync="pagination"
						:headers="headers"
						:items="courses"
						:server-items-length="totalItems"
						:loading="loading"
						:search="search"
						:hide-default-footer="!$vuetify.breakpoint.mdAndUp"
						:mobile-breakpoint="0"
						ref="vDataTable"
						:footer-props="{
							'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
							showFirstLastPage: true
						}">

						<template v-slot:body.prepend>
							<tr>
								<td>
									<v-icon >mdi-magnify</v-icon>
								</td>
								<td>
									<v-tooltip top v-if="!isEmptyColumnsFilter">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
												<v-icon>mdi-progress-close</v-icon>
											</v-btn>
										</template>
										<span>Svuota Filtri</span>
									</v-tooltip>
								</td>
								<td class="pl-0" width="1%">
									<v-select
										:items="[{'text': 'Si', 'value': 'subscribed'}, {'text': 'No', 'value': 'not_subscribed'}, {'text': 'In Attesa', value: 'pending'}]"
										v-model="columnsFilter['subscription_status']"
										placeholder="Iscritto"
										hide-details
										clearable
										dense
										solo
										flat
									></v-select>
								</td>								
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Nome"
										hide-details
										v-model="columnsFilter['name']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Brevetto"
										hide-details
										v-model="columnsFilter['patent']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Iscritti"
										hide-details
										v-model="columnsFilter['subscriptions']"
									></v-text-field>
								</td>
								<td class="pl-0" v-if="$store.getters.checkPermission('manage_courses')">
									<v-text-field
										dense
										solo
										flat
										placeholder="In Attesa"
										hide-details
										v-model="columnsFilter['subscriptions_pending']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-menu
										ref="first_lesson"
										:close-on-content-click="false"
										v-model="datepicker_menu['first_lesson']"
										:return-value.sync="columnsFilter['first_lesson']"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="290px"
										:nudge-right="40"
										style="width: 100%">
										<template v-slot:activator="{ on }">
											<v-text-field
												v-on="on"
												label="Inizio"
												readonly
												:value="$eventBusUtility.formatDate(columnsFilter['first_lesson'])"
												@click:clear="$set(columnsFilter, 'first_lesson', null)"
												dense
												solo
												flat
												hide-details
												clearable
											></v-text-field>
										</template>
										<v-date-picker 
											v-model="columnsFilter['first_lesson']"
											color="primary"
											locale="It"
											scrollable
											:first-day-of-week="1"
											@input="$refs['first_lesson'].save(columnsFilter['first_lesson'])">
										</v-date-picker>
									</v-menu>									
								</td>
								<td class="pl-0">
									<v-menu
										ref="next_lesson"
										:close-on-content-click="false"
										v-model="datepicker_menu['next_lesson']"
										:return-value.sync="columnsFilter['next_lesson']"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="290px"
										:nudge-right="40"
										style="width: 100%">
										<template v-slot:activator="{ on }">
											<v-text-field
												v-on="on"
												label="Prossima Lezione"
												readonly
												:value="$eventBusUtility.formatDate(columnsFilter['next_lesson'])"
												@click:clear="$set(columnsFilter, 'next_lesson', null)"
												dense
												solo
												flat
												hide-details
												clearable
											></v-text-field>
										</template>
										<v-date-picker 
											v-model="columnsFilter['next_lesson']"
											color="primary"
											locale="It"
											scrollable
											:first-day-of-week="1"
											@input="$refs['next_lesson'].save(columnsFilter['next_lesson'])">
										</v-date-picker>
									</v-menu>									
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Durata"
										hide-details
										v-model="columnsFilter['duration']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-menu
										ref="end_subscription_date"
										:close-on-content-click="false"
										v-model="datepicker_menu['end_subscription_date']"
										:return-value.sync="columnsFilter['end_subscription_date']"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="290px"
										:nudge-right="40"
										style="width: 100%">
										<template v-slot:activator="{ on }">
											<v-text-field
												v-on="on"
												label="Scadenza"
												readonly
												:value="$eventBusUtility.formatDate(columnsFilter['end_subscription_date'])"
												@click:clear="$set(columnsFilter, 'end_subscription_date', null)"
												dense
												solo
												flat
												hide-details
												clearable
											></v-text-field>
										</template>
										<v-date-picker 
											v-model="columnsFilter['end_subscription_date']"
											color="primary"
											locale="It"
											scrollable
											:first-day-of-week="1"
											@input="$refs['end_subscription_date'].save(columnsFilter['end_subscription_date'])">
										</v-date-picker>
									</v-menu>
								</td>
							</tr>                                        
						</template>				

						<template v-slot:item="{ item }">
							<tr class="cursor-pointer">
								<td style="white-space: nowrap">
									<actions-menu v-if="$store.getters.checkPermission('manage_courses')"
										:item="item"
										:actions="actions(item)"
									></actions-menu>
								</td>
								<td @click="show(item)">
									<v-icon v-if="subscriptionClosed(item)">mdi-lock-outline</v-icon>
								</td>		
								<td @click="show(item)">
									<v-icon v-if="item.subscription_status == 'subscribed'">mdi-checkbox-marked-outline</v-icon>
									<v-progress-circular v-else-if="item.subscription_status == 'pending'"
										indeterminate
										color="primary"
										size="20"
										width="3"
										></v-progress-circular>
									<v-icon v-else-if="item.subscription_status == 'not_subscribed'">mdi-checkbox-blank-outline</v-icon>
								</td>
								<td @click="show(item)">
									{{ item.name }}
								</td>
								<td @click="show(item)">
									{{ item.patent }}
								</td>
								<td @click="show(item)">
									{{ item.subscriptions }}
								</td>
								<td @click="show(item)" v-if="$store.getters.checkPermission('manage_courses')">
									{{ item.subscriptions_pending }}
								</td>
								<td @click="show(item)">
									{{ item.first_lesson | date }}
								</td>
								<td @click="show(item)">
									{{ item.next_lesson | date }}
								</td>
								<td @click="show(item)">
									{{ item.duration | duration }}
								</td>
								<td @click="show(item)">
									{{ item.end_subscription_date | date}}
								</td>
							</tr>
						</template>

						<template v-slot:footer.page-text="{ pageStart, pageStop }">
							<div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
							Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
						</template>

						<template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
							<div class="text-center d-flex background pa-4">
								<v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-text-field
									hide-details
									rounded
									solo
									dense
									prefix="#"
									label="Pagina"
									type="number"
									:min="1"
									:max="props.pagination.pageCount"
									v-model="newPageToJump">
									<template v-slot:append-outer>
										<v-btn dark color="primary" @click="jumpToPage()">
											Vai
										</v-btn>						
									</template>
								</v-text-field>
								<v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>					
							</div>
							<div class="text-center caption pr-4">
								<div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
								Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
							</div>
						</template>
					</v-data-table>

					<v-speed-dial v-if="$store.getters.checkPermission('manage_courses')"
						:class="{'mb-16': $store.getters.showBottomNavigator}"
						v-model="fab"
						right
						bottom
						fixed
						direction="left"
						transition="slide-y-reverse-transition">
						<template v-slot:activator>
							<v-btn v-model="fab" color="primary" dark fab>
								<v-icon v-if="fab">mdi-close</v-icon>
								<v-icon v-else>mdi-book-open-outline</v-icon>
							</v-btn>
						</template>
						<v-btn fab dark small color="indigo" @click="create()">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-speed-dial>

					</v-card>

				<footer-logo></footer-logo>

			</v-container>

			<course-editor	
				:element="element"
				:tab="tab"
				:subtab="subtab"
				:started_at="started_at"
				:course_id="course_id"
				:info="infoData"
			></course-editor>			

		</v-col>
	</v-row>
</template>


<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'
import CourseEditor from '@/components/modules/courses/CourseEditor'

export default {
	name: 'courses-crud',
	components: {
		HeaderCrud,
		CourseEditor
	},
	mixins: [DataTableMixin],
	props: {
		element: Number,
		course_id: Number,
		started_at: String,
		tab: {
			type: String,
			default: ''
		},
		subtab: {
			type: String,
			default: ''
		},
	},	 
	data() {
		return {
			fab: null,
			search: '',
			courses: [],
			selected: [],
			infoData: {},
			totalItems: 0,
			timeout: null,
			loading: false,
			datepicker_menu: {},
			pagination: {
				'sortBy': ['next_lesson'],
				'sortDesc': [true],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
		}
	},

	computed: {
		headers() {
			let headers = [
				{
					'text': 'Azioni',
					'value': 'actions',
					'sortable': false
				},
				{text: '', value: '', 'sortable': false},
				{text: 'Iscritto', value: 'subscribed_me'},
				{text: 'Nome', value: 'name'},
				{text: 'Brevetto', value: 'patents.name'},
				{text: 'Iscritti', value: 'subscriptions'},
				{text: 'In Attesa', value: 'subscriptions_pending'},
				{text: 'Inizio', value: 'first_lesson'},
				{text: 'Prossima Lezione', value: 'next_lesson'},
				{text: 'Durata', value: 'duration'},
				{text: 'Scadenza iscrizioni', value: 'end_subscription_date'},
			]
			if(!this.$store.getters.checkPermission('manage_courses')) {
				headers.splice(6, 1)
			}
			return headers
		},
		subscriptionClosed(course) {
			return (course) => {
				return (course.end_subscription_date && moment().isAfter(moment(course.end_subscription_date), 'day'))
			}
		},

		actions(course) {
			return (course) => {
				let courseActions = [
					{
						'icon': 'mdi-download',
						'text': 'Scarica presenze',
						'event': 'csvCoursePresences'
					},
					{
						'icon': 'mdi-pencil',
						'text': 'Modifica',
						'event': 'editCourse'
					},
					{
						'icon': 'mdi-delete-outline',
						'text': 'Elimina',
						'event': 'destroyCourse',
					},
				]
				// if(course.subscribed_me) {
				// 	courseActions.push({
				// 		'icon': 'mdi-account-remove-outline',
				// 		'text': 'Disiscrivimi',
				// 		'event': 'subscribe',						
				// 	})
				// } else {
				// 	courseActions.push({
				// 		'icon': 'mdi-account-plus-outline',
				// 		'text': 'Iscrivimi',
				// 		'event': 'subscribe',						
				// 	})
				// }
				return courseActions
			}
		},
	},

	created() {
		this.getInfo()


	},	

	methods: {
	
		getInfo() {
			// Get info data for model
			this.axios.get('courses-info').then(response => {
				// get info data
				this.infoData = response.data.info
				this.getData()
				this.$store.commit('END_LOADING')

				// if from notification
				if(this.course_id) {
					this.show({id: this.course_id})
				}
			}, response => {
				// error callback
				this.loading = false
			})
		},

		getData() {
			if(this.timeout) clearTimeout(this.timeout)

			this.timeout = setTimeout(() => {
				this.loading = true
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					filter: this.search,
				}

				this.axios.get('courses', { params: dataObject }).then(response => {
					this.courses = response.data.data
					this.totalItems = response.data.meta.total
					this.loading = false
				}).catch(() => {
					this.loading = false
				})
			}, 500)
		},

		subscribe(course) {
			this.axios.get('courses/handleSubscription/' + course.id +'/' + this.$store.getters.getUserPerson.id).then(response => {
				this.loading = false
				this.getData()
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
			}, response => {
				this.loading = false
			})
		},		

		show(item) {
			this.$eventBusUtility.$emit('showCourse', item)
		},

		create() {
			this.$eventBusUtility.$emit('createCourse')
		},
		
		getCSVCoursePresence(course) {
			this.$eventBusUtility.openFile(this.$store, '/course-presences-csv/', 'presenze.csv', 'POST', {'course_id': course.id})
		}

	},

	mounted() {
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.getData()
		})
		
		this.$eventBusUtility.$on('refreshInfo', (modelDef) => {
			this.getInfo()
		})

		this.$eventBusUtility.$on('subscribe', (course) => {
			this.subscribe(course)
		})
		
		this.$eventBusUtility.$on('csvCoursePresences', (course) => {
			this.getCSVCoursePresence(course)
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
		this.$eventBusUtility.$off('refreshInfo')
		this.$eventBusUtility.$off('subscribe')
	},
}
</script>