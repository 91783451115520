<template>
	<v-row no-gutters class="pa-0 fill-height">
        <v-tabs-items v-model="mutableTab" touchless class="fill-height" style="width: 100%">
            <v-tab-item value="people" class="fill-height">

                <profile-editor>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Personale
                            </v-tab>

                            <v-tab href="#organization" v-if="$store.getters.isSuperuser">
                                Associazione
                            </v-tab>

                            <v-tab href="#analytics" v-if="$store.getters.checkModulePermissions('analytics') && $store.getters.getUserPerson.id">
                                Statistiche
                            </v-tab>

                            <v-tab href="#patents" v-if="$store.getters.checkModulePermissions('courses')">
                                Brevetti
                            </v-tab>

                        </v-tabs>
                    </template>
                </profile-editor>
            </v-tab-item>

            <v-tab-item value="organization" class="fill-height" v-if="$store.getters.isSuperuser">
                <organization-editor>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Personale
                            </v-tab>

                            <v-tab href="#organization" v-if="$store.getters.isSuperuser">
                                Associazione
                            </v-tab>

                            <v-tab href="#analytics" v-if="$store.getters.checkModulePermissions('analytics')">
                                Statistiche
                            </v-tab>

                            <v-tab href="#patents" v-if="$store.getters.checkModulePermissions('courses')">
                                Brevetti
                            </v-tab>

                        </v-tabs>
                    </template>
                </organization-editor>
            </v-tab-item>

            <v-tab-item value="analytics" class="fill-height" v-if="$store.getters.checkModulePermissions('analytics')">
                <analytic-person
                    :person="{'id': $store.getters.getUserPerson.id, 'name': profile.name}"
                    profile>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Personale
                            </v-tab>

                            <v-tab href="#organization" v-if="$store.getters.isSuperuser">
                                Associazione
                            </v-tab>

                            <v-tab href="#analytics" v-if="$store.getters.checkModulePermissions('analytics')">
                                Statistiche
                            </v-tab>

                            <v-tab href="#patents" v-if="$store.getters.checkModulePermissions('courses')">
                                Brevetti
                            </v-tab>
                        </v-tabs>
                    </template>
                </analytic-person>                
            </v-tab-item>


            <v-tab-item value="patents" class="fill-height">
                <patent-person 
                    title="I tuoi Brevetti"
                    only-show
                    :person="$store.getters.getUserPerson"
                    :element="element">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Personale
                            </v-tab>

                            <v-tab href="#organization" v-if="$store.getters.isSuperuser">
                                Associazione
                            </v-tab>

                            <v-tab href="#analytics" v-if="$store.getters.checkModulePermissions('analytics')">
                                Statistiche
                            </v-tab>

                            <v-tab href="#patents" v-if="$store.getters.checkModulePermissions('courses')">
                                Brevetti
                            </v-tab>

                        </v-tabs>
                    </template>
                </patent-person>
            </v-tab-item>

        </v-tabs-items>
    </v-row>			

	
</template>

<script>

import ProfileEditor from '@/components/common/ProfileEditor'
import PatentPerson from '@/components/modules/people/PatentPerson'
import OrganizationEditor from '@/components/common/OrganizationEditor'
import AnalyticPerson from '@/components/modules/people/AnalyticPerson'

export default {
	name: 'profile',
	components: {
		PatentPerson,
		ProfileEditor,
		AnalyticPerson,
		OrganizationEditor,
	},

	props: {
		element: Number,
		tab: {
			type: String,
			default: 'people',
		}
	},

	data() {
		return {
			mutableTab: this.tab,
			name: '',
		}
	},

	computed: {
		profile() {
			return this.$store.getters.getUserProfile
		},
	},

	mounted() {

	},

	created() {
		this.$store.commit('END_LOADING')
	},

	methods: {

	}
}
</script>