<template>
    <v-menu
        transition="slide-y-transition"
        min-width="240"
		offset-y
		nudge-left="200"
        v-model="showPro">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="d-print-none">
                <v-icon>mdi-account-outline</v-icon>
            </v-btn>
		</template>

        <v-list class="fs14i">
                <v-list-item :to="{path: '/profile'}">
                    <v-list-item-avatar>
                        <v-avatar v-if="$store.getters.getUserProfile.avatar"
                            :size="40" 
                            class="custom-avatar"
                            v-ripple
                            :style="{'background-image': 'url(' + $store.getters.getUserProfile.avatar + ')'}">
                        </v-avatar>
                        <v-icon x-large v-else>mdi-account-circle</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="fs14i fw500">{{ $store.getters.getUserProfile.name}}</v-list-item-title>
                        <v-list-item-subtitle class="fs14i fw500">{{ $store.getters.getUserProfile.email}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            <v-divider></v-divider>

            <v-list-item @click="$store.dispatch('authLogout')">
                <v-list-item-content>
                    <v-list-item-title class="fs14i">Esci</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon color="red">mdi-exit-to-app</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
	name: 'profile-menu',
	props: {
		dark: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showPro: false,
		}
	},

}
</script>