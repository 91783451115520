<template>
    <div>
        <v-col class="col-12 col-md-8">
            <v-autocomplete class="font-weight-regular rounded-search"
                prepend-inner-icon="mdi-magnify"
                label="Cerca"
                :search-input.sync="searchPeople"
                :items="people"
                v-model="personSelected"
                multiple
                item-text="full_name"
                item-value="id"
                clearable
                background-color="background-1"
                hide-details
                rounded
                solo
                small-chips
                dense>
                <template v-slot:item="data">
                    <template>
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ data.item.subscription_status | trans }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </template>

                <template v-slot:append-outer v-if="$vuetify.breakpoint.smAndUp">
                    <v-icon :size="35" color="black">mdi-account-supervisor-outline</v-icon>
                    <v-btn color="success" outlined class="ml-2" v-if="personSelected && personSelected.length > 0" @click="subscribeMultipleCourse()">Iscrivi</v-btn>
                </template>
                <template v-slot:progress>
                    <v-progress-linear
                        color="success"
                        absolute
                        height="2"
                    ></v-progress-linear>
                </template>
            </v-autocomplete>
            <template v-if="!$vuetify.breakpoint.smAndUp">
                <v-btn color="success" outlined class="ml-2 mt-2" v-if="personSelected && personSelected.length > 0" @click="subscribeMultipleCourse()">Iscrivi</v-btn>
            </template>
        </v-col>

        <v-data-table class="elevation-1 rounded-lg mx-0 my-2"
            :headers="headers"
            :items="subscribedpeople"
            :loading="loading"
            :mobile-breakpoint="0"
            v-model="selected" 
            show-select            
            ref="vDataTable"
            :footer-props="{
                'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
                showFirstLastPage: true
            }">

            <template v-slot:header.data-table-select="{ on, props }">
                <v-simple-checkbox color="primary" v-bind="props"  @input="showSelectAll = $event" v-on="on"></v-simple-checkbox>
            </template>

            <template v-slot:body.prepend>
                <tr>
                    <td style="width: 10px">
                        <v-icon >mdi-magnify</v-icon>
                    </td>
                    <td class="pl-0">
                    </td>
                    <td style="width: 30px">
                        <v-tooltip top v-if="!isEmptyColumnsFilter">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
                                    <v-icon>mdi-progress-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Svuota Filtri</span>
                        </v-tooltip>
                    </td>
                    <td class="pl-0">
                        <v-text-field
                            dense
                            solo
                            flat
                            placeholder="Cognome e Nome"
                            hide-details
                            v-model="columnsFilter['full_name']"
                        ></v-text-field>
                    </td>
                    <td class="pl-0">
                    </td>
                    <td class="pl-0">
                    </td>
                    <td class="pl-0">
                    </td>
                    <td class="pl-0">
                    </td>
                    <td class="pl-0" style="width: 150px; margin: 0">
                        <v-select
                            :items="[{'text': 'Iscritto', 'value': true}, {'text': 'In Attesa', value: false}]"
                            v-model="columnsFilter['subscription_status']"
                            placeholder="Iscritto"
                            hide-details
                            clearable
                            dense
                            solo
                            flat
                            style="width: 150px; margin: 0"
                        ></v-select>
                    </td>
                </tr>
            </template>

            <template v-slot:item="{ item, select, isSelected}">
                <tr class="cursor-pointer">							
                    <td>
                        <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                    </td>                    
                    <td style="white-space: nowrap; width: 10px">

                    </td>	
                    <td style="white-space: nowrap; width: 30px">

                    </td>	
                    <td>
                        {{ item.full_name }}
                    </td>
                    <td>
                        {{ item.time_of_presence | duration }} / {{ item.minimal_freq_of_course | duration }}
                    </td>
                    <td>
                        {{ item.time_of_training | duration }} / {{ item.minimal_training_of_course | duration }}
                    </td>
                    <td>
                        <v-tooltip top v-for="(task_id, t) in item.minimal_exam_task" :key="item.id + '_' + t">
                            <template v-slot:activator="{ on: tooltip }">
                                <v-icon v-on="{ ...tooltip }" :color="tasksById[task_id].color" :disabled="!item.abilities.includes(task_id)" :size="18" class="pr-1">
                                    {{ tasksById[task_id].icon }}
                                </v-icon>
                            </template>
                            <span>{{ tasksById[task_id].name ? tasksById[task_id].name : '' }} {{ !item.abilities.includes(task_id) ? ' Non Possetuto' : '' }}</span>
                        </v-tooltip>
                    </td>
                    <td class="text-center">
                        <template v-if="item.exam">
                            <template v-if="item.exam.outcome">
                                <div>Passato: <span class="success--text">Si</span></div>
                                <div>Voto: {{ item.exam.rating }}</div>
                                <div>Data: {{ item.exam.date | date }}</div>
                            </template>
                            <template v-else-if="!item.exam.outcome">
                                <div>Passato: <span class="error--text">No</span></div>
                                <div>Voto: {{ item.exam.rating }}</div>
                                <div>Data: {{ item.exam.date | date }}</div>
                            </template>
                        </template>
                        <template v-else-if="item.can_do_exam">
                            <v-btn v-if="$store.getters.checkPermission('manage_exams') && !item.exam && item.approved" outlined small color="success" @click="openExamDialog(item)">Esame</v-btn>
                        </template>
                        <span v-else class="caption">Requesiti non corrispondenti</span>
                    </td>
                    <td class="text-center d-flex justify-space-around align-center">
                        <template v-if="course.subscribe_require_approval && item.approved == false">
                            <v-btn class="mr-1" :loading="loading" small color="success" outlined  @click="manageSubscriptionRequest(item, true)">Accetta</v-btn>
                            <v-btn class="ml-1" :loading="loading" small color="error" outlined  @click="manageSubscriptionRequest(item, false)">Rifiuta</v-btn>

                        </template>
                        <v-btn v-else :loading="loading" small color="red" outlined  @click="openConfirmUnsubscription(item)">Disiscrivi</v-btn>
                    </td>	
                </tr>
            </template>

            <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                Da {{ pageStart }} a {{ pageStop }} di {{ itemsLength }}
            </template>

        </v-data-table>     


        <v-speed-dial v-show="selected.length > 0"
            style="margin-bottom: 100px"
            fixed
            v-model="fab"
            bottom
            right
            :loading="loading"
            color="primary"
            direction="left"
            transition="slide-x-transition">
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="primary"
                    dark
                    fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-account-supervisor-outline</v-icon>
                </v-btn>
            </template>
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                             
                    <v-btn
                        v-on="{ ...tooltip }"
                        fab
                        dark
                        small
                        color="warning"
                        @click.native.stop="openMultipleuUnsubscriptionDialog()">
                        <v-icon>mdi-bookmark-remove-outline</v-icon>
                    </v-btn>
                </template>
                <span>Disiscrivi</span>   
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                             
                    <v-btn
                        v-on="{ ...tooltip }"
                        fab
                        dark
                        small
                        color="green"
                        @click.native.stop="openMultipleExamCreateDialog()">
                        <v-icon>mdi-book-check-outline</v-icon>
                    </v-btn>
                </template>
                <span>Assegna Esame</span>   
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                             
                    <v-btn
                        v-on="{ ...tooltip }"
                        fab
                        dark
                        small
                        color="info"
                        @click.native.stop="openMultipleExamEditDialog()">
                        <v-icon>mdi-book-edit-outline</v-icon>
                    </v-btn>
                </template>
                <span>Modifica Esame</span>   
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">                             
                    <v-btn
                        v-on="{ ...tooltip }"
                        fab
                        dark
                        small
                        color="error"
                        @click.native.stop="openMultipleExamDeleteDialog()">
                        <v-icon>mdi-book-remove-outline</v-icon>
                    </v-btn>
                </template>
                <span>Elimina Esame</span>   
            </v-tooltip>
        </v-speed-dial>


        <v-dialog v-model="examDialog" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" vif="examDialog" v-if="examDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Esame</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name ? model.name : ''}} {{ exam.person ? exam.person : '' }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container fluid class="px-0 pt-6 mx-0">
                        <validation-observer ref="editRecurrenceFields">

                            <v-row class="pb-2 mx-0 pt-2">
                                <v-col class="col-12 px-6 py-0">
                                    <v-checkbox
                                        label="Passato"
                                        v-model="exam['outcome']"
                                        color="primary"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Voto*"
                                        v-model="exam['rating']"
                                        outlined
                                        dense
                                        ref="rating"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-menu
                                        ref="date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['date']"
                                        :return-value.sync="exam['date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Data" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Data*"
                                                    :error-messages="errors"
                                                    append-icon="event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(exam['date'])"
                                                    outlined
                                                    @click:clear="exam['date'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="exam['date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['date'].save(exam['date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                        </validation-observer>

                    </v-container>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-btn color="background-1" fab small @click="closeDialog()">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="createExam()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="multipleExamEditDialog" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="multipleExamEditDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Esame</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeMultipleExamEditDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container fluid class="px-0 pt-6 mx-0">
                        <validation-observer ref="editRecurrenceFields">

                            <v-row class="mx-0 pl-6 py-6 d-block" v-if="multipleExamEditResult.errors">
                                <div class="subtitle-1 success--text">Modificato esame con successo {{ multipleExamEditResult.success }} di {{ multipleExamEditResult.all }}</div>
                                <div class="subtitle-1 error--text" v-if="multipleExamEditResult.errors.length > 0">Errori {{ multipleExamEditResult.errors.length }}:</div>
                                <div class="caption" v-for="(result, r) in multipleExamEditResult.errors" :key="r">
                                    {{ r+1 + ') ' }} {{ result.person }} : {{ result.status }}
                                </div>
                            </v-row>                            

                            <v-row v-else class="pb-2 mx-0 pt-2">
                                <v-col class="col-12 px-6 py-0">
                                    <v-checkbox
                                        label="Passato"
                                        v-model="exam['outcome']"
                                        color="primary"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Voto*"
                                        v-model="exam['rating']"
                                        outlined
                                        dense
                                        ref="rating"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-menu
                                        ref="date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['date']"
                                        :return-value.sync="exam['date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Data" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Data*"
                                                    :error-messages="errors"
                                                    append-icon="event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(exam['date'])"
                                                    outlined
                                                    @click:clear="exam['date'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="exam['date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['date'].save(exam['date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                        </validation-observer>

                    </v-container>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-btn color="background-1" fab small @click="closeMultipleExamEditDialog()">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    <v-btn v-if="selected.length > 0" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveMultipleExamEdit()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="multipleExamCreateDialog" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="multipleExamCreateDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Esame</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeMultipleExamCreateDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container fluid class="px-0 pt-6 mx-0">
                        <validation-observer ref="editRecurrenceFields">

                            <v-row class="mx-0 pl-6 py-6 d-block" v-if="multipleExamCreateResult.errors">
                                <div class="subtitle-1 success--text">Assegnato esame con successo {{ multipleExamCreateResult.success }} di {{ multipleExamCreateResult.all }}</div>
                                <div class="subtitle-1 error--text" v-if="multipleExamCreateResult.errors.length > 0">Errori {{ multipleExamCreateResult.errors.length }}:</div>
                                <div class="caption" v-for="(result, r) in multipleExamCreateResult.errors" :key="r">
                                    {{ r+1 + ') ' }} {{ result.person }} : {{ result.status }}
                                </div>
                            </v-row>

                            <v-row v-else class="pb-2 mx-0 pt-2">
                                <v-col class="col-12 px-6 py-0">
                                    <v-checkbox
                                        label="Passato"
                                        v-model="exam['outcome']"
                                        color="primary"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Voto*"
                                        v-model="exam['rating']"
                                        outlined
                                        dense
                                        ref="rating"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-menu
                                        ref="date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['date']"
                                        :return-value.sync="exam['date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Data" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Data*"
                                                    :error-messages="errors"
                                                    append-icon="event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(exam['date'])"
                                                    outlined
                                                    @click:clear="exam['date'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="exam['date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['date'].save(exam['date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                        </validation-observer>

                    </v-container>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-btn color="background-1" fab small @click="closeMultipleExamCreateDialog()">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    <v-btn v-if="selected.length > 0" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveMultipleExamCreate()">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirmUnsubscription" max-width="600" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="confirmUnsubscription">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Disiscrizione</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ model.full_name ? model.full_name : ''}}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 py-12 body-page mt-0">
                    <v-container>
                        <v-row class="mx-0 justify-center">
                            <div class="caption">Sei sicuro di voler disiscrivere questa persona?
                                <br>
                                Se la persona ha già partecipato a delle lezioni del corso, queste saranno eliminate
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="subscribeCourse(model)">Conferma</v-btn>
                </v-card-actions>                
            </v-card>
        </v-dialog>

        <v-dialog v-model="multipleSubscriptionDialog" max-width="600" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="multipleSubscriptionDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Iscrizione Multipla</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closemultipleSubscriptionDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 py-12 body-page mt-0">
                    <v-container>
                        <v-row class="mx-0 pl-6 py-6 d-block" v-if="multipleSubscriptionResult.all">
                            <div class="subtitle-1 success--text">Iscritti con successo {{ multipleSubscriptionResult.success }} di {{ multipleSubscriptionResult.all }}</div>
                            <div class="subtitle-1 error--text" v-if="multipleSubscriptionResult.errors.length > 0">Errori {{ multipleSubscriptionResult.errors.length }}:</div>
                            <div class="caption" v-for="(result, r) in multipleSubscriptionResult.errors" :key="r">
                                {{ r+1 + ') ' }} {{ result.person }} : {{ result.status }}
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closemultipleSubscriptionDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                </v-card-actions>                
            </v-card>
        </v-dialog>
        
        
        <v-dialog v-model="multipleExamDeleteDialog" max-width="600" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="multipleExamDeleteDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Eliminazione Multipla Esame</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeMultipleExamDeleteDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 py-12 body-page mt-0">
                    <v-container>

                        <v-row class="mx-0 pl-6 py-6 d-block" v-if="multipleExamDeleteResult.all">
                            <div class="subtitle-1 success--text">Elimato esame con successo {{ multipleExamDeleteResult.success }} di {{ multipleExamDeleteResult.all }}</div>
                            <div class="subtitle-1 error--text" v-if="multipleExamDeleteResult.errors.length > 0">Errori {{ multipleExamDeleteResult.errors.length }}:</div>
                            <div class="caption" v-for="(result, r) in multipleExamDeleteResult.errors" :key="r">
                                {{ r+1 + ') ' }} {{ result.person }} : {{ result.status }}
                            </div>
                        </v-row>                            
                        <v-row v-else class="mx-0 pl-6 py-6 d-block">
                            <div class="caption">
                                Vuoi eliminare gli esami per le persone selezionate?
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-if="selected.length > 0" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="deleteMultipleExam()">Conferma</v-btn>                            
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeMultipleExamDeleteDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                </v-card-actions>                
            </v-card>
        </v-dialog>
        
        
        <v-dialog v-model="multipleUnsubscriptionDialog" max-width="600" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card class="rounded-lg pa-0 elevation-0" v-if="multipleUnsubscriptionDialog">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Disiscrizione Multipla</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ course.name }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                    <v-btn icon @click="closeMultipleUnsubscriptionDialog()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 py-12 body-page mt-0">
                    <v-container>

                        <v-row class="mx-0 pl-6 py-6 d-block" v-if="multipleUnsubscriptionResult.all">
                            <div class="subtitle-1 success--text">Disiscritti con successo {{ multipleUnsubscriptionResult.success }} di {{ multipleUnsubscriptionResult.all }}</div>
                            <div class="subtitle-1 error--text" v-if="multipleUnsubscriptionResult.errors.length > 0">Errori {{ multipleUnsubscriptionResult.errors.length }}:</div>
                            <div class="caption" v-for="(result, r) in multipleUnsubscriptionResult.errors" :key="r">
                                {{ r+1 + ') ' }} {{ result.person }} : {{ result.status }}
                            </div>
                        </v-row>                        
                        <v-row v-else class="mx-0 pl-6 py-6 d-block">
                            <div class="caption">
                                Vuoi disiscrivere dal corso le persone selezionate?
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-if="selected.length > 0" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="multipleUnsubscription()">Conferma</v-btn>                            
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeMultipleUnsubscriptionDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                </v-card-actions>                
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
	name: 'subscribed-people',
	components: {
	},
	props: {
		course: Object,
	},
	data() {
		return {
			confirmUnsubscription: false,
			multipleSubscriptionDialog: false,

			multipleExamEditDialog: false,
			multipleExamEditResult: [],
            
			multipleExamCreateDialog: false,
			multipleExamCreateResult: [],

			multipleExamDeleteDialog: false,
			multipleExamDeleteResult: [],
            
			multipleUnsubscriptionDialog: false,
			multipleUnsubscriptionResult: [],
            
			multipleSubscriptionResult: [],
			dialogShow: false,
			examDialog: false,
			fullScreen: false,
			disabled: true,
			fab: false,
			selected: [],
			datepicker_menu: {},
			model: {},
			exam: {},
			info: {},
			personSelected: [],
			people: [],
			searchPeople: '',
			columnsFilter: {},
			totalItems: 0,
			subscribedpeople: [],
			loading: false,
			timeout: null,
			headers: [
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Cognome e Nome',
					'value': 'full_name',
					filter: (value) => this.customSearch(value, 'full_name')
				},
				{
					'text': 'Ore Presenza',
					'value': 'time_of_presence',
				},
				{
					'text': 'Ore Formazione',
					'value': 'time_of_training',
				},
				{
					'text': 'Mansioni Richieste',
					'value': 'minimal_exam_task',
				},
				{
					'text': 'Esame',
					'value': '',
					'align': 'center'
				},
				{
					'text': 'Azioni',
					'value': '',
					'align': 'center',
					'sortable': false,
					filter: (value, search, item) => {
						if (typeof this.columnsFilter['subscription_status'] == 'undefined' || this.columnsFilter['subscription_status'] == null) return true
						return item.approved == this.columnsFilter['subscription_status']
					}
				},
			],
		}
	},

	watch: {
		searchPeople: {
			handler(val) {
				if(this.timeout) {
					clearTimeout(this.timeout)
				}
			    this.timeout = setTimeout(() => {

					this.axios.get('courses/' + this.course.id +'/people/', {params: {search: val}}).then(response => {
						this.loading = false
						this.people = response.data.people

					}, response => {
						// error callback
						this.loading = false
					})
			    }, 500)
			},
		},
	},

	computed: {

		selectedIds() {
			return this.selected.map((element) => {
				return element.id
			})
		},

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},

		isEmptyColumnsFilter() {
			return _.isEmpty(this.columnsFilter)
		},

		countSelected() {
			return this.selected.length
		},
	},

	methods: {

        
		multipleExamAssign() {

		},
        
		openMultipleExamEditDialog() {
			this.multipleExamEditDialog = true
		},
        
		closeMultipleExamEditDialog() {
			this.multipleExamEditDialog = false
			this.$set(this, 'multipleExamEditResult', [])
		},
        
		openMultipleExamCreateDialog() {
			this.multipleExamCreateDialog = true
		},
        
		closeMultipleExamCreateDialog() {
			this.multipleExamCreateDialog = false
			this.$set(this, 'multipleExamCreateResult', [])
		},

		// MULTIPLE UNSUBSCRIPTION
		openMultipleuUnsubscriptionDialog() {
			this.multipleUnsubscriptionDialog = true
		},

		closeMultipleUnsubscriptionDialog() {
			this.$set(this, 'multipleUnsubscriptionResult', [])
			this.multipleUnsubscriptionDialog = false
		},

		multipleUnsubscription() {
			this.loading = true
            
			this.exam.course_id = this.course.id
			this.axios.post('courses/handleMultipleUnsubscription/' + this.course.id, {'people': this.selectedIds}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$set(this, 'selected', [])
				this.$set(this, 'multipleUnsubscriptionResult', response.data.result)
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.loading = false
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})            
		},
        
		// MULTIPLE DELETE
		openMultipleExamDeleteDialog() {
			this.multipleExamDeleteDialog = true
		},

		closeMultipleExamDeleteDialog() {
			this.$set(this, 'multipleExamDeleteResult', [])
			this.multipleExamDeleteDialog = false
		},        

		deleteMultipleExam() {
			this.loading = true
            
			this.exam.course_id = this.course.id
			this.axios.post('multiple-exams-delete/', {'course_id': this.course.id, 'people': this.selectedIds}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$set(this, 'selected', [])
				this.multipleExamDeleteResult = response.data.result
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.loading = false
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		},        

		// MULTIPLE EDIT
		saveMultipleExamEdit() {
			this.loading = true
            
			this.exam.course_id = this.course.id
			this.axios.post('multiple-exams-edit/', {'course_id': this.course.id, 'exam': this.exam, 'people': this.selectedIds}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$set(this, 'selected', [])
				this.multipleExamEditResult = response.data.result
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.loading = false
				this.exam = _.stubObject()
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		},
        
		// MULTIPLE CREATE
		saveMultipleExamCreate() {
			this.loading = true
            
			this.exam.course_id = this.course.id
			this.axios.post('multiple-exams-create/', {'course_id': this.course.id, 'exam': this.exam, 'people': this.selectedIds}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$set(this, 'selected', [])
				this.multipleExamCreateResult = response.data.result
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.loading = false
				this.exam = _.stubObject()
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		},

		closeMultipleExamEdit() {
			this.multipleExamEditDialog = true
			this.selected = []
		},
        
		emptyColumnsFilter() {
			this.columnsFilter = _.stubObject()
		},

		customSearch(value, key) {
			if (!this.columnsFilter[key]) return true
			return !value ? false : value.toLocaleLowerCase().indexOf(this.columnsFilter[key].toLocaleLowerCase()) > -1
		},

		closeDialog() {
			this.dialogShow = false
			this.examDialog = false
			this.confirmUnsubscription = false
			this.model = _.stubObject()
			this.exam = _.stubObject()
		},

		closemultipleSubscriptionDialog() {
			this.multipleSubscriptionResult = []
			this.multipleSubscriptionDialog = false
		},

		openExamDialog(person) {
			this.examDialog = true
			this.$set(this.exam, 'person', person.full_name)
			this.$set(this.exam, 'person_id', person.id)
			this.$set(this.exam, 'course_id', this.course.id)
		},

		getData() {
			if(this.timeout) {
				clearTimeout(this.timeout)
			}

			this.timeout = setTimeout(() => {

				this.loading = true

				this.axios.get('courses/getSubscribedPeople/' + this.course.id).then(response => {
					this.loading = false
					this.subscribedpeople = response.data.data

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)
		},

		openConfirmUnsubscription(item) {
			this.confirmUnsubscription = true
			this.model = {...item}
		},

		subscribeMultipleCourse() {
			this.loading = true
            
			this.axios.post('courses/handleMultipleSubscription/' + this.course.id, {'people': this.personSelected}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$set(this, 'personSelected', [])
				this.multipleSubscriptionResult = response.data.result
				this.multipleSubscriptionDialog = true
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.people = []
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		},

		subscribeCourse(item) {
			this.loading = true
            
			this.axios.get('courses/handleSubscription/' + this.course.id + '/' + item.id).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.closeDialog()
				this.people = []
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)
				this.getData()
			}, response => {
				// error callback
				this.loading = false
			})
		},
        
		manageSubscriptionRequest(item, approved) {
			this.loading = true
            
			this.axios.post('courses/handleSubscriptionRequest/' + this.course.id + '/' + item.id, {'approved': approved}).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.closeDialog()
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)
				this.getData()
			}, response => {
				// error callback
				this.loading = false
			})
		},        

		createExam() {
			this.loading = true
            
			this.axios.post('exams/', this.exam).then(response => {
				this.loading = false
				this.exam = _.stubObject()
				this.closeDialog()
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)

				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		},
	},

	mounted() {
		this.axios.get('courses/subscribedPeople-info/' + this.course.id).then(response => {
			this.info = response.data.info
			this.getData()

		}, response => {
			// error callback
			this.loading = false
		})        
	},

	beforeDestroy() {
	},    
}
</script>