

import { ifAuthenticated, ifTouchDevice } from '@/router/middleware/basic'
import AttendancesIndex from '@/components/modules/attendances/AttendancesIndex'
import AttendancesCreate from '@/components/modules/attendances/AttendancesCreate'
import LocalAttendance from '@/components/modules/attendances/LocalAttendance'


export const attendances = [
	{
		path: '/attendances',
		name: 'attendances',
		component: AttendancesIndex,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/attendances/create',
		name: 'attendances-create',
		component: AttendancesCreate,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/local-attendance',
		name: 'local-attendance',
		component: LocalAttendance,
		beforeEnter: ifTouchDevice
	},
]