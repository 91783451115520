<template>
	<v-row no-gutters class="pa-0 fill-height">
        <v-tabs-items v-model="mutableTab" touchless class="fill-height" style="width: 100%">
            <v-tab-item value="people" class="fill-height">

                <people-crud :element="element" tab="patents" v-if="mutableTab == 'people'">
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Persone
                            </v-tab>

                            <v-tab href="#groups">
                                Gruppi
                            </v-tab>

                            <v-tab href="#tasks">
                                Mansioni
                            </v-tab>

                        </v-tabs>
                    </template>
                </people-crud>
            </v-tab-item>

            <v-tab-item value="groups" class="fill-height">
                <groups-crud>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Persone
                            </v-tab>

                            <v-tab href="#groups">
                                Gruppi
                            </v-tab>

                            <v-tab href="#tasks">
                                Mansioni
                            </v-tab>

                        </v-tabs>
                    </template>
                </groups-crud>
            </v-tab-item>

            <v-tab-item value="tasks" class="fill-height">
                <tasks-crud>
                    <template slot="tabs">
                        <v-tabs
                            class="pt-4"
                            v-model="mutableTab"
                            background-color="white"
                            show-arrows
                            :centered="!$vuetify.breakpoint.mdAndUp">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#people">
                                Persone
                            </v-tab>

                            <v-tab href="#groups">
                                Gruppi
                            </v-tab>

                            <v-tab href="#tasks">
                                Mansioni
                            </v-tab>

                        </v-tabs>
                    </template>
                </tasks-crud>
            </v-tab-item>

        </v-tabs-items>
    </v-row>
</template>

<script>

import TasksCrud from '@/components/modules/people/TasksCrud'
import PeopleCrud from '@/components/modules/people/PeopleCrud'
import GroupsCrud from '@/components/modules/people/GroupsCrud'

export default {
	name: 'people-index',
	components: {
		TasksCrud,
		GroupsCrud,
		PeopleCrud,
	},
	props: {
		element: Number,
		tab: {
			type: String,
			default: 'people',
		}
	},    
	data() {
		return {
			mutableTab: this.tab,
		}
	},
}
</script>