import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import VueAxios from 'vue-axios'
import VueTheMask from 'vue-the-mask'
import vuetify from '@/plugins/vuetify'
import vue2Dropzone from 'vue2-dropzone'
import LoginPanel from '@/components/main/LoginPanel'
import FooterLogo from '@/components/common/FooterLogo'
import ActionsMenu from '@/components/common/ActionsMenu'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import CenteredLoader from '@/components/common/CenteredLoader'

import('./scss/' + (process.env.VUE_APP_CLIENT ?? '') + 'main.scss')
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'


if(process.env.NODE_ENV === 'development') {

} else if(process.env.NODE_ENV === 'production') {
	Sentry.init({
		Vue,
		dsn: 'https://41d801c498a94d96b6fbc27064af995d@o81644.ingest.sentry.io/5727799',
		integrations: [new Integrations.BrowserTracing()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0,
	})
}

// Fields Components
import ChecklisttypeFields from '@/components/modules/checklists/ChecklisttypeFields'

Vue.component('checklist-type-fields', ChecklisttypeFields)

import { VueEditor } from 'vue2-editor'
import 'whatwg-fetch'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/css/app.css'

import '@/components/libraries/filters'
import '@/components/libraries/validations'

import { eventBusUtility } from '@/eventBusUtility.js'

let major = 1
let minor = 2

if(!localStorage.major || localStorage.major < major) {
	localStorage.clear()
	window.location.reload(true)
	localStorage.major = major
	localStorage.minor = minor
} else if(!localStorage.minor || localStorage.minor < minor) {
	window.location.reload(true)
	localStorage.minor = minor
}

localStorage.version = major + '.' + minor

window._ = require('lodash')
window.moment = require('moment')
window.moment.locale('it')
Vue.prototype.moment = moment

Vue.use(Vuex)
Vue.use(VueTheMask)
Vue.use(VueAxios, axios)

Vue.component('vue-dropzone', vue2Dropzone)
Vue.component('vue-editor', VueEditor)
Vue.component('login-panel', LoginPanel)
Vue.component('confirm-dialog', ConfirmDialog)
Vue.component('actions-menu', ActionsMenu)
Vue.component('footer-logo', FooterLogo)
Vue.component('centered-loader', CenteredLoader)

Vue.config.productionTip = false
Vue.prototype.$eventBusUtility = eventBusUtility
Vue.prototype.$vuetify = vuetify

let url = new URL(window.location.href)
let token = ''
let readonlytoken = ''
if (url.searchParams) {
	token = url.searchParams.get('token')
	readonlytoken = url.searchParams.get('readonlytoken')
}

if (readonlytoken) {
	window.localStorage.setItem('touch-token', readonlytoken)
	localStorage.removeItem('user-token')
}

if (token) {
	window.localStorage.setItem('user-token', token)
	localStorage.removeItem('touch-token')
}


if(localStorage.getItem('touch-token')) {
	token = localStorage.getItem('touch-token')
	if(localStorage.getItem('user-token')) {
		Vue.axios.defaults.headers = {
			'X-FE-Version': localStorage.version,
			'Content-Type': 'application/json',
			'Accept':'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			Authorization: 'Bearer ' + localStorage.getItem('user-token')
		}
		store.dispatch('authLogout')
	}
	store.commit('ENABLE_TOUCH_DEVICE', token)
} else if(localStorage.getItem('user-token')) {
	token = localStorage.getItem('user-token')
	store.commit('SET_AUTH_DATA', {token: token})
}

Vue.axios.defaults.headers = {
	'X-FE-Version': localStorage.version,
	'Content-Type': 'application/json',
	'Accept':'application/json',
	'X-Requested-With': 'XMLHttpRequest',
	Authorization: 'Bearer ' + token
}


// router.beforeEach((to, from, next) => {
// 	store.commit('START_LOADING')
// 	next()
// })
// router.afterEach((to, from) => {
// 	store.commit('END_LOADING')
// })


// Add a response interceptor
Vue.axios.interceptors.response.use((response) => {
	store.commit('GO_ONLINE')
	return response
},
error => {
	if (error.message == 'Network Error') {
		store.commit('GO_OFFLINE')
	} else if (error.response.status == 503) {
	} else if (error.response.status == 400) {
		store.commit('SET_SNACKBAR', {
			timeout: 6000,
			text: error.response.data.message ? Vue.trans(error.response.data.message) : '',
			value: true,
			color: 'warning'
		})		
	} else if (error.response.status == 404) {
		eventBusUtility.navigateTo('notfound')		
	} else if (error.response.status == 403) {
		store.commit('SET_SNACKBAR', {
			timeout: 6000,
			text: error.response.data.message ? error.response.data.message : 'Permesso negato',
			value: true,
			color: 'warning'
		})
	} else if (error.response.status == 401) {
		if(store.getters.isMiseApp) {
			router.replace({name: 'disabled-mise'})
		}
		store.commit('SET_SNACKBAR', {
			timeout: 6000,
			text: error.response.data.message ? error.response.data.message : 'Permesso negato',
			value: true,
			color: 'warning'
		})
		if(store.getters.isTouchDevice && !store.getters.isAuth) {
			store.commit('DISABLE_TOUCH_DEVICE')
		} else {
			store.dispatch('clearAuthData')
		}
		
	} else {
		store.commit('SET_SNACKBAR', {
			timeout: 6000,
			text: 'Ops.. Qualcosa è andato storto',
			value: true,
			color: 'error'
		})
	}
	return Promise.reject(error)
})

const app = new Vue({
	el: '#app',
	axios,
	store,
	router,
  	vuetify,
	template: '<App/>',  
	components: { App },
	head: {
		meta: [
			{
				name: 'viewport',
				content:
				'width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
			}
		]
	},
})

let title = 'VolontApp'

if(store.getters.isMiseApp) {
	title = 'Gestione Turni'
}

document.title = title
