export default {
	data() {
		return {
			pageToJump: 1,
			selectedBE: null,
			selectAll: false,
			columnsFilter: {},
			newPageToJump: null,
			showSelectAll: false,
		}
	},

	computed: {

		countSelected() {
			return this.selected.length
		},

		selectedIds() {
			if(this.selectAll) {
				return 'all'
			}
			return this.selected.map((element) => {
				return element.id
			})
		},

		getUsersLimit() {
			if(this.maxUsers && this.usersNumber)
				return this.usersNumber + ' / ' + this.maxUsers
		},

		actionPositionAbsolute() {
			if(this.actionPosition === 'absolute') {
				return true
			} else {
				return false
			}
		},

		actionPositionFixed() {
			if(this.actionPosition === 'fixed') {
				return true
			} else {
				return false
			}
		},
		
		isEmptyColumnsFilter() {
			return _.isEmpty(this.columnsFilter)
		},
	},

	watch: {

		pagination: {
			handler() {
				this.getData()
			},
			deep: true,
		},

		search: {
			handler() {
				if(this.pagination != null) {
					if(this.pagination != null && this.pagination.page !== 1) {
						this.$set(this.pagination, 'page', 1)
					}
					this.getData()
				}
			}
		},

		columnsFilter: {
			handler() {
				if(this.pagination != null) {
					if(this.pagination.page !== 1) {
						this.$set(this.pagination, 'page', 1)
					}
					this.getData()
				}
			},
			deep: true,
		},
		
	    selected: {
	        handler() {
	            this.selected.length > 0 ? this.disabled = false : this.disabled = true
	        }
	    }
	},

	methods: {

		emptyColumnsFilter() {
			this.columnsFilter = _.stubObject()
		},

		prevPage() {
			this.$set(this.pagination, 'page', this.pagination.page - 1)
		},

		nextPage() {
			this.$set(this.pagination, 'page', this.pagination.page + 1)
		},

		jumpToPage() {
			this.$set(this.pagination, 'page', this.newPageToJump)
		},

		getChipText(header, chip) {
			let h = header.options.find((opt) => {
				return opt.value === chip
			})
			return h.text
		},

		toggleAll() {
			if (this.selected.length) {
				this.selected = []   
			} else { 
				this.selected = this.items.slice()
			}
		},

		changeSort(header) {
			if(typeof header.sortable === 'undefined' || header.sortable){
				let column = header.value
				if (this.pagination.sortBy === column) {
					this.pagination.descending = !this.pagination.descending
				} else {
					this.pagination.sortBy = column
					this.pagination.descending = false
				}
			}
		},

		setSelectAll() {
			this.showSelectAll = true
			this.selectAll = true
			this.selectedBE = this.totalItems
		},

		cancelSelectAll() {
			this.showSelectAll = false
			this.selectAll = false
			this.selectedBE = null
		},

		callSelect(select, event) {
			this.showSelectAll = false
			this.selectAll = false
			this.selectedBE = null
			select(event)
		},

		deleteModels() {
			let ids = this.selectedIds

			this.axios.post('people/delete', {ids: ids}).then(response => {
				// get body data
				let data = response.data
				this.disabled = true
				this.selected = []
				this.getData()
				this.confirmDelete = false
				let snackmessage = ''
				let snackcolor = ''
				if(data.status == 'message'){
					snackmessage = data.message
					snackcolor = 'warning'
				} else {
					snackmessage = 'Elementi eliminati con successo.'
					snackcolor = 'success'
				}
				this.$store.commit('setSnackbar', {timeout: 6000, text: snackmessage, value: true, color: snackcolor})

			}, response => {
				// error callback
			})
		},
	},
}