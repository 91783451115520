<template>
    <v-container fluid class="pa-6" style="position: fixed;" :class="{'open-nav': $eventBusUtility.calcOpenNav($vuetify)}">
        <v-card class="rounded-lg pa-0 mb-4 elevation-0 transparent">

            <v-card-title class="pa-0">
                <v-row no-gutters>
                    <v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
                        <div class="pl-0 font-weight-bold">{{ title }}</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 30px" v-if="totalItemsComputed">{{ totalItems }}</div>
                    </v-col>

                    <v-col class="col-2 col-sm-6 col-lg-6 ma-auto mr-0 hidden-sm-and-down" v-if="typeof search == 'string'">
                        <v-text-field class="font-weight-regular rounded-search"
                            prepend-inner-icon="mdi-magnify"
                            label="Cerca"
                            v-model="searchComputed"
                            clearable
                            background-color="background-1"
                            hide-details
                            rounded
                            solo
                            dense>
                            <template v-slot:append-outer>
                                <v-icon :size="35" color="black">{{ icon }}</v-icon>
                            </template>
                            <template v-slot:progress>
                                <v-progress-linear
                                    color="success"
                                    absolute
                                    height="2"
                                ></v-progress-linear>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col class="col-sm-6 col-lg-6 ma-auto mr-0 hidden-sm-and-down" v-if="dates">
                        <v-menu v-if="!$vuetify.breakpoint.smAndDown"
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            bottom
                            left
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    label="Seleziona Intervallo"
                                    v-model="formattedDates"
                                    v-bind="attrs"
                                    v-on="on"
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    prepend-inner-icon="mdi-calendar">
                                    <template v-slot:append-outer>
                                        <v-icon :size="35" color="black">mdi-clock-outline</v-icon>
                                    </template>
                                    <template v-slot:progress>
                                        <v-progress-linear 
                                            color="success"
                                            absolute 
                                            height="2"
                                        ></v-progress-linear>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="datesComputed"
                                :max="moment().format('Y-MM-DD')"
                                range>
                            </v-date-picker>
                        </v-menu>
                    </v-col>                    
                    <v-col class="col-2 col-lg-1 text-right ma-auto mr-0 hidden-md-and-up">
                        <v-icon :size="35" color="black">{{ icon }}</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-container class="pa-0 pt-3" fluid>
                <v-row v-if="typeof search == 'string'">
                    <v-col cols="12" class="hidden-md-and-up pa-3">
                        <v-text-field class="rounded-search"
                            prepend-inner-icon="mdi-magnify"
                            label="Cerca"
                            v-model="searchComputed"
                            clearable
                            hide-details
                            rounded
                            solo
                            background-color="#f5f7fa"
                            dense>
                            <template v-slot:append-outer v-if="$vuetify.breakpoint.mdAndUp">
                                <v-icon :size="35" color="black">{{ icon }}</v-icon>
                            </template>
                            <template v-slot:progress>
                                <v-progress-linear
                                    color="success"
                                    absolute
                                    height="2"
                                ></v-progress-linear>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row v-if="dates">
                    <v-col cols="12" class="hidden-md-and-up pa-3">
                        <v-menu v-if="!$vuetify.breakpoint.mdAndUp"
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            bottom
                            left
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    label="Seleziona Intervallo"
                                    v-model="formattedDates"
                                    v-bind="attrs"
                                    v-on="on"
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    prepend-inner-icon="mdi-calendar">
                                    <template v-slot:append-outer v-if="$vuetify.breakpoint.mdAndUp">
                                        <v-icon :size="35" color="black">mdi-clock-outline</v-icon>
                                    </template>
                                    <template v-slot:progress>
                                        <v-progress-linear 
                                            color="success"
                                            absolute 
                                            height="2"
                                        ></v-progress-linear>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="datesComputed"
                                :max="moment().format('Y-MM-DD')"
                                range>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>                
            </v-container>
            <slot name="header-tab">

            </slot>
        </v-card>
    </v-container>
</template>

<script>
export default {
	name: 'header-crud',
	props: {
		title: String,
		totalItems: Number,
		icon: String,
		search: String,
		dates: Array
	},
	data() {
		return {
			tab: 'tab-1',
			menu: false,
		}
	},

	computed: {

		totalItemsComputed() {
			return this.totalItems == 0 ? '0' : this.totalItems
		},

		formattedDates: {
			get() {

				let from, to = ''
				if(this.dates[0]) {
					from = moment(this.dates[0]).format('DD MMM Y')
				}
				if(this.dates[1]) {
					to = moment(this.dates[1]).format('DD MMM Y')
				}                
				return from + ' - ' + to
			},
			set(newDates) {
				//let from, to = ''
				if(newDates == null) {
					this.$emit('update:dates', [])                    
					return ''
				}              
			}
		},        

		searchComputed: {
			get() {
				return this.search
			},
			set(newSearch) {
				this.$emit('update:search', newSearch == null ? '' : newSearch)
			}
		},

		datesComputed: {
			get() {
				return this.dates
			},
			set(newDates) {
				this.$emit('update:dates', newDates == null ? [] : newDates)
			}
		},
	}
}
</script>