<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Impostazioni"
				icon="mdi-cog-outline">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 140px; height: 100%">
				<v-row>
					<v-col class="col-12 text-right">
						<v-dialog 
							v-model="touchDialog"
							max-width="900px"
							hide-overlay
							transition="scroll-x-reverse-transition"
							style="overflow-x: hidden"
							:fullscreen="$vuetify.breakpoint.xsOnly">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" dark v-bind="attrs" v-on="on">
									{{ $store.getters.isTouchDevice ? 'DIS' : '' }}ATTIVA TOUCH
								</v-btn>
							</template>							
							<v-card class="rounded-lg pa-0 elevation-0">
								<v-card-title class="pa-6">
									<v-row no-gutters>
										<v-col class="col-12">
											<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ $store.getters.isTouchDevice ? 'Disattiva' : 'Attiva' }} modalità touch</div>
											<div v-if="$store.getters.isTouchDevice" class="pl-0 font-weight-bold" style="font-size: 18px">Vuoi disattivare la modalità touch?</div>
											<div v-else class="pl-0 font-weight-bold" style="font-size: 18px">Vuoi davvero attivare la modalità touch che ti permette di rendere questo dispositivo attivo in consultazione informazione senza necessità di effettuare l'accesso con il proprio utente?</div>
										</v-col>
									</v-row>
								</v-card-title>
								<v-card-text style="overflow-x: hidden; margin-top: 0px" class="body-page pa-0" v-if="!$store.getters.isTouchDevice">
									<v-container class="px-0 justify-center" fluid>
                    					<validation-observer ref="manageFields">
											<v-row class="pb-2 mx-0 pt-4">
												<v-col class="col-12 px-6 py-2">
													<v-checkbox
														label="Disattiva rotazione sezioni"
														v-model="disableSectionRotation"
														color="primary"
														:input-value="[true, false]"
													></v-checkbox>
												</v-col>
												<v-col class="col-12 px-6 pl-3 py-2" v-if="$store.getters.checkModulePermissions('attendances')">
													<validation-provider name="Sede" rules="required" v-slot="{ errors }">
														<v-select 
															v-model="placeAttendance"
															:items="places"
															label="Sede"
															item-text="name"
															item-value="id"
															dense
															class="px-4"
															:error-messages="errors"
															outlined
														></v-select>
													</validation-provider>
												</v-col>			
											</v-row>
                    					</validation-observer>
									</v-container>
								</v-card-text>
								<v-divider></v-divider>
								<v-card-actions class="pt-6 pb-10 justify-center elevation-5">
									<v-spacer></v-spacer>
									<v-btn color="background-1" fab small @click="closeTouchDialog()">
										<v-icon>mdi-keyboard-backspace</v-icon>
									</v-btn>
									<v-btn color="success" height="40" :loading="loading" @click.native="toggleTouch()">{{ $store.getters.isTouchDevice ? 'DIS' : '' }}ATTIVA</v-btn>
								</v-card-actions>								
							</v-card>
						</v-dialog>
					</v-col>
				</v-row>
				<v-row>
					<calendars-crud></calendars-crud>
					<shifttype-crud class="pl-lg-2 pl-xs-0"></shifttype-crud>
					<calendar-general-settings class="pl-xs-0 pl-lg-0"></calendar-general-settings>
					<notificationrules
						class="pl-xs-0 pl-lg-0"
						type="calendar"
					></notificationrules>
				</v-row>
				<footer-logo></footer-logo>
			</v-container>
		</v-col>
    </v-row>
</template>

<script>

import CalendarsCrud from './CalendarsCrud'
import ShifttypeCrud from './ShifttypeCrud'
import Notificationrules from './Notificationrules'
import HeaderCrud from '@/components/common/HeaderCrud'
import CalendarGeneralSettings from './CalendarGeneralSettings'

export default {
	name: 'calendar-settings',
	components: {
		HeaderCrud,
		CalendarsCrud,
		ShifttypeCrud,
		Notificationrules,
		CalendarGeneralSettings
	},
	data() {
		return {
			touchDialog: false,
			loading: false,
			disableSectionRotation: false,
			placeAttendance: null,
			places: []
		}
	},
	methods: {
		closeTouchDialog() {
			this.touchDialog = false
			this.loading = false
		},
		toggleTouch() {
			this.loading = true
			if(this.$store.getters.isTouchDevice) {
				this.$store.commit('DISABLE_TOUCH_DEVICE')
				this.closeTouchDialog()
			} else {
				this.$store.dispatch('enableTouchDevice', {disableSectionRotation: this.disableSectionRotation, placeAttendance: this.placeAttendance}).then(() => {
					this.closeTouchDialog()
					this.$eventBusUtility.navigateTo('home')
				})
			}
		}
	},

	created() {
		if(this.$store.getters.checkModulePermissions('attendances')) {
			this.axios.get('places').then(response => {
				
				this.places = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})
		}
	}
}
</script>

<style>

</style>