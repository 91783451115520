<template>
	<validation-observer ref="fields" v-if="open">
		<v-row class="pb-2 mx-0">
			<v-col class="col-12 py-0 px-6 pt-4">
				<validation-provider name="Titolo" rules="required|max:255" v-slot="{ errors }">
					<v-text-field
						type="text"
						label="Titolo"
						v-model="model['title']"
						:error-messages="errors"
						outlined
						dense
						ref="title"
					></v-text-field>
				</validation-provider>
			</v-col>

			<v-col class="col-12 py-0 px-6 pt-4">
				<validation-provider name="Testo" rules="required|max:16777215" v-slot="{ errors }">
					<vue-editor
						v-model="model['body']"
						:class="{'vue-editor': false, 'vue-editor-error': typeof errors[0] !== 'undefined'}"
					></vue-editor>
					<div class="v-messages error--text pl-3">{{ errors[0] }}</div>
				</validation-provider>
			</v-col>

			<v-col class="col-12 py-0 px-6 pt-4">
				<validation-provider rules="size:2048" name="Immagine" v-slot="{ errors, validate }" v-if="!model['image']">
					<v-file-input
						label="Immagine"
						outlined 
						append-icon="mdi-paperclip"
						prepend-icon=""
						dense
						small-chips
						accept="image/png, image/jpeg, image/bmp"
						:error-messages="errors"
						@change="validate && previewImage()"
						v-model="model['image']">
					</v-file-input>
				</validation-provider>

				<template v-else>
					<v-img v-if="preview" max-width="200px" :src="preview"></v-img>
					<v-img v-else max-width="200px" :src="model['image']"></v-img>
					<v-btn dark color="red" @click="model['image'] = null">Elimina</v-btn>
				</template>

			</v-col>

			<v-col class="col-6 py-0 px-6 pt-4">
				<v-checkbox
					v-model="model['public']"
					label="Pubblico"
					hide-details
				></v-checkbox>
			</v-col>

			<v-col class="col-12 py-0 px-6 pt-4" v-if="selectedJoinedLists.length > 0 && !model['public']">
				<v-chip class="mr-2 mt-1" v-for="(selectedJoinedList, index) in selectedJoinedLists" :key="index">
					<span v-if="selectedJoinedList.full_name">{{ selectedJoinedList.full_name }}</span>
					<span v-else>{{ selectedJoinedList.name }}</span>
				</v-chip>
			</v-col>
			<v-col v-else-if="selectedJoinedLists.length == 0 && !model['public']" class="col-12 py-0 px-6 pt-4">
				<span class="caption">Nessun destinatario selezionato</span>
			</v-col>

			<v-expand-transition>
				<v-col class="col-12 py-0 px-6 pt-4" v-show="!model['public']">
					<v-btn color="success" @click="showVisibility()" elevation="2">Modifica destinatari</v-btn>
				</v-col>
			</v-expand-transition>

			<v-col class="col-12 pt-6 pl-6">
				<small>*campi obbligatori</small>
			</v-col>

		</v-row>

		<v-dialog v-model="selectVisibility" max-width="900" persistent scrollable :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown">
			<v-card class="rounded-lg pa-0 elevation-0">
				<v-card-title class="pa-3">
					<v-col class="col-10 ma-auto ml-0 py-1">
						<div class="pl-0 font-weight-bold">Modifica Destinatari</div>
					</v-col>
					<v-spacer></v-spacer>
					<v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
				</v-card-title>
				<v-divider></v-divider>

				<v-card-text class="px-1 pb-1">
					<v-container fluid class="px-0 pt-6 mx-0">

						<v-row no-gutters>

							<v-col class="px-6 col-12 ma-auto mr-0 hidden-sm-and-down">
								<v-text-field class="font-weight-regular rounded-search"
									prepend-inner-icon="mdi-magnify"
									label="Cerca"
									v-model="search"
									clearable
									background-color="background-1"
									hide-details
									rounded
									solo
									dense
									v-on:keyup="loadPeople()"
									>
									<template v-slot:append-outer>
										<v-icon :size="35" color="black">mdi-account-supervisor-outline</v-icon>
									</template>
									<template v-slot:progress>
										<v-progress-linear 
											color="success"
											absolute 
											height="2"
										></v-progress-linear>
									</template>							
								</v-text-field>
							</v-col>
						</v-row>
					</v-container>

					<v-row class="px-6 mx-0 pb-4" v-if="selectedJoinedLists.length > 0">
						<v-col cols="12" sm="12" md="12">
							<v-chip v-for="(selectedJoinedList, index) in selectedJoinedLists" :key="index" class="mr-2 mt-1">
								{{ selectedJoinedList.name }} {{ selectedJoinedList.surname }}
							</v-chip>
						</v-col>
					</v-row>
					<v-divider></v-divider>

					<div class="title pl-5 mt-2 mb-2">Gruppi</div>

					<v-divider></v-divider>
					<v-data-table
						v-model="model['selectedGroups']"
						:headers="headers"
						:items="filteredGroups"
						:single-select=false
						show-select
						:items-per-page="5"
						class="elevation-0"
						color="primary"
						:mobile-breakpoint="0">
						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
						</template>
						<template v-slot:item="{item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
								</td>
								<td>
									{{ item.name }}
								</td>
							</tr>
						</template>
					</v-data-table>
					<v-divider></v-divider>

					<div class="title pl-5 mt-2 mb-2">Mansioni</div>
					<v-divider></v-divider>

					<v-data-table
						v-model="model['selectedTasks']"
						:headers="headers"
						:items="filteredTasks"
						:single-select=false
						show-select
						:items-per-page="5"
						class="elevation-0"
						:mobile-breakpoint="0">
						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
						</template>
						<template v-slot:item="{item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
								</td>
								<td>
									{{ item.name }}
								</td>
							</tr>
						</template>
					</v-data-table>
					<v-divider></v-divider>

					<div class="title pl-5 mt-2 mb-2">Persone</div>
					<v-divider></v-divider>

					<v-data-table
						v-model="model['selectedPeople']"
						:headers="headersPeople"
						:items="people"
						:single-select=false
						show-select
						:options.sync="personOptions"
						:server-items-length="personTotal"
						:loading="personLoading"
						class="elevation-0"
						:mobile-breakpoint="0">
						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
						</template>
						<template v-slot:item="{item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
								</td>
								<td>
									{{ item.full_name }}
								</td>
							</tr>
						</template>						
					</v-data-table>

				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer></v-spacer>
					<v-btn color="background-1" min-width="100px" height="40" class="ml-4" @click="selectVisibility = false">Chiudi</v-btn>          
				</v-card-actions>
			</v-card>
		</v-dialog>
	</validation-observer>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'post-fields',
	props: {
		model: Object,
		open: Boolean,
	},

	data() {
		return {
			fullScreen: false,
			preview: null,
			selectVisibility: false,

			personLoading: true,
			personTotal: 0,
			personOptions: {},

			search: '',

			headers: [
				{
					text: 'Nome',
					align: 'start',
					sortable: true,
					value: 'name',
				},
			],
			headersPeople: [
				{
					text: 'Cognome e Nome',
					align: 'start',
					sortable: true,
					value: 'full_name',
				},
			],
			groups: [],
			tasks: [],
			people: [],

			peopleTimeout: null
		}
	},

	mounted() {
		// this.loadPeople()
		// this.loadGroups()
		// this.loadTasks()
	},

	computed: {

		selectedJoinedLists() {
			let joined = []
			if(typeof this.model['selectedGroups'] !== 'undefined')
				joined = joined.concat(this.model['selectedGroups'])
			if(typeof this.model['selectedTasks'] !== 'undefined')
				joined = joined.concat(this.model['selectedTasks'])
			if(typeof this.model['selectedPeople'] !== 'undefined')
				joined = joined.concat(this.model['selectedPeople'])

			return joined
		},

		filteredGroups() {
			let search = this.search ? this.search.toLowerCase().trim() : null
			if (!search) return this.groups
			return this.groups.filter(c => c.name.toLowerCase().indexOf(search) > -1)
		},

		filteredTasks() {
			let search = this.search ? this.search.toLowerCase().trim() : null
			if (!search) return this.tasks
			return this.tasks.filter(c => c.name.toLowerCase().indexOf(search) > -1)
		},

	},

	watch: {
		personOptions: {
			handler() {
				this.loadPeople()
			},
			deep: true,
		},	
	},

	methods: {

		previewImage() {
			if (typeof this.model['image'] !== 'undefined') {
				this.preview = URL.createObjectURL(this.model['image'])
			} else {
				this.preview = null
			}
		},

		showVisibility() {
			this.loadGroups()
			this.loadTasks()
			this.loadPeople()
			this.selectVisibility = true


			// this.selectedGroups = (typeof(this.model['selectedGroups']) !== 'undefined')?this.model['selectedGroups']:[]
			// this.selectedTasks = (typeof(this.model['selectedTask']) !== 'undefined')?this.model['selectedTask']:[]
			// this.selectedPeople = (typeof(this.model['selectedPeople']) !== 'undefined')?this.model['selectedPeople']:[]

		},

		loadGroups() {
			this.axios.get('groups').then(response => {
				this.groups = response.data.data
			})
		},

		loadTasks() {
			this.axios.get('tasks').then(response => {
				this.tasks = response.data.data
			})
		},

		loadPeople() {
			if(this.peopleTimeout) clearTimeout(this.peopleTimeout)

			this.peopleTimeout = setTimeout(() => {

				let {sortBy, sortDesc, page, itemsPerPage} = this.personOptions

				if (!itemsPerPage) {
					itemsPerPage = 10
				}

				let dataObject = {
					page: page,
					per_page: itemsPerPage,
					sortBy: sortBy,
					sortDesc: sortDesc,
					filter: this.search,
				}

				this.personLoading = true

				this.axios.get('people/', {params: dataObject}).then(response => {
					this.people = response.data.data
					this.personTotal = response.data.meta.total
					this.personLoading = false
				})
			}, 300)
		},

	}
}
</script>
<style>
</style>