import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Profile from '@/components/common/Profile'
import Privacy from '@/components/modules/Privacy'
import NotFound from '@/components/common/NotFound'
import Requests from '@/components/modules/Requests'
import LoginPanel from '@/components/main/LoginPanel'
import { eventBusUtility } from '@/eventBusUtility.js'
import VerifyEmail from '@/components/main/VerifyEmail'
import ResetPassword from '@/components/main/ResetPassword'
import Notifications from '@/components/common/Notifications'
import Organizations from '@/components/modules/Organizations'
import ModuleDisabled from '@/components/common/ModuleDisabled'
import Disabled from '@/components/modules/misericordie/Disabled'

Vue.use(VueRouter)

import { ifAuthenticated, ifNotAuthenticated, ifAuthenticatedAndSuperuser } from '@/router/middleware/basic'


import { board }  from '@/router/modules/board'
import { calendar }  from '@/router/modules/calendar'
import { courses }  from '@/router/modules/courses'
import { analytics }  from '@/router/modules/analytics'
import { attendances }  from '@/router/modules/attendances'
import { checklists }  from '@/router/modules/checklists'
import { people }  from '@/router/modules/people'
import { vehicles }  from '@/router/modules/vehicles'
import { handbook }  from '@/router/modules/handbook'
import { settings }  from '@/router/modules/settings'


export default new VueRouter({
	routes: [
		...board,
		...calendar,
		...courses,
		...analytics,
		...attendances,
		...checklists,
		...people,
		...vehicles,
		...handbook,
		...settings,
		{
			path: '/404',
			name: 'notfound',
			beforeEnter: ifAuthenticated,
			component: NotFound,
		},
		{
			path: '/',
			name: 'home',
			redirect: to => {
				if(process.env.VUE_APP_CLIENT == 'mise' || !store.getters.checkModulePermissions('board'))
					return '/calendar'
				else
					return '/board'
			},
			beforeEnter: ifAuthenticated
		},
		{
			path: '/login',
			name: 'login',
			component: LoginPanel,
			beforeEnter: ifNotAuthenticated
		},
		{
			path: '/verifyemail',
			name: 'verifyemail',
			component: VerifyEmail,
		},
		{
			path: '/resetpassword',
			name: 'resetpassword',
			component: ResetPassword,
		},
		{
			path: '/organizations',
			name: 'organizations',
			component: Organizations,
		},
		{
			path: '/requests',
			name: 'requests',
			component: Requests,
			beforeEnter: ifAuthenticatedAndSuperuser,
			props: true
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: Privacy,
		},
		{
			path: '/profile/',
			name: 'profile',
			beforeEnter: ifAuthenticated,
			component: Profile,
			props: true
		},
		{
			path: '/notifications/',
			name: 'notifications',
			beforeEnter: ifAuthenticated,
			component: Notifications,
		},
		{
			path: '/notifications/:id',
			name: 'notification',
			beforeEnter: ifAuthenticated,
			redirect: to => {
				Vue.axios.get(store.getters.getAuthUrl + '/notifications/'+to.params.id).then(response => {
					eventBusUtility.goToNotifications(response.data.data)
				})
			}
		},
		{
			path: '/disabled',
			name: 'disabled-mise',
			beforeEnter: ifNotAuthenticated,
			component: Disabled,
		},
		{
			path: '/module-disabled/:module',
			name: 'module-disabled',
			props: true,
			beforeEnter: ifAuthenticated,
			component: ModuleDisabled,
		},
		{
			path: '*',
			redirect: '/404'
		}		
	],
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return {
			  selector: to.hash
			}
		} else if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}	
})


