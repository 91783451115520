<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Mansioni"
				:totalItems="totalItems"
				icon="mdi-clipboard-account-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-data-iterator
					:items="taskTree"
					:search="search"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<task-row :key="'task_row_'+o" :item="item"></task-row>

						</template>

					</template>
				</v-data-iterator>

				<v-btn
					v-if="$store.getters.checkPermission('manage_tasks') && tasks.length < $store.getters.getCurrentOrganization.tasks"
					:class="{'mb-16': $store.getters.showBottomNavigator}"
					color="primary"
					fixed
					fab
					bottom
					right
					@click="create()">
					<v-icon>mdi-plus</v-icon>
				</v-btn>

				<footer-logo></footer-logo>
				
			</v-container>

			<v-dialog
				v-model="dialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters>

							<v-col class="col-12 ma-auto ml-0">
								<div class="pl-0 font-weight-bold">Mansione</div>
								<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ model.name }}</div>
								<div class="pl-0 font-weight-bold">{{ model.description }}</div>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 115px" class="body-page">
						<v-container class="px-0 justify-center" fluid>
							
							<template>
								<v-row class="ma-auto d-flex pt-6">
									<v-icon :style="{'color': model.color}">mdi-checkbox-blank</v-icon>
									<div class="pl-2">Colore - {{ model.color }}</div>
								</v-row>
								<v-row class="ma-auto d-flex pt-6">
									<v-icon>{{ model.icon }}</v-icon>
									<div class="pl-2">Icona</div>
								</v-row>
								<v-row class="ma-auto d-flex pt-6" v-if="model.parent_id">
									<v-icon>mdi-clipboard-account-outline</v-icon>
									<div class="pl-2">Mansione Padre: {{ model.parent_id }}</div>
								</v-row>
								<v-row class="ma-auto d-flex pt-6">
									<v-icon>{{model.exclude_statistics ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
									<div class="pl-2">Escludi da Statistiche</div>
								</v-row>
								<div class="pt-2 pl-1 caption">Le disponibilita per questa mansione {{ model.exclude_statistics ? '' : 'non' }} saranno escluse dalle statistiche</div>
								<v-row class="ma-auto d-flex pt-6">
									<v-icon>{{model.heritable ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
									<div class="pl-2">Ereditabile</div>
								</v-row>						
								<div class="pt-2 pl-1 caption">Le persone con una mansione superiore {{ model.heritable ? '' : 'non' }} erediteranno automaticamente questa mansione</div>
								<v-row class="ma-auto d-flex pt-6">
									<v-icon>{{model.virtual ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
									<div class="pl-2">Mansione Virtuale</div>
								</v-row>						
								<div class="pt-2 pl-1 caption">Queste mansioni {{ model.virtual ? '' : 'non' }} posso essere assegnate direttamente ma vengono acquisite a partire da altre</div>
								<v-row class="ma-auto d-block pt-6">
									<div>Mansioni Richieste</div>
									<div class="pt-2">
										<template v-if="model.subtask_task && model.subtask_task.length > 0">
											<v-chip small v-for="(required, r) in model.subtask_task" :key="r" :class="{'ml-1' : r != 0}">{{ required.name }}</v-chip>
										</template>
										<template v-else>
											<div class="caption">Nessuna</div>
										</template>
									</div>
								</v-row>						

							</template>


						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>

						<template v-if="$store.getters.checkPermission('manage_tasks')">
							<v-tooltip top>
								<template v-slot:activator="{ on: tooltip }">
									<v-btn v-on="{ ...tooltip }" color="error" outlined  height="40" class="ml-4" @click="confirmDestroy(model)">
										Elimina
									</v-btn>
								</template>
								Tool Tip
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on: tooltip }">
									<v-btn v-on="{ ...tooltip }" color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model)">
										Modifica
									</v-btn>
								</template>
								Accetta
							</v-tooltip>
						</template>
					</v-card-actions>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="confirmDelete = false">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="destroy()">
									Conferma
								</v-btn>
							</template>
							Conferma
						</v-tooltip>
					</v-card-actions>

				</v-card>
			</v-dialog>

			<v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters class="d-block">

							<template v-if="confirmDelete">
								<div class="pl-0 font-weight-bold">Elimina Mansione</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else-if="model.id">
								<div class="pl-0 font-weight-bold">Modifica Mansione</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Mansione</div>
							</template>

						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
						<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
						
							<task-fields
								ref="taskEditor"
								:model="model"
								:info="info"
							></task-fields>

						</v-container>

						<v-container v-else>
							<v-row class="mx-0 justify-center pt-6">
								<div class="caption" >Sei sicuro di voler eliminare questa Mansione?</div>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
						<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
						<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>				
					</v-card-actions>
				</v-card>
			
			
			</v-dialog>

		</v-col>
	</v-row>
</template>

<script>
import HeaderCrud from '@/components/common/HeaderCrud'
import TaskRow from '@/components/modules/people/TaskRow'
import TaskFields from '@/components/modules/people/TaskFields'

export default {
	name: 'tasks-crud',
  	components: {
		TaskRow,
		HeaderCrud,
		TaskFields,
	},
	data() {
		return {
			info: {},
			fab: null,
			tasks: [],
			search: '',
			model: {},
			totalItems: 0,
			dialog: false,
			loading: false,
			editorDialog: false,
			confirmDelete: false,
		}
	},

	watch: {

	},
	

	created() {
		this.getData()
	},


	mounted() {
		this.$eventBusUtility.$on('showTask', (item) => {
			this.show(item)
		})

		this.$eventBusUtility.$on('editTask', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyTask', (item) => {
			this.confirmDestroy(item)
		})
	},

	computed: {
		taskTree() {
			let map = {}, node, roots = [], i, list = this.tasks
  
			for (i = 0; i < list.length; i++) {
				map[list[i].id] = i // initialize the map
				list[i].children = [] // initialize the children
			}
			
			for (i = 0; i < list.length; i++) {
				node = list[i]
				if (node.parent_id) {
					// if you have dangling branches check that map[node.parent_id] exists
					list[map[node.parent_id]].children.push(node)
				} else {
					roots.push(node)
				}
			}
			return roots
		}
	},

	methods: {

		closeEditor() {
			this.dialog = false
			this.editorDialog = false
			this.confirmDelete = false
			this.model = _.stubObject()
			this.$refs.taskEditor ? this.$refs.taskEditor.$refs.fields.reset() : null
		},

		show(task) {
			
			this.axios.get('tasks/' + task.id).then(response => {
				
				this.dialog = true
				this.model = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})				
		},

		create() {
			this.editorDialog = true	
			this.loading = false
		},

		save() {
			this.$refs.taskEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('tasks/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('tasks', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		edit(item) {
			this.model = {...item}
			this.editorDialog = true
			this.dialog = false
			this.loading = false
		},

		destroy() {
			this.loading = true
			this.axios.delete('/tasks/' + this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},

		getData() {

			this.loading = true

			this.axios.get('/tasks-info').then(response => {
				this.info = response.data.info

				this.axios.get('tasks').then(response => {
					this.tasks = response.data.data
					this.totalItems = response.data.data.length
					this.loading = false

				}, response => {
					this.loading = false
				})

			}, response => {
				this.loading = false
			})

		},



	}
}
</script>