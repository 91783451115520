

import { ifAuthenticatedOrTouchDevice } from '@/router/middleware/basic'
import Calendar from '@/components/modules/calendar/Calendar'
import AvailabilityRequest from '@/components/modules/calendar/AvailabilityRequest'

export const calendar = [
	{
		path: '/calendar',
		name: 'calendar',
		component: Calendar,
		beforeEnter: ifAuthenticatedOrTouchDevice,
		props: true
	},	{
		path: '/shiftrequests/:id',
		name: 'availability-request',
		component: AvailabilityRequest,
		beforeEnter: ifAuthenticatedOrTouchDevice,
		props: true
	},
]