<template>
    <!-- <v-menu
        v-model="showNot" 
        transition="slide-y-transition"
        min-width="350" 
        max-width="350"
        offset-y 
        nudge-left="200">
        <template v-slot:activator="{ on }"> -->
            <v-btn icon @click="$eventBusUtility.navigateTo('notifications')">
                <v-badge right overlap color="green" :content="notificationCount" :value="notificationCount">
                    <v-icon>mdi-bell-outline</v-icon>
                </v-badge>
            </v-btn>
        <!-- </template>

        <v-list two-line class="pa-0 notifications-list background-2" style="max-height: 353px; overflow-y: auto;">
            <v-subheader class="pt-2 fs12i" style="height:38px">Notifiche</v-subheader>
            <template v-for="(notification, not) in $store.getters.getUserNotifications">
                <v-list-item
                    id="list-item-notification"
                    style="height:62px"
                    ripple
                    @click.stop="$eventBusUtility.goTo($router, notification.data.modelDef, notification.data.modelDefId)"
                    :key="not">
                    <v-list-item-avatar v-if="notification.data.type == 'task'">
                        <v-avatar v-if="$store.getters.avatars[notification.data.user_id]"
                            class="custom-avatar"
                            :size="34" 
                            v-ripple
                            :style="{'background-image': 'url(' + $store.getters.avatars[notification.data.user_id] + ')'}">
                        </v-avatar>
                        <v-avatar v-else :class="$eventBusUtility.bgColor(notification.data.user_id)" :size="34">
                            <span class="white--text fs14" v-if="notification.data.creator">{{ notification.data.creator.charAt(0) }}</span>
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-avatar v-else-if="notification.data.type == 'maillog'">
                        <v-avatar
                            size="34px" 
                            style="min-width: 34px; width: 34px; height:34px; border-radius: 50px; margin:auto"
                            class="primary">
                            <v-icon class="fs18i" color="white">drafts</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-avatar v-else>
                        <v-avatar v-if="$store.getters.avatars[notification.data.user_id]"
                            class="custom-avatar"
                            :size="34" 
                            v-ripple
                            :style="{'background-image': 'url(' + $store.getters.avatars[notification.data.user_id] + ')'}">
                        </v-avatar>
                        <v-avatar v-else :class="$eventBusUtility.bgColor(notification.data.user_id)" :size="34">
                            <span class="white--text fs14" v-if="notification.data.creator">{{ notification.data.creator.charAt(0) }}</span>
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content class="py-2 pl-0 pr-2">
                        <v-list-item-title class="fs11i"><span class="fw500">{{notification.data.title}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="fs11i">{{notification.data.subtitle}}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="notification.data.text" class="fs11i">{{notification.data.text}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-action-text class="fs11i">{{ formatNotificationTime(notification.created_at) }}</v-list-item-action-text>
                        <v-tooltip top class="fs12i">
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" class="fs18i" color="grey lighten-1" @click.stop="$eventBusUtility.changeNotificationStatus([notification], 'read')">email</v-icon>
                            </template>
                            <span class="fs11i">Segna come letta</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider inset :key="not+'_divider'"></v-divider>
            </template>
            <v-list-item v-if="!thereAreNotifications">
                <v-list-item-content>
                    <v-list-item-title class="fs11i fw500">Non ci sono notifiche per te</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item id="list-item-notification" style="height:62px">
                <v-list-item-content>
                    <v-btn text small ripple @click="openAllNotifications" color="indigo" class="fs11i" style="margin:auto">Vedi tutte le notifiche</v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu> -->
</template>

<script>

export default {
	name: 'notifications-menu',
	props: {
	},
	data: () => ({
		notifications: [],
		openNews: false,
		showNot: false,
		avatars: [],
	}),

	created() {
		//this.getNotifications()
	},

	computed: {

		thereAreNotifications() {
			if(this.$store.getters.getUserNotifications) {
				return this.$store.getters.getUserNotifications > 0 ? true : false
			} else return false
		},

		notificationCount() {
			if(this.$store.getters.getUserNotifications)
				return this.$store.getters.getUserNotifications > 99 ? '99+' : this.$store.getters.getUserNotifications
			else return false
		}
	},

	mounted() {
		this.$eventBusUtility.$on('updateNotifications', () => {
			this.getNotifications()
		})
	},
    
	beforeDestroy() {
		this.$eventBusUtility.$off('updateNotifications')
	},

	methods: {
		openAllNotifications() {
			this.$eventBusUtility.navigateTo('notifications')
		},

		formatNotificationTime(date) {
			if (date) {
				return moment(date).startOf('second').fromNow()
			} else {
				return ''
			}
		},

		getNotifications() {
			this.axios.get('notifications/', {params: {count: true, filters: {status: 'unread'}}}).then((response) => {
				this.$store.commit('SET_NOTIFICATIONS_COUNT', response.data.data)
				this.loaded = true
			}).catch((response) => {
				this.loaded = true
			})
		},
	}
}
</script>
<style>
    .notifications-list .v-list-item__title {
        font-size: 11px !important;
    }
</style>
