<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Impostazioni"
				icon="mdi-cog-outline">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 140px; height: 100%">
				<v-row>
					<v-col class="col-md-6 col-12 pa-0 mx-0">
						<simple-crud
							ref="placesCrud"
							title="Sede"
							modelDef="places"
							@saveModel="savePlace"
							:footer-logo="false">
							<template v-slot:item-content="{item}">
								<div class="body-1">{{ item.name }}</div>
							</template>

							<template v-slot:model-fields="{item}">
								<place-fields
									ref="placeFields"
									:model="item"
								></place-fields>
							</template>
						</simple-crud>
					</v-col>
				</v-row>

				<attendance-general-settings></attendance-general-settings>

				<footer-logo></footer-logo>
			</v-container>
		</v-col>
	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import SimpleCrud from '@/components/common/SimpleCrud'
import AttendanceGeneralSettings from './AttendanceGeneralSettings'
import PlaceFields from '@/components/modules/checklists/PlaceFields'

export default {
	name: 'attendance-settings',
	components: {
		HeaderCrud,
		SimpleCrud,
		PlaceFields,
		AttendanceGeneralSettings
	},
	data() {
		return {
			instrumentInfo: {}
		}
	},

	created() {
	},

	methods: {
		savePlace(item) {
			this.$refs.placeFields.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				this.$refs.placesCrud.save(item)
			}).catch(() => {

			})
		},
	},
 
}
</script>
