<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Timbrature"
				:totalItems="totalItems"
				icon="mdi-clock-outline"
				:dates.sync="dates">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

		<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 160px; height: 100%">

			<v-card :elevation="1" class="background" width="100%">
				<v-expand-transition>
					<v-alert v-show="showSelectAll" icon="mdi-check-box-multiple-outline" text prominent type="info">
						<template v-if="!selectAll">
							Tutte le <span class="font-weight-bold">{{ selected.length }}</span> presenze in questa pagina sono state selezionate.
							<v-btn @click="setSelectAll()" color="info" dark text>Seleziona tutte</v-btn> le {{ totalItems }} presenze esistenti
						</template>
						<template v-else>
							Tutte le <span class="font-weight-bold">{{ totalItems }}</span> presenze sono state selezionate.
							<v-btn @click="cancelSelectAll()" color="info" dark text>Annulla la selezione</v-btn>
						</template>
					</v-alert>
				</v-expand-transition>
				<v-data-table class="background-2"
					:options.sync="pagination"
					:headers="headers"
					:items="attendances"
					:server-items-length="totalItems"
					:loading="loading"
					:search="search"
					v-model="selected" 
					:hide-default-footer="!$vuetify.breakpoint.mdAndUp"
					show-select
					:mobile-breakpoint="0"
					ref="vDataTable"
					:footer-props="{
						'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
						showFirstLastPage: true
					}">
					<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props"  @input="showSelectAll = $event" v-on="on"></v-simple-checkbox>
					</template>

					<template v-slot:body.prepend>
						<tr>
							<td>
								<v-icon >mdi-magnify</v-icon>
							</td>
							<td>
								<v-tooltip top v-if="!isEmptyColumnsFilter">
									<template v-slot:activator="{ on: tooltip }">
										<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
											<v-icon>mdi-progress-close</v-icon>
										</v-btn>
									</template>
									<span>Svuota Filtri</span>
								</v-tooltip>
							</td>
							<td class="pl-0">
							</td>
							<td class="pl-0">
								<v-select
									:items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
									v-model="columnsFilter['manual']"
									placeholder="Manuale"
									hide-details
									clearable
									dense
									solo
									flat									
								></v-select>
							</td>
							<td class="pl-0">
								<v-select
									:items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
									v-model="columnsFilter['completed']"
									placeholder="Completa"
									hide-details
									clearable
									dense
									solo
									flat									
								></v-select>
							</td>
							<td class="pl-0">
								<v-text-field
									dense
									solo
									flat
									placeholder="Persona"
									hide-details
									v-model="columnsFilter['full_name']"
								></v-text-field>
							</td>
							<td class="pl-0">
								<v-select
									:items="[{'text': 'Entrata', 'value': 'in'}, {'text': 'Uscita', 'value': 'out'}]"
									v-model="columnsFilter['type']"
									placeholder="Tipo"
									hide-details
									clearable
									dense
									solo
									flat									
								></v-select>
							</td>
							<td class="pl-0">
								<v-text-field
									dense
									solo
									flat
									placeholder="Turno"
									hide-details
									v-model="columnsFilter['shift']"
								></v-text-field>
							</td>
							<td>
								<v-text-field
									dense
									solo
									flat
									placeholder="Luogo"
									hide-details
									v-model="columnsFilter['place']"
								></v-text-field>
							</td>
						</tr>                                        
					</template>

					<template v-slot:item="{item, select, isSelected}">
						<tr class="cursor-pointer">
							<td>
							<v-simple-checkbox color="primary" :value="isSelected" @input="callSelect(select, $event)"></v-simple-checkbox>
							</td>
							<td style="white-space: nowrap">
								<actions-menu
									:item="item"
									:actions="actions"
								></actions-menu>
							</td>								
							<td @click="show(item)">
								{{ item.date | datetime }}
							</td>
							<td @click="show(item)">
								<div>
									<v-icon>{{ item.manual == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
								</div>
							</td>
							<td @click="show(item)">
								<div>
									<v-icon>{{ item.completed == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
								</div>
							</td>
							<td @click="show(item)">
								{{ item.full_name }}
							</td>
							<td>
								<v-chip text-color="white" small :color="item.type == 'out' ? 'red' : 'green'">{{ item.type == 'out' ? 'Uscita' : 'Ingresso' }}</v-chip>
							</td>
							<td @click="show(item)">
								{{ item.shift }}

							</td>
							<td @click="show(item)">
								{{ item.place }}
							</td>
						</tr>
					</template>

					<template v-slot:footer.page-text="{ pageStart, pageStop }">
						<div class="primary--text"><span>{{ selectedBE ? selectedBE : countSelected }}</span> Selezionati</div>
						Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
					</template>

					<template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
						<div class="text-center d-flex background pa-4">
							<v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
								<v-icon>mdi-chevron-left</v-icon>
							</v-btn>
							<v-text-field
								hide-details
								rounded
								solo
								dense
								prefix="#"
								label="Pagina"
								type="number"
								:min="1"
								:max="props.pagination.pageCount"
								v-model="newPageToJump">
								<template v-slot:append-outer>
									<v-btn dark color="primary" @click="jumpToPage()">
										Vai
									</v-btn>						
								</template>
							</v-text-field>
							<v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
								<v-icon>mdi-chevron-right</v-icon>
							</v-btn>					
						</div>
						<div class="text-center caption pr-4">
							<div class="primary--text"><span>{{ selectedBE ? selectedBE : countSelected }}</span> Selezionati</div>
							Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
						</div>

					</template>
				</v-data-table>
				

				<v-speed-dial
					:class="{'mb-16': $vuetify.breakpoint.smAndDown}"
					fixed
					v-model="fab"
					bottom
					right
					:loading="loading"
					color="primary"
					direction="left"
					transition="slide-x-transition">
					<template v-slot:activator>
						<v-btn
							v-model="fab"
							color="primary"
							dark
							fab>
							<v-icon v-if="fab">mdi-close</v-icon>
							<v-icon v-else>mdi-clock-outline</v-icon>
						</v-btn>
					</template>              
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								v-on="{ ...tooltip }"
								fab
								dark
								small
								@click="$router.push({name: 'attendances-create'})"
								color="indigo">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Nuovo</span>
					</v-tooltip>
					<v-tooltip top v-if="$store.getters.checkPermission('manual_attendances')">
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								v-on="{ ...tooltip }"
								fab
								dark
								small
								@click="create()"
								color="blue">
								<v-icon>mdi-map-marker-outline</v-icon>
							</v-btn>
						</template>
						<span>Timbrature Manuale</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">                             
							<v-btn
								v-on="{ ...tooltip }"
								fab
								dark
								small
								color="green"
								:depressed="disabled"
								:disabled="disabled"
								@click.native.stop="$eventBusUtility.openFile($store, '/attendances-csv/', 'timbrature.csv', 'POST', {'selected': selectedIds})">
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</template>
                		<span>Esporta CSV</span>   
					</v-tooltip>
				</v-speed-dial>
			
			</v-card>

			<footer-logo></footer-logo>

		</v-container>

		<attendance-crud
			:info="infoData"
		></attendance-crud>


		</v-col>
	</v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'
import AttendanceCrud from '@/components/modules/attendances/AttendanceCrud'

export default {
	name: 'attendances-crud',
	components: {
		HeaderCrud,
		AttendanceCrud,
	},
	mixins: [DataTableMixin],
	props: {
	},

	data() {
		return {
			disabled: true,
			fab: false,
			infoData: {},
			selected: [],
			search: '',
			totalItems: 0,
			attendances: [],
			loading: false,
			pagination: {
				'sortBy': ['date'],
				'sortDesc': [false],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
			apiTimeout: null,
			dialogEditor: false,
			dates: [moment().format('Y-MM-DD'), moment().format('Y-MM-DD')],
			headers: [
				{
					'text': 'Azioni',
					'value': 'actions',
					sortable: false,
				},
				{
					text: 'Data',
					value: 'date'
				},
				{
					text: 'Manuale',
					value: 'manual',
					width: '1%'
				},
				{
					text: 'Completa',
					value: 'completed',
					width: '1%'
				},
				{
					text: 'Persona',
					value: 'full_name'
				},
				{
					text: 'Tipo',
					value: 'type',
					width: '1%'
				},
				{
					text: 'Turno',
					value: 'shift',
					sortable: false,
				},
				{
					text: 'Luogo',
					value: 'place'
				},
			],			
		}
	},

	computed: {
		actions() {
			return [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editAttendance'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyAttendance',
					'condition_key': 'role',
					'condition_operator': 'in',
					'condition_value': ['staff', 'volunteer']
				},
			]
		},

	},

	watch: {
		dates: {
			handler(newVal) {
				if(newVal[0] && newVal[1])
					this.getData()
			},
			deep: true,
		},
		columnsFilter: {
			handler(newVal) {
				localStorage.setItem('attendances_columnsFilter', JSON.stringify(newVal))
			},
			deep: true,
		},
	},

	methods: {

		getData() {
			if(this.apiTimeout) {
				clearTimeout(this.apiTimeout)
			}

			this.apiTimeout = setTimeout(() => {

				this.loading = true
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					dates: []
				}

				if(moment(this.dates[1]).isBefore(this.dates[0])) {
					let d = this.dates[0]
					dataObject.dates[0] = this.dates[1]
					dataObject.dates[1] = d
				} else {
					dataObject.dates = this.dates
				}					

				this.axios.get('attendances/', {params: dataObject}).then(response => {
					this.loading = false
					this.attendances = response.data.data
					this.totalItems = response.data.meta.total

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)
		},


		create() {
			this.$eventBusUtility.$emit('createManualAttendance')
		},

	},

	created() {

		if (localStorage.getItem('attendances_columnsFilter')) {
			this.columnsFilter = JSON.parse(localStorage.getItem('attendances_columnsFilter'))
		}

		// Get info data for model
		this.loading = true
		this.axios.get('attendances-info').then(response => {
			// get info data
			this.infoData = response.data.info
			this.getData()
			this.$store.commit('END_LOADING')

		}, response => {
			// error callback
			this.loading = false
		})

			
	},

	mounted() {
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.getData()
		})
		// WS Listeners
		this.$echo.private(`organization.${this.$store.getters.getCurrentOrganization.id}.attendances`)
			.listen('.RefreshAttendances', (event) => {
				this.getData()
			})		
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
		this.$echo.leave(`organization.${this.$store.getters.getCurrentOrganization.id}.attendances`)
	},
}

</script>