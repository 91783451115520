

import { ifAuthenticatedOrTouchDevice } from '@/router/middleware/basic'
import Board from '@/components/modules/board/Board'


export const board = [
	{
		path: '/board',
		name: 'board',
		component: Board,
		beforeEnter: ifAuthenticatedOrTouchDevice,
		props: true
	},
]