import Vue from 'vue'
import auth from './auth'

function initialState() {
	return {
		base_url: '',
		loading: false,
		sidebar: false,
		is_mobile: false,
		dialTimeout: 800,
		modelEdited: false,
		publicPath: process.env.BASE_URL,
		online: true,
		generalTypes: {},
		bottomNavigator: false,
		snackbar: {
			timeout: 6000,
			value: false,
			color: '',
			text: ''
		  },
	}
}

const state = initialState

const getters = {

	isMiseApp: () => window.location.host.includes('mise'),

	getSidebar: state => state.sidebar,

	isLoading: state => state.loading,

	isMobile: state => state.is_mobile,

	getBaseUrl: state => state.base_url,

	getDialTimeout: state => state.dialTimeout,

	getSnackbar: state => state.snackbar,

	getGeneralTypes: state => state.generalTypes,

	isModelEdited: state => state.modelEdited,

	getPublicPath: state => state.publicPath,

	isOnline: state => state.online,

	isOffline: state => !state.online,


}

const mutations = {

	SET_GLOBAL_DEFAULT: (state) =>  {
		const s = initialState()
		Object.keys(s).forEach(key => {
			state[key] = s[key]
		})
	},

	SET_SIDEBAR: (state, value) => {
		state.sidebar = value
	},

	START_LOADING: state => {
		state.loading = true
	},

	END_LOADING: state => {
		state.loading = false
	},

	SET_MOBILE: (state, is_mobile) => {
		state.is_mobile = is_mobile
	},

	SET_SNACKBAR: (state, snackbar) => {
		state.snackbar = snackbar
	},

	SET_MODEL_EDITED: (state, modelEdited) => {
		state.modelEdited = modelEdited
	},

	GO_ONLINE: (state) => {
		state.online = true
	},
	  
  	GO_OFFLINE: (state) => {
		state.online = false
  	},

	SET_GENERALTYPES: (state, response) => {
		state.generalTypes = response.data
	}

}

const actions = {

	checkMobile: ({ commit }) => {
		if (typeof window.cordova === 'object') commit('SET_MOBILE', true)
		else commit('SET_MOBILE', false)
	},

	getGeneraltypes: ({ commit }) => {
		return new Promise((resolve, reject) => {

			Vue.axios({url: '/generaltypes', method: 'GET' }).then(response => {
				
				commit('SET_GENERALTYPES', response.data)

			}).catch(response => {
				reject(response)
			})
			
		})
	},	

}


export default {
	state,
	getters,
	mutations,
	actions
}
