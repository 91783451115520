<template>
    <div class="body-page mt-0">
        <v-btn
            color="green"
            fab
            dark
            small
            absolute
            right
            @click.native.stop="exportCSV()">
            <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-row class="ma-auto d-flex body-page">
            <v-subheader class="px-0">Prossime 5 Disponiblità</v-subheader>
            <v-container fluid class="pa-6 px-0" style="margin-top: 0px; height: 100%">
                <v-data-iterator
                    :items="availabilitiesFuture"
                    disable-pagination
                    hide-default-footer>
                    <template v-slot:default="{ items }">

                        <template v-for="(item, o) in items">

                            <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" min-height="60" style="overflow: hidden;" :key="o">
                                <div class="pa-2 d-inline-flex" style="width: 100%">

                                    <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                                        <div>
                                            <div class="body-2">{{ formatStartDate(item.started_at) }} - {{ formatEndDate(item.ended_at) }}<span class="font-weight-bold"> - {{ item.name }}</span></div>
                                            <div class="caption">{{ item.note }}</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </template>

                    </template>
                </v-data-iterator>
            </v-container>
        </v-row>
        <v-row class="ma-auto d-flex body-page">
            <v-subheader class="px-0">Ultime 5 Disponiblità</v-subheader>
            <v-container fluid class="pa-6 px-0" style="margin-top: 0px; height: 100%">
                <v-data-iterator
                    :items="availabilitiesPast"
                    disable-pagination
                    hide-default-footer>
                    <template v-slot:default="{ items }">

                        <template v-for="(item, o) in items">

                            <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" min-height="60" style="overflow: hidden;" :key="o" @click="goToShift(item)">
                                <div class="pa-2 d-inline-flex" style="width: 100%">

                                    <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                                        <div>
                                            <div class="body-2">{{ formatStartDate(item.started_at) }} - {{ formatEndDate(item.ended_at) }}<span class="font-weight-bold"> - {{ item.name }}</span></div>
                                            <div class="caption">{{ item.note }}</div>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </template>

                    </template>
                </v-data-iterator>
            </v-container>
        </v-row>
    </div>
</template>

<script>
export default {
	name: 'person-availabilities',
	props: {
		id: Number,
	},
        
	data() {
		return {
			loading: false,
			availabilitiesPast: [],
			availabilitiesFuture: [],
		}
	},

	computed: {
		formatStartDate(date) {
			return (date) => {
				let month = moment(date).format('ddd, D MMM YYYY [dalle] HH:mm')
				return month.charAt(0).toUpperCase() + month.slice(1)                
			}
		},

		formatEndDate(date) {
			return (date) => {
				let month = moment(date).format('ddd, D MMM [alle] HH:mm')
				return month.charAt(0).toUpperCase() + month.slice(1)                
			}
		}        
	},

	methods: {

		goToShift(shift) {
		    this.$router.push({ name: 'calendar', params: { element: parseInt(shift.id), tab: shift.calendar_id, started_at: shift.started_at}})
		},

		exportCSV() {
			let csv = ['Prossime 5 Disponiblita']
			csv.push('\n')
			let headers = ['Data Inizio', 'Data Fine', 'Turno']
			let row = []

			csv.push(headers.join(';'))

			// Write first row of headers
			// Take all tr -> forEach tr take cell -> forEach cell check if is checked
			let rows = _.each(this.availabilitiesFuture, (obj) => {
				row = []
				_.forEach(['started_at', 'ended_at', 'name'], (header) => {
					let val = obj[header]
					if(!isNaN(val) && val != undefined)
						val = val.toString()

					row.push(val)
				})
				csv.push(row.join(';'))

			})

			csv.push('')
			csv.push('Ultime 5 Disponiblita')
			csv.push(headers.join(';'))

			rows = _.each(this.availabilitiesPast, (obj) => {
				row = []
				_.forEach(['started_at', 'ended_at', 'name'], (header) => {
					let val = obj[header]
					if(!isNaN(val) && val != undefined)
						val = val.toString()

					row.push(val)
				})
				csv.push(row.join(';'))

			})


			let csvFile
			let downloadLink
			// CSV file
			csvFile = new Blob([csv.join('\n')], {type: 'text/csv'})
			// Download link
			downloadLink = document.createElement('a')
			// File name
			downloadLink.download =  'disponibilita.csv'
			// Create a link to the file
			downloadLink.href = window.URL.createObjectURL(csvFile)
			// Hide download link
			downloadLink.style.display = 'none'
			// Add the link to DOM
			document.body.appendChild(downloadLink)
			// Click download link
			downloadLink.click()
			document.body.removeChild(downloadLink)
            
		}

	},

	created() {
		this.loading = true
		this.axios.get('people-availabilities/' + this.id).then(response => {
                
			this.availabilitiesPast = response.data.data.availabilitiesPast
			this.availabilitiesFuture = response.data.data.availabilitiesFuture
			this.loading = false

		}, response => {
			this.loading = false
		})
	}
}
</script>

<style>

</style>