<template>
    <v-dialog
        v-model="dialogEditor"
        fullscreen
        hide-overlay
        transition="scroll-x-reverse-transition"
        scrollable
        style="overflow-x: hidden">
        <v-card tile>
            <v-card-title class="pa-6" style="position: fixed">
                <v-row no-gutters class="d-block">
                    <template v-if="confirmDelete">
                        <div class="pl-0 font-weight-bold">Elimina Lezione</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                    </template>
                    <template v-else-if="model.id">
                        <div class="pl-0 font-weight-bold">Modifica Lezione</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                    </template>
                    <template v-else>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuova Lezione</div>
                    </template>
                </v-row>
            </v-card-title>
            <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                <v-container class="px-0 justify-center" fluid>
                    
                    <validation-observer ref="lessonFields">
                        <v-row no-gutters>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                <validation-provider name="Titolo Lezione" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        type="text"
                                        label="Titolo Lezione*"
                                        v-model="model['name']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        ref="name"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                                    <v-textarea
                                        type="text"
                                        label="Descrizione"
                                        v-model="model['description']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-text-field
                                    label="Formatore"
                                    outlined
                                    dense
                                    v-model="model['trainer']"
                                ></v-text-field>
                            </v-col>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-autocomplete class="font-weight-regular"
                                    label="Aula"
                                    v-model="model['room_id']"
                                    item-value="id"
                                    item-text="name"
                                    clearable
                                    small-chips
                                    :items="info.rooms"
                                    dense
                                    outlined>
                                </v-autocomplete>
                            </v-col>                                
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-menu
                                    ref="date"
                                    :close-on-content-click="false"
                                    v-model="datepicker_menu['date']"
                                    :return-value.sync="model['date']"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    :nudge-right="40"
                                    style="width: 100%">
                                    <template v-slot:activator="{ on }">
                                        <validation-provider name="Data" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                v-on="on"
                                                label="Data*"
                                                append-icon="event"
                                                :error-messages="errors"
                                                readonly
                                                :value="$eventBusUtility.formatDate(model['date'])"
                                                outlined
                                                @click:clear="model['date'] = ''"
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker 
                                        v-model="model['date']"
                                        color="primary"
                                        locale="It"
                                        scrollable
                                        :first-day-of-week="1"
                                        @input="$refs['date'].save(model['date'])">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <validation-provider name="Ora di Inizio" rules="required|is_time" v-slot="{ errors }">
                                    <v-text-field
                                        label="Ora di Inizio*"
                                        v-model="model['start']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        v-mask="'##:##'"
                                        return-masked-value						
                                        ref="start"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <validation-provider name="Ora di Fine" rules="required|is_time" v-slot="{ errors }">
                                    <v-text-field
                                        label="Ora di Fine*"
                                        v-model="model['end']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        v-mask="'##:##'"
                                        return-masked-value						
                                        ref="end"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    </validation-observer>
                </v-container>
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi</span>
                </v-tooltip>
                <v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
                <v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
                <v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
	name: 'lesson-editor',
	props: {
		course: Object,
		info: Object,
	},    
	data() {
		return {
			model: {},
			saved: false,
			dialog: false,
			loading: false,
			dialogShow: false,
			dialogEditor: false,
			confirmDelete: false,
			datepicker_menu: {}
		}
	},

	created() {
	},


	methods: {
		closeDialog() {
			this.dialogShow = false
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(id) {
			this.axios.get('lessons/edit/' + id).then(response => {
                
				this.dialogShow = false
				this.dialogEditor = true
				this.model = {...response.data.data}
				this.$set(this.model, 'date', moment(this.model.start).format('YYYY-MM-DD'))
				this.$set(this.model, 'start', moment(this.model.start).format('HH:mm'))
				this.$set(this.model, 'end', moment(this.model.end).format('HH:mm'))
				this.loading = false

			}, response => {
				this.loading = false
			})
		}, 

		show(item) {					
			this.dialogShow = true
			this.model = {...item}
		},

		create() {
			this.dialogEditor = true
		},

		confirmDestroy() {
			this.dialogShow = true
			this.confirmDelete = true
		},        

		destroy() {
			this.closeDialog()
		},

		save() {
			this.$refs.lessonFields.validate().then(result => {
				if (!result) {
					return false
				}
				let data = {...this.model}
				data.start = data.date + ' ' + data.start + ':00'
				data.end = data.date + ' ' + data.end + ':00'
				this.loading = true
				if(this.model.id) {
					this.axios.put('lessons/' + this.model.id, data).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
						this.$eventBusUtility.$emit('refreshCourse', this.course.id)
						this.closeDialog()
					}, response => {
						this.loading = false
					})
				} else {
					data.course_id = this.course.id
					this.axios.post('lessons', data).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
						this.$eventBusUtility.$emit('refreshCourse', this.course.id)
						this.closeDialog()
					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})                
		},

		deleteLesson() {
			this.loading = true
			this.axios.delete('lessons/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
				this.$eventBusUtility.$emit('refreshCourse', this.course.id)
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},         

	},


	mounted() {
		this.$eventBusUtility.$on('showLesson', (item) => {
			this.show(item.id)
		}),

		this.$eventBusUtility.$on('editLesson', (item) => {
			this.edit(item.id)
		}),
               
		this.$eventBusUtility.$on('createLesson', () => {
			this.create()
		})
            
		this.$eventBusUtility.$on('destroyLesson', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('showLesson')
		this.$eventBusUtility.$off('editLesson')
		this.$eventBusUtility.$off('createLesson')
		this.$eventBusUtility.$off('destroyLesson')
	},      
}
</script>