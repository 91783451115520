<template>
    <v-card class="rounded-lg" style="border: 2px solid #E0E0E0">
        <v-img v-if="post.image" max-height="250" :src="post.image"></v-img>
        <v-card-title>
            {{ post.title }}
            <v-spacer></v-spacer>
            <actions-menu
                v-if="$store.getters.isAuth && ($store.getters.checkPermission('manage_other_posts') || post.person_id == $store.getters.getUserPerson.id)"
                :item="post"
                :actions="actions"
            ></actions-menu>
        </v-card-title>

        <v-card-subtitle>
            {{ post.person ? post.person.full_name : '--' }} - 
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ moment(post.created_at).fromNow() }}</span>
                </template>
                <span>{{ moment(post.created_at).format('dddd d MMMM YYYY [ore] HH:mm') }}</span>
            </v-tooltip>
            <v-spacer></v-spacer>
        </v-card-subtitle>

        <v-card-text class="text--primary">
            <v-row align="center" class="mx-0">
                <div class="" style="width: 100%; min-height: 1px!important;" v-html="post.body"></div>
            </v-row>
        </v-card-text>

        <v-card-text class="" v-if="!post.public">
            <v-row>
                <v-col class="pb-0">
                    <v-tooltip bottom v-if="post.people.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ post.people.length }} Destinatari{{ post.people.length == 1 ? 'o' : '' }}</span>
                        </template>
                        <span>{{ listSelectedNames }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>
        
        <v-card-text class="pt-0">
            <v-row>
                <v-col class="pb-0">
                    <v-tooltip bottom v-if="post.reactions && post.reactions.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ post.reactions.length }} Love</span>
                        </template>
                        <span>{{ listReactionsNames }}</span>
                    </v-tooltip>
                </v-col>
                <v-col class="text-right pb-0">
                    <v-tooltip bottom v-if="post.comments && post.comments.length > 0">
                        <template v-slot:activator="{ on, attrs }">
                            <span
                            v-bind="attrs"
                            v-on="on"
                            @click="toggleComments()"
                            >{{ post.comments.length }} Comment{{ post.comments.length == 1 ? 'o' : 'i' }}</span>
                        </template>
                        <span>{{ listCommentsNames }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions>
            <v-btn color="primary" text @click="love">
                <v-icon v-if="hasReaction" :size="20" class="pr-2">mdi-heart</v-icon>
                <v-icon v-else :size="20" class="pr-2">mdi-heart-outline</v-icon>
                Love
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="toggleComments()">
                <v-icon :size="20" class="pr-2">mdi-comment-text-outline</v-icon>
                Commenta
            </v-btn>
        </v-card-actions>

            <v-card v-if="post.showComments" >
                <v-divider class="mx-4"></v-divider>
                <v-card-text class="pl-0 pr-1">
                    <post-comments :post-id="post.id" :comments="post.comments"></post-comments>
                </v-card-text>
            </v-card>

    </v-card>
</template>

<script>
import PostComments from './PostComments'

export default {
	name: 'post-card',
	components : {
		PostComments
	},
	props: {
		post: Object
	},
	data() {
		return {
			fab: false,
			editPost: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editPost'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyPost',
				},
			],
		}
	},

	mounted() {
	},

	computed: {

		listSelectedNames() {
			return this.post.people.map(person => {
				return person.full_name
			}).join(', ')
		},

		listCommentsNames() {
			return this.post.comments.map(comment => {
				return comment.person ? comment.person.full_name : '--'
			}).join(', ')
		},
        
		listReactionsNames() {
			return this.post.reactions.map(reaction => {
				return reaction.person ? reaction.person.full_name : '--'
			}).join(', ')
		},        

		hasReaction() {
			return this.$store.getters.isAuth && this.post.reactions.find(reaction => {
				return reaction.person && reaction.person.id == this.$store.getters.getUserPerson.id
			})
		},
	},

	methods: {

		love() {
			if(!this.$store.getters.isAuth) {
				return
			}
			let url = ''
			if(this.hasReaction) {
				url = 'removeLove'
			} else {
				url = 'addLove'
			}
            
			this.axios.post('posts/'+this.post.id+'/'+url).then(response => {})
		},
        
		toggleComments() {
			this.$set(this.post, 'showComments', !!!this.post.showComments)
			setTimeout(() => { 
				this.$eventBusUtility.$emit('redrawVueMasonry')
			}, 5)

		},
        
		
	},
}
</script>