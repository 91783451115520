<template>
    <v-dialog v-model="open" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">{{ title }}</div>                
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="px-0 pt-6 mx-0">
                    <v-row v-if="confirmDelete" class="mx-0 justify-center py-6">
                        <div class="caption" >Sei sicuro di voler eliminare questa Destinazione?</div>
                    </v-row>

                    <validation-observer ref="addDestinationFields" v-else>

                        <v-row class="pb-2 mx-0 pt-2">
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <validation-provider name="Ora di Inizio" rules="required|is_time" v-slot="{ errors }">
                                    <v-text-field
                                        label="Ora di Inizio*"
                                        v-model="model['schedule']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        v-mask="'##:##'"
                                        return-masked-value						
                                        ref="schedule"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="$store.getters.checkModulePermissions('vehicles')">
                                <v-autocomplete class="font-weight-regular"
                                    label="Veicolo"
                                    v-model="model['vehicle_id']"
                                    clearable
                                    small-chips
                                    :items="info.vehicles"
                                    item-value="id"
                                    item-text="name"
                                    dense
                                    outlined>
                                </v-autocomplete>
                            </v-col>

                            <v-col class="col-12 py-0 px-6 pt-3">
                                <v-text-field
                                    type="text"
                                    label="Luogo"
                                    v-model="model['location']"
                                    outlined
                                    dense
                                    ref="location"
                                ></v-text-field>
                            </v-col>

                        </v-row>

                    </validation-observer>

                </v-container>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="deleteDestination()">Elimina</v-btn>
				<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
	name: 'destination-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			model: {},
			shift: {},
			open: false,
			fullScreen: false,
			loading: false,
			confirmDelete: false,
			datepicker_menu: {},
		}
	},

	computed: {
		title () {
			if(this.confirmDelete) {
				return 'Elimina Destinazione'
			} else if(this.model.id) {
				return 'Modifica Destinazione'
			} else {
				return 'Aggiungi Destinazione'
			}
		}
	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('addDestination', (shift) => {
			this.openCreateDialog(shift)
		})
		this.$eventBusUtility.$on('editDestination', (destination) => {
			this.openEditDialog(destination)
		})
		this.$eventBusUtility.$on('deleteDestination', (destination) => {
			this.confirmDelete = true
			this.openEditDialog(destination)
		})
	},

	methods: {
    
		openCreateDialog(shift) {
			this.shift = {...shift}
			this.model = {shift_id: this.shift.id, schedule: moment(this.shift.started_at).format('HH:mm')}
			this.open = true
		},

		openEditDialog(destination) {
			this.model = {...destination}
			this.open = true
		},

		closeDialog() {
			this.model = _.stubObject()
			this.open = false
			this.confirmDelete = false
		},

		save() {
			this.$refs.addDestinationFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				// is in edit
				let data = this.model
				if(data.id) {
					this.axios.put('destinations/' + data.id, data).then(response => {
				        this.loading = false
						this.$eventBusUtility.$emit('refreshCalendar')
						this.closeDialog()
    
					}, response => {
				        this.loading = false
					})
				} else {
					this.axios.post('destinations/', data).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshCalendar')
						this.closeDialog()
    
					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})
		},

		deleteDestination() {
			this.loading = true
			this.axios.delete('/destinations/' + this.model.id).then(response => {
				this.loading = false
				this.closeDialog()
				this.$eventBusUtility.$emit('refreshCalendar')

			}, response => {
				this.loading = false
			})
		}        

	},

	beforeDestroy() {
		this.$eventBusUtility.$off('addDestination')
		this.$eventBusUtility.$off('editDestination')
		this.$eventBusUtility.$off('deleteDestination')
	},    

}
</script>

<style>

</style>