<template>
	<v-app>

		<v-navigation-drawer
			v-if="$store.getters.isAuth || $store.getters.isTouchDevice"
			:clipped="$vuetify.breakpoint.lgAndUp"
			v-model="sidebarStatus"
			width="250"
			floating
			app>

			<v-list dense shaped class="pr-0">
				<v-list-item v-if="!$store.getters.isAuth && $store.getters.isTouchDevice">
					<v-list-item-content>
						<v-list-item-title><span class="primary--text">{{ $store.getters.getCurrentOrganization.name }}</span></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-group
					v-else
					:value="false" class="main-menu">
					<template v-slot:activator>
						<template v-if="$store.getters.isMiseApp">
							<v-list-item-title class="primary--text">{{ $store.getters.getCurrentOrganization.name }}</v-list-item-title>
						</template>
						<template v-else>
							<v-list-item-avatar v-if="!$store.getters.getUserProfile.avatar" class="mr-2">
								<v-icon x-large>mdi-account-circle</v-icon>
							</v-list-item-avatar>
							<v-avatar v-else
								class="mr-2 custom-avatar"
								:size="40"
								:style="{'background-image': 'url(' + $store.getters.getUserProfile.avatar + ')'}">
							</v-avatar>
							<v-list-item-content>
								<v-list-item-title class="title" style="font-size: 14px !important">{{ $store.getters.getUserProfile.name }} {{ $store.getters.getUserProfile.surname }}</v-list-item-title>
								<v-list-item-subtitle class="primary--text">{{ $store.getters.getCurrentOrganization.name }}</v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</template>
					<template v-if="$store.getters.getUserOrganizations.length > 0">
						<v-list-item class="pr-3"
							v-for="(organization, o) in $store.getters.getUserOrganizations"
							:key="o"
							link
							@click="changeOrganization(organization)">
							<v-list-item-title style="font-size: 13px !important">{{ organization.name }}</v-list-item-title>
							<v-list-item-icon class="ml-2">
								<v-icon style="font-size: 11px !important" :color="$store.getters.getCurrentOrganization.id === organization.id ? 'success' : 'grey'">mdi-checkbox-blank-circle</v-icon>
							</v-list-item-icon>
						</v-list-item>
					</template>
					<v-list-item v-else>
						<v-list-item-content>
							<v-list-item-title>Nessuna Associazione</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>

			<v-divider></v-divider>

			<v-list dense shaped>
				<template v-if="$store.getters.isMiseApp">
					<template v-for="(section, s) in $store.getters.getUserSections">
						<v-list-item v-if="section.status && checkSectionPermissions(section.route)" link exact :to="{ name: section.route }" :disabled="section.status === false" :key="s">
							<v-list-item-action>
								<v-badge v-if="section.route == 'requests'" overlap bordered bottom :content="$store.getters.getRequestsCount" :value="$store.getters.getRequestsCount != 0"><v-icon>{{ section.icon }}</v-icon></v-badge>
								<v-icon v-else>{{ section.icon }}</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ section.trans | trans }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</template>
				<!-- <template v-else>
					<template v-for="(section, s) in $store.getters.getUserSections">
						<v-list-item link exact v-if="checkSectionPermissions(section.route)" :to="{ path: $store.getters.checkModulePermissions(section.route) ? '/'+section.route : '/module-disabled/'+section.route }" :class="{'module-disabled': !$store.getters.checkModulePermissions(section.route) && section.route != 'requests'}" :key="s">
							<v-list-item-action>
								<v-badge v-if="section.route == 'requests'" overlap bordered bottom :content="$store.getters.getRequestsCount" :value="$store.getters.getRequestsCount != 0"><v-icon>{{ section.icon }}</v-icon></v-badge>
								<v-icon v-else>{{ section.icon }}</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>{{ section.trans | trans }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
				</template> -->
				<template v-else-if="$store.getters.getUserSections.length > 0">
					<v-list-item link exact :to="{ path: $store.getters.checkModulePermissions('board') ? '/board' : '/module-disabled/board' }" :class="!$store.getters.checkModulePermissions('board') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-view-dashboard-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Bacheca</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ path: $store.getters.checkModulePermissions('calendar') ? '/calendar' : '/module-disabled/calendar' }" :class="!$store.getters.checkModulePermissions('calendar') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-calendar-today</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Calendario</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('people')" link exact :to="{ path: $store.getters.checkModulePermissions('people') ? '/people' : '/module-disabled/people' }" :class="!$store.getters.checkModulePermissions('people') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-account-supervisor-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Persone</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('vehicles')" link exact :to="{ path: $store.getters.checkModulePermissions('vehicles') ? '/vehicles' : '/module-disabled/vehicles' }" :class="!$store.getters.checkModulePermissions('vehicles') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-truck-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Veicoli</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ path: $store.getters.checkModulePermissions('analytics') ? '/analytics' : '/module-disabled/analytics' }" :class="!$store.getters.checkModulePermissions('analytics') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-equalizer-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Statistiche</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ path: $store.getters.checkModulePermissions('courses') ? '/courses' : '/module-disabled/courses' }" :class="!$store.getters.checkModulePermissions('courses') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-book-open-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Corsi</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('checklists')" link exact :to="{ path: $store.getters.checkModulePermissions('checklists') ? '/checklists' : '/module-disabled/checklists' }" :class="!$store.getters.checkModulePermissions('checklists') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-clipboard-text</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Checklist</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ path: $store.getters.checkModulePermissions('checklists') ? '/compile_checklists' : '/module-disabled/checklists' }" :class="!$store.getters.checkModulePermissions('checklists') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-clipboard-check-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Compila Checklist</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('attendances')" link exact :to="{ path: $store.getters.checkModulePermissions('attendances') ? '/attendances' : '/module-disabled/attendances' }" :class="!$store.getters.checkModulePermissions('attendances') ? 'module-disabled' : ''">
						<v-list-item-action>
							<v-icon>mdi-clock-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Timbrature</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="$store.getters.checkModulePermissions('handbook')" link exact :to="{ name: 'handbook' }">
						<v-list-item-action>
							<v-icon>mdi-book-check-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Prontuario</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('settings')" link exact :to="{ name: 'settings' }">
						<v-list-item-action>
							<v-icon>mdi-cog-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Impostazioni</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item v-if="checkSectionPermissions('requests')" link exact :to="{ name: 'requests' }">
						<v-list-item-action>
							<v-badge overlap bordered bottom :content="$store.getters.getRequestsCount" :value="$store.getters.getRequestsCount != 0">
								<v-icon>mdi-face-agent</v-icon>
							</v-badge>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Richieste</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>

				<!-- <template v-if="$store.getters.getUserSections.length == 0 && !$store.getters.isMiseApp"> -->
				<template v-if="!$store.getters.isMiseApp">
					<v-list-item link exact :to="{ name: 'organizations'}">
						<v-list-item-action>
							<v-icon>mdi-home-city-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Associazioni</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ name: 'privacy'}">
						<v-list-item-action>
							<v-icon>mdi-shield-check-outline</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>{{ 'privacy' | trans }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
				<v-list-item v-if="$store.getters.isAuth" link exact @click="logout()">
					<v-list-item-action>
						<v-icon color="primary">mdi-exit-to-app</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Esci</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<template v-else-if="$store.getters.isTouchDevice && !$store.getters.isMiseApp">
					<v-list-item link exact :to="{ name: 'local-attendance' }" v-if="$store.getters.checkModulePermissions('attendances') && $store.getters.getPlaceAttendance">
						<v-list-item-action>
							<v-icon>mdi-clock</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Timbratura</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link exact :to="{ name: 'login' }">
						<v-list-item-action>
							<v-icon color="primary">mdi-login</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>Login</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-navigation-drawer>


	<v-app-bar
		v-if="$store.getters.isAuth || $store.getters.isTouchDevice"
		:clipped-left="$vuetify.breakpoint.lgAndUp"
		color="primary"
		dark
		app>
		<v-btn @click.stop="sidebarStatus = !sidebarStatus" icon class="d-print-none"><v-icon>mdi-text-short</v-icon></v-btn>
		<v-toolbar-title style="width: 300px" class="ml-0 pl-4" color="primary">
			<span class="hidden-sm-and-down">{{title}}</span>
		</v-toolbar-title>
		<v-spacer></v-spacer>

		<notifications-menu v-if="$store.getters.isAuth && $vuetify.breakpoint.mdAndUp" class="d-print-none"></notifications-menu>

		<profile-menu v-if="$store.getters.isAuth" class="d-print-none"></profile-menu>

	</v-app-bar>
	
		<v-fade-transition>
			<centered-loader v-show="$store.getters.isLoading" :size="50" color="primary" ></centered-loader>
		</v-fade-transition>

		<v-main>
			<v-container fluid class="pa-0 fill-height d-block">
				<v-slide-x-transition>
					<router-view v-if="!$store.getters.isLoading" :key="$route.query.t"></router-view>
				</v-slide-x-transition>
			</v-container>
		</v-main>

		<v-bottom-navigation v-if="$store.getters.showBottomNavigator"
			color="primary"
			height="72"
			grow
			app>
			<v-btn @click="$eventBusUtility.navigateTo('board')" class="pb-3">
				<span class="bottom-nav">Bacheca</span>
				<v-icon>mdi-view-dashboard-outline</v-icon>
			</v-btn>

			<v-btn @click="$eventBusUtility.navigateTo('calendar')" class="pb-3">
				<span class="bottom-nav">Calendario</span>
				<v-icon>mdi-calendar-today</v-icon>
			</v-btn>

			<v-btn @click="$eventBusUtility.navigateTo('profile')" class="pb-3">
				<span class="bottom-nav">Profilo</span>
				<v-icon>mdi-account-outline</v-icon>
			</v-btn>

			<v-btn @click="$eventBusUtility.navigateTo('notifications')" class="pb-3">
				<span class="bottom-nav">Notifiche</span>
				<v-icon>mdi-bell-outline</v-icon>
			</v-btn>
		</v-bottom-navigation>


		<v-snackbar class="text-center"
			:timeout="$store.getters.getSnackbar.timeout"
			:color="$store.getters.getSnackbar.color"
			v-model="snackbarValue"
			multi-line>
			{{ $store.getters.getSnackbar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click.native="$store.commit('SET_SNACKBAR', {timeout: 6000, text: '', value: false, color: ''})">Chiudi</v-btn>
			</template>		
		</v-snackbar>        
	</v-app>

</template>

<script>

import ProfileMenu from './components/common/ProfileMenu'
import NotificationsMenu from '@/components/common/NotificationsMenu'

export default {
	name: 'App',
	components: {
		ProfileMenu,
		NotificationsMenu
	},

	data() {
		return {
			title: 'VolontApp'
		}
	},

	watch: {
		'$vuetify.breakpoint': {
			handler() {
				this.$store.commit('SET_BOTTOM_NAVIGATOR', this.$store.getters)
			},
			deep: true
		}
	},

	computed: {

		snackbarValue: {
			get() {
				return this.$store.getters.getSnackbar.value
			},
			set(newValue) {
				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: '',
					value: newValue,
					color: ''
				})
			}
		},

		sidebarStatus: {
			get() {
				return this.$store.getters.getSidebar
			},
			set(newValue) {
				this.$store.commit('SET_SIDEBAR', newValue)
			}
		},

	},
		
	created() {
		if(this.$store.getters.isMiseApp) {
			this.title = 'Gestione Turni'
		}
	},

	mounted() {
	},

	beforeCreate() {
		this.$store.dispatch('checkMobile')

		if(this.$store.getters.isAuth || this.$store.getters.isTouchDevice) {
			this.$store.commit('START_LOADING')
			this.$store.commit('SET_SIDEBAR', this.$vuetify.breakpoint.lgAndUp)
				
			this.$store.dispatch('fetchUser').then(() => {
				this.$store.commit('END_LOADING')
			})
		}
	},

	methods: {

		logout() {
			this.$store.dispatch('authLogout')          
		},

		changeOrganization(organization) {
			this.$store.dispatch('changeOrganization', organization).then(() => {
				this.$router.go()
			})
		},

		checkSectionPermissions(section) {
			let sectionsByModule = this.$store.getters.getUserSectionsByModule

			if(section == 'requests') {
				return this.$store.getters.getUserPerson ? this.$store.getters.getUserPerson.role == 'superuser' : false
			}
			if(typeof sectionsByModule[section] == 'undefined') {
				return false
			}
			if(this.$store.getters.isTouchDevice && !this.$store.getters.isAuth && !['board', 'calendar'].includes(sectionsByModule[section].route)) {
				return false
			}
			if(sectionsByModule[section].route == 'vehicles') {
				return this.$store.getters.checkPermission('show_vehicles')
			}
			if(sectionsByModule[section].route == 'analytics') {
				return (this.$store.getters.getUserPerson && ['superuser', 'staff'].includes(this.$store.getters.getUserPerson.role)) || !this.$store.getters.checkSetting('calendar', 'hide_global_statistics')
			}
			if(sectionsByModule[section].route == 'people') {
				return this.$store.getters.checkPermission('show_people')
			}
			if(sectionsByModule[section].route == 'checklists') {
				return this.$store.getters.checkPermission('show_checklists')
			}


			return ((this.$store.getters.getUserPerson && this.$store.getters.getUserPerson.role == 'superuser') || section != 'settings')
		},


	},
}
</script>
<style>

	html { overflow-y: auto }

	.theme--light.v-application {
		background-color: var(--v-background-base, white) !important;
	}

	.v-input {
		font-size: 14px !important;
	}
	.v-label {
		font-size: 14px !important;
	}
	.v-list-item__title {
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 14px !important;
	}    
	.v-input-group--text-field input, .v-input-group--text-field textarea {
		font-size: 14px !important;
	}
	.v-input-group--select .input-group__selections__comma {
		font-size: 14px !important;
	}
	.datatable__actions__select .input-group--select .input-group__selections__comma {
		font-size: 12px !important;
	}
	.v-data-table td {
		font-size: 12px !important;
		height: 48px !important;
	}
	table.v-table thead td:not(:nth-child(1)), table.v-table tbody td:not(:nth-child(1)), table.v-table thead th:not(:nth-child(1)), table.v-table tbody th:not(:nth-child(1)), table.table thead td:first-child, table.table tbody td:first-child, table.table thead th:first-child, table.table tbody th:first-child {
		padding: 0 8px !important;
	}
	.pb-offset {
		padding-bottom: 20% !important;
	}
	p {
		margin-bottom: 0 !important;
	}
    .v-input__append-outer {
        margin: auto !important;
        margin-left: 12px !important;
    }
	.body-page {
		border-radius: 25px 25px 0px 0px;
		background-color:#f5f7fa;
		margin-top:160px;
		z-index:1;
		position: sticky
	}
	
	.bottom-nav {
		font-size: 0.60rem !important;
		line-height: 1.8 !important;
	}

	.open-nav {
		width: calc(100% - 250px) !important;
		transition: .2s cubic-bezier(.4,0,.2,1);
	}
	.rounded-search .v-progress-linear {
		height: 2px;
    	width: calc(100% - 28px);
    	margin-left: 14px;
	}

	.module-disabled {
		opacity: 0.6;
	}
	.main-menu .v-list-group__header {
		padding-left: 10px;
	}

	/* .v-input--checkbox .v-input__slot {
		height: 40px;
		border-radius: 4px;
		background-color: white;
	} */
	
</style>
