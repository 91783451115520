
import store from '@/store'


export function ifAuthenticated(to, from, next) {
	if (store.getters.isAuth) {
		next()
		return
	}
	if (from.name == 'login') {
		store.commit('END_LOADING')
	}

	if(store.getters.isMiseApp) {
		next('/disabled')
		return
	}
	next('/login')
}

export function ifAuthenticatedOrTouchDevice(to, from, next) {
	if (store.getters.isAuth || store.getters.isTouchDevice) {
		next()
		return
	}
	if (from.name == 'login') {
		store.commit('END_LOADING')
	}

	if(store.getters.isMiseApp) {
		next('/disabled')
		return
	}
	next('/login')
}

export function ifTouchDevice(to, from, next) {
	if (store.getters.isTouchDevice) {
		next()
		return
	}
	if (from.name == 'login') {
		store.commit('END_LOADING')
	}

	if(store.getters.isMiseApp) {
		next('/disabled')
		return
	}
	next('/login')
}

export function ifAuthenticatedAndSuperuser(to, from, next) {
	if (store.getters.isAuth && store.getters.getUserPerson.role == 'superuser') {
		next()
		return
	}
	next('/')
}

export function ifNotAuthenticated(to, from, next) {
	if(to.name == 'login' && store.getters.isMiseApp) {
		next('/disabled')
		return
	}
	if (!store.getters.isAuth) {
		next()
		return
	}
	next('/')
}