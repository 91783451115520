<template>
    <v-dialog v-model="open" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card class="rounded-lg pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Modifica Ricorrenza</div>
                    <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ model.name ? model.name : ''}} {{ model.shifttype ? '['+ model.shifttype.name +']' : '' }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="px-0 pt-6 mx-0">
                    <validation-observer ref="editRecurrenceFields">

                            <v-row class="pb-2 mx-0 pt-2">
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Nome"
                                        v-model="model['name']"
                                        outlined
                                        dense
                                        ref="name"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Tipo Turno" rules="required" v-slot="{ errors }">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Tipo Turno*"
                                            v-model="model['shifttype_id']"
                                            :error-messages="errors"
                                            item-value="id"
                                            item-text="name"
                                            clearable
                                            small-chips
                                            :items="info.shifttypes"
                                            dense
                                            outlined>
                                        </v-autocomplete>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Ora di Inizio" rules="required|is_time" v-slot="{ errors }">
                                        <v-text-field
                                            label="Ora di Inizio*"
                                            v-model="model['start_time']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            v-mask="'##:##'"
                                            return-masked-value						
                                            ref="start_time"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>                                

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Ora di Fine" rules="required|is_time" v-slot="{ errors }">
                                        <v-text-field
                                            label="Ora di Fine*"
                                            v-model="model['end_time']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            v-mask="'##:##'"
                                            return-masked-value						
                                            ref="end_time"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="$store.getters.checkModulePermissions('vehicles')">
                                    <v-autocomplete class="font-weight-regular"
                                        label="Veicolo"
                                        v-model="model['vehicle_id']"
                                        clearable
                                        item-value="id"
                                        item-text="name"
                                        small-chips
                                        :items="info.vehicles"
                                        dense
                                        outlined>
                                    </v-autocomplete>
                                </v-col>


                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Note" rules="required|integer" v-slot="{ errors }">
                                        <v-text-field
                                            type="number"
                                            label="Punteggio*"
                                            v-model="model['score']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="score"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 py-0 px-6 pt-3">
                                    <v-text-field
                                        type="text"
                                        label="Luogo"
                                        v-model="model['location']"
                                        outlined
                                        dense
                                        ref="location"
                                    ></v-text-field>
                                </v-col>

                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Note" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                            label="Note"
                                            name="note"
                                            v-model="model['note']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="note"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0">
                                    <v-checkbox
                                        label="Solo Future"
                                        v-model="model['only_future']"
                                        color="primary"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>

                            </v-row>

                    </validation-observer>

                </v-container>

            </v-card-text> 
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import Vue from 'vue'

export default {
	name: 'edit-recurrence-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			model: {},
			open: false,
			fullScreen: false,
			loading: false,
			datepicker_menu: {},
		}
	},

	computed: {
	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('editRecurrence', (data) => {
			this.openEditDialog(data)
		})
	},

	methods: {
    

		openEditDialog(data) {
			this.model = {...data}
			Vue.set(this.model, 'start_time', moment(this.model.started_at).format('HH:mm'))
			Vue.set(this.model, 'end_time', moment(this.model.ended_at).format('HH:mm'))
			Vue.set(this.model, 'only_future', true)
			this.open = true
		},

		closeDialog() {
			this.model = _.stubObject()
			this.open = false
		},

		save() {
			this.$refs.editRecurrenceFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				// is in edit
				let data = this.model
				data.started_at = moment(this.model.started_at).format('YYYY-MM-DD') + ' ' + this.model.start_time + ':00'
				data.ended_at = moment(this.model.ended_at).format('YYYY-MM-DD') + ' ' + this.model.end_time + ':00'
				data.edit_recurrence = true

				this.axios.put('shifts/' + this.model.id, data).then(response => {
					// get body data
					this.loading = false
					this.closeDialog()

				}, response => {
					this.loading = false
				})
			}).catch(() => {

			})
		},

	}

}
</script>

<style>

</style>