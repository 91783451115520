<template>
    <div>
        <v-dialog
            v-model="dialogShow"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <div class="pl-0 font-weight-bold">Persona</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                            <div class="pl-0 font-weight-bold">{{ model.description }}</div>
                        </v-col>
                    </v-row>
                 
                </v-card-title>
                <v-card-title class="pa-0 px-2">
                    <v-tabs
                        class="pt-4"
                        v-model="mutableTab"
                        background-color="white"
                        show-arrows
                        :centered="!$vuetify.breakpoint.mdAndUp">
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#personal">
                            Personale
                        </v-tab>

                        <v-tab href="#analytics" v-if="$store.getters.checkModulePermissions('analytics')">
                            Statistiche
                        </v-tab>

                        <v-tab href="#patents" v-if="$store.getters.checkModulePermissions('courses')">
                            Brevetti
                        </v-tab>

                    </v-tabs>   

                </v-card-title>
                <v-card-text style="overflow-x: hidden; margin-top: 20px" class="body-page" v-if="dialogShow">
                    <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                        <v-tabs-items v-model="mutableTab" class="transparent">                    
                            <v-tab-item value="personal" class="fill-height">

                                <v-row class="ma-auto d-flex pt-6" v-if="model.code">
                                    <v-icon>mdi-numeric</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Codice</div>
                                        <div class="pl-2">{{ model.code }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6" v-if="model.primary_telephone">
                                    <v-icon>mdi-phone</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Telefono</div>
                                        <div class="pl-2">{{ model.primary_telephone }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6" v-if="model.primary_email">
                                    <v-icon>mdi-at</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Email</div>
                                        <div class="pl-2">{{ model.primary_email }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6" v-if="model.user_email">
                                    <v-icon>mdi-account</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Utente</div>
                                        <div class="pl-2">{{ model.user_email }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6" v-if="model.start_service_date">
                                    <v-icon>mdi-calendar</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Data Inizio Servizio</div>
                                        <div class="pl-2">{{ model.start_service_date | date }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-account-check-outline</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Stato Attuale</div>
                                        <div class="pl-2">{{ model.person_status_text }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6" v-if="model.badge_active">
                                    <v-icon>mdi-badge-account-horizontal-outline</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Badge</div>
                                        <div class="pl-2">{{ model.badge }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-clipboard-account-outline</v-icon>
                                    <div class="pl-2 caption">Mansioni</div>
                                </v-row>
                                <div class="d-block ml-6">
                                    <v-chip small class="ma-1" v-for="(task, t) in model.tasks" :key="t">
                                        <v-avatar left>
                                            <v-icon :color="tasksById[task].color">{{ tasksById[task].icon }}</v-icon>
                                        </v-avatar>
                                        {{ tasksById[task].name }}
                                    </v-chip>
                                </div>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-account-group</v-icon>
                                    <div class="pl-2 caption">Gruppi</div>
                                </v-row>
                                <div class="d-block ml-6">
                                    <v-chip small class="ma-1" v-for="(group, g) in model.groups" :key="g">{{ group.name }}</v-chip>
                                </div>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-text</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Note</div>
                                        <div class="pl-2">{{ model.description }}</div>
                                    </div>
                                </v-row>

                                <person-availabilities class="pt-6"
                                    :id="model.id"
                                ></person-availabilities>

                            </v-tab-item>

                            <v-tab-item value="analytics" class="fill-height" v-if="$store.getters.checkModulePermissions('analytics')">
                                <analytic-person
                                    :person="model"
                                ></analytic-person>
                            </v-tab-item>

                            <v-tab-item value="patents" class="fill-height" v-if="$store.getters.checkModulePermissions('courses')">
                                <patent-person
                                    :person="model"
                                    :element="element"
                                ></patent-person>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questa Persona?</div>
                        </v-row>
                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    
                    <template v-if="$store.getters.checkPermission('manage_other_people') || model.creator_id == $store.getters.getUserPerson.id">
                        <v-btn color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">
                            Elimina
                        </v-btn>
                        <v-btn color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model.id)">
                            Modifica
                        </v-btn>
                    </template>
                </v-card-actions>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="deletePerson()">
                                Conferma
                            </v-btn>
                        </template>
                        Conferma
                    </v-tooltip>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogEditor"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6" style="position: fixed">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <template v-if="confirmDelete">
                                <div class="pl-0 font-weight-bold">Elimina Persona</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                            </template>
                            <template v-else-if="model.id">
                                <div class="pl-0 font-weight-bold">Modifica Persona</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.full_name }}</div>
                                <div class="pl-0 font-weight-bold">{{ model.description }}</div>
                            </template>
                            <template v-else>
                                <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuova Persona</div>
                            </template>
                        </v-col>
                    </v-row>
                </v-card-title>
                
                <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                    <v-container class="px-0 justify-center" fluid>
                        
                        <validation-observer ref="fields">
                            <v-row class="pb-2 mx-0 pt-4">
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Codice Interno"
                                        v-model="model['code']"
                                        outlined
                                        dense
                                        ref="code"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Nome*"
                                            v-model="model['name']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="name"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <validation-provider name="Cognome" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Cognome*"
                                            v-model="model['surname']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="surname"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <validation-provider name="Codice Fiscale" rules="length:16" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Codice Fiscale"
                                            v-model="model['fiscal_code']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="fiscal_code"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Indirizzo"
                                        v-model="model['address_street']"
                                        outlined
                                        dense
                                        ref="address_street"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Cod. Postale"
                                        v-model="model['address_zipcode']"
                                        outlined
                                        dense
                                        ref="address_zipcode"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-autocomplete class="font-weight-regular"
                                        label="Provincia"
                                        v-model="model['province_id']"
                                        item-value="id"
                                        item-text="name"
                                        clearable
                                        small-chips
                                        :items="$store.getters.getGeneralTypes.provinces"
                                        dense
                                        outlined
                                        ref="address_province">
                                    </v-autocomplete>                                    
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Città"
                                        v-model="model['address_city']"
                                        outlined
                                        dense
                                        ref="address_city"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <validation-provider name="Email Primaria" rules="email" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Email Primaria"
                                            v-model="model['primary_email']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="primary_email"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <validation-provider name="Email Secondaria" rules="email" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Email Secondaria"
                                            v-model="model['secondary_email']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="secondary_telephone"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Telefono Primario"
                                        v-model="model['primary_telephone']"
                                        outlined
                                        dense
                                        ref="primary_telephone"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Telefono Secondario"
                                        v-model="model['secondary_telephone']"
                                        outlined
                                        dense
                                        ref="secondary_telephone"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6" v-if="model.id && model.user_id">
                                    <v-checkbox
                                        label="Staff"
                                        v-model="model['staff']"
                                        color="primary"
                                        :input-value="[true, false]"
                                        ref="staff"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6">
                                    <v-checkbox
                                        label="Dipendente"
                                        v-model="model['employee']"
                                        color="primary"
                                        :input-value="[true, false]"
                                        ref="employee"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                    <v-text-field
                                        type="text"
                                        label="Codice Badge"
                                        v-model="model['badge']"
                                        outlined
                                        dense
                                        ref="badge"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6">
                                    <v-checkbox
                                        label="Badge Attivo"
                                        v-model="model['badge_active']"
                                        color="primary"
                                        :input-value="[true, false]"
                                        ref="badge_active"
                                    ></v-checkbox>
                                </v-col>                                
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-menu
                                        ref="start_service_date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['start_service_date']"
                                        :return-value.sync="model['start_service_date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-on="on"
                                                label="Data Inizio Servizio"
                                                append-icon="mdi-event"
                                                readonly
                                                :value="$eventBusUtility.formatDate(model['start_service_date'])"
                                                outlined
                                                :clearable="true"
                                                @click:clear="model['start_service_date'] = ''"
                                                dense
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker 
                                            v-model="model['start_service_date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['start_service_date'].save(model['start_service_date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-autocomplete class="font-weight-regular"
                                        label="Mansioni"
                                        v-model="model['tasks']"
                                        item-value="id"
                                        item-text="name"                                        
                                        clearable
                                        small-chips
                                        return-object
                                        :items="tasks"
                                        dense
                                        multiple
                                        outlined>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Stato" rules="required" v-slot="{ errors }">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Stato*"
                                            v-model="model['person_status']"
                                            :error-messages="errors"
                                            default="available"
                                            clearable
                                            small-chips
                                            :items="$store.getters.getGeneralTypes.person_status"
                                            dense
					                        @change="statusChange($event)"
                                            outlined>
                                        </v-autocomplete>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="showStatusFields">
                                    <v-menu
                                        ref="status_start_date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['status_start_date']"
                                        :return-value.sync="model['status_start_date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Data Inizio Stato" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Data Inizio Stato*"
                                                    :error-messages="errors"
                                                    append-icon="mdi-event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(model['status_start_date'])"
                                                    outlined
                                                    :clearable="true"
                                                    @click:clear="model['status_start_date'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="model['status_start_date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['status_start_date'].save(model['status_start_date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3" v-if="showStatusFields">
                                    <v-menu
                                        ref="status_end_date"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['status_end_date']"
                                        :return-value.sync="model['status_end_date']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Data Fine Stato" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Data Fine Stato*"
                                                    :error-messages="errors"
                                                    append-icon="mdi-event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(model['status_end_date'])"
                                                    outlined
                                                    :clearable="true"
                                                    @click:clear="model['status_end_date'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="model['status_end_date']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :first-day-of-week="1"
                                            @input="$refs['status_end_date'].save(model['status_end_date'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12 py-0 pt-3 px-6">
                                    <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                                        <v-textarea
                                            label="Descrizione"
                                            name="description"
                                            v-model="model['description']"
                                            :error-messages="errors"
                                            type="text"
                                            outlined
                                            dense
                                            counter
                                            ref="description"
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>

                            </v-row>
                        </validation-observer>


                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="attemptstorePerson()">
                                Salva
                            </v-btn>
                        </template>
                        Salva
                    </v-tooltip>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="manageDialog"
            max-width="800px"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card class="rounded-lg pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold" style="font-size: 20px">Gestisci Persone e {{ type == 'group' ? 'Gruppi' : 'Mansioni' }}</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ selectedIds ? selectedIds.length : '' }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="closeManageDialog()"><v-icon>mdi-close</v-icon></v-btn>                
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container class="pa-2" fluid>
                    <v-radio-group class="pl-4" v-model="radios">				
                        <v-radio value="remove" color="red">
                            <template slot="label">
                                <div><strong class="red--text">Rimuovi</strong> {{ remove }}</div>
                            </template>
                        </v-radio>
                        <v-radio value="add" color="info">
                            <template slot="label">
                                <div><strong class="info--text">Assegna</strong> {{ add }}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <validation-observer ref="manageFields">

                        <validation-provider v-if="type == 'group'" name="Gruppo" rules="required" v-slot="{ errors }">
                            <v-select 
                                v-model="groupSelected"
                                :items="info.groups"
                                label="Gruppo*"
                                item-text="name"
                                item-value="id"
                                dense
                                class="px-4"
                                :error-messages="errors"
                                outlined
                            ></v-select>
                        </validation-provider>

                        <validation-provider v-else name="Mansione" rules="required" v-slot="{ errors }">
                            <v-select 
                                v-model="taskSelected"
                                :items="info.tasks"
                                label="Mansione*"
                                item-text="name"
                                item-value="id"                                
                                dense
                                class="px-4"
                                :error-messages="errors"
                                outlined
                            ></v-select>
                        </validation-provider>

                    </validation-observer>

                    <small class="pl-4">* Indica i campi obbligatori</small>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeManageDialog()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="type == 'task'" color="success" min-width="100px" height="40" class="ml-4" @click="saveTasks()">Salva</v-btn>
					<v-btn v-else color="success" min-width="100px" height="40" class="ml-4" @click="saveGroups()">Salva</v-btn>
				</v-card-actions>                
            </v-card>
        </v-dialog>
        
        
        <v-dialog v-model="personStatusDialog"
            max-width="800px"
            persistent
            :fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card class="rounded-lg pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold" style="font-size: 20px">Lo stato di questa persona è stato modificato</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="closePersonStatusDialog()"><v-icon>mdi-close</v-icon></v-btn>                
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-container fluid class="pa-0 mx-0 fill-height">

                            <v-row no-gutters class="pa-0 mx-0 fill-height">
                                <v-alert icon="mdi-delete-outline" style="width: 100%" prominent text type="error" class="ma-0 d-flex">
                                    In questo modo tutte le disponiblità nel periodo selezionato verranno eliminate
                                </v-alert>
                            </v-row>

                    </v-container>
                </v-card-text>
                <v-divider></v-divider>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closePersonStatusDialog()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn color="success" min-width="100px" height="40" class="ml-4" @click="storePerson()">Salva</v-btn>
				</v-card-actions>                
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Vue from 'vue'
import PatentPerson from '@/components/modules/people/PatentPerson'
import AnalyticPerson from '@/components/modules/people/AnalyticPerson'
import PersonAvailabilities from '@/components/modules/people/PersonAvailabilities'

export default {
	name: 'person-crud',
	components: {
		PatentPerson,
		AnalyticPerson,
		PersonAvailabilities
	},
	props: {
		info: Object,
		selectedIds: Array,
		element: Number,
		tab: {
			type: String,
			default: 'personal'
		}
	},
        
	data() {
		return {
			mutableTab: this.tab,
			type: null,
			model: {},
			saved: false,
			radios: 'add',
			loading: false,
			dialogShow: false,
			taskSelected: null,
			datepicker_menu: {},
			dialogEditor: false,
			manageDialog: false,
			groupSelected: null,
			confirmDelete: false,
			showStatusFields: true,
			personStatusDialog: false,
			openStatusDialog: false,
		}
	},

	computed: {

		remove() {
			return this.type == 'group' ? 'dal Gruppo le Persone selezionate' : 'la mansione alle Persone selezionate'
		},

		add() {
			return this.type == 'group' ? 'al Gruppo le Persone selezionate' : 'la mansione alle Persone selezionate'
		},

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},

		tasks() {
			return this.info.tasks ? this.info.tasks.filter((task) => {
				return task.virtual == false
			}) : []
		},

	},

	methods: {

		statusChange(event) {
			if(this.model.id) {
				if(event == 'available') {
					this.model.status_start_date = null
					this.model.status_end_date = null
					this.showStatusFields = false
					this.openStatusDialog = false
				} else {
					this.showStatusFields = true
					this.openStatusDialog = true
				}
			}
		},

		closeDialog() {
			this.dialogShow = false
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		closeManageDialog() {
			this.manageDialog = false
			this.$refs.manageFields.reset()
		},
        
		closePersonStatusDialog() {
			this.personStatusDialog = false
		},

		saveGroups() {
			this.$refs.manageFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.saved = true
				this.axios.post('people-group/', {selected: this.selectedIds, action: this.radios, group_id: this.groupSelected}).then(response => {
					this.saved = false
					this.closeManageDialog()
				}, response => {
					// error callback
					this.saved = false
				})

			}).catch(() => {

			})
		},

		saveTasks() {
			this.$refs.manageFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.saved = true
				this.axios.post('people-task/', {selected: this.selectedIds, action: this.radios, task_id: this.taskSelected}).then(response => {
					this.saved = false
					this.closeManageDialog()
					this.$eventBusUtility.$emit('refreshTable', 'person')
				}, response => {
					// error callback
					this.saved = false
				})

			}).catch(() => {

			})
		},

		edit(id) {
			this.axios.get('people/edit/' + id).then(response => {
                
				this.dialogShow = false
				this.dialogEditor = true
				this.model = response.data.data
				if(this.model.person_status == 'available') {
					this.model.status_start_date = null
					this.model.status_end_date = null
					this.showStatusFields = false
				} else {
					this.showStatusFields = true
				}                
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		show(id) {
			this.axios.get('people/' + id).then(response => {
					
				this.dialogShow = true
				this.model = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		create() {
			this.dialogEditor = true
			this.$set(this.model, 'person_status', 'available')
		},
            
		attemptstorePerson() {
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				if(this.openStatusDialog) {
					this.personStatusDialog = true
				} else {
					this.storePerson()
					this.closePersonStatusDialog()
					this.openStatusDialog = false                    
				}

			}).catch(() => {

			})                
		},

		storePerson() {
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				this.loading = true

				if(this.model.id) {
					this.axios.put('people/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'people')
						this.closeDialog()
						this.closePersonStatusDialog()
					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('people', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'people')
						this.closeDialog()

					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})
		},

		deletePerson() {
			this.loading = true
			this.axios.delete('people/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'people')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},

		confirmDestroy() {
			this.confirmDelete = true
			this.dialogShow = true
		},

	},

	mounted() {
		this.$eventBusUtility.$on('showPeople', (item) => {
			this.show(item.id)
		}),

		this.$eventBusUtility.$on('editPerson', (item) => {
			this.edit(item.id)
		}),
               
		this.$eventBusUtility.$on('createPerson', () => {
			this.create()
		})
            
		this.$eventBusUtility.$on('destroyPerson', (item) => {
			this.confirmDestroy()
			Vue.set(this, 'model', item)
		})

		this.$eventBusUtility.$on('managePeople', (type) => {
			this.type = type
			this.manageDialog = true
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('showPeople')
		this.$eventBusUtility.$off('editPerson')
		this.$eventBusUtility.$off('createPerson')
		this.$eventBusUtility.$off('destroyPerson')
	},

}
</script>

<style>

</style>