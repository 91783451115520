<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

            <v-container fluid class="pa-0" style="position: fixed;" :class="{'open-nav': $store.getters.getSidebar && $vuetify.breakpoint.mdAndUp}">
                <v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters>  
                            <v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold">Profilo</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 30px">{{ fullName }}</div>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <slot name="tabs">


                    </slot>

                </v-card>
            </v-container>

            <v-container fluid class="pa-6 body-page pt-8" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 170px; height: 100%">
                <v-row>

                    <v-col class="col-12 col-lg-8" :class="hasPerson ? 'col-lg-8' : 'col-lg-12'">
                        <v-card :elevation="1" class="background" width="100%">
                            <validation-observer ref="fields">
                                <v-row class="pb-2 mx-0 pt-4">
                                    <div class="ma-auto pt-4 text-center">
                                        <image-input v-model="profile.fileImage">
                                            <template v-slot:activator="{preview}">
                                                <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 124 :100" v-ripple v-if="!profile.avatar && !preview" class="grey lighten-3 mb-3 cursor-pointer">
                                                    <v-icon :size="$vuetify.breakpoint.mdAndUp ? 124 :100">mdi-account-circle</v-icon>
                                                </v-avatar>
                                                <v-avatar v-else
                                                    :size="$vuetify.breakpoint.mdAndUp ? 124 :100"
                                                    v-ripple
                                                    class="custom-avatar mb-3 cursor-pointer"
                                                    :style="{'background-image': 'url(' + (preview ? preview : profile.avatar) + ')'}">
                                                </v-avatar>
                                            </template>
                                        </image-input>
                                    </div>
                                </v-row>
                                <v-col class="col-12 px-6 py-0" v-if="!profile.email_verified_at">
                                    <v-alert icon="mdi-email-remove-outline" prominent text type="warning">
                                        <v-row align="center">
                                            <v-col class="grow">
                                                La tua email non è stata verificata! Non potrai effettuare il reset della password ne ricevere notifiche via mail.
                                                <br>Clicca su Verifica per verificare la tua email.
                                            </v-col>
                                            <v-col class="shrink">
                                                <v-btn color="warning" outlined :loading="sending" @click="sendVerificationEmail()">Verifica</v-btn>
                                            </v-col>    
                                        </v-row>                        
                                    </v-alert>
                                </v-col>
                                <v-row class="mx-0 pt-4">
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                type="text"
                                                label="Nome"
                                                v-model="profile['name']"
                                                :error-messages="errors"
                                                outlined
                                                dense
                                                ref="name"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <validation-provider name="Cognome" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                type="text"
                                                label="Cognome"
                                                v-model="profile['surname']"
                                                :error-messages="errors"
                                                outlined
                                                dense
                                                ref="name"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-0">
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-text-field
                                            type="text"
                                            label="Telefono Primario"
                                            v-model="profile['primary_telephone']"
                                            outlined
                                            dense
                                            ref="primary_telephone"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-text-field
                                            type="text"
                                            label="Telefono Secondario"
                                            v-model="profile['secondary_telephone']"
                                            outlined
                                            dense
                                            ref="secondary_telephone"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row class="mx-0">
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-text-field
                                            type="text"
                                            label="Indirizzo"
                                            v-model="profile['address_street']"
                                            outlined
                                            dense
                                            ref="primary_telephone"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-text-field
                                            type="text"
                                            label="Cap"
                                            v-model="profile['address_zipcode']"
                                            outlined
                                            dense
                                            ref="secondary_telephone"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mx-0">
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-text-field
                                            type="text"
                                            label="Città"
                                            v-model="profile['address_city']"
                                            outlined
                                            dense
                                            ref="address_city"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Provincia"
                                            v-model="profile['province_id']"
                                            item-value="id"
                                            item-text="name"
                                            clearable
                                            small-chips
                                            :items="$store.getters.getGeneralTypes.provinces"
                                            dense
                                            outlined
                                            ref="province_id">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            
                            </validation-observer>

                            <v-row class="px-4">
                                <v-col class="col-12 py-0 px-6 pt-4">
                                    <v-subheader class="pl-0">Modifica Password
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn v-model="editPassword" v-on="{ ...tooltip }" class="ml-4" text small icon @click="editPassword = !editPassword">
                                                    <v-icon>{{ editPassword ? 'mdi-lock-open-outline' : 'mdi-lock-outline' }}</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Modifica</span>
                                        </v-tooltip>
                                    </v-subheader>
                                </v-col>
                                
                                <validation-observer tag="div" ref="passwordFields" class="row mx-0 pt-4" v-if="editPassword">
                                    <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0">
                                        <validation-provider name="Password" rules="confirmed:repeat_password|min:8" v-slot="{ errors }">
                                            <v-text-field
                                                label="Password"
                                                name="password"
                                                v-model="profile['password']"
                                                :error-messages="errors"
                                                data-vv-name="password"
                                                :append-icon="e1 ? 'mdi-eye-outline' : 'eye-off-outline'"
                                                @click:append="() => {e1 = !e1}"
                                                :type="!e1 ? 'password' : 'text'"
                                                ref="password"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0">
                                        <validation-provider name="Ripeti Password" rules="required|min:8" v-slot="{ errors }" vid="repeat_password">
                                            <v-text-field
                                                label="Ripeti Password"
                                                name="repeat_password"
                                                v-model="profile['repeat_password']"
                                                :error-messages="errors"
                                                :append-icon="e2 ? 'mdi-eye-outline' : 'eye-off-outline'"
                                                @click:append="() => {e2 = !e2}"
                                                :type="!e2 ? 'password' : 'text'"
                                                outlined
                                                dense
                                                ref="repeat_password"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-0 caption">
                                        <li>La tua password non può essere troppo simile alle altre tue informazioni personali.</li>
                                        <li>La tua password deve contenere almeno 8 caratteri.</li>
                                        <li>La tua password non può essere una password comunemente usata.</li>
                                        <li>La tua password non può essere interamente numerica.</li>
                                    </v-col>
                                    <v-card class="elevation-0" style="width: 100%">
                                        <v-card-actions class="pa-6 pb-10 justify-center elevation-0">
                                            <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                                            <v-btn color="success" min-width="100px" height="40" @click="updateProfile()">Salva Password</v-btn>
                                        </v-card-actions>
                                        <v-divider></v-divider>
                                    </v-card>
                                </validation-observer>
                            </v-row>

                            <v-col class="col-12 py-0 px-6 pt-4">
                                <v-subheader class="pl-0">Notifiche</v-subheader>
                            </v-col>
                        
                            <v-col class="col-12 py-0 px-6 pt-4">
                                <v-switch
                                    inset
                                    class="text-center"
                                    label="Ricevi Notifiche"
                                    v-model="profile['allow_notifications']"
                                    :input-value="[true, false]"
                                    color="primary"
                                ></v-switch>
                            </v-col>
                            <v-slide-x-transition>
                                <v-col class="col-12 py-0 px-6 pt-4" v-show="profile['allow_notifications']">
                                    <v-switch
                                        inset
                                        class="text-center"
                                        label="Ricevi Notifiche Mail"
                                        v-model="profile['allow_mail']"
                                        :input-value="[true, false]"
                                        color="primary"
                                    ></v-switch>
                                </v-col>
                            </v-slide-x-transition>
                            <v-slide-x-transition>
                                <v-col class="col-12 py-0 px-6 pt-4" v-show="profile['allow_notifications']">
                                    <v-switch
                                        inset
                                        class="text-center"
                                        label="Ricevi Notifiche Push"
                                        v-model="profile['allow_push']"
                                        :input-value="[true, false]"
                                        color="primary"
                                    ></v-switch>
                                </v-col>
                            </v-slide-x-transition>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 px-6 py-0">
                                <v-checkbox
                                    label="Aggiorna le mie informazioni in tutte le associazioni"
                                    v-model="profile['user_cascade_update']"
                                    color="primary"
                                ></v-checkbox>
                            </v-col>

                            <v-divider></v-divider>
                            <v-card-actions class="pa-6 pb-10 justify-center elevation-0">
                                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                                <v-btn color="red" min-width="100px" dark height="40" @click="deleteProfile()">Elimina Profilo</v-btn>
                                <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="updateProfile()">Salva</v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-col>

                    <v-col class="col-12 col-lg-4 pt-0" v-if="hasPerson">
                        <v-card :elevation="0" class="background" width="100%">
                            <person-availabilities
                                :id="$store.getters.getUserPerson.id"
                            ></person-availabilities>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>



      	<v-dialog
			v-model="deleteDialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters class="d-block">

                        <template>
                            <div class="pl-0 font-weight-bold">Elimina Profilo</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ fullName }}</div>
                        </template>

					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare il tuo Profilo?</div>
                        </v-row>
                    </v-container>
				</v-card-text>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="deleteDialog = false">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn color="success" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
				</v-card-actions>
			</v-card>
		
		
		</v-dialog>        
    </v-row>

</template>

<script>

import ImageInput from '@/components/common/ImageInput'
import PersonAvailabilities from '@/components/modules/people/PersonAvailabilities'

export default {
	name: 'profile-editor',
	components: {
		ImageInput,
		PersonAvailabilities
	},

	props: {

	},

	data() {
		return {
			e1: false,
			e2: false,
			tab: 'tab-1',
			password: '',
			sending: false,
			loading: false,
			deleteDialog: false,
			confirmpassword: '',
			editPassword: false,
			allow_notifications: '',
			profile: {...this.$store.getters.getUserProfile},
		}
	},

	computed: {
		fullName() {
			return this.profile.name + ' ' + this.profile.surname         
		},

		hasPerson() {
			return this.$store.getters.getUserPerson.id ? true : false            
		}
	},

	methods: {

		savePassword() {
			this.$refs.passwordFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				this.axios.put(this.$store.getters.getAuthUrl + '/update-profile/', {...this.profile}).then(this.savedProfile)

			}).catch(() => {

			})
		},

		updateProfile() {
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				let formData = new FormData()
				for (let key in this.profile) {
                    
					if (key != 'fileImage') {
						formData.append(key, this.profile[key])
					} else {
						formData.append(key, this.profile[key] ? this.profile[key] : '')
					}
				}
				formData.append('_method', 'PUT')

				this.axios.post(this.$store.getters.getAuthUrl + '/update-profile/', formData).then((response) => this.savedProfile(response))

			}).catch(() => {

			})
		},

		savedProfile(response) {
			this.loading = false
			this.$store.commit('SET_USER_AVATAR', response.data.data.avatar)
			this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: 'Profilo aggiornato', value: true, color: 'success'})
		},

		deleteProfile() {
			this.deleteDialog = true
		},

		destroy() {
			this.axios.delete(this.$store.getters.getAuthUrl + '/delete-user').then(response => {
				this.$store.dispatch('authLogout')
			})
		},

		sendVerificationEmail() {
			this.sending = true
			this.axios.get(this.$store.getters.getAuthUrl + '/send-verification-email/' + this.profile.id).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: 'Email Inviata', value: true, color: 'success'})
				this.sending = false
			}).catch(() => {
				this.sending = false
			})
		}
	}
}
</script>
