<template>
    <v-dialog
        v-model="dialogShow"
        fullscreen
        hide-overlay
        transition="scroll-x-reverse-transition"
        scrollable
        style="overflow-x: hidden">
        <v-card tile v-if="model" class="rounded-lg">
            <v-card-title class="pa-6">
                <v-row no-gutters>

                    <v-col class="col-12 ma-auto ml-0">
                        <div class="pl-0 font-weight-bold">Presenze</div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text style="overflow-x: hidden; margin-top: 20px" class="pa-6 body-page" v-if="dialogShow">
                <v-data-table class="background-2 elevation-1 rounded-lg"
                    :options.sync="pagination"
                    :headers="headers"
                    :items="lessonattendances"
                    :server-items-length="totalItems"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                    :mobile-breakpoint="0"
                    ref="vDataTable"
                    :footer-props="{
                        'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
                        showFirstLastPage: true
                    }">

                    <template v-slot:body.prepend>
                        <tr>
                            <td style="width: 10px">
                                <v-icon >mdi-magnify</v-icon>
                            </td>
                            <td style="width: 30px">
                                <v-tooltip top v-if="!isEmptyColumnsFilter">
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
                                            <v-icon>mdi-progress-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Svuota Filtri</span>
                                </v-tooltip>
                            </td>
                            <td class="pl-0">
                                <v-text-field
                                    dense
                                    solo
                                    flat
                                    placeholder="Cognome e Nome"
                                    hide-details
                                    v-model="columnsFilter['full_name']"
                                ></v-text-field>
                            </td>
                            <td class="pl-0">
                            </td>

                        </tr>                                        
                    </template>

                    <template v-slot:item="{ item }">
                        <tr class="cursor-pointer">							
                            <td style="white-space: nowrap; width: 10px">

                            </td>	
                            <td style="white-space: nowrap; width: 30px">

                            </td>	
                            <td @click="show(item)">
                                {{ item.full_name }}
                            </td>	
                            <td class="text-center">
                                <v-btn v-if="!item.presence" :loading="loading" color="red" outlined width="100" @click="subscribeLesson(item)">Assente</v-btn>
                                <v-btn v-else color="success" :loading="loading" width="100" @click="subscribeLesson(item)">Presente</v-btn>
                            </td>	
                        </tr>
                    </template>

                    <template v-slot:footer.page-text="{ pageStart, pageStop }">
                        <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                        Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
                    </template>

                    <template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
                        <div class="text-center d-flex background pa-4">
                            <v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-text-field
                                hide-details
                                rounded
                                solo
                                dense
                                prefix="#"
                                label="Pagina"
                                type="number"
                                :min="1"
                                :max="props.pagination.pageCount"
                                v-model="newPageToJump">
                                <template v-slot:append-outer>
                                    <v-btn dark color="primary" @click="jumpToPage()">
                                        Vai
                                    </v-btn>						
                                </template>
                            </v-text-field>
                            <v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>					
                        </div>
                        <div class="text-center caption pr-4">
                            <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                            Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
                        </div>
                    </template>
                    
                </v-data-table>            
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi</span>
                </v-tooltip>
                <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="$eventBusUtility.openFile($store, '/lessons/' + model.id + '/print', 'FoglioFirme_' + model.id)">
                    Stampa Foglio Firme
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DataTableMixin from '@/mixins/DataTableMixin'

export default {
	name: 'lesson-attendances',
	components: {
	},
	mixins: [DataTableMixin],

	data() {
		return {
			dialogShow: false,
			disabled: true,
			fab: false,
			infoData: {},
			model: {},
			selected: [],
			search: '',
			totalItems: 0,
			lessonattendances: [],
			loading: false,
			apiTimeout: null,
			pagination: {
				'sortBy': ['full_name'],
				'sortDesc': [false],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
			headers: [
				{
					'text': '',
					'value': ''
				},
				{
					'text': '',
					'value': ''
				},
				{
					'text': 'Cognome e Nome',
					'value': 'full_name'
				},
				{
					'text': 'Stato',
					'value': 'status',
					'align': 'center'
				},
			],
		}
	},

	methods: {
		closeDialog() {
			this.dialogShow = false
			this.confirmDelete = false
			this.model = _.stubObject()
			this.lessonattendances = []
		},

		getData() {
			if(this.apiTimeout) {
				clearTimeout(this.apiTimeout)
			}

			this.apiTimeout = setTimeout(() => {

				this.loading = true
                    
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					filter: this.search,
				}

				this.axios.get('lessons/presences/' + this.model.id, { params: dataObject }).then(response => {
					this.loading = false
					this.lessonattendances = response.data.data
					this.totalItems = response.data.meta.total

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)
		},

		openLessonAttendances(lesson) {
			this.model = {...lesson}
			this.dialogShow = true
		},

		subscribeLesson(item) {
			this.loading = true
			this.axios.get('lessons/handleLessonPresence/' + this.model.id + '/' + item.id).then(response => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
				this.getData()

			}, response => {
				// error callback
				this.loading = false
			})
		} ,     
	},

	mounted() {
		this.$eventBusUtility.$on('openLessonAttendances', (lesson) => {
			this.openLessonAttendances(lesson)
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('openLessonAttendances')
	},    
}
</script>