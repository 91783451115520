<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Impostazioni"
				icon="mdi-cog-outline">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 140px; height: 100%">
				<v-row>
					<v-col class="col-lg-6 col-md-6 col-12 pa-0 mx-0">
						<simple-crud
							ref="instrumentsCrud"
							title="Strumenti"
							modelDef="instruments"
							@saveModel="saveInstrument"
							:footer-logo="false">
							<template v-slot:item-content="{item}">
								<div class="body-1">{{ item.name }}</div>
								<div class="body-1">{{ item.unit }}</div>
								<div class="caption" v-if="item.instrumentarea">{{ item.instrumentarea.name }}</div>
							</template>

							<template v-slot:model-fields="{item}">
								<instrument-fields
									ref="instrumentFields"
									:info="instrumentInfo"
									:model="item"
								></instrument-fields>
							</template>
						</simple-crud>

					</v-col>
					<v-col class="col-lg-6 col-md-6 col-12 pa-0 mx-0">
						<simple-crud
							ref="instrumentareasCrud"
							title="Aree"
							modelDef="instrumentareas"
							@saveModel="saveInstrumentarea"
							@savedModel="reloadInstrumentAreas"
							:footer-logo="false">
							<template v-slot:item-content="{item}">
								<div class="body-1">{{ item.name }}</div>
							</template>

							<template v-slot:model-fields="{item}">
								<instrumentarea-fields
									ref="instrumentareaFields"
									:model="item"
								></instrumentarea-fields>
							</template>
						</simple-crud>
					</v-col>

					<notificationrules 
						class="pl-xs-0 pl-lg-0"
						type="checklist"
					></notificationrules>

				</v-row>

				<footer-logo></footer-logo>			
			</v-container>
		
		</v-col>
    </v-row>
</template>

<script>
import Notificationrules from './Notificationrules'
import HeaderCrud from '@/components/common/HeaderCrud'
import SimpleCrud from '@/components/common/SimpleCrud'
import InstrumentFields from '@/components/modules/checklists/InstrumentFields'
import InstrumentareaFields from '@/components/modules/checklists/InstrumentareaFields'

export default {
	name: 'checklist-settings',
	components: {
		HeaderCrud,
		SimpleCrud,
		InstrumentFields,
		Notificationrules,
		InstrumentareaFields,
	},
	data() {
		return {
			instrumentInfo: {}
		}
	},

	created() {
		this.reloadInstrumentAreas()
	},

	methods: {
		saveInstrument(item) {
			this.$refs.instrumentFields.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				this.$refs.instrumentsCrud.save(item)
			}).catch(() => {

			})
		},

		saveInstrumentarea(item) {
			this.$refs.instrumentareaFields.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				this.$refs.instrumentareasCrud.save(item)
			}).catch(() => {

			})
		},

		reloadInstrumentAreas() {
			this.axios.get('instrumentareas').then(response => {
				this.instrumentInfo = {
					areas: response.data.data
				}
			})
		}
	},
 
}
</script>
