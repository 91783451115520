import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import it from 'vuetify/es5/locale/it'
import { Ripple } from 'vuetify/lib/directives'
import store from '@/store/index'


Vue.use(Vuetify, {
	directives: {
		Ripple
	},
})

let theme = {
	primary: '#e55352',
	secondary: '#b0bec5',
	accent: '#8c9eff',
	success: '#98C674',
	error: '#E55352',
	warning: '#ECAF11',
	input: '#E0E0E0',
	background: '#fff',
	'background-1': '#f5f7fa',
}

if(store.getters.isMiseApp) {
	theme.primary = '#009fe3'
	theme.secondary = '#ffed00'
	theme.accent = '#4545bd'
	theme.success = '#00713C'
	theme.error = '#E30613'
	theme.warning = '#FFED00'
}

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},	
	lang: {
		locales: { it },
		current: 'it'
	},
	theme: {
		options: { customProperties: true },
		themes: {
			light: theme,
		}
	}
})
