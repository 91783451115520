<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Richieste di Approvazione"
				:totalItems="totalItems"
				icon="mdi-face-agent"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 160px; height: 100%">
				
				<v-data-iterator
					:options.sync="pagination"
					:server-items-length="totalItems"
					:items="requests"
					:footer-props="{
						'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
						showFirstLastPage: true
					}">
					<template v-slot:default="{ items }">

						<v-card class="rounded-lg pa-0 mb-2" :ref="'request' + request.id" :class="request.id == element ? 'activeNotification' : ''" max-height="200" style="overflow: hidden;" v-for="(request, o) in items" :key="o">
							<v-row no-gutters class="pa-2">
								
								<v-col class="col-3 col-sm-3 col-md-2 col-lg-2" style="vertical-align: middle; margin: auto; margin-left: 0px">
									<v-avatar v-if="request.avatar"
										class="custom-avatar ml-2 mr-4 align-self-center"
										:size="84" 
										v-ripple
										:style="{'background-image': 'url(' + request.avatar + ')'}">
									</v-avatar>
									<v-avatar v-else size="84px" class="grey lighten-3 cursor-pointer">
										<v-icon x-large>mdi-account</v-icon>
									</v-avatar>			
								</v-col>


								<v-col class="col-9 col-sm-9 col-md-3 col-lg-3 pb-xs-2 text-right" style="vertical-align: middle; margin: auto">
									<div style="display: flex; flex-direction: column;">
										<span class="text-center">{{ request.name + ' ' + request.surname }}</span>
										<span class="caption font-weight-bold text-center grey--text text--darken-1">{{ request.email }}</span>
									</div>
									<div style="display: flex; flex-direction: column;" class="hidden-md-and-up">
										<span class="v-btn v-btn--disabled v-btn--flat v-btn--text theme--light v-size--default">
											Richiesta Inviata
										</span>
										<span class="caption font-weight-bold text-center grey--text text--darken-1"> il {{ request.request_date | datetime }}</span>
									</div>							
								</v-col>


								<v-col class="col-6 col-xs-12 col-sm-4 col-md-3 col-lg-3 pb-xs-2 text-right hidden-sm-and-down" style="vertical-align: middle; margin: auto">
									<div style="display: flex; flex-direction: column;">
										<span class="v-btn v-btn--disabled v-btn--flat v-btn--text theme--light v-size--default">
											Richiesta Inviata
										</span>
										<span class="caption font-weight-bold text-center grey--text text--darken-1"> il {{ request.request_date | datetime }}</span>
									</div>
								</v-col>
								

								<v-col class="col-12 col-sm-12 col-md-4 col-lg-4 text-center hidden-sm-and-down" style="vertical-align: middle; margin: auto; margin-right:0px">

									<v-card-actions>
										<v-spacer></v-spacer>
										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" class="mr-1" color="error" text outlined :disabled="request.response_date" @click="manageResponse(request, false)">
													Rifiuta
												</v-btn>
											</template>
											<span>Rifiuta Richiesta</span>
										</v-tooltip>
										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" class="ml-1" color="success" text outlined :disabled="request.response_date" @click="manageResponse(request, true)">
													Accetta
												</v-btn>
											</template>
											<span>Accetta Richiesta</span>
										</v-tooltip>
									</v-card-actions>

								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row no-gutters>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-3 pb-xs-2 hidden-md-and-up pa-0" style="vertical-align: middle; margin: auto">

									<v-card-actions class="text-center pa-0">

										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">					
												<v-btn v-on="{ ...tooltip }" color="error" width="50%" text :disabled="request.response_date" @click="manageResponse(request, false)">
													Rifiuta
												</v-btn>
											</template>
											<span>Rifiuta Richiesta</span>
										</v-tooltip>

										<v-tooltip top>
											<template v-slot:activator="{ on: tooltip }">
												<v-btn v-on="{ ...tooltip }" color="success" width="50%" text :disabled="request.response_date" @click="manageResponse(request, true)">
													Accetta
												</v-btn>
											</template>
											<span>Accetta Richiesta</span>
										</v-tooltip>

										<v-divider vertical></v-divider>
									</v-card-actions>
								</v-col>						
							</v-row>
						</v-card>

					</template>

					<template v-slot:footer.page-text="{ pageStart, pageStop }">
						Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
					</template>
					
				</v-data-iterator>
			</v-container>

			<v-dialog
				v-model="dialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters>

							<v-col class="col-12 ma-auto ml-0" v-if="requestSelected">
								<div class="pl-0 font-weight-bold">Vuoi {{ dialogRefused ? 'rifiutare' : 'accettare'}} la richiesta di</div>
								<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ requestSelected.name }} {{ requestSelected.surname }}</div>
								<div class="pl-0 font-weight-bold">per l'associazione</div>
								<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ requestSelected.organization }}?</div>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 200px" class="body-page">
					<v-container class="px-0 justify-center" fluid>
						
						<template v-if="!dialogRefused">
							<v-row class="mx-0 justify-center pt-6">
								<v-checkbox
									v-model="newPeople"
									label="Crea Nuovo Volontario">
								</v-checkbox>
							</v-row>
							<v-row class="mx-0 justify-center pt-6">
								<validation-observer ref="requestDialog">
									<validation-provider name="Volontario" rules="required" v-slot="{ errors }">
										<v-autocomplete class="font-weight-regular rounded-search"
											prepend-inner-icon="mdi-magnify"
											label="Volontario"
											v-model="unassignedPeopleSelected"
											:error-messages="errors"
											style="max-width:600px"
											clearable
											small-chips
											:disabled="newPeople"
											rounded
											:items="unassignedPeople"
											solo
											dense
											loading>
											<template v-slot:progress>
												<v-progress-linear
													color="success"
													absolute 
													:indeterminate="loadingUnassignedPeople"
													height="2"
												></v-progress-linear>
											</template>							
										</v-autocomplete>
									</validation-provider>
								</validation-observer>
							</v-row>
						</template>

						<v-row class="mx-0 justify-center pt-6">
							<div class="caption" v-if="!dialogRefused">Scegli se vuoi creare l'utente come nuovo volontario, altrimenti se vuoi associarlo a un volontario già esistente effettua una ricerca e selezionalo.</div>
							<div class="caption" v-else>Se decidi di rifiutare la richiesta di questo utente non sarà più possibile accettarla finchè questo non ne effettuerà un altra.</div>
						</v-row>
					</v-container>
					</v-card-text>
					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="dialog = false">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="!dialogRefused" color="secondary" :loading="loadingResponse" min-width="100px" outlined  height="40" class="ml-4" @click="dialogRefused = true">
							Rifiuta
						</v-btn>
						<v-btn v-else color="secondary" :loading="loadingResponse" :min-width="dialogRefused ? '200px' : '100px'" outlined  height="40" class="ml-4" @click="requestResponse(false)">
							Rifiuta
						</v-btn>
						<v-tooltip top v-if="!dialogRefused">
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="success" :loading="loadingResponse" min-width="100px" height="40" class="ml-4" @click="validateManageResponse(true)">
									Accetta
								</v-btn>
							</template>
							Accetta
						</v-tooltip>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'

export default {
	name: 'requests',
	components: {
		HeaderCrud,
	},	
	props: {
		element: Number,
	},
	mixins: [DataTableMixin],
	data() {
		return {
			search: '',
			requests: [],
			totalItems: 0,
			dialog: false,
			loading: false,
			newPeople: false,
			debounceUnwatch: {},
			unassignedPeople: [],
			dialogRefused: false,
			requestSelected: null,
			loadingResponse: false,
			newPageToJump: null,
			pageToJump: 1,
			unassignedPeopleSelected: null,
			loadingUnassignedPeople: false,
			pagination: {
				'sortBy': ['request_date'],
				'sortDesc': [true],
				'page': 1,
				'itemsPerPage': 10,
				'totalItems': 0,
			},	
		}
	},

	watch: {

		newPeople: {
			handler: function(newValue, oldValue) {
				if(newValue) {
					this.unassignedPeopleSelected = null
				}
			}
		},

		pagination: {
			handler() {
				this.getData()
			},
			deep: true,
		},

	},
	

	created() {
		this.getData()
	},


	mounted() {
		this.axios.get('/unassigned-people').then(response => {
			this.unassignedPeople = response.data.data.unassignedPeople
			this.loading = false

		}, response => {
			// error callback
			this.loading = false
		})

	},


	methods: {
		
		validateManageResponse(approved) {

			this.loadingResponse = true
			if(this.newPeople) {
				this.requestResponse(approved)
			} else {
				this.$refs.requestDialog.validate().then(result => {
					if (!result) {
						this.loadingResponse = false
						return false
					}
	
					this.requestResponse(approved)
	
				}).catch(() => {
					this.loadingResponse = false
				})
			}

		},

		getData() {

			this.loading = true

			let dataObject = {
				page: this.pagination.page,
				per_page: this.pagination.itemsPerPage,
				sortBy: this.pagination.sortBy,
				sortDesc: this.pagination.sortDesc,
				advancedFilters: this.advancedFilters,
				filter: this.search,
				subFilters: this.subFilters,
			}

			this.axios.get('/requests', {params: {...dataObject}}).then(response => {
				this.requests = response.data.data
				this.totalItems = response.data.meta.total
				this.loading = false

			}, response => {
				// error callback
				this.loading = false
			})
		},

		manageResponse(requestSelected, action) {
			this.requestSelected = requestSelected
			action ? this.dialogRefused = false : this.dialogRefused = true
			this.dialog = true

		},

		prevPage() {
			this.$set(this.pagination, 'page', this.pagination.page - 1)
		},

		nextPage() {
			this.$set(this.pagination, 'page', this.pagination.page + 1)
		},

		jumpToPage() {
			this.$set(this.pagination, 'page', this.newPageToJump)
		},		

		requestResponse(approved) {
			
			this.loadingResponse = true
			this.axios.put('requests/' + this.requestSelected.id, {approved: approved, unassignedPeopleSelected: this.unassignedPeopleSelected}).then(response => {

				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: response.data.message,
					value: true,
					color: 'success'
				})
				this.getData()
				
				this.loadingResponse = false

				this.dialogRefused = false
				this.dialog = false
			}, response => {
				// error callback
				this.loadingResponse = false
			})
		},


	}
}
</script>

<style>

</style>