<template>
  <v-container
    class="text-center"
    fill-height
    style="height: calc(100vh - 258px);"
  >
    <v-row align="center">
      <v-col>
        <v-img
          class="mb-12"
          contain
          height="150"
          :src="logo"
        />
        <h1 class="display-2 primary--text">Whoops, 404</h1>

        <p>La pagina che stavi cercando non esiste</p>

        <v-btn @click="$store.dispatch('redirectHome')"
          color="primary"
          outlined
          class="mt-5"
        >
          Portami via di qua!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: 'notfound',

	computed: {
		logo() {
			return this.$vuetify.theme.dark ? 'img/logo_yello_bianco.svg' : 'img/logo_yello.svg'
		}
	},

	created () {
		this.$store.commit('END_LOADING')
	},
}
</script>