<template>

	<v-row class="pa-0 mx-0 fill-height">

		<v-container fluid>
		
			<v-data-iterator
				:items="filteredItems"
				:search="search"
				:loading="loading"
				:hide-default-footer="!$vuetify.breakpoint.mdAndUp"
				:mobile-breakpoint="0"
				:options.sync="pagination"
				:footer-props="{
					'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
					showFirstLastPage: true
				}">
				<template v-slot:header>
					<v-subheader>{{title}}
						<v-spacer></v-spacer>
						<v-btn v-if="!actionsFixed" fab dark style="z-index: 1" small color="primary" @click="$emit('addModel')">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-subheader>
					<v-col class="ma-auto mr-0 px-0">
						<v-text-field class="font-weight-regular rounded-search"
							prepend-inner-icon="mdi-magnify"
							label="Cerca"
							v-model="filters"
							clearable
							hide-details
							rounded
							solo
							dense>
							<template v-slot:progress>
								<v-progress-linear
									color="success"
									absolute
									height="2"
								></v-progress-linear>
							</template>
						</v-text-field>
					</v-col>	
				</template>
				<template v-slot:default="{ items }">

					<template v-for="(item, o) in items">

						<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o" @click="$emit('clickItem', item)">
							<div class="pa-2 d-inline-flex" style="width: 100%">
								
								<div v-if="typeof item.avatar !== 'undefined'" style="width: 50px; vertical-align: middle; margin: auto;">
									<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
										<v-icon :style="{'color': item.color}">{{ item.icon }}</v-icon>
									</v-avatar>
								</div>

								<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
									<div>
										<slot name="item-content" :item="item">
											<div class="body-1">{{ item.name }}</div>
										</slot>
									</div>
								</div>
								<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto">

									<div class="ma-auto">
										<v-menu v-if="$vuetify.breakpoint.mdAndUp"
											right
											bottom
											rounded="lg"
											:nudge-width="200"
											:nudge-bottom="35">
											<template v-slot:activator="{ on, attrs }">
												<v-btn icon v-bind="attrs" v-on="on">
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</template>

											<v-list dense>
												<v-subheader class="pl-4">AZIONI</v-subheader>

												<v-list-item link @click="$emit(action.eventName, item)" v-for="(action, a) in actions" :key="a">
													<v-list-item-icon>
														<v-icon>{{ action.icon }}</v-icon>
													</v-list-item-icon>
													<v-list-item-content>
														<v-list-item-title>{{ action.text }}</v-list-item-title>
													</v-list-item-content>
												</v-list-item>

											</v-list>
										</v-menu>

										<div class="text-center" v-else>
											<v-bottom-sheet v-model="sheet[item.id]">
											<template v-slot:activator="{ on, attrs }">
												<v-btn icon v-bind="attrs" v-on="on">
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</template>
												<v-list dense class="pb-6">
													<v-subheader class="pl-4">AZIONI</v-subheader>

													<v-list-item link @click="$emit(action.eventName, item)" v-for="(action, a) in actions" :key="a + '_b'">
														<v-list-item-icon>
															<v-icon>{{ action.icon }}</v-icon>
														</v-list-item-icon>
														<v-list-item-content>
															<v-list-item-title>{{ action.text }}</v-list-item-title>
														</v-list-item-content>
													</v-list-item>

												</v-list>
											</v-bottom-sheet>
										</div>
									</div>

								</div>

							</div>
						</v-card>
					</template>

				</template>

				<template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
					Da {{ pageStart }} a {{ pageStop }} di {{ itemsLength }}
				</template>

				<template v-slot:footer="props" v-if="$vuetify.breakpoint.smAndDown">
					<div class="text-center d-flex background-1 pa-4">
						<v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<v-text-field
							hide-details
							rounded
							solo
							dense
							prefix="#"
							label="Pagina"
							type="number"
							:min="1"
							:max="props.pagination.pageCount"
							v-model="newPageToJump">
							<template v-slot:append-outer>
								<v-btn dark color="primary" @click="jumpToPage()">
									Vai
								</v-btn>						
							</template>
						</v-text-field>
						<v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>					
					</div>
					<div class="text-center caption pr-4">
						Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ props.pagination.itemsLength }}
					</div>

				</template>

			</v-data-iterator>

			<!-- Fab actions button -->
			<v-btn
				v-if="actionsFixed"
				right
				bottom
				fixed
				color="primary" 
				dark
				fab
				:class="{'mb-15': $vuetify.breakpoint.smAndDown}"
				@click="$emit('addModel')">
				<v-icon>mdi-plus</v-icon>
			</v-btn>

			<!-- Confirm dialog for delete action -->
			<confirm-dialog v-model="confirmDelete" :data="deletingItem" @confirm="destroy"></confirm-dialog>

			<!-- Dialog than should be contain fields for edit/create model -->
			<v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters class="d-block">
							<template v-if="editingItem.id">
								<div class="pl-0 font-weight-bold">Modifica {{ title }}</div>
								<div class="pl-0 font-weight-bold text-h4">{{ editingItem.name }}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo {{ title }}</div>
							</template>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 120px" class="body-page pa-0">
						<slot name="model-fields" :item="editingItem"></slot>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="$emit('saveModel', editingItem)">Salva</v-btn>
					</v-card-actions>
				</v-card>					
			</v-dialog>

			<footer-logo v-if="footerLogo"></footer-logo>

		</v-container>

	</v-row>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue'

export default {
	name: 'simple-crud',

  	components: { ConfirmDialog },

	props: {
		title: String,
		modelDef: String,
		search: {
			type: String,
			default: ''
		},
		disablePagination: {
			type: Boolean,
			default: false
		},
		actionsFixed: {
			type: Boolean,
			default: false
		},
		footerLogo: {
			type: Boolean,
			default: true
		},
		icon: {
			type: String,
			default: 'mdi-cog-outline'
		},
		totalItems: {
			type: Number,
			default: 0
		},
		actions: {
			type: Array,
			default: () => [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'eventName': 'editModel'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'eventName': 'destroyModel'
				},
			],
		},
	},
	data() {
		return {
			fab: null,
			pagination: {},
			newPageToJump: null,
			sheet: {},
			items: [],
			loading: false,
			confirmDelete: false,
			editorDialog: false,
			editingItem: {},
			deletingItem: {},
			filters: ''
		}
	},

	computed: {
		filteredItems() {
			if (this.filters == undefined || this.filters == '') return this.items
			return this.items.filter((item) =>  item.name.toLocaleLowerCase().indexOf(this.filters.toLocaleLowerCase()) > -1)
		},
	},

	mounted() {
		this.getData()

		this.$on('addModel', () => {
			this.editingItem = {}
			this.editorDialog = true
		})

		this.$on('editModel', (item) => {
			this.editingItem = item
			this.editorDialog = true
		})

		this.$on('destroyModel', (item) => {
			this.deletingItem = item
			this.confirmDelete = true
		})
	},

	beforeDestroy() {
		this.$off('editModel')
		this.$off('destroyModel')
	},

	methods: {


		getData() {

			this.loading = true

			this.axios.get(this.modelDef).then(response => {
				this.items = response.data.data
				this.$emit('update:totalItems', this.items.length)
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		save(item) {
			if(item.id) {
				this.loading = true
				this.axios.put(this.modelDef + '/' + item.id, item).then(response => {
					this.loading = false
					this.getData()
					this.closeEditor()
					this.$emit('savedModel', response.data)

				}, response => {
					this.loading = false
				})					
			} else {
				this.loading = true
				this.axios.post(this.modelDef, item).then(response => {
					this.loading = false
					this.getData()
					this.closeEditor()
					this.$emit('savedModel', response.data)

				}, response => {
					this.loading = false
				})
			}
		},

		destroy(item) {
			this.loading = true
			this.axios.delete(this.modelDef + '/' + item.id,).then(response => {
				this.loading = false
				this.getData()
				this.confirmDelete = false

			}, response => {
				this.loading = false
			})
		},

		closeEditor() {
			this.editorDialog = false
			this.editingItem = _.stubObject()
		},

	}
}
</script>
