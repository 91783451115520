<template>
    <v-app style="width: 100%">
        <v-container class="fill-height" fluid>
            <v-slide-y-transition mode="out-in">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="10" md="6" lg="4" xl="4" class="text-center">
                        <img src="img/volontapp-login.png" style="width: 300px" alt="VolontApp" class="pb-4">
                        <v-spacer></v-spacer>
                        <validation-observer ref="login">
                            <validation-provider name="Email" rules="email|required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="email"
                                    name="Email"
                                    :error-messages="errors"
                                    prepend-icon="person"
                                    ref="email"
                                    label="Email"
                                    type="email"
                                    @keyup.enter="submit"
                                    @input="loginError = false"
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider name="Password" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="password"
                                    :error-messages="errors"
                                    prepend-icon="lock"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    @keyup.enter="submit"
                                    @input="loginError = false"
                                ></v-text-field>
                            </validation-provider>
                        </validation-observer>
                        <div v-if="loginError" class="input-group--error input-group--prepend-icon input-group--required input-group--text-field error--text">
                            <div class="input-group__details"><div class="input-group__messages"><div class="input-group__error">{{loginMessage ? loginMessage : 'Email o Password errati' }}</div></div></div>
                        </div>

                        <v-row class="pt-2" align="center" justify="center">
                            <v-col cols="12" class="text-center">
                                <v-btn
                                    large
                                    block
                                    rounded
                                    ripple
                                    color="primary"
                                    :loading="loading"
                                    @click="submit" 
                                    :disabled="!valid">
                                    Login
                                </v-btn>
                                <template v-if="!$store.getters.isTouchDevice">
                                    <v-col cols="12" class="text-center pa-0 pt-2">
                                        <v-btn
                                            style="text-transform: initial;"
                                            color="primary"
                                            text
                                            small
                                            @click="resetPasswordDialog = true">
                                            Password Dimenticata?
                                        </v-btn>
                                    </v-col>                                    
                                    <v-col cols="12" class="text-center pb-0 pt-8">
                                        <span class="caption">Non sei ancora registrato?</span>
                                    </v-col>
                                    <v-col cols="12" class="text-center pt-2">
                                        <v-btn
                                            style="text-transform: initial;"
                                            color="primary"
                                            text
                                            small
                                            @click="toggleDialog(true)">
                                            Registrati
                                        </v-btn>
                                    </v-col>

                                </template>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-slide-y-transition>
        </v-container>

        <register
            v-if="!$store.getters.isTouchDevice"
            :open="openRegisterDialog"
            @close="toggleDialog(false)">
        </register>

		<v-dialog 
			v-model="resetPasswordDialog"
			max-width="900px"
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden"
			:fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card class="rounded-lg pa-0 elevation-0">
                <v-card-title class="pa-6">
                    <v-row no-gutters>
                        <v-col class="col-12">
                            <div class="pl-0 font-weight-bold" style="font-size: 30px">Reset Password</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 18px">Inserisci il tuo indirizzo email per fare il reset della password</div>
                        </v-col>
                    </v-row>
                </v-card-title>                
                <v-card-text style="overflow-x: hidden; margin-top: 0px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid>
						<validation-observer ref="resetPassword">
							<v-row class="pb-2 mx-0 pt-8">
								<v-col class="col-12 px-6 py-0">
									<validation-provider name="Email" rules="email|required" v-slot="{ errors }">
										<v-text-field
											label="Email*"
											type="email"
											v-model="resetPassword['email']"
											:error-messages="errors"
											outlined data-vv-validate-on="blur" 
											dense
											ref="name"
										></v-text-field>
									</validation-provider>
								</v-col>
                                <v-expand-transition>
                                    <v-col v-show="sended" class="col-12 px-6 py-0">
                                        <v-alert icon="mdi-email-check-outline" prominent text type="success">
                                            La mail per il reset della password è stata inviata alla tua casella di posta!
                                        </v-alert>
                                    </v-col>
                                </v-expand-transition>
                            </v-row>
                        </validation-observer>
                    </v-container>
                </v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="resetPasswordDialog = false">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>                    
					<v-btn color="success" min-width="100px" height="40" class="ml-4" :loading="loading" @click.native="sendResetPassword()">Invia</v-btn>
                </v-card-actions>                
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import Register from '@/components/main/Register'

export default {
	name: 'login-panel',
	components: {
		Register
	},
	data() {
		return {
			email: '',
			valid: true,
			password: '',
			sended: false,
			loading: false,
			loginMessage: '',
			loginError: false,
			resetPassword: {},
			openRegisterDialog: false,
			resetPasswordDialog: false,
		}
	},

	methods: {

		toggleDialog(value) {
			this.openRegisterDialog = value
		},

		submit() {
			this.loginError = false
			this.loading = true

			this.$refs.login.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				}
				this.$store.dispatch('authLogin', {email: this.email, password: this.password}).then(() => {
					this.loading = false
					this.$store.dispatch('redirectHome')
				}).catch((response) => {
					if(response.data && response.data.message) {
						this.loginMessage = response.data.message
					}
					this.loginError = true
					this.loading = false
				})

			}).catch(() => {
				this.loginError = true
				this.loading = false
			})

		},

		sendResetPassword() {
			this.loginError = false
			this.loading = true

			this.$refs.resetPassword.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				}
				this.axios.post(this.$store.getters.getAuthUrl + '/reset-password', this.resetPassword).then((response) => {
					this.loading = false
					this.sended = true
				})

			}).catch(() => {
				this.loginError = true
				this.loading = false
			})

		}
	},

	mounted() {
		this.$refs.email.focus()
	}
}
</script>