import Vue from 'vue'
import { trans } from '@/components/utilities/trans.js'


Vue.trans = trans
Vue.prototype.$trans = trans


// FILTERS

Vue.filter('capitalize', (value) => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('date', (value) => {
	if (!value) return ''
	if(value.length > 10) {
		return moment(value).format('DD/MM/YYYY HH:mm')
	} else {
		return moment(value).format('L')
	}
})

Vue.filter('datetime', (value) => {
	if (!value) return ''
	value = value.toString()
	return moment(value).format('DD/MM/YYYY HH:mm')
})

Vue.filter('time', (value) => {
	if (!value) return ''
	value = value.toString()
	return moment(value).format('HH:mm')
})

Vue.filter('trans', (value) => {
	if (!value) return ''
	return trans(value)
})

Vue.filter('boolean', (value) => {
	if (value) {
		return 'mdi-checkbox-marked-outline'
	} else {
		return 'mdi-checkbox-blank-outline'
	}
})
Vue.filter('bool', (value) => {
	if (value) {
		return 'Si'
	} else {
		return 'No'
	}
})

Vue.filter('day', (value) => {
	if (!value) return ''
	return moment().day(value).format('dddd')
})


Vue.filter('currency', (value, decimails = 5) => {
	if (value === '' || typeof value === 'undefined') return ''
	if(!isNumber(value)){ 
		try {
			value = JSON.parse(value)			
		} catch (error) {
			return ''
		}
	}
	if(!isNumber(value)) return ''
	return new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
		maximumFractionDigits: decimails
	}).format(value)
})

// NON FUNZIONA CON I GIORNI - GESTITO NEL TRANS
/*
Vue.filter('ruleDay', (value) => {
	if (!value) return ''
	switch (value) {
		case 'weekday':
			return 'Feriale'
		case 'preholiday':
			return 'Pre-festivo'
		case 'holiday':
			return 'Festivo'
		case 'always':
			return 'Sempre'
	}
	return moment().day(value).format("dddd")
})
*/

Vue.filter('duration', (value) => {
	if (!value) return '00:00'
	let h = value / 60 | 0,
		m = value % 60 | 0
	let result = ''
	if(h <= 9) {
		result = '0' + Math.round(h).toString() + ':' 
	} else {
		result = Math.round(h).toString() + ':'
	}
	if(m <= 9) {
		result = result +  '0' + m.toString()
	} else {
		result = result + m.toString()
	}

	return result
})

Vue.filter('currency', (value, decimails = 5) => {
	if (value === '' || typeof value === 'undefined') return ''
	if(!isNumber(value)){ 
		try {
			value = JSON.parse(value)			
		} catch (error) {
			return ''
		}
	}
	if(!isNumber(value)) return ''
	return new Intl.NumberFormat('it-IT', {
		style: 'currency',
		currency: 'EUR',
		maximumFractionDigits: decimails
	}).format(value)
})

Vue.filter('byte', function (bytes, decimals, kib) {
	kib = kib || false
	if (bytes === 0) return '0 Bytes'
	if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number'
	const k = kib ? 1024 : 1000
	const dm = decimals || 2
	const sizes = kib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

function isNumber(n) {
	return !isNaN(parseFloat(n)) && isFinite(n)
}