<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Brevetti"
				:totalItems="totalItems"
				icon="mdi-certificate-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>


			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-card :elevation="1" class="background" width="100%">
					<v-expand-transition>
						<v-alert v-show="showSelectAll" icon="mdi-check-box-multiple-outline" text prominent type="info">
							<template v-if="!selectAll">
								Tutte i <span class="font-weight-bold">{{ selected.length }}</span> brevetti in questa pagina sono stati selezionati.
								<v-btn @click="setSelectAll()" color="info" dark text>Seleziona tutti</v-btn> i {{ totalItems }} brevetti esistenti
							</template>
							<template v-else>
								Tutti i <span class="font-weight-bold">{{ totalItems }}</span> brevetti sono stati selezionati.
								<v-btn @click="cancelSelectAll()" color="info" dark text>Annulla la selezione</v-btn>
							</template>
						</v-alert>
					</v-expand-transition>								
					<v-data-table
						:options.sync="pagination"
						:headers="headers"
						:items="patents"
						:server-items-length="totalItems"
						:loading="loading"
						:search="search"
						v-model="selected"
						:hide-default-footer="!$vuetify.breakpoint.mdAndUp"
						show-select
						:mobile-breakpoint="0"
						ref="vDataTable"
						:footer-props="{
							'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
							showFirstLastPage: true
						}">

						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props"  @input="showSelectAll = $event" v-on="on"></v-simple-checkbox>
						</template>

						<template v-slot:body.prepend>
							<tr>
								<td>
									<v-icon >mdi-magnify</v-icon>
								</td>
								<td>
									<v-tooltip top v-if="!isEmptyColumnsFilter">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
												<v-icon>mdi-progress-close</v-icon>
											</v-btn>
										</template>
										<span>Svuota Filtri</span>
									</v-tooltip>
								</td>
								<td class="pl-0">
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Nome"
										hide-details
										v-model="columnsFilter['name']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<!-- <v-text-field
										dense
										solo
										flat
										placeholder="Mansioni"
										hide-details
										v-model="columnsFilter['task']"
									></v-text-field> -->
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Durata"
										hide-details
										v-model="columnsFilter['valid_month']"
									></v-text-field>
								</td>
							</tr>                                        
						</template>

						<template v-slot:item="{ item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="callSelect(select, $event)"></v-simple-checkbox>
								</td>								
								<td style="white-space: nowrap">
									<actions-menu v-if="$store.getters.checkPermission('manage_patents')"
										:item="item"
										:actions="actions"
									></actions-menu>
								</td>
								<td @click="show(item)"></td>		
								<td @click="show(item)">
									{{ item.name }}
								</td>
								<td @click="show(item)">
									<v-tooltip top v-for="(task, t) in item.tasks" :key="item.id + '_' + t">
										<template v-slot:activator="{ on: tooltip }">
											<v-icon v-on="{ ...tooltip }" :color="tasksById[task.id].color" :size="18" class="pr-1">
												{{ tasksById[task.id].icon }}
											</v-icon>
										</template>
										<span>{{ tasksById[task.id].name ? tasksById[task.id].name : '' }}</span>
									</v-tooltip>
								</td>
								<td @click="show(item)">
									{{ item.valid_month }}
								</td>
							</tr>
						</template>

						<template v-slot:footer.page-text="{ pageStart, pageStop }">
							<div class="primary--text"><span>{{ selectedBE ? selectedBE : countSelected }}</span> Selezionati</div>
							Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
						</template>

						<template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
							<div class="text-center d-flex background pa-4">
								<v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-text-field
									hide-details
									rounded
									solo
									dense
									prefix="#"
									label="Pagina"
									type="number"
									:min="1"
									:max="props.pagination.pageCount"
									v-model="newPageToJump">
									<template v-slot:append-outer>
										<v-btn dark color="primary" @click="jumpToPage()">
											Vai
										</v-btn>						
									</template>
								</v-text-field>
								<v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>					
							</div>
							<div class="text-center caption pr-4">
								<div class="primary--text"><span>{{ selectedBE ? selectedBE : countSelected }}</span> Selezionati</div>
								Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
							</div>

						</template>						
					</v-data-table>

					<v-speed-dial
						v-if="$store.getters.checkPermission('manage_patents')"
						:class="{'mb-16': $store.getters.showBottomNavigator}"
						fixed
						v-model="fab"
						bottom
						right
						:loading="loading"
						color="primary"
						direction="left"
						transition="slide-x-transition">
						<template v-slot:activator>
							<v-btn
								v-model="fab"
								color="primary"
								dark
								fab>
								<v-icon v-if="fab">mdi-close</v-icon>
								<v-icon v-else>mdi-certificate-outline</v-icon>
							</v-btn>
						</template>              
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									@click.native.stop="create()"
									color="indigo">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							<span>Nuovo</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">                             
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									color="green"
									@click.native.stop="$eventBusUtility.openFile($store, '/patentperson-bypatents-csv/', 'Brevetti.csv', 'POST', {'selected': selectedIds})">
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</template>
							<span>Esporta CSV</span>   
						</v-tooltip>
					</v-speed-dial>									
				</v-card>

				<footer-logo></footer-logo>

			</v-container>

			<patent-editor
				:info="info"
			></patent-editor>

		</v-col>
	</v-row>
</template>


<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'
import PatentEditor from '@/components/modules/courses/PatentEditor'

export default {
	name: 'patents-crud',
	components: {
		HeaderCrud,
		PatentEditor,
	},
	mixins: [DataTableMixin],
	data() {
		return {
			search: '',
			fab: false,
			patents: [],
			info: {},
			selected: [],
			timeout: null,
			totalItems: 0,			
			loading: false,			
			headers: [
				{text: 'Azioni', value: 'actions', 'sortable': false},				
				{text: '', value: '', 'sortable': false},				
				{text: 'Nome', value: 'name'},
				{text: 'Mansioni', value: 'task', sortable: false},
				{text: 'Durata Brevetto [mesi]', value: 'valid_month'},
			],
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editPatent'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyPatent',
				},
			],
			pagination: {
				'sortBy': ['name'],
				'sortDesc': [false],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
		}
	},

	computed: {

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},    		
	},

	mounted() {
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.getData()
		})
	},
	
	created() {
		// Get info data for model
		this.axios.get('patents-info').then(response => {
			// get info data
			this.info = response.data.info
			this.getData()
			this.$store.commit('END_LOADING')

		}, response => {
			// error callback
			this.loading = false
		})

	},
	methods: {

		getData() {
			if(this.timeout) clearTimeout(this.timeout)

			this.timeout = setTimeout(() => {
				this.loading = true
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					filter: this.search,
				}				
				this.axios.get('patents', {params: dataObject}).then(response => {
					this.patents = response.data.data
					this.totalItems = response.data.meta.total
					this.loading = false
				}).catch(() => {
					this.loading = false
				})
			}, 500)
		},

		show(item) {
			this.$eventBusUtility.$emit('showPatent', item)
		},

		create() {
			this.$eventBusUtility.$emit('createPatent')
		},
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
	},

}
</script>