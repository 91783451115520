<template>
    <v-col class="col-12 pa-0 mx-0">
		<v-subheader>Regole Notifiche {{ type | trans}}
			<v-spacer></v-spacer>
			<v-btn fab dark class="mb-n8" style="z-index: 2" small color="primary" @click="create()">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-subheader>
        <v-card class="rounded-lg pa-0">
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Azioni
                                </th>
                                <th class="text-left">
                                    Persona
                                </th>
                                <template v-if="type == 'calendar'">
                                    <th class="text-left">
                                        Giorni
                                    </th>
                                    <th class="text-left">
                                        Tipo Turni
                                    </th>
                                </template>
                                <template v-else>
                                    <th class="text-left">
                                        Tipo Checklist
                                    </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, s) in notificationrules" :key="s">
                                <td style="white-space: nowrap">
                                    <actions-menu
                                        :item="item"
                                        :actions="actions"
                                    ></actions-menu>
                                </td>
                                <td>{{ item.person.full_name }}</td>
                                <template v-if="type == 'calendar'">
                                    <td>
                                        <template v-if="item.options">
                                            <v-chip x-small v-for="(day, d) in item.options.days" :key="d" class="text-capitalize">{{ day | trans }}</v-chip>
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="item.options && shifttypesById">
                                            <template v-for="(shifttype, s) in item.options.shifttypes">
                                                <v-chip x-small :key="s" v-if="shifttypesById[shifttype]">{{ shifttypesById[shifttype].name }}</v-chip>
                                            </template>
                                        </template>
                                    </td>
                                </template>
                                <template v-else>
                                    <td>
                                        <template v-if="item.options">
                                            <v-chip x-small v-for="(checklisttype, s) in item.options.checklisttypes" :key="s">{{ checklisttypesById ? checklisttypesById[checklisttype].name : '' }}</v-chip>
                                        </template>
                                    </td>
                                </template>                                
                            </tr>
                            <tr v-if="notificationrules && notificationrules.length == 0">
                                <td :colspan="type == 'calendar' ? 4 : 3" class="text-center">Nessun risultato presente</td>
                            </tr>
                        </tbody>
                        </template>
                </v-simple-table>    
            </v-card-text>
        </v-card>

      	<v-dialog
			v-model="editorDialog"
			fullscreen
			hide-overlay
			transition="scroll-x-reverse-transition"
			scrollable
			style="overflow-x: hidden">
			<v-card tile>
				<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters class="d-block">

                        <template>
                            <div class="pl-0 font-weight-bold">{{ title }}</div>
                        </template>

					</v-row>
				</v-card-title>
          		<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
					<v-container class="justify-center" fluid v-if="!confirmDelete">
					
                        <validation-observer ref="notificationrulesFields">
                            <v-row no-gutters>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Persona" rules="required" v-slot="{ errors }">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Persona*"
                                            v-model="model['person_id']"
                                            item-value="id"
                                            item-text="full_name"
                                            :error-messages="errors"
                                            clearable
                                            small-chips
                                            :items="info.people"
                                            dense
                                            outlined>
                                        </v-autocomplete>
                                    </validation-provider>
                                </v-col>
                            </v-row>


                            <template v-if="type == 'calendar'">
                                <v-subheader>Giorni</v-subheader>
                                <v-row no-gutters class="px-4">
                                    <v-checkbox
                                        label="Lunedì"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="monday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Martedì"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="tuesday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Mercoledì"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="wednesday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Giovedì"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="thursday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Venerdì"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="friday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Sabato"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="saturday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Domenica"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="sunday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Feriale"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="weekday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Pre-Festivo"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="preholiday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Festivo"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="holiday"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Sempre"
                                        v-model="days"
                                        color="primary"
                                        class="pr-2"
                                        value="always"
                                    ></v-checkbox>
                                </v-row>
                                <v-subheader>Tipi turno</v-subheader>
                                <v-row no-gutters class="px-4">
                                    <v-checkbox v-for="(shifttype, t) in info.shifttypes" :key="t"
                                        :label="shifttype.name"
                                        v-model="shifttypes"
                                        color="primary"
                                        class="pr-2"
                                        :value="shifttype.id"
                                    ></v-checkbox>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-subheader>Tipi Checklist</v-subheader>
                                <v-row no-gutters class="px-4">
                                    <v-checkbox v-for="(checklisttype, t) in info.checklisttypes" :key="t"
                                        :label="checklisttype.name"
                                        v-model="checklisttypes"
                                        color="primary"
                                        class="pr-2"
                                        :value="checklisttype.id"
                                    ></v-checkbox>
                                </v-row>                                
                            </template>

                        </validation-observer>

					</v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questa Regola di Notifica?</div>
                        </v-row>
                    </v-container>                    
				</v-card-text>

				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
						</template>
						<span>Chiudi</span>
					</v-tooltip>
					<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
					<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveNotificationrule()">Salva</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="saveNotificationrule()">Crea</v-btn>
				</v-card-actions>
			</v-card>
		
		</v-dialog>

      </v-col>
</template>

<script>

import Vue from 'vue'

export default {
	name: 'notificationrules',
	props: {
		type: String,
	},
	data() {
		return {
			info: {},
			model: {},
			days: [],
			loading: false,
			shifttypes: [],
			checklisttypes: [],
			editorDialog: false,
			notificationrules: [],
			confirmDelete: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editNotificationrule' + this.type
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyNotificationrule'  + this.type
				},
			],            
		}
	},

	computed: {
		title() {
			if(this.confirmDelete) {
				return 'Elimina Regola'
			} else if(this.model.id) {
				return 'Modifica Regola Notifiche'
			} else {
				return 'Nuova Regola Notifiche'
			}
		},

		shifttypesById() {
			const initialValue = {}
			return this.info.shifttypes ? this.info.shifttypes.reduce((obj, item) => {
				return {
					...obj,
					[item['id']]: item,
				}
			}, initialValue) : null
		},

		checklisttypesById() {
			const initialValue = {}
			return this.info.checklisttypes ? this.info.checklisttypes.reduce((obj, item) => {
				return {
					...obj,
					[item['id']]: item,
				}
			}, initialValue) : null
		},
	},

	methods: {
		getData() {
			this.loading = true
			this.axios.get('notificationrules', {params: {type: this.type}}).then(response => {
				this.notificationrules = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		getInfo() {
			this.loading = true
			this.axios.get('notificationrules-info', {params: {type: this.type}}).then(response => {
				this.info = response.data.info
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		closeEditor() {
			this.editorDialog = false
			this.confirmDelete = false
			this.days = []
			this.checklisttypes = []
			this.shifttypes = []
			this.model = _.stubObject()
		},

		edit(item) {
			this.model = {...item}
			if(this.type == 'calendar') {
				Vue.set(this, 'days', item.options.days)
				Vue.set(this, 'shifttypes', item.options.shifttypes)
			} else {
				Vue.set(this, 'checklisttypes', item.options.checklisttypes)
			}
			this.loading = false
			this.editorDialog = true
		},

		create() {
			this.editorDialog = true
		},        

		destroy() {
			this.loading = true
			this.axios.delete('notificationrules/'+ this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},        

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		saveNotificationrule() {
			this.$refs.notificationrulesFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				this.$set(this.model, 'type', this.type)
				if(this.type == 'calendar') {
					this.model.options = {'days': this.days, 'shifttypes': this.shifttypes}
				} else {
					this.model.options = {'checklisttypes': this.checklisttypes}
				}
				if(this.model.id) {
					this.axios.put('notificationrules/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('notificationrules', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		}        

	},

	created() {
		this.getData()
		this.getInfo()
	},

	mounted() {
		this.$eventBusUtility.$on('editNotificationrule' + this.type, (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyNotificationrule' + this.type, (item) => {
			this.confirmDestroy(item)
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('editNotificationrule' + this.type)
		this.$eventBusUtility.$off('destroyNotificationrule' + this.type)
	},
}
</script>

<style>

</style>