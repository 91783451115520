import Vue from 'vue'
import router from '@/router'
import global from './global'
import Echo from 'laravel-echo'
import { eventBusUtility } from '@/eventBusUtility.js'

function initialState() {
	return {
		profile: {},
		settings: {},
		sections: [],
		permissions: {},
		notifications: 0,
		requests: 0,
		person: {},
		organizations: [],
		currentOrganization: {},
		bottomNavigator: false,
	
	}	
}

const state = initialState

const getters = {

	getUserProfile: state => state.profile,

	getCurrentOrganization: state => state.currentOrganization,

	getUserOrganizations: state => state.organizations,

	getUserUuid: state => state.uuid,

	getUserSections: state => state.sections,

	getUserSectionsByModule: state => {
		const initialValue = {}
		return state.sections ? state.sections.reduce((obj, item) => {
			return {
				...obj,
				[item['module']]: item,
			}
		}, initialValue) : []
	},

	getRequestsCount: state => state.requests,

	getUserNotifications: state => state.notifications,

	getUserPerson: state => state.person,

	getUserPermission: (state) => (key) => state.permissions[key] ? state.permissions[key] : null,

	checkPermission: (state) => (key, value = null) => {
		if(state.person && state.person.role == 'superuser')
			return true

		else if(state.person && state.person.role == 'staff') {
			if(value) {
				if (state.permissions[key] && state.permissions[key].includes(value)) {
					return true
				} else {
					return false
				}
			}
			if (state.permissions[key]) {
				return true
			} else {
				return false
			}
		} else return false
	},

	isSuperuser: (state) => state.person && state.person.role == 'superuser',

	isStaff: (state) => state.person && (state.person.role == 'superuser' || state.person.role == 'staff'),

	checkSetting: (state) => (model, key, value = null) => {
		if(value) {
			if (state.settings[model][key] && state.settings[model][key].includes(value)) {
				return true
			} else {
				return false
			}
		}
		if (state.settings[model][key] == '1') {
			return true
		} else {
			return false
		}
	},

	checkModulePermissions: (state, getters) => (key) => {
		if(!key) {
			return true
		}
		if(getters.getUserSectionsByModule[key] && getters.getUserSectionsByModule[key]['status'] == true) {
			return true
		} else {
			return false
		}
	},	

	showBottomNavigator: state => state.bottomNavigator,

}

const actions = {

	redirectHome: ({ state }) => {
		if(state.currentOrganization.id) {
			eventBusUtility.navigateTo('home')
		} else {
			eventBusUtility.navigateTo('organizations')
		}
	},

	fetchUser: ({commit, dispatch, state, rootGetters}) => {
		return new Promise((resolve, reject) => {
			Vue.prototype.$echo = new Echo({
				broadcaster: 'socket.io',
				host: rootGetters.getHostname,
				authEndpoint: rootGetters.getHostname + '/broadcasting/auth',
				auth: {
					headers: {
						Authorization: 'Bearer ' + rootGetters.getAuthToken
					}
				}
			})

			Vue.axios({url: rootGetters.getAuthUrl +'/user-details', method: 'GET' }).then(response => {
				localStorage.user = JSON.stringify(response.data.data)
				
				commit('SET_USER', response.data.data)
				dispatch('setupNotifications')
				commit('SET_BOTTOM_NAVIGATOR', rootGetters)

				// WS Listeners
				Vue.prototype.$echo.private('notifications.user.' + response.data.data.user.id).notification((notification) => {
					Vue.axios({url: rootGetters.getAuthUrl + '/notifications/', params: {count: true, filters: {status: 'unread'}}, method: 'GET'}).then(response => {
						// get body data
						commit('SET_NOTIFICATIONS_COUNT', response.data.data)

					}, response => {
						// error callback
					})
				})

				if(rootGetters.getUserOrganizations.length == 0) {
					if(router.currentRoute.name !== 'verifyemail')
						eventBusUtility.navigateTo('organizations')
					resolve(true)
				} else {
					let fqdn = ''
					let currentOrganization
					if (localStorage.getItem('fqdn') !== null) {
						fqdn = localStorage.getItem('fqdn')
		
						currentOrganization = rootGetters.getUserOrganizations.find((organization) => {
							return organization.fqdn == fqdn
						})
					}

					if(typeof currentOrganization === 'undefined') {
						currentOrganization = rootGetters.getUserOrganizations[0]
					}
		
					commit('SET_CURRENT_ORGANIZATION', currentOrganization)
					dispatch('fetchUserInOrganization').then(() => {
						if(state.person && state.person.role == 'superuser') {
							Vue.prototype.$echo.private(`organization.${rootGetters.getCurrentOrganization.id}.requests`).listen('.OrganizationUserEvent', (event) => {
								dispatch('retrieveRequestsCount')
							})
						}

						if(rootGetters.isTouchDevice) {
							let idleTime = 0
							let timerIncrement = () => {
								idleTime++
				
								if (idleTime > 3 && rootGetters.isAuth && rootGetters.isTouchDevice) { // 2 minutes
									dispatch('authLogout')
								}
							}
				
							commit('SET_TOUCH_TIMER', setInterval(timerIncrement, 30000) )
				
							let resetIncrement = () => idleTime = 0
				
							window.addEventListener('click', resetIncrement)
							window.addEventListener('keypress', resetIncrement)
						}
						resolve(true)
					})
					dispatch('getGeneraltypes')
				}

			}).catch(response => {
				dispatch('authLogout')
				reject(response)
			})
			
		})
	},

	fetchUserInOrganization: ({commit, dispatch}) => {
		return new Promise((resolve, reject) => {
			Vue.axios({url: '/user-in-organization', method: 'GET' }).then(response => {
				commit('SET_PERSON_DATA', response.data.data)
				resolve(true)
			}).catch(response => {
				dispatch('authLogout')
				reject(response)
			})
		})
	},

	fetchNotifications: ({commit, dispatch}, params) => {
		return new Promise((resolve, reject) => {
			Vue.axios({url: 'notifications', method: 'GET', params: params }).then(response => {
				commit('SET_NOTIFICATIONS', response.data.data)
				resolve(true)
			}).catch(response => {
				dispatch('authLogout')
				reject(response)
			})
		})
	},

	changeOrganization: ({commit, dispatch}, organization) => {
		return new Promise((resolve, reject) => {
			commit('SET_CURRENT_ORGANIZATION', organization)

			dispatch('fetchUserInOrganization').then(() => {				
				resolve(true)
			}).catch((response) => {
				reject(response)
			})

		})
	},

	retrieveRequestsCount: ({commit}) => {
		Vue.axios.get('/requests').then(response => {
			commit('SET_REQUESTS_COUNT', response.data.meta.total)
		})
	},

	setupNotifications: ({rootGetters}) => {
		if(typeof window.plugins == 'undefined') return

		//START ONESIGNAL CODE
		//Remove this method to stop OneSignal Debugging 
		// window.plugins.OneSignal.setLogLevel({logLevel: 6, visualLevel: 0});
		
		let notificationOpenedCallback = function(notificationData) {
			if(typeof notificationData.notification.additionalData.redirect_url !== 'undefined') {
				router.push({path: notificationData.notification.additionalData.redirect_url, trigger: true, replace: true})
			} else if(typeof notificationData.notification.additionalData.notification_id !== 'undefined') {
				router.push({path: 'notifications/'+notificationData.notification.additionalData.notification_id, trigger: true, replace: true})
			}
		}

		window.plugins.OneSignal.setAppId("fdfb0bca-df0f-45f0-9c57-aba51add60cf");
		window.plugins.OneSignal.setNotificationOpenedHandler(notificationOpenedCallback);
		window.plugins.OneSignal.promptForPushNotificationsWithUserResponse();
	
		window.plugins.OneSignal.getDeviceState(function(stateChanges) {
			if(stateChanges.hasNotificationPermission && stateChanges.userId) {
				Vue.axios.post(rootGetters.getAuthUrl + '/store-device-id', {
					'uuid': stateChanges.userId
				})
			}
		});
		//END ONESIGNAL CODE
	}
}

const mutations = {

	SET_USER_DEFAULT: (state) =>  {
		const s = initialState()
		Object.keys(s).forEach(key => {
			state[key] = s[key]
		})
	},

	SET_BOTTOM_NAVIGATOR: (state, rootGetters) => {
		state.bottomNavigator = !rootGetters.isMiseApp && Vue.prototype.$vuetify.framework.breakpoint.smAndDown && rootGetters.isAuth
	},	

	SET_USER: (state, response) => {
		state.profile = response.user
		state.organizations = response.organizations
	},

	SET_NOTIFICATIONS_COUNT: (state, count) => {
		state.notifications = count
	},

	SET_REQUESTS_COUNT: (state, count) => {
		state.requests = count
	},

	SET_USER_AVATAR: (state, avatar) => {
		state.profile.avatar = avatar
	},

	SET_ORGANIZATION_AVATAR: (state, avatar) => {
		state.currentOrganization.avatar = avatar
	},
	
	SET_CURRENT_ORGANIZATION: (state, organization) => {
		state.currentOrganization = organization
		global.state.base_url = organization.fqdn
		localStorage.setItem('fqdn', organization.fqdn)
		Vue.axios.defaults.baseURL = organization.fqdn + '/api/v1'
	},
	
	SET_PERSON_DATA: (state, response) => {
		state.person = response.person
		state.settings = response.settings
		state.sections = response.sections
		state.permissions = response.permissions
		state.notifications = response.notifications
		state.requests = response.requests
	},

	SET_SETTINGS: (state, response) => {
		state.settings = response.settings
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}