import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { extend } from 'vee-validate'
import { setInteractionMode } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { required, email, max_value, min_value, min, max, confirmed, size, length, integer, double, numeric } from 'vee-validate/dist/rules'
import {VeeValidate, localize, ValidationProvider, ValidationObserver } from 'vee-validate'

// COMPONENTS
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

setInteractionMode('lazy')

// LOCALE
localize('it', it)

// RULES
extend('email', email)
extend('required', required)
extend('max_value', max_value)
extend('min_value', min_value)
extend('max', max)
extend('min', min)
extend('size', size)
extend('confirmed', confirmed)
extend('length', length)
extend('integer', integer)
extend('double', double)
extend('numeric', numeric)

extend('is_time', {
	message: field => 'Il formato deve essere HH:MM',
	validate: (value) => new Promise(resolve => {
		let regex = new RegExp('([0-1][0-9]|2[0-3]):([0-5][0-9])')
		resolve({
			valid: value && regex.test(value)
		})
	}),
})

extend('contemporary_vehicle', {
	validate: (value, params) => {
		let model = params[0]
		if(model && model.vehicle_id && model.started_at && model.ended_at && model.start_time && model.end_time && model.calendar_id) {
			return axios.post('contemporary-vehicle/',
				{
					'started_at': model.started_at + ' ' + model.start_time,
					'ended_at': model.ended_at + ' ' + model.end_time,
					'calendar_id': model.calendar_id,
					'vehicle_id': model.vehicle_id,
					'shift_id': model.id,
				}).then((response) => {
				// Notice that we return an object containing both a valid property and a data property.
				if(response.data.message) {
					params.errors = response.data.message
				} else {
					return {valid: true}
				}
			})
		}
	},	
	message: (field, params) => {
		return params.errors ? params.errors.join(' ') : ''
	},
})

extend('unique_email', {
	validate: (value, params) => {
		let model = params[0]
		if(model) {
			return axios.post(store.getters.getAuthUrl + '/unique-email/',
				{
					'email': model.email,
				}).then((response) => {
				// Notice that we return an object containing both a valid property and a data property.
				if(response.data.error) {
					params.error = response.data.error
				} else {
					return {valid: true}
				}
			})
		}
	},
	message: (field, params) => {
		return params.error ? Vue.trans(params.error):  ''
	},
})

extend('date_interval', {
	params: ['started_at', 'start_time', 'end_time'],
	validate(value, { started_at, start_time, end_time }) {
		if(value && started_at && start_time && end_time) {
			return moment(started_at + ' ' + start_time, 'DD/MM/YYYY hh:mm:00').isBefore(moment(value + ' ' + end_time, 'DD/MM/YYYY hh:mm:00'))
		}
	},
	message: field => 'La data di fine deve essere successiva alla data di inizio',
})