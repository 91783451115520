<template>
    <v-container
        class="text-center"
        fill-height
        style="height: calc(100vh - 258px)">
        <v-row align="center">
            <v-col>
                <v-img
                    class="mb-12"
                    contain
                    height="200"
                    src="img/volontapp-login.png">
                </v-img>
                <h1 class="display-2 primary--text pb-2">Complimenti!</h1>

                <p>{{ message }}</p>

                <v-btn @click="$store.dispatch('redirectHome')"
                    color="primary"
                    outlined
                    class="mt-8">
                    Ritorna alla Bacheca!
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'verifyemail',

	props: {
	},

	data() {
		return {
			message: '',
		}
	},

	mounted() {

		Vue.axios.get(this.$route.query.redirect_url).then(response => {
			this.message = response.data.message
            
		}, response => {

		})
	}
}
</script>

<style>

</style>