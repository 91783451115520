<template>
    <v-col class="col-12 pa-0 mx-0">
        <v-subheader>Impostazioni Calendario</v-subheader>

        <v-card class="rounded-lg pa-0">
            <v-card-text>
                <validation-observer ref="fieldsSettings">
                    <v-row no-gutters>
                        <v-col class="col-12 py-0 px-lg-2 pl-xs-0">
                            <v-subheader>Intervallo giorni per iscrizione al turno (volontario)</v-subheader>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                            <validation-provider name="Minimo giorni disponibilità" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    type="number"
                                    label="Minimo giorni disponibilità"
                                    v-model="settings['minimum_subscription_availability']"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                    ref="minimum_subscription_availability"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                            <validation-provider name="Massimo giorni disponibilità" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    type="number"
                                    label="Massimo giorni disponibilità"
                                    v-model="settings['maximum_subscription_availability']"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                    ref="maximum_subscription_availability"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col class="col-12 py-0 pl-xs-0">
                            <v-subheader>Intervallo giorni per cancellazione al turno (volontario)</v-subheader>
                        </v-col>                    
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                            <validation-provider name="Minimo giorni cancellazione" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    type="number"
                                    label="Minimo giorni cancellazione"
                                    v-model="settings['minimum_cancellation_availability']"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                    ref="minimum_cancellation_availability"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                            <validation-provider name="Massimo giorni cancellazione" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    type="number"
                                    label="Massimo giorni cancellazione"
                                    v-model="settings['maximum_cancellation_availability']"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                    ref="maximum_cancellation_availability"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>

                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Permetti definizione orario di disponibilità da parte del volontario"
                                v-model="settings['overwrite_availability_schedule']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="overwrite_availability_schedule"
                            ></v-checkbox>
                        </v-col>
                        <!--<v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Disattiva la verifica delle disponibilità contemporanee"
                                v-model="settings['ignore_contemporary_availabilities']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="ignore_contemporary_availabilities"
                            ></v-checkbox>
                        </v-col>-->
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Approvazione disponibilità"
                                hint="La disponibilita per un turno deve essere approvata da uno Staff"
                                persistent-hint
                                v-model="settings['availability_approval']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="availability_approval"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Mostra solo turni disponibili"
                                hint="Mostra solo i turni a cui il volontario puo partecipare"
                                persistent-hint
                                v-model="settings['show_only_available_shifts']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="show_only_available_shifts"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Distingui dipendenti"
                                persistent-hint
                                v-model="settings['distinguish_employees']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="distinguish_employees"
                                hide-details
                            >
                            </v-checkbox>
                            <span class="pt-0 mt-0 v-messages theme--light v-messages__wrapper v-messages__message">Distingue i dipendenti sul calendario con
                                <v-icon size="20" class="pl-0" >mdi-briefcase-outline</v-icon>
                            </span>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Nascondi statistiche globali ai volontari"
                                persistent-hint
                                v-model="settings['hide_global_statistics']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="hide_global_statistics"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Attiva raggruppamento calendari"
                                hint="I calendari verranno raggruppati in base ai gruppi creati"
                                persistent-hint
                                v-model="settings['calendar_group']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="calendar_group"
                            ></v-checkbox>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Attiva sospensione volontari inattivi"
                                hint="I Volontari verranno scollegati dalla propria utenza e impostati come Sospesi in caso di inattività"
                                persistent-hint
                                v-model="settings['suspend_people']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="suspend_people"
                            ></v-checkbox>
                            <validation-provider name="Giorni di inattività" rules="min_value:30" v-slot="{ errors }">
                                <v-text-field
                                    v-if="settings['suspend_people']"
                                    class="mt-3"
                                    type="number"
                                    label="Giorni di inattività"
                                    v-model="settings['min_days_inactivity']"
                                    outlined
                                    dense
                                    ref="min_days_inactivity"
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center">
                <v-spacer></v-spacer>
                <v-btn color="success" min-width="100px" height="40" class="ml-4" @click="saveSettings()" :loading="loading">Salva</v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
</template>

<script>

import SettingsOptionsMixin from '@/mixins/SettingsOptionsMixin'

export default {
	name: 'calendar-general-settings',
	mixins: [SettingsOptionsMixin],
	data() {
		return {
			items: [],
			model: 'calendar',
			settings: {},
			loading: false
		}
	},

	methods: {

	},

	created() {
	},

	mounted() {
	},

	beforeDestroy() {
	},       
}
</script>

<style>

</style>