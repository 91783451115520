<template>
	<v-progress-circular :style="style" :size="size" indeterminate :color="color" class="d-print-none"></v-progress-circular>
</template>

<script>

export default {
		
	name: 'centered-loader',
	props: {
		size: Number,
		color: String,
	},

	data() {
		return {
			style: {}
		}
	},

	created() {
		this.centerLoader()
	},

	mounted() {
		window.addEventListener('resize', this.centerLoader)
	},
		
	methods: {
		centerLoader() {
			let leftWidth = window.innerWidth/2 - this.size/2
			let topWidth = window.innerHeight/2 - this.size/2
			this.style = {
				position: 'fixed',
				left: leftWidth+'px',
				top: topWidth+'px'
			}
		}
	},
}

</script>