<template>
    <v-dialog v-model="open" max-width="900" persistent class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Duplica</div>
                    <div v-if="model.id" class="pl-0 font-weight-bold" style="font-size: 24px">{{ model.name ? model.name : ''}} {{ '['+ model.shifttype.name +']' }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="px-1 pb-1">

                <v-container fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="pt-6 mx-0">
                    <validation-observer ref="duplicateShiftFields">
                    
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption">Seleziona la data di inizio per cui vuoi duplicare questo evento</div>
                        </v-row>
                        <v-row class="justify-center align-content-center  mx-0 py-0 px-6" style="min-height: 150px">
                            <v-col class="col-lg-6 col-sm-12 col-md-12 col-12">
                                <v-menu
                                    ref="new_date"
                                    :close-on-content-click="false"
                                    v-model="duplicateMenu"
                                    :return-value.sync="duplicateNewDate"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    :nudge-right="40"
                                    style="width: 100%">
                                    <template v-slot:activator="{ on }">
                                        <validation-provider name="Data Inizio Turno" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                v-on="on"
                                                label="Data Inizio Turno"
                                                :error-messages="errors"
                                                append-icon="event"
                                                readonly
                                                :value="$eventBusUtility.formatDate(duplicateNewDate)"
                                                outlined
                                                @click:clear="duplicateNewDate = ''"
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker 
                                        v-model="duplicateNewDate"
                                        color="primary"
                                        locale="It"
                                        scrollable
                                        :first-day-of-week="1"
                                        @input="$refs['new_date'].save(duplicateNewDate)">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </validation-observer>

                </v-container>

            </v-card-text> 
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
                <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="duplicate()">Duplica</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import Vue from 'vue'

export default {
	name: 'duplicate-shift-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			model: {},
			shift: {},
			open: false,
			fullScreen: false,
			loading: false,
			duplicateDialog: false,
			duplicateMenu: false,
			duplicateNewDate: null,
		}
	},

	computed: {
	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('duplicateShift', (data) => {
			this.openDuplicateDialog(data)
		})
	},

	methods: {
    
		openDuplicateDialog(data) {
			this.model = {...data}
			this.open = true
		},

		closeDialog() {
			this.model = _.stubObject()
			this.duplicateNewDate = null
			this.open = false
		},


		duplicate() {
			this.$refs.duplicateShiftFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				this.axios.post('/shifts/' + this.model.id+'/duplicate', {'new_started_at': this.duplicateNewDate}).then(response => {
					this.loading = false
					this.closeDialog()
				}, response => {
					this.loading = false
				})
			}).catch(() => {

			})
		},

	},

	beforeDestroy() {
		this.$eventBusUtility.$off('duplicateShift')
	},

}
</script>

<style>

</style>