<template>
    <v-dialog v-model="open" max-width="1600" class="round-card" persistent :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">{{title}}</div>
                    <div v-if="model.id" class="pl-0 font-weight-bold" style="font-size: 24px">{{ model.name ? model.name : ''}} {{ '['+ model.shifttype.name +']' }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="px-1 pb-1" v-if="model.id">

                <v-container fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="mx-0">
                    
                    <v-row class="mx-0 justify-center pt-6">

                        <v-col class="col-lg-3 col-sm-12 col-md-12 col-12">
                            <shift
                                :shift="model"
                                availability
                                class="background-2 rounded-lg mb-2"
                                :clicked-shifttypetask-id="shifttypetask.task_id">
                            </shift>
                            <v-expansion-panels focusable class="rounded-lg">
                                <v-expansion-panel>
                                    <v-expansion-panel-header style="min-height: 48px">Gruppi</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-checkbox v-for="(group, g) in groups"
                                            :key="g"
                                            :value="group.id"
                                            v-model="filters"
                                            :label="group.name"
                                            hide-details
                                        ></v-checkbox>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>

                        <v-col class="col-lg-9 col-sm-12 col-md-12 col-12" :style="{'min-height': $vuetify.breakpoint.mdAndUp ? '750px' : 'auto'}">
                            
                            <v-card :elevation="1" class=" background" width="100%">
                                <v-expand-transition>
                                    <v-alert v-show="showSelectAll" icon="mdi-check-box-multiple-outline" text prominent type="info">
                                        <template v-if="!selectAll">
                                            Tutte le <span class="font-weight-bold">{{ selected.length }}</span> disponibilità in questa pagina sono state selezionate.
                                            <v-btn @click="setSelectAll()" color="info" dark text>Seleziona tutte</v-btn> le {{ items.length }} disponibilità esistenti
                                        </template>
                                        <template v-else>
                                            Tutte le <span class="font-weight-bold">{{ selected.length }}</span> disponibilità sono state selezionate.
                                            <v-btn @click="cancelSelectAll()" color="info" dark text>Annulla la selezione</v-btn>
                                        </template>
                                    </v-alert>
                                </v-expand-transition>
                                <v-data-table class="background-2 availabilities-table" v-if="open"
                                    :headers="headers"
                                    :items="filteredItems"
                                    :loading="loading"
                                    v-model="selected"
                                    multi-sort
                                    :sort-by="['type', 'affinity']"
                                    :sort-desc="[false, true]"
                                    :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                                    show-select
                                    :mobile-breakpoint="0"
                                    ref="vDataTable"
                                    :page.sync="page"
                                    :footer-props="{
                                        'items-per-page-options': [10, 25, 50],
                                        showFirstLastPage: true
                                    }">
                                    <template v-slot:header.data-table-select="{ on, props }">
                                        <v-simple-checkbox color="primary" v-bind="props" v-on="on" @input="showSelectAll = $event"></v-simple-checkbox>                                      
                                    </template>
                                    <template v-slot:body.prepend>
                                        <tr>
                                            <td>
                                                <v-icon>mdi-magnify</v-icon>
                                            </td>
                                            <td class="pl-0">
                                                <v-text-field
                                                    dense
                                                    solo
                                                    flat
                                                    placeholder="Nome"
                                                    hide-details
                                                    v-model="search.full_name"
                                                ></v-text-field>
                                            </td>
                                            <td class="pl-0">
                                                <v-autocomplete
                                                    :items="tasks"
                                                    v-model="search.tasks"
                                                    item-value="id"
                                                    item-text="name"
                                                    placeholder="Mansioni"
                                                    hide-details
                                                    clearable
                                                    dense
                                                    solo
                                                    flat>
                                                    <template v-slot:item="data">
                                                        <v-list-item-avatar :height="20" :min-width="20" :width="20">
                                                            <v-icon small :color="data.item.color">{{ data.item.icon }}</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>                                                    
                                                </v-autocomplete>
                                            </td>
                                            <td class="pl-0">
                                                <v-text-field
                                                    dense
                                                    solo
                                                    flat
                                                    placeholder="Telefono"
                                                    hide-details
                                                    v-model="search.primary_telephone"
                                                ></v-text-field>
                                            </td>
                                            <td class="pl-0">
                                                <v-text-field
                                                    dense
                                                    solo
                                                    flat
                                                    placeholder="Note"
                                                    hide-details
                                                    v-model="search.note"
                                                ></v-text-field>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>                                        
                                    </template>

                                    <template v-slot:item="{item, select, isSelected}">

                                        <tr>
                                            <td>
                                                <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                                            </td>
                                            <td>
                                                {{ item['full_name'] }}
                                            </td>
                                            <td>
                                                <v-tooltip top v-for="(task_id) in item.abilities" :key="task_id">
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-icon v-on="{ ...tooltip }" :color="tasksById[task_id].color" :size="18" class="pr-1">
                                                            {{ tasksById[task_id].icon }}
                                                        </v-icon>
                                                    </template>
                                                    <span>{{ tasksById[task_id].name }}</span>
                                                </v-tooltip>
                                            </td>
                                            <td >
                                                {{ item['primary_telephone'] }}
                                            </td>
                                            <td>
                                                {{ item['note'] }}
                                            </td>
                                            <td>
                                                <v-progress-linear
                                                    :color="item['affinity'] ? 'teal' : 'error'"
                                                    :value="item['affinity'] ? item['affinity'] : 0"
                                                    stream
                                                ></v-progress-linear>
                                            </td>
                                            <td>
                                                <div class="d-flex">
                                                    <div class="d-inline-flex mt-3" v-if="item.type == 'available' && !item.approved">
                                                        <v-btn
                                                            depressed
                                                            small
                                                            class="mr-2"
                                                            color="success"
                                                            :loading="loadingAcceptAvailability[item.id]"
                                                            @click="selectAvailability(item, 'available')">
                                                        Accetta
                                                        </v-btn>
                                                        <v-btn
                                                            depressed
                                                            small
                                                            class="mr-2"
                                                            color="primary"
                                                            :loading="loadingRefusedAvailability[item.id]"
                                                            @click="selectAvailability(item, 'refused')">
                                                        Rifiuta
                                                        </v-btn>
                                                    </div>

                                                    <v-select style="width: 190px" class="py-2 pr-4" v-if="item.type != 'busy' && item.person_status == 'available'" :key="item.id"
                                                        :items="((item.task_id && item.task_id != shifttypetask.task_id) ? [{text: tasksById[item.task_id].name, value: 0}] : []).concat($store.getters.getGeneralTypes.availability_status)"
                                                        :value="(item.task_id && item.task_id != shifttypetask.task_id) ? 0 : item.type"
                                                        hide-details
                                                        clearable
                                                        dense
                                                        solo
                                                        flat
                                                        @change="selectAvailability(item, $event)"
                                                    ></v-select>

                                                    <div v-else-if="item.type == 'busy'" style="width: 174px; height: 54px" class="d-flex">
                                                        <span class="ma-auto ml-4">Impegnato</span>
                                                    </div>

                                                    <div v-else-if="item.person_status != 'available'" style="width: 174px; height: 54px" class="d-flex">
                                                        <span class="ma-auto ml-4">{{item.person_status_text}}</span>
                                                    </div>

                                                    <v-tooltip top v-if="item.type == 'available'">
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn v-on="{ ...tooltip }" small class="ma-auto" @click="setOpenSettings(item.id)"><v-icon>mdi-cog-outline</v-icon></v-btn>
                                                        </template>
                                                        <span>Dettagli</span>
                                                    </v-tooltip>
                                                    <v-tooltip top v-else-if="item.type == 'busy' && $store.getters.checkPermission('unlock_busy_people')">
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn v-on="{ ...tooltip }" small class="ma-auto" @click="unlockAvailability(item)"><v-icon>mdi-lock-open-outline</v-icon></v-btn>
                                                        </template>
                                                        <span>Sblocca</span>
                                                    </v-tooltip>
                                                    <v-tooltip top v-else-if="item.user_id && !item.type">
                                                        <template v-slot:activator="{ on: tooltip }">
                                                            <v-btn v-if="item.shiftrequest_id" v-on="{ ...tooltip }" small class="ma-auto" color="primary" @click="showRequest(item)"><v-icon>mdi-cellphone-sound</v-icon></v-btn>
                                                            <v-btn v-else v-on="{ ...tooltip }" small class="ma-auto" @click="openSendRequest(item)"><v-icon>mdi-cellphone-sound</v-icon></v-btn>
                                                        </template>
                                                        <span>{{ item.shiftrequest_id ? 'Richiesta Inviata' : 'Invia Richiesta' }}</span>
                                                    </v-tooltip>

                                                    <v-btn v-else plain color="white" depressed small class="ma-auto"><v-icon></v-icon></v-btn>

                                                </div>
                                                <v-fade-transition>
                                                    <div v-if="openSettings[item.id]" class="px-2">
                                                        <validation-observer ref="availabilityFields">
                                                            <validation-provider name="Ora di Inizio" rules="is_time" v-slot="{ errors }">
                                                                <v-text-field
                                                                    placeholder="Ora di Inizio"
                                                                    v-model="item['started_at']"
                                                                    :error-messages="errors"
                                                                    v-mask="'##:##'"
                                                                    return-masked-value
                                                                    dense>
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon size="20">mdi-clock-outline</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </validation-provider>
                                                            <validation-provider name="Ora di Fine" rules="is_time" v-slot="{ errors }">
                                                                <v-text-field
                                                                    placeholder="Ora di Fine"
                                                                    v-model="item['ended_at']"
                                                                    :error-messages="errors"
                                                                    v-mask="'##:##'"
                                                                    return-masked-value
                                                                    dense>
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon size="20">mdi-clock-outline</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </validation-provider>
                                                            <validation-provider name="Punteggio" rules="integer|min_value:0" v-slot="{ errors }">
                                                                <v-text-field
                                                                    type="number"
                                                                    min="0"
                                                                    placeholder="Punteggio"
                                                                    v-model="item['score']"
                                                                    :error-messages="errors"
                                                                    dense>
                                                                    <template v-slot:prepend-inner>
                                                                        <v-icon size="20">mdi-star-half-full</v-icon>
                                                                    </template>
                                                                </v-text-field>
                                                            </validation-provider>
                                                            <v-text-field
                                                                placeholder="Note"
                                                                v-model="item['note']"
                                                                dense>
                                                            </v-text-field>
                                                        </validation-observer>
                                                        <v-btn block color="success" @click="saveCustomAvailability(item, item.type)">Salva</v-btn>
                                                    </div>
                                                </v-fade-transition>
                                                <small v-if="item.availability_id">
                                                    Ultima modifica: <br>
                                                    <i>{{ item.creator }}, {{ formatDuration(item.updated_at) }} </i></small>
                                            </td>

                                        </tr>
                                    </template>


                                    <template v-slot:footer.page-text="{ pageStart, pageStop }">
                                        <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                                        Da {{ pageStart }} a {{ pageStop }} di {{ filteredItems.length }}
                                    </template>

                                    <template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
                                        <div class="text-center d-flex background pa-4">
                                            <v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="page == 1" @click="prevPage()">
                                                <v-icon>mdi-chevron-left</v-icon>
                                            </v-btn>
                                            <v-text-field
                                                hide-details
                                                rounded
                                                solo
                                                dense
                                                prefix="#"
                                                label="Pagina"
                                                type="number"
                                                :min="1"
                                                :max="maxPage"
                                                v-model.number="newPageToJump">
                                                <template v-slot:append-outer>
                                                    <v-btn dark color="primary" @click="jumpToPage()">
                                                        Vai
                                                    </v-btn>						
                                                </template>
                                            </v-text-field>
                                            <v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="page == maxPage" @click="nextPage()">
                                                <v-icon>mdi-chevron-right</v-icon>
                                            </v-btn>					
                                        </div>
                                        <div class="text-center caption pr-4">
                                            <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                                            Da {{ props.pagination.pageStart + 1 }} a {{ props.pagination.pageStop }} di {{ filteredItems.length }}
                                        </div>

                                    </template>
                                </v-data-table>
                            </v-card>

                        </v-col>

                    </v-row>

                </v-container>

            </v-card-text> 
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

                <v-btn color="background-1" :disabled="disabledDialog" fab small @click="closeAvailabilityDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
                <v-slide-x-reverse-transition>
                    <v-btn v-show="selected.length > 0" color="success" class="ml-4" @click="openSendRequest()">
                        Invia Richiesta
                        <v-icon class="pl-2" size="20">mdi-cellphone-sound</v-icon>
                    </v-btn>
                </v-slide-x-reverse-transition>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="sendRequestDialog" max-width="600" class="round-card" persistent :fullscreen="$vuetify.breakpoint.smAndDown" scrollable>
            <v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class="background pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Invia Richiesta</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="closeSendRequest()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>            

                <v-card-text style="overflow-x: hidden; margin-top: 0px" class="px-1 pb-1 body-page">

                    <v-container fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="mx-0">
                        <validation-observer ref="requestFields">
                            <span class="pl-3">{{ requestDialogMessage }}</span>
                            <v-row class="pb-2 mx-0 pt-4">
                                <v-col class="col-12 py-0 px-3 pt-2">
                                    <validation-provider name="Durata" rules="required" v-slot="{ errors }">
                                        <v-autocomplete
                                            label="Durata"
                                            :items="requestDurations"
                                            v-model="request['duration']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                        ></v-autocomplete>
                                    </validation-provider>
                                </v-col>     
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Scade al completamento del turno"
                                        v-model="request['expiring_on_shift_completed']"
                                        color="primary"
                                        :input-value="[true, false]"
                                        ref="expiring_on_shift_completed"
                                    ></v-checkbox>
                                </v-col>                                                      
                            </v-row>
                        </validation-observer>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeSendRequest()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="sendRequest()">
                        Invia
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="showRequestDialog" max-width="600" class="round-card" persistent :fullscreen="$vuetify.breakpoint.smAndDown" scrollable>
            <v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class="background pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-col class="col-10 ma-auto ml-0 py-1">
                        <div class="pl-0 font-weight-bold">Richiesta Inviata</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="closeSendRequest()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>            

                <v-card-text style="overflow-x: hidden; margin-top: 0px" class="px-1 pb-1 body-page">

                    <v-container fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="mx-0">
                        <v-row class="ma-auto d-flex pt-6">
                            <v-icon>mdi-clipboard-account-outline</v-icon>
                            <div class="pl-2 caption">Mansione</div>
                        </v-row>
                        <div class="d-block ml-6" v-if="request.task">
                            <v-chip small class="ma-1">
                                <v-avatar left>
                                    <v-icon :color="request.task.color">{{ request.task.icon }}</v-icon>
                                </v-avatar>
                                {{ request.task.name }}
                            </v-chip>
                        </div>

                        <v-row class="ma-auto d-flex pt-6">
                            <v-icon :color="setRequestStatusColor">mdi-checkbox-marked-outline</v-icon>
                            <div class="d-block">
                                <div class="pl-2 caption">Stato</div>
                                <div class="pl-2">{{ setRequestStatusText }}</div>
                            </div>
                        </v-row>     

                        <v-row class="ma-auto d-flex pt-6">
                            <v-icon>mdi-calendar-check-outline</v-icon>
                            <div class="d-block">
                                <div class="pl-2 caption">Scadenza</div>
                                <div class="pl-2">{{ request.expiring_at | date }}</div>
                            </div>
                        </v-row>
                        
                        <v-row class="ma-auto d-flex pt-6" v-if="request.creator">
                            <v-icon>mdi-account-outline</v-icon>
                            <div class="d-block">
                                <div class="pl-2 caption">Creata da</div>
                                <div class="pl-2">{{ request.creator.full_name }}</div>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeShowRequest()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn color="red" dark :loading="loading" min-width="100px" height="40" class="ml-4" @click="deleteRequest()">
                        Elimina
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>

import Vue from 'vue'
import DataTableMixin from '@/mixins/DataTableMixin'
import Shift from '@/components/modules/calendar/Shift'

export default {
	name: 'availability-dialog',
  	components: {
		Shift,
	},
   	mixins: [DataTableMixin], 
	props: {
		groups: Array,
	},    
	data() {
		return {
			model: {},
			open: false,
			fullScreen: false,
			shifttypetask: {},
			headers: [
				{
					'text': 'Cognome e Nome',
					'value': 'full_name',
					filter: (value) => this.customSearch(value, 'full_name')
				},
				{
					'text': 'Mansioni',
					'value': 'abilities',
					'width': '150',
					filter: (value) => this.customTasksSearch(value)
				},
				{
					'text': 'Telefono',
					'value': 'primary_telephone',
					'width': '120',
					filter: (value) => this.customSearch(value, 'primary_telephone')
				},
				{
					'text': 'Note',
					'value': 'note',
					filter: (value) => this.customSearch(value, 'note')
				},
				{
					'text': 'Affinità',
					'value': 'affinity'
				},
				{
					'text': 'Disponibilità',
					'value': 'type',
					sort: (a,b) => this.customAvailabilitySort(a, b)
				},
			],
			availabilityStatus: [
				{
					'text': 'Diponibile',
					'value': 'available',
				},
				{
					'text': 'Indisponibile',
					'value': 'unavailable'
				},
				{
					'text': 'Da Richiamare',
					'value': 'to_call_back',
				},
				{
					'text': 'Non Risponde',
					'value': 'not_responding',
				},
				{
					'text': 'Rifiutata',
					'value': 'refused'
				},
			],
			requestDurations: [
				{
					'text': '15 Minuti',
					'value': 15,
				},
				{
					'text': '1 Ora',
					'value': 60,
				},
				{
					'text': '1 Giorno',
					'value': 1440,
				},
			],
			request: {'duration': 15},
			openSettings:{},
			items: [],
			tasks: [],
			totalItems: 0,
			selected: [],
			loading: false,
			loadingAcceptAvailability: {},
			loadingRefusedAvailability: {},
			pagination: {},
			filters: [],
			search: {},
			getDataTimeout: null,
			newPageToJump: null,
			page: 1,
			showSelectAll: false,
			selectAll: false,
			sendRequestDialog: false,
			showRequestDialog: false,
			requestDialogMessage: '',
			disabledDialog: false
		}
	},

	computed: {

		tasksById() {
			return this.$eventBusUtility.tasksById(this.tasks)
		},

		title() {
			if(this.model) {
				return 'Turno del ' + moment(this.model.started_at).format('DD/MM/YYYY')
			}
			return 'Turno'
		},

		formatDuration(date) {
			return (date) => {
				return moment(date).fromNow()
			}
		},

		maxPage() {
			return Math.floor(10 / this.page)
		},

		filteredItems() {
			let filtered = this.items.filter((item) => {
				if(this.filters.length > 0) {
					return this.filters.some(r => item.groupsId.includes(r))
				}
			})
			return filtered.length > 0 ? filtered : this.items
		},

		setRequestStatusColor() {
			if(this.request.expired) {
				return 'red'
			} else if(this.request.accepted) {
				return 'success'
			} else if(this.request.accepted == false && this.request.expired == false)  {
				return 'warning'
			} else {
				return 'blue'
			}
			return ''
		},

		setRequestStatusText() {
			if(this.request.expired) {
				return 'Richiesta scaduta'
			} else if(this.request.accepted) {
				return 'Richiesta accettata'
			} else if(this.request.accepted == false && this.request.expired == false)  {
				return 'Richiesta rifiutata'
			} else {
				return 'Richiesta in attesa'
			}
			// se rifiutata?
			return ''
		}

	},

	watch: {

		'shifttypetask.id': {
			handler(newValue) {
				if(newValue) {
					this.getData()
				}
			},
			deep: true
		},

	},    

	created() {
		//this.$watch('search', this.getData)
		this.axios.get('tasks').then(response => {
			this.tasks = response.data.data
		})
	},

	mounted() {
		this.$eventBusUtility.$on('openShiftAvailability', (data) => {
			this.openAvailabilityDialog(data)
		})

		this.$echo.private(`organization.${this.$store.getters.getCurrentOrganization.id}.calendar`)
			.listen('.ShiftUpdated', (event) => {
				let shift = event.shift
				if(this.model.id == shift.id) {
					this.model = shift
					//     this.axios.get('shifts/'+shift.id).then(response => {
					//         shift = response.data.data
					//         this.model = shift
					this.getData()
					//     })
				}
			})
	},

	beforeDestroy() {
		// Unlisten WS
		this.$echo.leave(`organization.${this.$store.getters.getCurrentOrganization.id}.calendar`)
	},

	methods: {

		customAvailabilitySort(a, b) {
			let list = {'available': 0, 'unavailable': 3, 'to_call_back': 1, 'not_responding': 2, 'busy': 5, 'rejected': 6, 'null': 4}
			let sortA = a ? list[a??'null'] : list['null']
			let sortB = b ? list[b??'null'] : list['null']

			if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB)
			return 0
		},

		openSendRequest(item) {
			this.request.task_id = this.shifttypetask.task_id
			this.request.shift_id = this.model.id
            
			if(this.selectedIds == 'all') {
				this.request.people_id = this.items.filter(item => item.id)
				this.requestDialogMessage = 'Invia richiesta a ' + this.request.people_id.length + ' persone'
			} else if(this.selectedIds.length > 0) {
				this.request.people_id = this.selectedIds
				this.requestDialogMessage = 'Invia richiesta a ' + this.request.people_id.length + ' persone'
			} else {
				this.request.people_id = [item.id]
				this.requestDialogMessage = 'Invia la richiesta a ' + item.full_name
			}
			this.sendRequestDialog = true
		},

		showRequest(item) {
			this.axios.get('shiftrequests/' + item.shiftrequest_id).then((response) => {
				this.loading = false
				this.request = response.data.data
				this.showRequestDialog = true
			}, response => {
				// error callback
				this.loading = false
			})            
		},
        
		closeSendRequest() {
			this.request = _.stubObject()
			this.requestDialogMessage = ''
			this.sendRequestDialog = false
		},
        
		closeShowRequest() {
			this.request = _.stubObject()
			this.showRequestDialog = false
		},

		sendRequest() {
			this.loading = true
			this.$refs.requestFields.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				}                
				this.axios.post('shiftrequests', {...this.request}).then((response) => {
					this.loading = false
					this.$store.commit('SET_SNACKBAR', {
						timeout: 6000,
						text: 'Richiesta Inviata',
						value: true,
						color: 'success'
					})
					this.closeSendRequest()
					this.getData()
				}, response => {
					// error callback
					this.loading = false
				})
			}).catch(() => {
				this.loading = false
			})              
		},

		deleteRequest() {
			this.axios.delete('shiftrequests/' + this.request.id).then((response) => {
				this.loading = false
				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: 'Richiesta Eliminata',
					value: true,
					color: 'success'
				})
				this.closeShowRequest()
				this.getData()
			}, response => {
				// error callback
				this.loading = false
			})
		},

		setSelectAll() {
			this.showSelectAll = true
			this.selectAll = true
			this.selected = this.items
		},

		cancelSelectAll() {
			this.showSelectAll = false
			this.selectAll = false
			this.selected = []
		},

		prevPage() {
			this.page = this.page - 1
		},

		nextPage() {
			this.page = this.page + 1
		},

		jumpToPage() {
			this.page = this.newPageToJump
		},

		customSearch(value, key) {
			if (!this.search[key]) return true
			return !value ? false : value.toLocaleLowerCase().indexOf(this.search[key].toLocaleLowerCase()) > -1
		},
        
		customTasksSearch(value) {
			if (!this.search['tasks']) return true
			return !value ? false : value.includes(this.search['tasks']) 
		},


		getData() {
			if(this.getDataTimeout) {
				clearTimeout(this.getDataTimeout)
			}
			this.disabledDialog = true
			this.getDataTimeout = setTimeout(() => {
				this.loading = true
                    
				let dataObject = {
					task_id: this.shifttypetask.task_id,
				}

				this.axios.get('shifts/' + this.model.id + '/list-people/', {params: dataObject}).then(response => {
					this.loading = false
					this.items = response.data.data
					this.totalItems = this.items.length
					this.disabledDialog = false

				}, response => {
					// error callback
					this.loading = false
					this.disabledDialog = false
				})
			}, 300)
				
		},

		saveCustomAvailability(field, event) {
			this.$refs.availabilityFields.validate().then(result => {
				if (!result) {
					return false
				}                
				this.selectAvailability(field, event)
			}).catch(() => {

			})                
		},

		selectAvailability(field, event) {
			let data = {...field}
			data['shift_id'] = this.model.id
			data['person_id'] = field.id
			data['task_id'] = this.shifttypetask.task_id
			data['type'] = event

			if(event == 'refused') {
				this.loadingRefusedAvailability[field.id] = true
			} else {
				this.loadingAcceptAvailability[field.id] = true
			}
			this.axios.post('availabilities', data).then(response => {
				this.loadingRefusedAvailability = _.stubObject()
				this.loadingAcceptAvailability = _.stubObject()

			}, response => {
				// error callback
				this.loadingRefusedAvailability = _.stubObject()
				this.loadingAcceptAvailability = _.stubObject()
			})            
		},     

		openAvailabilityDialog(data) {
			this.model = data.shift
			this.shifttypetask = data.shifttypetask
			this.open = true
		},

		closeAvailabilityDialog() {
			this.model = _.stubObject()
			this.items = []
			this.selected = []
			this.shifttypetask = _.stubObject()
			this.openSettings = _.stubObject()
			this.open = false
		},

		setOpenSettings(id) {
			if(this.openSettings[id] === true) {
				Vue.set(this.openSettings, id, false)
			} else {
				Vue.set(this.openSettings, id, true)
			}
		},

		unlockAvailability(item) {
			this.axios.delete('availabilities/' + item.availability_id).then(response => {
				this.loading = false
                
			}, response => {
				this.loading = false
			})
		},


	}

}
</script>

<style>
   .availabilities-table .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
        padding: 0 15px !important;
    }
</style>