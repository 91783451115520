

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import VehiclesIndex from '@/components/modules/vehicles/VehiclesIndex'

export const vehicles = [
	{
		path: '/vehicles',
		name: 'vehicles',
		component: VehiclesIndex,
		beforeEnter: ifAuthenticated
	},
]