export default function() {
	return [
		{
			'text': 'AGUGLIARO',
			'value': 'AGUGLIARO',
		},
		{
			'text': 'ALBETTONE',
			'value': 'ALBETTONE',
		},
		{
			'text': 'ALONTE',
			'value': 'ALONTE',
		},
		{
			'text': 'ALTAVILLA VICENTINA',
			'value': 'ALTAVILLA VICENTINA',
		},
		{
			'text': 'ALTISSIMO',
			'value': 'ALTISSIMO',
		},
		{
			'text': 'ARCUGNANO',
			'value': 'ARCUGNANO',
		},
		{
			'text': 'ARSIERO',
			'value': 'ARSIERO',
		},
		{
			'text': 'ARZIGNANO',
			'value': 'ARZIGNANO',
		},
		{
			'text': 'ASIAGO',
			'value': 'ASIAGO',
		},
		{
			'text': 'ASIGLIANO VENETO',
			'value': 'ASIGLIANO VENETO',
		},
		{
			'text': 'BARBARANO VICENTINO',
			'value': 'BARBARANO VICENTINO',
		},
		{
			'text': 'BASSANO DEL GRAPPA',
			'value': 'BASSANO DEL GRAPPA',
		},
		{
			'text': 'BOLZANO VICENTINO',
			'value': 'BOLZANO VICENTINO',
		},
		{
			'text': 'BREGANZE',
			'value': 'BREGANZE',
		},
		{
			'text': 'BRENDOLA',
			'value': 'BRENDOLA',
		},
		{
			'text': 'BRESSANVIDO',
			'value': 'BRESSANVIDO',
		},
		{
			'text': 'BROGLIANO',
			'value': 'BROGLIANO',
		},
		{
			'text': 'CALDOGNO',
			'value': 'CALDOGNO',
		},
		{
			'text': 'CALTRANO',
			'value': 'CALTRANO',
		},
		{
			'text': 'CALVENE',
			'value': 'CALVENE',
		},
		{
			'text': 'CAMISANO VICENTINO',
			'value': 'CAMISANO VICENTINO',
		},
		{
			'text': 'CAMPIGLIA DEI BERICI',
			'value': 'CAMPIGLIA DEI BERICI',
		},
		{
			'text': 'CAMPOLONGO SUL BRENTA',
			'value': 'CAMPOLONGO SUL BRENTA',
		},
		{
			'text': 'CARRÈ',
			'value': 'CARRÈ',
		},
		{
			'text': 'CARTIGLIANO',
			'value': 'CARTIGLIANO',
		},
		{
			'text': 'CASSOLA',
			'value': 'CASSOLA',
		},
		{
			'text': 'CASTEGNERO',
			'value': 'CASTEGNERO',
		},
		{
			'text': 'CASTELGOMBERTO',
			'value': 'CASTELGOMBERTO',
		},
		{
			'text': 'CHIAMPO',
			'value': 'CHIAMPO',
		},
		{
			'text': 'CHIUPPANO',
			'value': 'CHIUPPANO',
		},
		{
			'text': 'CISMON DEL GRAPPA',
			'value': 'CISMON DEL GRAPPA',
		},
		{
			'text': 'COGOLLO DEL CENGIO',
			'value': 'COGOLLO DEL CENGIO',
		},
		{
			'text': 'CONCO',
			'value': 'CONCO',
		},
		{
			'text': 'CORNEDO VICENTINO',
			'value': 'CORNEDO VICENTINO',
		},
		{
			'text': 'COSTABISSARA',
			'value': 'COSTABISSARA',
		},
		{
			'text': 'CREAZZO',
			'value': 'CREAZZO',
		},
		{
			'text': 'CRESPADORO',
			'value': 'CRESPADORO',
		},
		{
			'text': 'DUEVILLE',
			'value': 'DUEVILLE',
		},
		{
			'text': 'ENEGO',
			'value': 'ENEGO',
		},
		{
			'text': 'EX BACINO ALTO ASTICO POSINA',
			'value': 'EX BACINO ALTO ASTICO POSINA',
		},
		{
			'text': 'EX BACINO LEOGRA TIMONCHIO',
			'value': 'EX BACINO LEOGRA TIMONCHIO',
		},
		{
			'text': 'EX BACINO MEDIO ASTICO',
			'value': 'EX BACINO MEDIO ASTICO',
		},
		{
			'text': 'FARA VICENTINO',
			'value': 'FARA VICENTINO',
		},
		{
			'text': 'FOZA',
			'value': 'FOZA',
		},
		{
			'text': 'GALLIO',
			'value': 'GALLIO',
		},
		{
			'text': 'GAMBELLARA',
			'value': 'GAMBELLARA',
		},
		{
			'text': 'GAMBUGLIANO',
			'value': 'GAMBUGLIANO',
		},
		{
			'text': 'GRISIGNANO DI ZOCCO',
			'value': 'GRISIGNANO DI ZOCCO',
		},
		{
			'text': 'GRUMOLO DELLE ABBADESSE',
			'value': 'GRUMOLO DELLE ABBADESSE',
		},
		{
			'text': 'ISOLA VICENTINA',
			'value': 'ISOLA VICENTINA',
		},
		{
			'text': 'LAGHI',
			'value': 'LAGHI',
		},
		{
			'text': 'LASTEBASSE',
			'value': 'LASTEBASSE',
		},
		{
			'text': 'LONGARE',
			'value': 'LONGARE',
		},
		{
			'text': 'LONIGO',
			'value': 'LONIGO',
		},
		{
			'text': 'LUGO DI VICENZA',
			'value': 'LUGO DI VICENZA',
		},
		{
			'text': 'LUSIANA',
			'value': 'LUSIANA',
		},
		{
			'text': 'MALO',
			'value': 'MALO',
		},
		{
			'text': 'MARANO VICENTINO',
			'value': 'MARANO VICENTINO',
		},
		{
			'text': 'MAROSTICA',
			'value': 'MAROSTICA',
		},
		{
			'text': 'MASON VICENTINO',
			'value': 'MASON VICENTINO',
		},
		{
			'text': 'MOLVENA',
			'value': 'MOLVENA',
		},
		{
			'text': 'MONTE DI MALO',
			'value': 'MONTE DI MALO',
		},
		{
			'text': 'MONTEBELLO VICENTINO',
			'value': 'MONTEBELLO VICENTINO',
		},
		{
			'text': 'MONTECCHIO MAGGIORE',
			'value': 'MONTECCHIO MAGGIORE',
		},
		{
			'text': 'MONTECCHIO PRECALCINO',
			'value': 'MONTECCHIO PRECALCINO',
		},
		{
			'text': 'MONTEGALDA',
			'value': 'MONTEGALDA',
		},
		{
			'text': 'MONTEGALDELLA',
			'value': 'MONTEGALDELLA',
		},
		{
			'text': 'MONTEVIALE',
			'value': 'MONTEVIALE',
		},
		{
			'text': 'MONTICELLO CONTE OTTO',
			'value': 'MONTICELLO CONTE OTTO',
		},
		{
			'text': 'MONTORSO VICENTINO',
			'value': 'MONTORSO VICENTINO',
		},
		{
			'text': 'MOSSANO',
			'value': 'MOSSANO',
		},
		{
			'text': 'MUSSOLENTE',
			'value': 'MUSSOLENTE',
		},
		{
			'text': 'NANTO',
			'value': 'NANTO',
		},
		{
			'text': 'NOGAROLE VICENTINO',
			'value': 'NOGAROLE VICENTINO',
		},
		{
			'text': 'NOVE',
			'value': 'NOVE',
		},
		{
			'text': 'NOVENTA VICENTINA',
			'value': 'NOVENTA VICENTINA',
		},
		{
			'text': 'ORGIANO',
			'value': 'ORGIANO',
		},
		{
			'text': 'PEDEMONTE',
			'value': 'PEDEMONTE',
		},
		{
			'text': 'PIANEZZE',
			'value': 'PIANEZZE',
		},
		{
			'text': 'PIOVENE ROCCHETTE',
			'value': 'PIOVENE ROCCHETTE',
		},
		{
			'text': 'POJANA MAGGIORE',
			'value': 'POJANA MAGGIORE',
		},
		{
			'text': 'POSINA',
			'value': 'POSINA',
		},
		{
			'text': 'POVE DEL GRAPPA',
			'value': 'POVE DEL GRAPPA',
		},
		{
			'text': 'POZZOLEONE',
			'value': 'POZZOLEONE',
		},
		{
			'text': 'QUINTO VICENTINO',
			'value': 'QUINTO VICENTINO',
		},
		{
			'text': 'RECOARO TERME',
			'value': 'RECOARO TERME',
		},
		{
			'text': 'ROANA',
			'value': 'ROANA',
		},
		{
			'text': 'ROMANO D\'EZZELINO',
			'value': 'ROMANO D\'EZZELINO',
		},
		{
			'text': 'ROSÀ',
			'value': 'ROSÀ',
		},
		{
			'text': 'ROSSANO VENETO',
			'value': 'ROSSANO VENETO',
		},
		{
			'text': 'ROTZO',
			'value': 'ROTZO',
		},
		{
			'text': 'SALCEDO',
			'value': 'SALCEDO',
		},
		{
			'text': 'SAN NAZARIO',
			'value': 'SAN NAZARIO',
		},
		{
			'text': 'SAN PIETRO MUSSOLINO',
			'value': 'SAN PIETRO MUSSOLINO',
		},
		{
			'text': 'SAN VITO DI LEGUZZANO',
			'value': 'SAN VITO DI LEGUZZANO',
		},
		{
			'text': 'SANDRIGO',
			'value': 'SANDRIGO',
		},
		{
			'text': 'SANTORSO',
			'value': 'SANTORSO',
		},
		{
			'text': 'SARCEDO',
			'value': 'SARCEDO',
		},
		{
			'text': 'SAREGO',
			'value': 'SAREGO',
		},
		{
			'text': 'SCHIAVON',
			'value': 'SCHIAVON',
		},
		{
			'text': 'SCHIO',
			'value': 'SCHIO',
		},
		{
			'text': 'SOLAGNA',
			'value': 'SOLAGNA',
		},
		{
			'text': 'SOSSANO',
			'value': 'SOSSANO',
		},
		{
			'text': 'SOVIZZO',
			'value': 'SOVIZZO',
		},
		{
			'text': 'TEZZE SUL BRENTA',
			'value': 'TEZZE SUL BRENTA',
		},
		{
			'text': 'THIENE',
			'value': 'THIENE',
		},
		{
			'text': 'TONEZZA DEL CIMONE',
			'value': 'TONEZZA DEL CIMONE',
		},
		{
			'text': 'TORREBELVICINO',
			'value': 'TORREBELVICINO',
		},
		{
			'text': 'TORRI DI QUARTESOLO',
			'value': 'TORRI DI QUARTESOLO',
		},
		{
			'text': 'TRISSINO',
			'value': 'TRISSINO',
		},
		{
			'text': 'VAL LIONA',
			'value': 'VAL LIONA',
		},
		{
			'text': 'VALDAGNO',
			'value': 'VALDAGNO',
		},
		{
			'text': 'VALDASTICO',
			'value': 'VALDASTICO',
		},
		{
			'text': 'VALLI DEL PASUBIO',
			'value': 'VALLI DEL PASUBIO',
		},
		{
			'text': 'VALSTAGNA',
			'value': 'VALSTAGNA',
		},
		{
			'text': 'VELO D\'ASTICO',
			'value': 'VELO D\'ASTICO',
		},
		{
			'text': 'VICENZA',
			'value': 'VICENZA',
		},
		{
			'text': 'VILLAGA',
			'value': 'VILLAGA',
		},
		{
			'text': 'VILLAVERLA',
			'value': 'VILLAVERLA',
		},
		{
			'text': 'ZANÈ',
			'value': 'ZANÈ',
		},
		{
			'text': 'ZERMEGHEDO',
			'value': 'ZERMEGHEDO',
		},
		{
			'text': 'ZOVENCEDO',
			'value': 'ZOVENCEDO',
		},
		{
			'text': 'ZUGLIANO',
			'value': 'ZUGLIANO',
		},
	]
}