<template>
    <v-container fluid class="pa-0 pt-4 mx-0" style="height: 100%">
		
		<v-tabs 
			style="height: 100%"
			:class="$vuetify.breakpoint.smAndDown ? 'px-2'  : 'px-4'"
			v-model="mutableTab"
			color="primary"
			slider-color="primary">

			<v-tab ripple>
				Pubblica
			</v-tab>
			<v-tab v-if="$store.getters.isAuth" ripple>
				Privata
			</v-tab>

			<v-tab-item class="body-page pt-1 mt-2" style="margin: 0px; height: 100%">
				<div v-masonry horizontal-order="true" transition-duration="0.3s" item-selector=".item-public" style="margin: 0px; height: 100%" :class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-4'" class="mt-3">
					<v-row class="mx-0" style="margin: 0px; height: 100%">
						<v-col v-masonry-tile v-for="post in posts" class="item-public col-12 col-sm-8 col-md-6 col-lg-4 pa-1" :key="post.id">
							<post-card
								:ref="'post' + post.id"
								:class="post.id == element ? 'activeNotification' : 'elevation-0'"
								:post="post"
							></post-card>
						</v-col>
					</v-row>
					<v-btn
						v-if="$store.getters.checkPermission('create_public_post')"
						:class="{'mb-16': $store.getters.showBottomNavigator}"
						color="primary"
						fixed
						fab
						bottom
						right
						@click="createPost('public')">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</div>
			</v-tab-item>

			<v-tab-item v-if="$store.getters.isAuth" class="body-page pt-1 mt-2" style="margin: 0px; height: 100%" >
				<div v-masonry horizontal-order="true" transition-duration="0.3s" item-selector=".item-private" style="margin: 0px; height: 100%" :class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-4'" class="mt-3">
					<v-row class="mx-0" style="margin: 0px; height: 100%">
						<v-col v-masonry-tile v-for="post in privates" class="item-private col-12 col-sm-8 col-md-6 col-lg-4 pa-1" :key="post.id">
							<post-card
								:ref="'post' + post.id"
								:class="post.id == element ? 'activeNotification' : 'elevation-0'"
								:post="post"
							></post-card>
						</v-col>				
					</v-row>
					<v-btn
						v-if="$store.getters.checkPermission('create_private_post')"
						:class="{'mb-16': $store.getters.showBottomNavigator}"			
						color="primary"
						fixed
						fab
						bottom
						right
						@click="createPost('private')">
						<v-icon>mdi-plus</v-icon>
					</v-btn>		
				</div>
			</v-tab-item>
		</v-tabs>

		<post-dialog
			@deleted="removePost"
			@stored="storePost"
			@updated="updatePost"
		></post-dialog>

		<vue-editor style="display:none"/>

	</v-container>
</template>
<script>
import Vue from 'vue'
import PostCard from './posts/PostCard'
import PostDialog from './posts/PostDialog'
import {VueMasonryPlugin} from 'vue-masonry'
Vue.use(VueMasonryPlugin)

export default {
	name: 'board',
	components: {
		PostCard,
		PostDialog
	},
	props: {
		element: Number,
		tab: {
			type: Number,
			default: 0
		}
	},	

	data() {
		return {
			posts: [],
			privates: [],
			switchTimeout: null,
			mutableTab: this.tab
		}
	},

	created() {
		this.axios.get('posts').then(response => {
			this.posts = response.data.data

			// auto switch calendar
			if(!this.$store.getters.isAuth && this.$store.getters.isTouchDevice && !this.$store.getters.getDisableSectionRotation) {
				this.setSwitchTimeout()
				window.addEventListener('click', this.setSwitchTimeout)
				window.addEventListener('keypress', this.setSwitchTimeout)
				window.addEventListener('mousemove', this.setSwitchTimeout)
				window.addEventListener('scroll', this.setSwitchTimeout)
			}
			setTimeout(() => { 
				this.$eventBusUtility.$emit('redrawVueMasonry')
			}, 1500)
		})

		if(this.$store.getters.isAuth) {
			this.axios.get('posts?private=1').then(response => {
				this.privates = response.data.data
			})
		}
	},

	mounted() {
		// WS Listeners
		this.$echo.private(`organization.${this.$store.getters.getCurrentOrganization.id}.board`)
			.listen('.PostUpdated', (event) => {
				this.updatePost(event.post_id)
			})
			.listen('.PostDeleted', (event) => {
				this.removePost(event.post_id)
			})
			.listen('.PostCreated', (event) => {
				this.storePost(event.post_id)
			})

		this.$eventBusUtility.$on('redrawVueMasonry', () => {
			this.$redrawVueMasonry()
		})			
	},

	beforeDestroy() {
		// Unlisten WS
		this.$echo.leave(`organization.${this.$store.getters.getCurrentOrganization.id}.board`)
		
		window.removeEventListener('click', this.setSwitchTimeout)
		window.removeEventListener('keypress', this.setSwitchTimeout)
		window.removeEventListener('mousemove', this.setSwitchTimeout)
		window.removeEventListener('scroll', this.setSwitchTimeout)

		clearTimeout(this.switchTimeout)
	},

	methods: {

		setSwitchTimeout() {
			if(this.switchTimeout) {
				clearTimeout(this.switchTimeout)
			}
			this.switchTimeout = setTimeout(() => {
				this.$eventBusUtility.navigateTo('calendar')
			}, 15000)
		},

		createPost(type) {
			this.$eventBusUtility.$emit('createPost', type)
		},

		removePost(id) {
			let post_public_index = this.posts.findIndex((post) => post.id == id)
			if (post_public_index !== -1) {
				this.posts.splice(post_public_index, 1)
				return
			}

			let post_private_index = this.privates.findIndex((post) => post.id == id)
			if (post_private_index !== -1) {
				this.privates.splice(post_private_index, 1)
				return
			}
			
		},

		updatePost(post) {
			if(!isNaN(post)) {
				let post_public_index = this.posts.findIndex((p) => p.id == post)
				let post_private_index = this.privates.findIndex((p) => p.id == post)

				if (post_public_index !== -1 || post_private_index !== -1) {
					this.axios.get('posts/' + post).then((response) => {
						let new_post = response.data.data
						if (new_post.public) {
							Vue.set(this.posts, post_public_index, new_post)
						} else {
							Vue.set(this.privates, post_private_index, new_post)
						}
					})
				}
			} else {
				// let post_public_index = this.posts.findIndex((p) => p.id == post.id)
				// let post_private_index = this.privates.findIndex((p) => p.id == post.id)

				// if(post.public) {
				// 	Vue.set(this.posts, post_public_index, post)
				// } else {
				// 	Vue.set(this.privates, post_private_index, post)
				// }
			}
		},

		storePost(post) {
			if(!isNaN(post)) {
				let post_public_index = this.posts.findIndex((p) => p.id == post)
				let post_private_index = this.privates.findIndex((p) => p.id == post)

				if (post_public_index === -1 && post_private_index === -1) {
					this.axios.get('posts/' + post).then((response) => {
						let new_post = response.data.data
						if (new_post.public) {
							this.posts.unshift(response.data.data)
						} else {
							this.privates.unshift(response.data.data)
						}
					})
				}
			} else {
				// post.reactions = []
				// post.comments = []
				// if(post.public) {
				// 	this.posts.unshift(post)
				// } else {
				// 	this.privates.unshift(post)
				// }
			}
		},
	},
}
</script>
<style>
.v-window {
	height: 100%;
}

.v-window__container {
	height: 100%;
}

</style>