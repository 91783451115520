<template>
	<v-row no-gutters class="pa-0 fill-height">
		<v-container fluid class="pa-6" style="position: fixed;" :class="{'open-nav': $store.getters.getSidebar && $vuetify.breakpoint.mdAndUp}">
			<v-card class="rounded-lg pa-0 mb-4 elevation-0 transparent">

				<v-card-title class="pa-0">
					<v-row no-gutters>

						<v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
							<div class="pl-0 font-weight-bold" style="font-size: 30px">Privacy</div>
						</v-col>

						<v-col class="col-2 col-lg-1 text-right ma-auto mr-0 hidden-md-and-up">
							<v-icon :size="35" color="black">mdi-shield-check-outline</v-icon>
						</v-col>

					</v-row>
				</v-card-title>

                <v-tabs
                    class="pt-6"
                    v-model="tab"
                    background-color="white"
                     show-arrows>
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#tab-1">
                        Informativa Privacy
                    </v-tab>

                    <v-tab href="#tab-2">
                        Dispositivi Connessi
                    </v-tab>

                    <v-tab href="#tab-3">
                        Tentativi di accesso
                    </v-tab>
                </v-tabs>
            </v-card>
        </v-container>
		<v-container fluid class="pa-6 body-page">

                <v-tabs-items v-model="tab">

                    <v-tab-item value="tab-1">
                            <v-card class="elevation-0 background-1 pb-12" style="height: 100%">
                                <div class="title">Titolari e responsabili esterni del trattamento</div>
                                <div class="caption pt-4">Titolare del trattamento dati è la società Stiip S.r.l., con sede in Via G. Galilei, 95 - 51031 Agliana PT, C.F. e P.IVA 01854250477, contattabile ai seguenti recapiti: indirizzo e-mail info@volontapp.it; indirizzo pec stiip@pec.it; Tel. 0574/027026.</div>
                                <div class="caption pt-2">Nel momento in cui effettuerai l’iscrizione ad una o più associazioni di volontariato, i tuoi dati verranno trattati mediante VolontApp anche da parte di queste associazioni, per finalità da loro determinate. Tali associazioni diverranno titolari autonomi dei trattamenti effettuati mediante VolontApp. Con riferimento a tali trattamenti, Stiip S.r.l. assumerà il ruolo di responsabile esterno del trattamento, in quanto tratterà i tuoi dati per conto delle associazioni a cui sei iscritto e seguendo le loro istruzioni.</div>

                                <div class="title pt-4">Dati oggetto del trattamento</div>
                                <div class="caption pt-4">Dopo aver scaricato l’applicazione VolontApp sul tuo dispositivo, per poterla utilizzare è richiesta l’iscrizione mediante creazione di un account personale. Per poter creare un account, dovrai compilare alcuni campi inserendo i tuoi dati personali. I dati obbligatori, poiché indispensabili per usufruire del servizio oggetto dell’applicazione, sono contrassegnati da un asterisco. Gli altri campi sono anch’essi necessari a permettere una migliore fornitura del servizio ma la loro compilazione è facoltativa. Ove questi campi non venissero riempiti, sarà comunque possibile procedere con l’iscrizione e usufruire della prestazione richiesta. Il campo Bio è a compilazione libera. Ti invitiamo a non utilizzarlo per inserire tuoi dati sensibili (che rivelino l’origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l’appartenenza sindacale, dati genetici, dati biometrici, dati relativi alla salute o alla vita sessuale o all’orientamento sessuale), né tantomeno dati riferiti a soggetti terzi, anche se trattasi di dati comuni.I dati relativi alla tua persona che verranno trattati sono solo quelli da te inseriti nel modulo di iscrizione. Potrai vederli e modificarli in ogni momento accedendo al tuo profilo personale.</div>
                                <div class="caption pt-2">Potranno inoltre essere oggetto di trattamento i dati di Log-in e Log-out all’applicazione VolontApp.</div>
                                <div class="caption pt-2">VolontApp fornisce alle associazioni e ai volontari un servizio di geolocalizzazione. Grazie a questo servizio, quando ti trovi nei pressi di un’associazione di volontariato alla quale sei iscritto, potrai registrare la tua presenza utilizzando il tuo dispositivo mobile dotato di GPS. Per rendere possibile questo servizio, dovremo trattare i tuoi dati di geolocalizzazione. Ad essere oggetto di trattamento sarà soltanto la tua posizione nel momento in cui utilizzerai la funzione per registrarti in associazione.</div>
                                
                                <div class="title pt-4">Finalità e basi giuridiche del trattamento</div>
                                <div class="caption pt-2">I dati da te rilasciati al momento dell’iscrizione a VolontApp vengono trattati al fine di fornirti i servizi oggetto dell’applicazione, ovvero di consentire all’associazione di volontariato a cui sei iscritto di gestire la tua posizione personale all’interno dell’organizzazione. Nel momento in cui scarichi VolontApp, stai chiedendo di poter usufruire di questo servizio. Base giuridica di questo trattamento è quella di cui all’art.6, par. 1 lett. b) Reg. UE 2016/679.</div>
                                <div class="caption pt-2">I dati di log vengono trattati al solo fine di verificare quando non utilizzi più l’applicazione da molto tempo. Ciò serve al titolare al fine di definire un criterio di durata di conservazione dei dati e cancellare i tuoi dati se non stai più utilizzando l’applicazione. La base giuridica di questo trattamento è il legittimo interesse del titolare, ai sensi dell’art. 6, par. 1 lett. f) Reg. UE 2016/679. Questo trattamento non è lesivo per i diritti dell’interessato ed è anzi finalizzato a minimizzare i tempi di conservazione dei dati che non sono più necessari. Inoltre, i dati di log sono messi a tua conoscenza in un’apposita sezione, permettendoti di verificare gli accessi ed accorgerti di eventuali intrusioni sul tuo profilo personale.</div>
                                <div class="caption pt-2">I dati di geolocalizzazione vengono trattati al fine di fornirti il servizio di registrazione delle presenze in associazione mediante l’utilizzo di un dispositivo mobile. Per poter utilizzare il servizio di registrazione delle presenze, ti verrà richiesta l’attivazione della funzione GPS sul tuo dispositivo mobile. L’attivazione di questa funzione equivale alla richiesta da parte tua di utilizzare il servizio. Ovviamente, potrai smettere in ogni momento di utilizzare il servizio, impedendo a VolontApp di utilizzare il GPS del dispositivo mobile. La base giuridica di questo trattamento è quella di cui all’art. 6, par. 1 lett. b) Reg. UE 2016/679.</div>

                                <div class="title pt-4">Finalità e basi giuridiche del trattamento</div>
                                <div class="caption pt-2">I dati verranno trattati in formato elettronico. Una volta raccolti, i dati verranno conservati in formato digitale presso dei server dedicati forniti da società terze.</div>
                                <div class="caption pt-2">I dati verranno conservati sino a che perdura la finalità, ovvero fino a che continuerai ad utilizzare l’applicazione. Trascorsi 18 mesi dal tuo ultimo log-in, si presume che questa finalità sia venuta meno e pertanto i tuoi dati verranno cancellati.</div>
                                <div class="caption pt-2">I dati concernenti le registrazioni delle tue presenze presso le associazioni di volontariato vengono conservati da Stiip S.r.l. soltanto in qualità di responsabile esterno, sulla base delle istruzioni fornite dalle associazioni stesse. La durata del trattamento è quindi determinata dalla necessità dell’associazione di volontariato, in qualità di titolare del trattamento, di conservare il registro delle presenze dei propri volontari. Nel caso venga meno il rapporto contrattuale fra un’associazione di volontariato e Stiip S.r.l., quest’ultima non conserverà le registrazioni delle presenze relative a quella associazione.</div>

                                <div class="title pt-4">Categorie di destinatari</div>
                                <div class="caption pt-2">I dati trattati mediante VolontApp verranno conservati presso dei server dedicati forniti da società terze e situati all’interno dell’Unione Europea. I dati potranno essere trattati da personale del titolare e del responsabile del trattamento, nonché da soggetti terzi, quali hosting provider e sviluppatori software. Titolare e responsabile avranno cura di assicurarsi che questi soggetti adottino misure adeguate di protezione dei dati personali.</div>

                                <div class="title pt-4">Diritti degli interessati</div>
                                <div class="caption pt-2">Oltre a queste informazioni, per garantirti un trattamento dei tuoi dati il più corretto e trasparente possibile, devi essere a conoscenza del fatto che:</div>
                                    <ul class="caption pt-2">
                                        <li>hai il diritto di chiedere al titolare del trattamento l'accesso ai tuoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che ti riguarda o di opporti al trattamento;</li>
                                        <li>hai diritto di chiedere al titolare di fornirti i tuoi dati in un formato strutturato o di trasmetterli ad un diverso operatore;</li>
                                        <li>se hai fornito il consenso per una o più specifiche finalità, hai il diritto di revocare tale consenso in qualsiasi momento;- se ritieni che vi sia stata una violazione nel trattamento dei tuoi dati, potrai depositare un reclamo presso l’Autorità Garante per il trattamento dei dati personali.</li>
                                    </ul>
                                <div class="caption pt-2">Per approfondire la conoscenza e la portata dei tuoi diritti, puoi fare riferimento agli articoli dal 15 al 22 del Reg. UE 2016/679.</div>
                                
                                <div class="title pt-4">Comunicazioni</div>
                                <div class="caption pt-2">Per qualsiasi comunicazione o richiesta o per esercitare i tuoi diritti riguardanti il trattamento dei dati personali, puoi rivolgerti a Stiip S.r.l., con sede in Via G. Galilei, 95 - 51031 Agliana PT, C.F. e P.IVA 01854250477, contattabile ai seguenti recapiti: indirizzo e-mail info@volontapp.it; indirizzo pec stiip@pec.it; Tel. 0574/027026.</div>

                            </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2" class="fill-height">
                            <v-card class="elevation-0 background-1" style="height: 100%">
                                <div class="title">Dispositivi connessi al tuo account <v-btn text icon color="primary" @click="getActiveDevices">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn></div>
                                <v-data-table
                                    :headers="active_devices_headers"
                                    :items="active_devices"
                                    :items-per-page="15"
                                    class="elevation-1"
                                    :loading="loading_active_devices"
                                >
                                    <template v-slot:item.remove="{ item }">
                                    <v-btn text icon color="primary" @click="confirmRevoke(item)">
                                        <v-icon color="primary">mdi-delete-circle</v-icon>
                                    </v-btn>
                                    </template>
                                </v-data-table>

                                <confirm-dialog v-model="confirmDelete" :data="deletingItem" @confirm="revoke"></confirm-dialog>


                                <v-btn block color="primary" dark @click="confirmRevokeAll">Disconnetti tutti i dispositivi</v-btn>
                                <confirm-dialog v-model="confirmDeleteAll" @confirm="revokeAll"></confirm-dialog>

                            </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3" class="fill-height">
                            <v-card class="elevation-0 background-1" style="height: 100%">
                                <div class="title">Tentativi di accesso al tuo account negli ultimi 30 giorni <v-btn text icon color="primary" @click="getLoginAttempts">
                                    <v-icon>mdi-autorenew</v-icon>
                                </v-btn></div>
                                <v-data-table
                                    :headers="login_attempts_headers"
                                    :items="login_attempts"
                                    :items-per-page="15"
                                    class="elevation-1"
                                    :loading="loading_login_attempts"
                                >
                                    <template v-slot:item.result="{ item }">
                                        <v-icon v-if="item.result" color="success">mdi-check-circle</v-icon>
                                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                                    </template>
                                </v-data-table>
                            </v-card>
                    </v-tab-item>

                </v-tabs-items>                
		</v-container>

    </v-row>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog'

export default {
	name: 'privacy',
   	components: { ConfirmDialog },

	data() {
		return {
			tab: null,
			confirmDelete: false,
			confirmDeleteAll: false,
			deletingItem: {},
			loading_active_devices: false,
			active_devices_headers: [
				{text: 'Data e Ora', value: 'created_at'},
				{text: 'Indirizzo IP', value: 'ip_address'},
				{text: 'Browser', value: 'browser'},
				{text: 'Sistema Operativo', value: 'platform'},
				{text: 'Dispositivo', value: 'device'},
				{text: 'User Agent', value: 'user_agent'},
				{text: 'Rimuovi', value: 'remove'},
			],
			active_devices: [],
			loading_login_attempts: false,
			login_attempts_headers: [
				{text: 'Data e Ora', value: 'created_at'},
				{text: 'Indirizzo IP', value: 'ip_address'},
				{text: 'Browser', value: 'browser'},
				{text: 'Sistema Operativo', value: 'platform'},
				{text: 'Dispositivo', value: 'device'},
				{text: 'User Agent', value: 'user_agent'},
				{text: 'Esito', value: 'result'},
			],
			login_attempts: []
		}
	},
	created() {
		this.getActiveDevices()
		this.getLoginAttempts()
	},
	methods: {
		getLoginAttempts() {
			this.loading_login_attempts = true
			this.axios.get(this.$store.getters.getAuthUrl + '/login_attempts').then(response => {
				this.login_attempts = response.data.data
			}).finally(() => {
				this.loading_login_attempts = false
			})
		},
		getActiveDevices() {
			this.loading_active_devices = true
			this.axios.get(this.$store.getters.getAuthUrl + '/active_devices').then(response => {
				this.active_devices = response.data.data
			}).finally(() => {
				this.loading_active_devices = false
			})
		},
		confirmRevoke(item) {
			this.deletingItem = item
			this.confirmDelete = true
		},
		revoke(item) {
			this.deletingItem = {}
			this.axios.delete('active_devices/'+item.id).then(() => {
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: 'Dispositivo rimosso', value: true, color: 'success'})
				this.getActiveDevices()
			})
		},
		confirmRevokeAll(item) {
			this.confirmDeleteAll = true
		},
		revokeAll() {
			this.axios.post('revoke_all_active_devices/').then(() => {
				this.getActiveDevices()
			})
		}
	},
}
</script>

<style>

</style>