<template>        
    <v-timeline align-top dense :class="{'pt-0': true, 'mobile-timeline': $vuetify.breakpoint.smAndDown}" style="z-index: 0">
        <v-timeline-item v-if="$store.getters.isAuth" fill-dot :small="$vuetify.breakpoint.smAndDown">
            <template v-slot:icon>
                <v-avatar v-if="$store.getters.getUserProfile.avatar" >
                    <v-img :src="$store.getters.getUserProfile.avatar"></v-img>
                </v-avatar>
                <v-avatar v-else>
                    <span class="white--text" style="line-height: 2">{{ $store.getters.getUserProfile.name[0] }}</span>
                </v-avatar>
            </template>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field 
                        label="Nuovo Commento"
                        v-model="newComment"
                        outlined
                        rounded
                        solo
                        dense
                        hide-details
                        append-icon="mdi-send"
                        @keyup.enter="sendComment"
                        @click:append="sendComment"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-timeline-item>

        <template v-for="comment in comments">
            <post-comment :key="'comment_'+comment.id" :is-reply="isReply" :post-id="postId" :comment="comment"></post-comment>
        </template>
    </v-timeline>
</template>

<script>
import PostComment from './PostComment'

export default {
	name: 'post-comments',
	components: {PostComment},

	props: {
		comments: Array,
		postId: Number,
		commentId: Number,
		isReply: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			newComment: '',
			editCommentIndex: -1
		}
	},

	methods: {

		updateComment(comment) {
			if(comment.text) {
				this.axios.post('comments/'+comment.id, {_method: 'PUT', text: comment.text}).then(response => {
					this.editCommentIndex = -1
				})
			}
		},

		sendComment() {
			if(this.newComment != '') {
				let data = {post_id: this.postId, text: this.newComment}
				if(this.isReply) {
					data.comment_id = this.commentId
				}
				this.axios.post('comments', data).then(response => {
					this.newComment = ''				
				})
			}
		},
	},
}
</script>

<style>
.mobile-timeline .v-timeline-item__divider {
    min-width: 40px !important;
}

.mobile-timeline.v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 40px) !important;
}

.v-application--is-ltr .mobile-timeline.v-timeline--dense:not(.v-timeline--reverse):before, .v-application--is-rtl .mobile-timeline.v-timeline--reverse.v-timeline--dense:before {
    left: 19px !important;
}
</style>