<template>
    <v-dialog v-model="open" max-width="800" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" persistent scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div v-if="confirmDelete" class="pl-0 font-weight-bold">Elimina Post</div>
                    <div v-else-if="model.id" class="pl-0 font-weight-bold">Modifica Post</div>
                    <div v-else class="pl-0 font-weight-bold">Nuovo Post</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-1 pb-1">

                <v-container v-if="!confirmDelete" fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="pt-6 mx-0">
                    <post-fields
                        v-if="open"
                        :open.sync="open"
                        ref="postfields"
                        :model.sync="model">
                    </post-fields>
                </v-container>
                <v-container v-else>
                    <v-row class="mx-0 justify-center pa-6">
                        <div class="caption">Sei sicuro di voler eliminare questo Post?</div>
                    </v-row>
                </v-container>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
                <v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="savePost()">Salva</v-btn>          
                <v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="savePost()">Crea</v-btn>          
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import PostFields from './PostFields'

export default {
	name: 'post-dialog',
	components: {
		PostFields
	},
	props: {
	},
	data() {
		return {
			model: {},
			open: false,
			type: null,
			loading: false,
			    fullScreen: false,
			confirmDelete: false,
		}
	},

	mounted() {
		this.$eventBusUtility.$on('createPost', (type) => {
			this.createPost(type)
		})

		this.$eventBusUtility.$on('editPost', (data) => {
			this.editPost(data)
		})

		this.$eventBusUtility.$on('destroyPost', (data) => {
			this.confirmDestroy(data)
		})
	},

	methods: {

		createPost(type) {
			this.type = type
			this.$set(this.model, 'public', (type == 'public' ? true : false))
			this.open = true
		},

		editPost(data) {
			this.model = {...data}
			this.open = true
		},

		savePost() {
			this.$refs.postfields.$refs.fields.validate().then(success => {
				if (!success) {
					return
				}
				this.loading = true
				let formData = new FormData()
				for (let key in this.model) {
					if (['selectedGroups', 'selectedPeople', 'selectedTasks'].includes(key)) {
						let ids = []
						this.model[key].forEach((item) => {
							ids.push(item.id)
						})
						formData.append(key, ids)
					} else if (key != 'image') {
						formData.append(key, this.model[key])
					} else {
						if(typeof this.model[key] != 'string') {
							formData.append(key, this.model[key] ? this.model[key] : '')   
						}                            
					}
				}

				if(this.model.id) {
					formData.append('_method', 'PUT')
					this.axios.post('posts/' + this.model.id, formData).then(response => {
						this.$store.commit('SET_SNACKBAR', {
							timeout: 6000,
							text: 'Post modificato con successo',
							value: true,
							color: 'success'
						})
						this.$emit('updated', response.data.data)
						this.loading = false
						this.closeDialog()
					})
				} else {
					this.axios.post('posts', formData).then(response => {
						this.model = {}
						this.$store.commit('SET_SNACKBAR', {
							timeout: 6000,
							text: 'Post creato con successo',
							value: true,
							color: 'success'
						})
						this.$emit('stored', response.data.data)
						this.loading = false
						this.closeDialog()
					})
				}
			})
		},

		closeDialog() {
			this.model = _.stubObject()
			this.open = false
			this.confirmDelete = false
		},

		confirmDestroy(data) {
			this.model = {...data}
			this.open = true
			this.confirmDelete = true
		},

		destroy() {
			this.loading = true
			this.axios.delete('posts/' + this.model.id).then(response => {
				if(response.status) {
					this.$store.commit('SET_SNACKBAR', {
						timeout: 6000,
						text: 'Post eliminato con successo',
						value: true,
						color: 'success'
					})
					this.$emit('deleted', this.model.id)
					this.loading = false
					this.closeDialog()                     
				}
				this.loading = false
			})
		}

	},

	beforeDestroy() {
		this.$eventBusUtility.$off('createPost')
		this.$eventBusUtility.$off('editPost')
		this.$eventBusUtility.$off('destroyPost')
	},
}
</script>
<style lang="">

</style>    
