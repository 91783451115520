

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import HandbookIndex from '@/components/modules/handbook/HandbookIndex'

export const handbook = [
	{
		path: '/handbook',
		name: 'handbook',
		component: HandbookIndex,
		beforeEnter: ifAuthenticated
	},
]