import Vue from 'vue'

export default {

	methods: {
		saveSettings() {
			this.$refs.fieldsSettings.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				} 
				this.loading = true
                
				let data = {model: this.model}
				_.forEach(this.settings, (value, key) => {
					data[key] = value
				})

				this.axios.post('settings/', data).then(response => {
					// get body data
					this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: 'Impostazioni salvate', value: true, color: 'success'})
					this.$store.commit('SET_SETTINGS', response.data)
					this.loading = false
				}, response => {
					// error callback
					this.loading = false
				})
			}).catch(() => {

			})
		},
	},
    
	created() {
		this.axios.get('settings/'+this.model).then(response => {

			_.forEach(response.data.settings, (setting) => {                
				Vue.set(this.settings, [setting.key], setting.value)
			})
            
			this.$store.commit('END_LOADING')

		}, response => {
			// error callback
		})
	}
}