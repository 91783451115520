<template>
    <v-dialog v-model="open" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card class="rounded-lg pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Estendi Ricorrenza</div>
                    <div class="pl-0 font-weight-bold" style="font-size: 20px">{{ model.name ? model.name : ''}} {{ model.shifttype ? '['+ model.shifttype.name +']' : '' }}{{ repeatUntillOldFormat }}</div>
                
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="px-0 pt-6 mx-0">
                    <validation-observer ref="extendRecurrenceFields">

                        <v-row class="pb-2 mx-0 pt-2">
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-menu
                                    ref="repeat_until"
                                    :close-on-content-click="false"
                                    v-model="datepicker_menu['repeat_until']"
                                    :return-value.sync="model['repeat_until']"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    :nudge-right="40"
                                    style="width: 100%">
                                    <template v-slot:activator="{ on }">
                                        <validation-provider name="Ripeti Fino" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                v-on="on"
                                                label="Ripeti Fino*"
                                                :error-messages="errors"
                                                append-icon="event"
                                                readonly
                                                :value="$eventBusUtility.formatDate(model['repeat_until'])"
                                                outlined
                                                @click:clear="model['repeat_until'] = ''"
                                                dense
                                            ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker 
                                        v-model="model['repeat_until']"
                                        color="primary"
                                        locale="It"
                                        scrollable
                                        :min="repeatUntillOld"
                                        :first-day-of-week="1"
                                        @input="$refs['repeat_until'].save(model['repeat_until'])">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6 col-sm-12 col-md-6 col-lg-6 py-0 px-6">
                                <v-checkbox
									v-model="model['recreate']"
									:label="'Ricrea anche Turni futuri eliminati a partire dal '+moment(shift.started_at).format('DD/MM/Y')">
								</v-checkbox>
                            </v-col>
                        </v-row>

                    </validation-observer>

                </v-container>

            </v-card-text> 
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
	name: 'extend-recurrence-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			model: {},
			shift: {},
			open: false,
			fullScreen: false,
			loading: false,
			datepicker_menu: {},
			repeatUntillOld: '',
			repeatUntillOldFormat: '',
		}
	},

	computed: {
	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('extendRecurrence', (data) => {
			this.openEditDialog(data)
		})
	},

	methods: {
    
		openEditDialog(data) {
			this.model = {...data.recurrence}
			this.shift = {...data}
			this.repeatUntillOld = this.model.repeat_until
			this.repeatUntillOldFormat = moment(this.model.repeat_until).format('DD/MM/YYYY')
			this.open = true
		},

		closeDialog() {
			this.model = _.stubObject()
			this.open = false
		},

		save() {
			this.$refs.extendRecurrenceFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				// is in edit
				let data = this.model
				data.shift_id = this.shift.id
                
				this.axios.put('recurrences/' + this.model.id, data).then(response => {
					// get body data
					this.loading = false
					this.closeDialog()

				}, response => {
					this.loading = false
				})
			}).catch(() => {

			})
		},

	}

}
</script>

<style>

</style>