<template>
    <v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class="background pa-0 elevation-0">
        <v-card-title class="pa-3">
            <v-col class="col-10 ma-auto ml-0 py-1">
                <div class="pl-0 font-weight-bold" style="font-size: 30px">Richiesta Disponibilità</div>
            </v-col>
            <v-spacer></v-spacer>
        </v-card-title>            

        <v-card-text style="overflow-x: hidden; margin-top: 0px" class="px-1 pb-1 body-page">

            <v-container fluid :class="{'px-3': $vuetify.breakpoint.mdAndUp, 'px-0': $vuetify.breakpoint.smAndDown}" class="mx-0">
                <v-row class="ma-auto d-flex pt-6">
                    <v-icon>mdi-clipboard-account-outline</v-icon>
                    <div class="pl-2 caption">Mansione</div>
                </v-row>
                <div class="d-block ml-6" v-if="request.task">
                    <v-chip small class="ma-1">
                        <v-avatar left>
                            <v-icon :color="request.task.color">{{ request.task.icon }}</v-icon>
                        </v-avatar>
                        {{ request.task.name }}
                    </v-chip>
                </div>

                <v-row class="ma-auto d-flex pt-6">
                    <v-icon>mdi-calendar-outline</v-icon>
                    <div class="d-block">
                        <div class="pl-2 caption">Turno</div>
                        <div class="pl-2">{{ request.shift }}</div>
                    </div>
                </v-row>     

                <v-row class="ma-auto d-flex pt-6">
                    <v-icon :color="setRequestStatusColor">mdi-checkbox-marked-outline</v-icon>
                    <div class="d-block">
                        <div class="pl-2 caption">Stato</div>
                        <div class="pl-2">{{ setRequestStatusText }}</div>
                    </div>
                </v-row>

                <v-row class="ma-auto d-flex pt-6">
                    <v-icon>mdi-calendar-check-outline</v-icon>
                    <div class="d-block">
                        <div class="pl-2 caption">Scadenza</div>
                        <div class="pl-2">{{ request.expiring_at | date }}</div>
                    </div>
                </v-row>
                
                <v-row class="ma-auto d-flex pt-6" v-if="request.creator">
                    <v-icon>mdi-account-outline</v-icon>
                    <div class="d-block">
                        <div class="pl-2 caption">Creata da</div>
                        <div class="pl-2">{{ request.creator.full_name }}</div>
                    </div>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
            <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
            <v-btn color="background-1" fab small @click="$eventBusUtility.navigateTo('board')">
                <v-icon>mdi-keyboard-backspace</v-icon>
            </v-btn>
            <template v-if="(!request.expired && request.accepted != false)">
                <v-btn color="success" dark :loading="loading" min-width="100px" height="40" class="ml-4" @click="responseRequest(true)">
                    Accetta
                </v-btn>
                <v-btn color="red" dark :loading="loading" min-width="100px"  height="40" class="ml-4" @click="responseRequest(false)">
                    Rifiuta
                </v-btn>
            </template>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
	name: 'availability-request',
	props: {
		id: Number,
	},      
	data() {
		return {
			request: {},
			loading: false
		}
	},

	computed: {
		setRequestStatusColor() {
			if(this.request.expired) {
				return 'red'
			} else if(this.request.accepted) {
				return 'success'
			} else if(this.request.accepted == false && this.request.expired == false)  {
				return 'warning'
			} else {
				return 'blue'
			}
			return ''
		},

		setRequestStatusText() {
			if(this.request.expired) {
				return 'Richiesta scaduta'
			} else if(this.request.accepted) {
				return 'Richiesta accettata'
			} else if(this.request.accepted == false && this.request.expired == false)  {
				return 'Richiesta rifiutata'
			} else {
				return 'Richiesta in attesa'
			}
			// se rifiutata?
			return ''
		}
	},
 
	created() {
		this.axios.get('shiftrequests-open/'+ this.id).then(response => {
			this.request = response.data.data
		})
	},

	methods: {
		responseRequest(value) {
			this.loading = true
			this.axios.put('shiftrequests-response/'+ this.id, {response: value}).then(response => {
				this.request = response.data.data
				this.loading = false
			}, response => {
				// error callback
				this.loading = false
			})
		}
	}
}
</script>

<style>

</style>