<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">
			

            <v-container v-if="profile" fluid class="pa-0" style="position: fixed;" :class="{'open-nav': $store.getters.getSidebar && $vuetify.breakpoint.mdAndUp}">
                <v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters>  
                            <v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold">Profilo</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 30px">{{ person.name }}</div>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <slot name="tabs">


                    </slot>

                </v-card>
            </v-container>			


			<v-col class="col-sm-6 col-lg-4 ma-auto mr-0 pt-8">
                <v-menu
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					transition="scale-transition"
					offset-y
					bottom
					left
					min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							label="Seleziona Intervallo"
							v-model="formattedDates"
							v-bind="attrs"
							v-on="on"
							background-color="background-1"
							hide-details
							rounded
							solo
							dense
							prepend-inner-icon="mdi-calendar">
							<template v-slot:append-outer>
								<v-icon :size="35" color="black">mdi-equalizer-outline</v-icon>
							</template>
							<template v-slot:progress>
								<v-progress-linear 
									color="success"
									absolute 
									height="2"
								></v-progress-linear>
							</template>
						</v-text-field>
					</template>
					<v-date-picker
						ref="picker"
						v-model="dates"
						:max="moment().format('Y-MM-DD')"
						range>
					</v-date-picker>
				</v-menu>
			</v-col>      


            <v-container fluid class="pa-6 page-body pt-8" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="height: 100%" :style="profile ? 'margin-top: 70px;' : ''">
				<v-card :elevation="0" class="background" width="100%">

					<v-row no-gutters>
						<v-col class="col-12 col-lg-4 background-1">
							<v-card :elevation="0" class="background-1" width="100%">
								<div id="tasks" class="background-1" ref="tasks" style="height: 450px">
									
								</div>
							</v-card>

						</v-col>
						<v-col class="col-12 col-lg-8">
							<v-card :elevation="0" class="background-1" width="100%">
								<div id="shifts" class="background-1" ref="shifts" style="height: 450px">
									
								</div>
							</v-card>
							<v-card :elevation="1" class="background-1" width="100%">
								<v-data-table class="background-2"
									:headers="headers"
									:items="shifts"
									:loading="loading"
									:mobile-breakpoint="0"
									ref="vDataTable"
									:footer-props="{
										'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
										showFirstLastPage: true
									}">

									<template v-slot:item="{ item }">
										<tr class="cursor-pointer">
											<td>
											</td>
											<td>
												{{ item.month_number }}
											</td>
											<td>
												{{ item.shift_count }}
											</td>
										</tr>
									</template>

									<template v-slot:footer.page-text="{ pageStart, pageStop }">
										Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
									</template>

								</v-data-table>

							</v-card>
						</v-col>
					</v-row>

					<v-row no-gutters class="background-1 mt-0 pt-4">
						<v-col class="col-12 background-1">
							<v-card :elevation="0" class="background-1" width="100%">
								<div id="shiftsheatmap" class="background-1" ref="shiftsheatmap" style="height: 800px">
									
								</div>
							</v-card>
						</v-col>					
					</v-row>

					<v-row no-gutters class="background-1 mt-0 pt-8">
						<v-col class="col-12">
							<v-card :elevation="1" class="background-1" width="100%">
								<v-data-table class="background-2"
									:headers="availabilitiesHeaders"
									:items="availabilities"
									:loading="loading"
									:mobile-breakpoint="0"
									ref="vDataTable"
									:footer-props="{
										'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
										showFirstLastPage: true
									}">

									<template v-slot:item="{ item }">
										<tr class="cursor-pointer">
											<td>
											</td>
											<td>
												{{ item.shift }}
											</td>
											<td>
												{{ item.started_at | date }}
											</td>
											<td>
												{{ item.ended_at | date }}
											</td>
										</tr>
									</template>

									<template v-slot:footer.page-text="{ pageStart, pageStop }">
										Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
									</template>

								</v-data-table>

							</v-card>
						</v-col>
					</v-row>					
					<v-btn
						color="green"
						fab
						dark
						small
						absolute
						right
						@click.native.stop="$eventBusUtility.openFile($store, '/person-availabilities-csv/' + person.id, 'turni.csv', 'POST', {'dates': formattedAPIDates})">
						<v-icon>mdi-download</v-icon>
					</v-btn>
				</v-card>

				<footer-logo></footer-logo>
			</v-container>

		</v-col>
  	</v-row>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
    
am4core.useTheme(am4themes_animated)
    

export default {
	name: 'analytic-person',
	components: {
	},    
	props: {
		person: Object,
		profile: Boolean,
	},
	data() {
		return {
			menu: false,
			loading: false,
			tasksChart: {},
			shiftsChart: {},
			shiftsHeatMap: [],
			availabilities: [],
			tasks: [],
			shifts: [],
			dates: [moment().startOf('year').format('Y-MM-DD'), moment().format('Y-MM-DD')],
		}
	},

	watch: {
		dates: {
			handler(newVal) {
				if(newVal[0] && newVal[1])
					this.initialize()
				this.initializeHeatMap()
				this.initializeAvailabitilesAnalytics()
			},
			immediate: true,
			deep: true,
		},    
	},

	computed: {

		totalItems() {
			return this.shifts.length
		},

		headers() {
			return [
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Mese',
					'value': 'month_number',
				},
				{
					'text': 'N. Turni',
					'value': 'shift_count',
				},
			]
		},
		
		availabilitiesHeaders() {
			return [
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Turno',
					'value': 'shift',
				},
				{
					'text': 'Inizio',
					'value': 'started_at',
				},
				{
					'text': 'Fine',
					'value': 'ended_at',
				},
			]
		},

		formattedAPIDates() {
 			let dates = []
			if(moment(this.dates[1]).isBefore(this.dates[0])) {
				let d = this.dates[0]
				dates[0] = this.dates[1]
				dates[1] = d
			} else {
				dates = this.dates
			}
			return dates
		},		

		formattedDates: {
			get() {
				let from, to = ''
				if(this.dates[0]) {
					from = moment(this.dates[0]).format('DD MMM Y')
				}
				if(this.dates[1]) {
					to = moment(this.dates[1]).format('DD MMM Y')
				}                
				return from + ' - ' + to
			},
			set(newDates) {
				this.$emit('update:dates', [])
				return ''
			}
		},
	},
    
	methods: {

		initialize() {
			delete this.tasksChart
			this.loading = true
			this.axios.get('person-analytics/' + this.person.id, {params: {'dates': this.formattedAPIDates}}).then(response => {
				this.shifts = response.data.data.shifts.map((shift) => {
					let month = moment(shift.month_number, 'MM-YY').format('MMM YY')
					return {
						'shift_count': shift.shift_count,
						'month_number': month.charAt(0).toUpperCase() + month.slice(1),
					}
				})
				this.tasks = response.data.data.tasks

				this.tasksChart = am4core.create('tasks', am4charts.PieChart)
				this.tasksChart.hiddenState.properties.opacity = 0 // this creates initial fade-in
				this.tasksChart.data = this.tasks

				this.tasksChart.radius = am4core.percent(70)
				this.tasksChart.innerRadius = am4core.percent(40)
				this.tasksChart.startAngle = 180
				this.tasksChart.endAngle = 360  
				this.tasksChart.numberFormatter.numberFormat = '#.##'

				let label = this.tasksChart.seriesContainer.createChild(am4core.Label)
				label.text = 'Totale'
				label.horizontalCenter = 'middle'
				label.verticalCenter = 'middle'
				label.fontSize = 11
				label.dy = -50
				label.fill = am4core.color('#00E676')

				let label2 = this.tasksChart.seriesContainer.createChild(am4core.Label)
				label2.text = '[bold]' + this.tasks.reduce((acc, task) => acc + parseInt(task.task_count), 0) + '[/]'
				label2.horizontalCenter = 'middle'
				label2.verticalCenter = 'middle'
				label2.fontSize = 24
				label2.dy = -30
				label2.fill = am4core.color('#00E676')


				let series = this.tasksChart.series.push(new am4charts.PieSeries())
				series.dataFields.value = 'task_count'
				series.dataFields.category = 'task_name'

				series.slices.template.cornerRadius = 10
				series.slices.template.innerCornerRadius = 7
				series.slices.template.draggable = false
				series.slices.template.inert = true
				series.alignLabels = false


				series.hiddenState.properties.startAngle = 90
				series.hiddenState.properties.endAngle = 90
				series.slices.template.tooltipText = '[font-size: 12px]{task_name}: {value.percent} % ({value})[/]'
				series.labels.template.text = '[font-size: 11px]{task_name}: {value.percent} %[/]'

				this.tasksChart.legend = new am4charts.Legend()
				let markerTemplate = this.tasksChart.legend.markers.template
				markerTemplate.width = 10
				markerTemplate.height = 10
				this.tasksChart.legend.labels.template.text = '[font-size: 11px]{task_name}: {value}[/]'
				series.legendSettings.itemValueText = '[font-size: 11px]{value.percent} %[/]'
                

				// Create chart instance
				this.shiftsChart = am4core.create('shifts', am4charts.XYChart)
				this.shiftsChart.data = this.shifts
				this.shiftsChart.numberFormatter.numberFormat = '#.'

				// Create axes
				let categoryAxis = this.shiftsChart.xAxes.push(new am4charts.CategoryAxis())
				categoryAxis.dataFields.category = 'month_number'
				categoryAxis.renderer.grid.template.location = 0
				categoryAxis.renderer.minGridDistance = 30
				//categoryAxis.renderer.labels.template.horizontalCenter = 'right'
				categoryAxis.renderer.labels.template.verticalCenter = 'middle'
				categoryAxis.renderer.labels.template.rotation = 0
				categoryAxis.tooltip.disabled = true
				categoryAxis.renderer.minHeight = 110

				let valueAxis = this.shiftsChart.yAxes.push(new am4charts.ValueAxis())
				valueAxis.renderer.minWidth = 50

				// Create series
				let shiftSeries = this.shiftsChart.series.push(new am4charts.ColumnSeries())
				shiftSeries.sequencedInterpolation = true
				shiftSeries.dataFields.valueY = 'shift_count'
				shiftSeries.dataFields.categoryX = 'month_number'
				shiftSeries.columns.template.tooltipText = '[{valueY}: bold]{shift_count}[/]'
				shiftSeries.columns.template.strokeWidth = 0

				shiftSeries.tooltip.pointerOrientation = 'vertical'

				shiftSeries.columns.template.column.cornerRadiusTopLeft = 10
				shiftSeries.columns.template.column.cornerRadiusTopRight = 10
				shiftSeries.columns.template.column.fillOpacity = 0.8

				// on hover, make corner radiuses bigger
				let hoverState = shiftSeries.columns.template.column.states.create('hover')
				hoverState.properties.cornerRadiusTopLeft = 0
				hoverState.properties.cornerRadiusTopRight = 0
				hoverState.properties.fillOpacity = 1

				shiftSeries.columns.template.adapter.add('fill', (fill, target) => {
					return this.shiftsChart.colors.getIndex(target.dataItem.index)
				})

				this.loading = false
			}, response => {
				// error callback
			})                
		},

		initializeHeatMap() {
			this.axios.get('person-heatmap/' + this.person.id, {params: {'dates': this.formattedAPIDates}}).then(response => {

				// Themes begin
				am4core.useTheme(am4themes_animated)
				// Themes end

				this.shiftsHeatMap = am4core.create('shiftsheatmap', am4charts.XYChart)
				this.shiftsHeatMap.maskBullets = false

				let xAxis = this.shiftsHeatMap.xAxes.push(new am4charts.CategoryAxis())
				let yAxis = this.shiftsHeatMap.yAxes.push(new am4charts.CategoryAxis())

				xAxis.dataFields.category = 'weekday'
				yAxis.dataFields.category = 'hour'

				xAxis.renderer.grid.template.disabled = true
				xAxis.renderer.minGridDistance = 40

				yAxis.renderer.grid.template.disabled = true
				yAxis.renderer.inversed = true
				yAxis.renderer.minGridDistance = 30

				let series = this.shiftsHeatMap.series.push(new am4charts.ColumnSeries())
				series.dataFields.categoryX = 'weekday'
				series.dataFields.categoryY = 'hour'
				series.dataFields.value = 'value'
				series.sequencedInterpolation = true
				series.defaultState.transitionDuration = 3000

				let bgColor = new am4core.InterfaceColorSet().getFor('background')

				let columnTemplate = series.columns.template
				columnTemplate.strokeWidth = 1
				columnTemplate.strokeOpacity = 0.2
				columnTemplate.stroke = bgColor
				columnTemplate.tooltipText = '{weekday}, {hour}: {value.workingValue.formatDuration(\'mm:ss\')}'
				columnTemplate.width = am4core.percent(100)
				columnTemplate.height = am4core.percent(100)
				
				series.heatRules.push({
					target: columnTemplate,
					property: 'fill',
					min: am4core.color(bgColor),
					max: this.shiftsHeatMap.colors.getIndex(0)
				})

				// heat legend
				let heatLegend = this.shiftsHeatMap.bottomAxesContainer.createChild(am4charts.HeatLegend)
				heatLegend.width = am4core.percent(100)
				heatLegend.series = series
				heatLegend.valueAxis.renderer.labels.template.fontSize = 9
				heatLegend.valueAxis.renderer.minGridDistance = 30
				heatLegend.valueAxis.durationFormatter.formatDuration = 'mm:ss' 
				heatLegend.valueAxis.tooltipText = 'mm:ss' 

				// heat legend behavior
				series.columns.template.events.on('over', (event) => {
					handleHover(event.target)
				})

				series.columns.template.events.on('hit', (event) => {
					handleHover(event.target)
				})

				function handleHover(column) {
					if (!isNaN(column.dataItem.value)) {
						heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
						heatLegend.valueAxis.tooltipDataItem = column.dataItem
						heatLegend.valueAxis.durationFormatter.formatDuration = 'mm:ss' 
						heatLegend.valueAxis.tooltip.label.text = duration(column.dataItem.value)

					} else {
						heatLegend.valueAxis.hideTooltip()
					}
				}

				function duration(value) {
					if (!value) return '00:00'
					let h = value / 60 | 0,
						m = value % 60 | 0
					let result = ''
					if(h <= 9) {
						result = '0' + Math.round(h).toString() + ':' 
					} else {
						result = Math.round(h).toString() + ':'
					}
					if(m <= 9) {
						result = result +  '0' + m.toString()
					} else {
						result = result + m.toString()
					}

					return result + ' ore'
				}

				series.columns.template.events.on('out', (event) => {
					heatLegend.valueAxis.hideTooltip()
				})

				this.shiftsHeatMap.data = response.data.data.heatData

			}, response => {
				// error callback
			})    
		},

		initializeAvailabitilesAnalytics() {
			this.axios.get('person-availabilities/' + this.person.id, {params: {'dates': this.formattedAPIDates}}).then(response => {
				
				this.availabilities = response.data.data

			}, response => {
				// error callback
			}) 
		},	
	},
 
}
</script>