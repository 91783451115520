<template>
    <v-card class="background-2 rounded-lg" @click.native="manageUserAvailability(shift)">
        <v-toolbar dark flat :color="colorShift" class="shift" style="height: auto !important">
            <div>
                <div class="font-weight-bold" style="font-size: 13px !important;">{{ formatEventDate(shift)}} </div>
                <div class="font-weight-bold" style="font-size: 13px !important;">{{ shift.name }} <span class="text-uppercase">[{{ shift.shifttype.name }}]</span></div>
            </div>
            <v-spacer></v-spacer>

            <actions-menu
                v-if="checkActionPermission(shift)"
                class="d-print-none"
                :item="shift"
                :actions="actions"
                :showAction="false"
            ></actions-menu>

        </v-toolbar>

        <v-list dense class="pa-0">

            <template v-for="(shifttypetask, s) in shift.shifttype.shifttypetasks">
                <div :key="s">

                    <template v-if="checkPermission">

                        <v-list-item :input-value="clickedShifttypetaskId == shifttypetask.task_id ? true : false" :disabled="shifttypetask.hidden ? true : false" class="shift-task-header c-t-divider px-1" @click="openShiftAvailability(shifttypetask)" style="pointer-events: inherit !important;">
                            <v-list-item-content class="py-0">
                                <v-list-item-title style="font-size: 13px !important">{{ shifttypetask.name }}
                                    <v-tooltip top v-if="shifttypetask.hidden">
                                        <template v-slot:activator="{ on }">
                                            <v-icon size="20" v-on="on" class="pl-1" >mdi-eye-off</v-icon>
                                        </template>
                                        <span>Nascosto</span>
                                    </v-tooltip>
                                    <v-tooltip top v-if="shifttypetask.extra">
                                        <template v-slot:activator="{ on }">                                                
                                            <v-icon size="20" v-on="on" class="pl-0" >mdi-bookmark-outline</v-icon>
                                        </template>
                                        <span>Extra</span>
                                    </v-tooltip>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small :elevation="0" dark :color="colorShiftTask(shift, shifttypetask)" style="height: 24px !important">
                                    <span>{{ shifttypetask.min ? '≥ ' + shifttypetask.min : '' }} {{shifttypetask.max ? '≤ ' + shifttypetask.max : '' }}</span>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>

                    <template v-if="!shifttypetask.hidden">
                        <template v-for="(availability, a) in shift.availables">
                            <div :key="a" v-if="availability.type == 'available' && availability.task_id == shifttypetask.task_id">
                                <v-list-item class="shift-task  px-1">
                                    <v-list-item-action class="pl-4">
                                        <v-icon :size="20" :style="{color:shifttypetask.color}">{{ shifttypetask.icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content class="pl-1 py-0">
                                        <v-list-item-title :style="{'font-size': '13px !important', color: availability.approved ? null : 'grey'}">
                                            <v-icon :size="18" v-if="showEmployee(availability)">mdi-briefcase-outline</v-icon> 
                                            {{ availability.person ? availability.person.full_name : '--'}}
                                            <v-tooltip top v-if="availability.certified">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon size="20" v-on="on" class="pl-0" >mdi-check-decagram</v-icon>
                                                </template>
                                                <span>Certificata</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="availability.note">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon size="20" v-on="on" class="pl-0" >mdi-message-bulleted</v-icon>
                                                </template>
                                                <span>{{availability.note}}</span>
                                            </v-tooltip>
                                            <v-tooltip top v-if="availability.started_at || availability.ended_at">
                                                <template v-slot:activator="{ on }">                                                
                                                    <v-icon size="20" v-on="on" class="pl-0" >mdi-clock-outline</v-icon>
                                                </template>
                                                <span>{{availability.started_at || shift.started_at | time}} - {{availability.ended_at || shift.ended_at | time}}</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                        </template>
                    </template> 
                </div>

            </template>

        </v-list>

        <template v-if="shift.location">
            <v-divider></v-divider>
            <p class="text-caption px-1"><v-icon small>mdi-map-marker</v-icon> {{shift.location}}</p>
        </template>

        <template v-if="shift.note">
            <v-divider></v-divider>
            <p class="text-caption px-1"><v-icon small>mdi-message-bulleted</v-icon> {{shift.note}}</p>
        </template>

        <div v-for="(destination, d) in shift.destinations" :key="d" class="c-t-divider">
            <div class="shift-task px-1 d-flex background-1 ">
                <div class="d-block" style="width: 100%">
                    <div class="d-flex py-1" style="width: 100%">
                        <v-icon :size="20" color="primary">mdi-clock-time-five-outline</v-icon>
                        <span v-if="destination.schedule" class="ma-auto ml-2" style="font-size: 13px !important;">{{ formatScheduleTime(destination.schedule) }}</span>
                        <template v-if="destination.vehicle">
                            <v-icon :size="20" class="mr-1" color="primary">mdi-car</v-icon>
                            <span class="ma-auto ml-1 mr-1" style="font-size: 13px !important;" v-if="destination.vehicle.code">{{ destination.vehicle.code }}</span>
                        </template>
                    </div>

                    <div class="d-flex py-0" style="width: 100%">
                        <span style="font-size: 13px !important;">{{ destination.location }}</span>
                    </div>
                    <div class="d-flex pt-0 pb-2" style="width: 100%" v-if="$store.getters.checkPermission()">
                        <v-btn color="primary" outlined text small class="ma-auto" @click="editDestination(destination)">Modifica</v-btn>
                        <v-btn color="primary" outlined text small class="ma-auto" @click="deleteDestination(destination)">Elimina</v-btn>
                    </div>
                </div>
            </div>
        </div>


        <div class="shift-task c-t-divider px-1 d-flex">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon :size="20" v-on="on" color="primary">{{ checklistIcon(shift) ? checklistIcon(shift)[0] : '' }}</v-icon>
                </template>
                <span>{{ checklistIcon(shift) ? checklistIcon(shift)[1] : '' }}</span>
            </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on }">                                                
                    <v-icon :size="20" v-on="on" color="primary" class="pl-1">{{ shift.recurrence_id ? 'mdi-calendar-sync-outline' : '' }}</v-icon>
                </template>
                <span>Ricorrenza #{{shift.recurrence_id}}</span>
            </v-tooltip>
            <v-tooltip top v-if="shift.vehicle">
                <template v-slot:activator="{ on }">
                    <div class="ma-auto mr-0">
                        <v-icon :size="20" v-on="on" color="primary">mdi-car</v-icon>
                        <span class="ma-auto ml-2 mr-2" style="font-size: 13px !important;">{{ shift.vehicle.code }}</span>            
                    </div>
                </template>
                <span>{{ shift.vehicle.full_name }}</span>
            </v-tooltip>
        </div>

    </v-card>
</template>

<script>
export default {
	name: 'shift',
	props: {
		shift: Object,
		availability: {
			default: false,
			type: Boolean,
		},
		clickedShifttypetaskId: Number,
		dayType: String,
	},
	data() {
		return {
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editShift'
				},
				{
					'icon': 'mdi-plus-circle-multiple-outline',
					'text': 'Duplica',
					'event': 'duplicateShift'
				},
				{
					'icon': 'mdi-calendar-edit',
					'text': 'Modifica Ricorrenza',
					'event': 'editRecurrence',
					'condition_key': 'recurrence_id',
				},
				{
					'icon': 'mdi-calendar-start',
					'text': 'Estendi Ricorrenza',
					'event': 'extendRecurrence',
					'condition_key': 'recurrence_id',
				},
				{
					'icon': 'mdi-calendar-multiple',
					'text': 'Duplica Disponibilità',
					'event': 'duplicateAvailability',
					'condition_key': 'recurrence_id',
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'deleteShift'
				},
				{
					'icon': 'mdi-map-marker-plus-outline',
					'text': 'Aggiungi Destinazione',
					'event': 'addDestination'
				},
			],
		}
	},

	computed: {

		formatCurrentDate() {
			let month = moment(this.today).format('ddd, D MMM')
			return month.charAt(0).toUpperCase() + month.slice(1)
		},

		formatEventDate(event) {
			return (event) => {
				if(event.long_shift) {
					return moment(event.started_at).format('ddd, D MMM') + ' ⋅ ' + moment(event.started_at).format('HH:mm') + ' - ' + moment(event.ended_at).format('ddd, D MMM') + ' ⋅ ' + moment(event.ended_at).format('HH:mm') + ' *'
				} else {
					return moment(event.started_at).format('HH:mm') + ' - ' + moment(event.ended_at).format('HH:mm')
				}
			}
		},
        
		formatScheduleTime(time) {
			return (time) => {
				return moment(time, 'hh:mm').format('hh:mm')
			}
		},

		colorShift() {
			if(this.shift.covered) {
				if(this.shift.covered.shift) {
					return 'success'
				} else if(this.shift.availables.length > 0) {
					return 'warning'
				}
			}
			return 'error'
		},

		colorShiftTask(shift, task) {
			return (shift, task) => {
				if(!task.required && !task.sufficient) {
					return '#bbbbbb'
				}
				if(shift.covered && shift.covered[task.task_id]) {
					return 'success'
				}
				return 'error'
			}
		},

		checkPermission() {
			if(this.$store.getters.checkPermission('calendars', this.shift.calendar_id) && this.$store.getters.checkPermission('shifttypes', this.shift.shifttype_id) && this.checkDays(this.shift) && this.checkSchedule(this.shift)) {
				return true
			} else return false
		},

		showEmployee(availability) {
			return (availability) => {
				if(this.$store.getters.checkSetting('calendar', 'distinguish_employees') && availability.person.employee) {
					return true
				} else return false
			}
		},

		checklistIcon(shift) {
			return (shift) => {
				if(!this.$store.getters.checkModulePermissions('checklists')) {
					return false
				} else if(shift.checklists.length == 0) {
					return false
				} else {
					if(!shift.checklists[0].certified_at) {
						return ['mdi-checkbox-blank-circle-outline', 'Checklist non compilata']
					} else if(!shift.checklists[0].compliant) {
						return ['mdi-alert-circle-outline', 'Checklist non conforme']
					} else {
						return ['mdi-check-circle', 'Checklist valida']
					}
				}
			}
		}
	},

	created() {
		if(!this.$store.getters.checkPermission('duplicate_availability')) {
			this.actions.splice(4, 1)
		}
	},

	methods: {

		openShiftAvailability(shifttypetask) {
			if(!shifttypetask.hidden)
			    this.$eventBusUtility.$emit('openShiftAvailability', {'shifttypetask': shifttypetask, 'shift': this.shift})            
		},

		editDestination(destination) {
			this.$eventBusUtility.$emit('editDestination', destination)                        
		},

		deleteDestination(destination) {
			this.$eventBusUtility.$emit('deleteDestination', destination)                        
		},

		manageUserAvailability(shift) {
			if(this.$store.getters.isAuth && !this.checkPermission) {
				this.$eventBusUtility.$emit('manageUserAvailability', shift)
			}
		},

		checkDays(shift) {
			return (this.$store.getters.checkPermission('days', 'all') || this.$store.getters.checkPermission('days', moment(shift.started_at).locale('en').format('dddd').toLowerCase()) || this.$store.getters.checkPermission('days', this.dayType))
		},

		checkSchedule(shift) {
			let start_time = moment(moment(shift.started_at).format('YYYY-MM-DD') + ' ' + (this.$store.getters.getUserPermission('start_time') ?? '00:00'))
			let end_time = moment(moment(shift.ended_at).format('YYYY-MM-DD') + ' ' + (this.$store.getters.getUserPermission('end_time') ?? '23:59'))
			let shift_start = moment(shift.started_at)
			let shift_end = moment(shift.ended_at)

			return (shift_start.isSameOrAfter(start_time) && shift_end.isSameOrBefore(end_time))
		},

		checkActionPermission(shift) {
			return this.$store.getters.isAuth && ( !this.availability && (  moment().isSameOrBefore(moment(shift.started_at)) || this.$store.getters.checkPermission('edit_past_shift') ) && ( this.$store.getters.checkPermission('manage_other_shifts') || shift.creator_id == this.$store.getters.getUserPerson.id ) )
		}

	}
}
</script>

<style>
    .shift .v-toolbar__content {
        padding: 4px 12px;
        height: auto !important;
    }
    .shift-task {
        min-height: 22px !important;
    }
    .shift-task-header {
        min-height: 30px !important;
    }
    .shift-task .v-list-item__action, .shift-task-header .v-list-item__action {
        margin: 0px !important
    }
</style>