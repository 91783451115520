<template>
    <validation-observer ref="fields">

        <v-row class="pb-2 mx-0 pt-4">
            <v-col cols="12" md="6">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
                <v-autocomplete class="font-weight-regular"
                    label="Area"
                    v-model="model['instrumentarea_id']"
                    clearable
                    small-chips
                    :items="info.areas"
                    item-value="id"
                    item-text="name"
                    dense
                    outlined>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
                <validation-provider name="Unità di misura" rules="required" v-slot="{ errors }">
                    <v-autocomplete class="font-weight-regular"
                        label="Unità di misura"
                        v-model="model['unit']"
                        :error-messages="errors"
                        clearable
                        small-chips
                        :items="['nr', 'bar']"
                        dense
                        outlined>
                    </v-autocomplete>
                </validation-provider>
            </v-col>

            <v-col cols="12">
                <validation-provider name="Descrizione" rules="max:16777215" v-slot="{ errors }">
                    <v-textarea
                        label="Descrizione"
                        name="description"
                        v-model="model['description']"
                        :error-messages="errors"
                        type="text"
                        outlined
                        dense
                        counter
                        ref="description"
                    ></v-textarea>
                </validation-provider>
            </v-col>        
        </v-row>
        
    </validation-observer>    
</template>

<script>

export default {
	name: 'instrument-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
		info: Object,
	},

}
</script>
