<template>
    <div>
        <v-dialog
            v-model="dialogShow"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <div class="pl-0 font-weight-bold">Brevetto</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </v-col>
                    </v-row>
                 
                </v-card-title>

                <v-card-title class="pa-0 px-2">
                    <v-tabs
                        class="pt-4"
                        v-model="mutableTab"
                        background-color="white"
                        show-arrows
                        :centered="!$vuetify.breakpoint.mdAndUp">
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#patent">
                            Brevetto
                        </v-tab>                        

                        <v-tab href="#people">
                            Persone
                        </v-tab>

                    </v-tabs>   

                </v-card-title>

                <v-card-text style="overflow-x: hidden; margin-top: 20px" class="body-page" v-if="dialogShow">
                    <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                        <v-tabs-items v-model="mutableTab" class="transparent">        
                            <v-tab-item value="patent" class="fill-height">

                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-clipboard-account-outline</v-icon>
                                    <div class="pl-2 caption">Mansioni Fornite</div>
                                </v-row>
                                <div class="d-block ml-6">
                                    <v-chip small class="ma-1" v-for="(task, t) in model.tasks" :key="t">
                                        <v-avatar left>
                                            <v-icon :color="tasksById[task.id].color">{{ tasksById[task.id].icon }}</v-icon>
                                        </v-avatar>
                                        {{ tasksById[task.id].name }}
                                    </v-chip>
                                </div>                            
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-calendar</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Mesi di validità</div>
                                        <div class="pl-2">{{ model.valid_month }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-bell-outline</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Notifica responsabili alla scadenza</div>
                                        <div class="pl-2">{{ model.notify_expiration_person | bool }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-account-outline</v-icon>
                                    <div class="pl-2 caption">Responsabili</div>
                                </v-row>
                                <div class="d-block ml-6">
                                    <!-- <v-chip small class="ma-1" v-for="(person, t) in model.staffpeople" :key="t">
                                        <v-avatar left>
                                            <v-icon :color="tasksById[person].color">{{ tasksById[person].icon }}</v-icon>
                                        </v-avatar>
                                        {{ tasksById[person].name }}
                                    </v-chip> -->
                                </div>   
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-bell-outline</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Notifica utente alla scadenza</div>
                                        <div class="pl-2">{{ model.notify_expiration_person | bool }}</div>
                                    </div>
                                </v-row>
                                <v-row class="ma-auto d-flex pt-6">
                                    <v-icon>mdi-calendar</v-icon>
                                    <div class="d-block">
                                        <div class="pl-2 caption">Giorni di avviso</div>
                                        <div class="pl-2">{{ model.notify_expiration_day }}</div>
                                    </div>
                                </v-row>
                            </v-tab-item>

                            <v-tab-item value="people" class="fill-height">
                                <v-row no-gutters>
                                    <v-col class="col-2 col-sm-6 col-lg-6 ma-auto mr-0 mb-6 hidden-sm-and-down">
                                        <v-text-field class="font-weight-regular rounded-search"
                                            prepend-inner-icon="mdi-magnify"
                                            label="Cerca"
                                            v-model="search"
                                            clearable
                                            hide-details
                                            rounded
                                            solo
                                            dense>
                                        </v-text-field>
                                    </v-col>
                              
                                </v-row>
                                
                                <v-data-table class="background-2"
                                    :headers="headers"
                                    :items="people"
                                    :search="search"
                                    :loading="loading"
                                    v-model="selected" 
                                    show-select
                                    :mobile-breakpoint="0"
                                    ref="vDataTable"
                                    :footer-props="{
                                        'items-per-page-options': [10, 25, 50],
                                        showFirstLastPage: true
                                    }">
                                    <template v-slot:header.data-table-select="{ on, props }">
                                        <v-simple-checkbox color="primary" v-bind="props"  @input="showSelectAll = $event" v-on="on"></v-simple-checkbox>
                                    </template>


                                    <template v-slot:item="{item, select, isSelected}">
                                        <tr>
                                            <td>
                                                <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                                            </td>							
                                            <td>
                                                {{ item.full_name }}
                                            </td>
                                            <td>
                                                {{ moment(item.date).format('D/MM/Y')}}
                                            </td>
                                            <td>
                                                {{ moment(item.expiration_date).format('D/MM/Y')}}
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-slot:footer.page-text="{ pageStart, pageStop }">
                                        <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                                        Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
                                    </template>
                                    
                                </v-data-table>

                                <v-speed-dial
                                    class="mb-24"
                                    fixed
                                    v-model="fab"
                                    bottom
                                    right
                                    :loading="loading"
                                    color="primary"
                                    direction="left"
                                    transition="slide-x-transition"
                                    v-if="selected.length > 0">
                                    <template v-slot:activator>
                                        <v-btn
                                            v-model="fab"
                                            color="primary"
                                            dark
                                            fab>
                                            <v-icon v-if="fab">mdi-close</v-icon>
                                            <v-icon v-else>mdi-certificate-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">                             
                                            <v-btn
                                                v-on="{ ...tooltip }"
                                                fab
                                                dark
                                                small
                                                color="green"
                                                @click.native.stop="$eventBusUtility.exportCSV(headers, selected, 'persone_' + model.name)">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Esporta CSV</span>   
                                    </v-tooltip>
                                </v-speed-dial>


                            </v-tab-item>                            
                        </v-tabs-items>

                    </v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questo Brevetto?</div>
                        </v-row>
                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    
                    <template v-if="$store.getters.checkPermission('manage_patents')">
                        <v-btn color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">
                            Elimina
                        </v-btn>
                        <v-btn color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model.id)">
                            Modifica
                        </v-btn>
                    </template>
                </v-card-actions>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="deletePatent()">
                                Conferma
                            </v-btn>
                        </template>
                        Conferma
                    </v-tooltip>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogEditor"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile>
                <v-card-title class="pa-6" style="position: fixed">
                    <v-row no-gutters class="d-block">
                        <template v-if="confirmDelete">
                            <div class="pl-0 font-weight-bold">Elimina Brevetto</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold">Modifica Brevetto</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Brevetto</div>
                        </template>
                    </v-row>
                </v-card-title>
                <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                    <v-container class="px-0 justify-center" fluid>
                        
                        <validation-observer ref="patentFields">
                            <v-row no-gutters>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            type="text"
                                            label="Nome"
                                            v-model="model['name']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="name"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <v-subheader class="pl-0">Mansioni fornite</v-subheader>
                                    <v-row class="mb-3" no-gutters>
                                        <v-col cols="3" class="pr-3" v-for="task in tasks" :key="'t_'+task.id">
                                            <v-checkbox
                                                :label="task.name"
                                                v-model="model.tasks"
                                                :value="task.id"
                                                class="ma-auto"
                                                hide-details>                                                
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">                            
                                    <validation-provider name="mesi di validita" rules="required|min_value:1" v-slot="{ errors }">
                                        <v-text-field
                                            label="Mesi di validità"
                                            type="number"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            v-model="model['valid_month']"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <v-checkbox class="mb-3"
                                        label="Notifica responsabili alla scadenza"
                                        v-model="model['notify_expiration_staff']"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <v-subheader class="pl-0">Responsabili</v-subheader>
                                    <v-row class="mb-3" no-gutters>
                                        <v-col cols="3" class="pr-3" v-for="responsible in info.staffpeople" :key="'r_'+responsible.id">
                                        <v-checkbox
                                            :label="responsible.full_name"
                                            v-model="model.responsibles"
                                            :value="responsible.id"
                                            class="ma-auto"
                                            hide-details>                                            
                                        </v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <v-checkbox class="mb-3"
                                        label="Notifica utente alla scadenza"
                                        v-model="model['notify_expiration_person']"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-12 py-0 px-6 pt-3">
                                    <validation-provider name="Giorni di avviso" rules="required|min_value:1" v-slot="{ errors }">
                                        <v-text-field
                                            label="Giorni di avviso"
                                            type="number"
                                            outlined
                                            dense
                                            :error-messages="errors"
                                            v-model="model['notify_expiration_day']"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </validation-observer>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
                    <v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
                    <v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
	name: 'patent-editor',
	props: {
		info: Object,
		tab: {
			type: String,
			default: 'patent'
		}        
	},    
	data() {
		return {
			model: {},
			saved: false,
			dialog: false,
			loading: false,
			dialogShow: false,
			dialogEditor: false,
			confirmDelete: false,
			fab: false,
			mutableTab: this.tab,
			search: '',
			people: [],
			totalItems: 0,
			selected: [],
			headers: [
				{
					'text': 'Cognome e Nome',
					'value': 'full_name',
				},
				{
					'text': 'Data inizio validità',
					'value': 'date',
				},
				{
					'text': 'Data scadenza',
					'value': 'expiration_date',
				},
			],
		}
	},

	created() {
		this.model['minimal_frequency'] = 0
	},

	computed: {

		countSelected() {
			return this.selected.length
		},

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},        

		tasks() {
			return this.info.tasks ? this.info.tasks.filter((task) => {
				return task.virtual == false
			}) : []
		}        
	},

	methods: {
		closeDialog() {
			this.dialogShow = false
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(id) {
			this.axios.get('patents/edit/' + id).then(response => {
                
				this.dialogShow = false
				this.dialogEditor = true
				this.model = {...response.data.data}
				this.loading = false

			}, response => {
				this.loading = false
			})
		}, 

		show(item) {					
			this.dialogShow = true
			this.model = {...item}
			this.getPeople()

		},

		create() {
			this.dialogEditor = true
			this.model.responsibles = []
			this.model.tasks = []
		},

		confirmDestroy() {
			this.dialogShow = true
			this.confirmDelete = true
		},        

		destroy() {
			this.closeDialog()
		},

		save() {
			this.$refs.patentFields.validate().then(result => {
				if (!result) {
					return false
				}            
				this.loading = true
				if(this.model.id) {
					this.axios.put('patents/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'patent')
						this.$eventBusUtility.$emit('refreshInfo', 'courses')

						this.closeDialog()
					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('patents', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'patent')
						this.$eventBusUtility.$emit('refreshInfo', 'courses')
						this.closeDialog()
					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})
		},

		deletePatent() {
			this.loading = true
			this.axios.delete('patents/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'patents')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},

		getPeople() {
			this.loading = true

			let dataObject = {
				columnsFilter: {'patent_id': this.model.id},
			}

			this.axios.get('patentpersons/', { params: dataObject }).then(response => {
				this.people = response.data.data.map((person) => {
					return {...person, 'full_name': person.person.full_name}
				})

				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

	},


	mounted() {
		this.$eventBusUtility.$on('showPatent', (item) => {
			this.show(item)
		}),

		this.$eventBusUtility.$on('editPatent', (item) => {
			this.edit(item.id)
		}),
               
		this.$eventBusUtility.$on('createPatent', () => {
			this.create()
		})
            
		this.$eventBusUtility.$on('destroyPatent', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('showPatent')
		this.$eventBusUtility.$off('editPatent')
		this.$eventBusUtility.$off('createPatent')
		this.$eventBusUtility.$off('destroyPatent')
	},	    
}
</script>