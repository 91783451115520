<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="scroll-x-reverse-transition"
        scrollable
        style="overflow-x: hidden">
        <v-card :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-6">
                <v-row no-gutters>
                    <v-col class="col-12 ma-auto ml-0">
                        <div class="pl-0 font-weight-bold">Condizioni per la Checklist</div>
                        <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ checklisttype.name }}</div>
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
            </v-card-title>

            <v-card-text style="overflow-x: hidden; margin-top: 20px" class="body-page">
                <v-col class="col-12 pa-0 mx-0">
                    <div class="d-flex">
                        <v-spacer></v-spacer>
                        <v-btn fab dark class="mb-n10" style="z-index: 2" small color="primary" @click="openEditor({})">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-container class="px-0 justify-center" fluid>
                    <div v-for="(group, index) in groups" :key="'group_'+index">
                        <v-subheader v-if="group.instrumentarea_id != 'null'">{{ group.checklist_conditions[0].instrument ? group.checklist_conditions[0].instrument.instrumentarea.name : '' }}</v-subheader>
                        <span v-else>ALTRO</span>
                        <v-data-table
                            :items="group.checklist_conditions"
                            :headers="headers"
                            hide-default-footer
                            disable-filtering
                            disable-pagination
                            disable-sort
					        :mobile-breakpoint="0"
                            class="elevation-1">
                            <template v-slot:body="{ items }">
                                <draggable tag="tbody" v-model="group.checklist_conditions" @end="sortGroup(index)">
                                    <tr v-for="item in items" :key="item.id" @click="openEditor(item)" class="cursor-pointer">
                                        <td>{{ item.instrument.name }}</td>
                                        <td>
                                            <v-icon v-if="item.is_boolean" color="success">mdi-check</v-icon>
                                            <span v-else>{{ item.min }} - {{ item.max }}</span>
                                        </td>
                                    </tr>
                                </draggable>
                            </template>
                        </v-data-table>
                    </div>
                </v-container>
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi</span>
                </v-tooltip>
                <v-btn color="success" min-width="100px" height="40" class="ml-4" @click="saveChecklist()">Salva</v-btn>
            </v-card-actions>            

            <v-dialog
                v-model="editorDialog"
                transition="scroll-x-reverse-transition"
                scrollable
                :fullscreen="$vuetify.breakpoint.smAndDown"
                max-width="1000"
                persistent
                style="overflow-x: hidden">
                <v-card class="rounded-lg pa-0 elevation-0">
                    <v-card-title class="pa-3">
                        <v-col class="col-10 ma-auto ml-0 py-1">
                            <div v-if="editingItem.id" class="pl-0 font-weight-bold">Modifica</div>
                            <div v-if="editingItem.id" class="pl-0 font-weight-bold" style="font-size: 24px">{{ editingItem.name }}</div>
                            <div v-else class="pl-0 font-weight-bold" style="font-size: 24px">Nuova condizione</div>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-card-title>   

                    <v-card-text style="overflow-x: hidden; margin-top: 0px" class="body-page pa-0">
                        <checklisttype-condition-fields :model="editingItem"></checklisttype-condition-fields>
                    </v-card-text>

                    <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
                                    <v-icon>mdi-keyboard-backspace</v-icon>
                                </v-btn>
                            </template>
                            <span>Chiudi</span>
                        </v-tooltip>
                        <v-btn v-if="edit" color="error" min-width="100px" height="40" class="ml-4" @click="deleteCondition(editingItem)">Elimina</v-btn>
                        <v-btn color="success" min-width="100px" height="40" class="ml-4" @click="saveCondition()">Salva</v-btn>
                    </v-card-actions>
                </v-card>					
            </v-dialog>
        </v-card>
	</v-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import ChecklisttypeConditionFields from '@/components/modules/checklists/ChecklisttypeConditionFields'

export default {
	name: 'checklist-type-conditions',
	components: {
		draggable,
		ChecklisttypeConditionFields
	},
	data() {
		return {
			headers: [
				{text: 'Strumento', value: 'instrument'},
				{text: 'Condizione', value: 'condition'},
				// {text: '', value: 'actions'},
			],
			checklisttype: {},
			editingItem: {},
			editorDialog: false,
			edit: false,
		}
	},

	props: {
		id: Number,
		dialog: Boolean,
	},

	computed: {
		groups: function() {
			return _.chain(this.checklisttype.checklist_conditions)
				.sortBy('order')
				.groupBy('instrument.instrumentarea_id')
				.map((value, key) => ({ instrumentarea_id: key, checklist_conditions: value }))
				.value()
		}
	},

	mounted() {
		this.axios.get('checklisttypes/'+this.id).then(response => {
			this.checklisttype = response.data.data            
		})
	},

	methods: {
		sortGroup(group_index) {
			this.groups[group_index].checklist_conditions.forEach((item, index) => {
				item.order = index
			})
		},

		sortGroups() {
			this.groups.forEach((group, index) => {
				this.sortGroup(index)
			})
		},

		openEditor(item = {}) {
			this.editorDialog = true
			this.editingItem = {...item}
			this.edit = ! _.isEmpty(item)
		},

		closeDialog() {
			this.$emit('update:dialog', false)
		},

		closeEditor() {
			this.editorDialog = false
			this.editingItem = _.stubObject()
		},

		saveCondition() {
			if(this.edit) {
				const index = this.checklisttype.checklist_conditions.findIndex(o => o.instrument_id == this.editingItem.instrument_id )
				if(typeof index !== 'undefined') {
					this.checklisttype.checklist_conditions.splice(index, 1, this.editingItem)
				}
			} else {
				this.checklisttype.checklist_conditions.push(this.editingItem)
			}
			this.sortGroups()
			this.closeEditor()
		},

		deleteCondition(item) {
			const index = this.checklisttype.checklist_conditions.findIndex(o => o.instrument_id == item.instrument_id )

			this.checklisttype.checklist_conditions.splice(index, 1)
			this.sortGroups()
			this.closeEditor()
		},

		saveChecklist() {
			this.axios.put('checklisttypes/'+this.checklisttype.id, this.checklisttype).then(response => {
				this.closeDialog()
			})
		}
	},
}
</script>