import Vue from 'vue'
import $router from './router'
import $store from '@/store'
import * as Sentry from '@sentry/browser'

export const eventBusUtility = new Vue({
	data() {
		return {
			comparators: {
				eq: (a, b) => a === b,
				gt: (a, b) => a > b,
				date_gt_today: (a, b) => moment().isBefore(a, 'day')
			},
			bg_colors: [
				'primary lighten-1',
				'primary darken-1',
				'teal accent-3',
				'red lighten-1',
				'purple lighten-3',
				'deep-purple lighten-3',
				'indigo lighten-4',
				'blue lighten-4',
				'teal lighten-3'
			],
			asyncDebounce: {}
		}
	},

	computed: {
		strcutshow() {
			if(this.isHydrated) {
				switch (this.$vuetify.breakpoint.name) {
				case 'xs': return 35
				case 'sm': return 48
				case 'md': return 65
				case 'lg': return 100
				case 'xl': return 150
				default:
					return 150
				}
			} else {
				return 150
			}
		},

		truncateString(text, n = 100) {
			return (text, n = 100) => {
				if(typeof text === 'string') {
					if(text.length > n) {
						return text.substring(0, n) + '...'
					}
				}
				return text
			}
		},		
	},

	methods: {

		tasksById(tasks) {
			const initialValue = {}
			return tasks ? tasks.reduce((obj, item) => {
				return {
					...obj,
					[item['id']]: item,
				}
			}, initialValue) : []
		},

		calcOpenNav(vuetify) {
			if($store.getters.getSidebar && (vuetify.breakpoint.xs || vuetify.breakpoint.sm || vuetify.breakpoint.md)) {
				return false
			}
			if($store.getters.getSidebar && vuetify.breakpoint.lgAndUp) {
				return true
			}
			return false
		},		

		asyncSearch(field, search) {
			if(typeof field.async !== 'undefined' && field.async) {
				Vue.set(field, 'searching', true)
				if(this.asyncDebounce[field.key]) {
					clearTimeout(this.asyncDebounce[field.key])
				}

				this.asyncDebounce[field.key] = setTimeout(() => {
					Vue.nextTick(() => {
						this.$eventBusUtility.$emit('asyncSearched', {field, search})
					})
					if(search) {
						let params =  {
							search: search,
						}
						if(field.appends) {
							params.appends = field.appends
						}

						this.axios.get(field.async_url, {params}).then(response => {
							// field.options = response.data.options
							Vue.set(field, 'options', response.data.options)
							Vue.set(field, 'searching', false)
						})
					} else {
						Vue.set(field, 'searching', false)
						field.options = []
					}
				}, 300)
			}
		},

		personStatusColor(type) {
			switch (type) {
			case 'available':
				return 'light-green accent-4'
			case 'vacation':
				return 'amber accent-3'
			case 'ill':
				return 'teal accent-3'
			case 'reserve':
				return 'light-blue accent-3'
			case 'unavailable':
				return 'red accent-3'
			case 'suspended':
				return 'orange accent-3'
			case 'resignation':
				return 'grey accent-3'
			}
		},
		
		calendarType(type) {
			switch(type) {
			case 'standard':
				return 'teal--text text--accent-3'
			case 'availability':
				return 'light-blue--text text--accent-3'
			case '':
				return 'orange--text text--lighten-2'
			}
		},		

		webNotify(notification = {}) {
			let title = 'Yello! - Nuova Notifica'
			let body = (notification.title || '') + ' ' + (notification.subtitle || '') + ' ' + (notification.text || '')
			let options = {
				icon: '/img/cropped-favicon-192x192.png',
				body: body
			}

			// Let's check if the browser supports notifications
			if (!('Notification' in window)) {
				// console.log('This browser does not support desktop notification')
			}
			
			// Let's check whether notification permissions have already been granted
			else if (Notification.permission === 'granted') {
				// If it's okay let's create a notification
				try {
					var browser_notification = new Notification(title, options)
				} catch (e) {
					if (e.name == 'TypeError')
						return false
				}
			}
			
			// Otherwise, we need to ask the user for permission
			else if (Notification.permission !== 'denied') {
				Notification.requestPermission(function (permission) {
					// If the user accepts, let's create a notification
					if (permission === 'granted') {
						try {
							let browser_notification = new Notification(title, options)
						} catch (e) {
							if (e.name == 'TypeError')
								return false
						}
					}
				})
			}

			if(typeof browser_notification !== 'undefined') {
				browser_notification.onclick = () => {
					browser_notification.close()
					window.focus()
					this.goTo($router, notification.modelDef, notification.modelDefId)
				}
			}
			
			// At last, if the user has denied notifications, and you 
			// want to be respectful there is no need to bother them any more.
		},
		
		isNumber(n) {
			return !isNaN(parseFloat(n)) && isFinite(n)
		},

		isJson(str) {
			try {
				JSON.parse(str)
			} catch (e) {
				return false
			}
			return true
		},

		// Calc background color of avatars
		bgColor(id) {
			return this.bg_colors[id % this.bg_colors.length]
		},


		// ROUTER UTILITIES 
		chooseRedirect(router, response, redirect, modelDef) {
			// IF BACK TURN BACK
			if( _.hasIn(redirect, 'back')) {
				router.go(redirect.back)
			} else {
				let params = _.hasIn(redirect, 'params') ? redirect.params : {}
				// TURN BACK IN MODEL SPECIFIED AND EREDITED BY CRUD
				if(redirect && _.hasIn(redirect, 'modelDef')) {
					if(_.hasIn(redirect, 'id')) {
						router.push({ name: redirect.modelDef, params: { id: parseInt(redirect.id), ...params }})
					} else {
						router.push({ name: redirect.modelDef })
					}
				} else {
					// IF IN CREATE
					if(response.data.id) {
						router.push({ name: modelDef, params: { id: parseInt(response.data.id), ...params }})
					}
					// ELSE IN EDIT AND TURN IN SHOW
					else {
						router.push({ name: modelDef})
					}
				}
			}
		},

		goTo(router, name, id, defaultInput = null, params = {}) {
			if(!(router.history.current.name == name && router.history.current.params.id == id)) {
				if(id) {
					router.push({ name: name, params: { id: parseInt(id), defaultInput: defaultInput , ...params}})
				} else {
					router.push({ name: name, params: { defaultInput: defaultInput, ...params }})
				}
			}
		},
		
		navigateTo(name, id = null, defaultInput = null, params = {}) {
			$router.push({name: name}).catch(err => { 
				// Ignore the vuex err regarding  navigating to the page they are already on.
				if (err.name != 'NavigationDuplicated') {
					Sentry.captureException(err)
				}
			})
		},

		getFile(store, url, filename, preview = false) {
			return new Promise((resolve, reject) => {
				url = _.startsWith(url, '/api') ? url : '/api' + url

				fetch(store.getters.baseUrl+url, {
					headers: {
						'Content-Type': 'application/json',
						'X-Requested-With': 'XMLHttpRequest',
						'Authorization': 'Bearer ' + store.getters.token
					}
				})
					.then(r => {
						r.blob()
							.then((blob) => {
								if(preview) {
									resolve(this.previewFile(blob))
								} else {
									this.showFile(blob, filename)

									resolve()
								}
							})
					})
			})
		},   

		previewFile(blob) {
			let newBlob = new Blob([blob], {type: blob.type})
			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			  window.navigator.msSaveOrOpenBlob(newBlob)
			  return
			}
			// For other browsers: 
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob)
			return data
		},

		openFile(store, url, filename, method = 'GET', params = {}) {
			let options = {
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': 'Bearer ' + store.getters.getAuthToken
				},
				method: method
			}
			if(!_.isEmpty(params)){
				options['body'] = JSON.stringify(params)
			}
			fetch(store.getters.getCurrentOrganization.fqdn + '/api/v1'+url, options)
			// fetch(store.getters.baseUrl+'/api'+url, options)
				.then(r => {
					for (let pair of r.headers.entries()) {
						if(pair[0] == 'content-disposition' && pair[1].startsWith('filename=')) {
							filename = pair[1].split('filename=')[1].split(';')[0]
							filename = filename.substr(1).substring(0, filename.length - 2)
							break
						}
					}
					r.blob()
						.then(blob => this.showFile(blob, filename))
				})
		},

		generateZip(store, url, params = {}) {
			this.axios.post(url, params).then(response => {
				let snackmessage = ''
				let snackcolor = ''
				if(response.data.status == 'fail'){
					snackmessage = response.data.message
					snackcolor = 'warning'
				} else{
					snackmessage = 'Generazione archivio avviata'
					snackcolor = 'success'
				}
				store.commit('setSnackbar', {timeout: 6000, text: snackmessage, value: true, color: snackcolor})
			}, response => {
				// error callback
			})
		},
		saveCordovaFile(DataBlob, filename) {
			let folderpath = cordova.file.externalRootDirectory+'Download/'
			window.resolveLocalFileSystemURL(folderpath, function(dir) {
				dir.getFile(filename, {create:true}, function(file) {
					file.createWriter(function(fileWriter) {
						fileWriter.write(DataBlob)
						let finalPath = folderpath+filename
						cordova.plugins.fileOpener2.open(finalPath,
							DataBlob.type,
							{
								error : function(){ },
								success : function(){ }
							})
	
					}, function(){
						alert('Unable to save file in path '+ folderpath)
					})
				})
			})
		},

		showFile(blob, filename){
			if(typeof window.cordova !== 'undefined') {
				var newBlob = new Blob([blob], {type: blob.type})
				this.saveCordovaFile(newBlob, filename)
				return
			}

			let userAgent = window.navigator.userAgent
			if (window.navigator.msSaveOrOpenBlob) { //IE 11+
				window.navigator.msSaveOrOpenBlob(blob, filename)
			} else if (userAgent.match('CriOS')) { //Chrome iOS
				let reader = new FileReader()
				reader.onloadend = function () { window.open(reader.result)}
				reader.readAsDataURL(blob)
			} else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) { //Safari & Opera iOS
				let url = window.URL.createObjectURL(blob)
				window.location.href = url
			} else {
				// otherwise only Chrome works like it should
				// It is necessary to create a new blob object with mime-type explicitly set
				var newBlob = new Blob([blob], {type: blob.type})
				
				// For other browsers: 
				// Create a link pointing to the ObjectURL containing the blob.
				const data = window.URL.createObjectURL(newBlob)
				let link = document.createElement('a')
				link.href = data
				link.setAttribute('target', '_blank')
				link.download=filename
				//   link.style.display = 'none'
				//   document.body.appendChild(link)
				link.click()
				//   document.body.removeChild(link)
				setTimeout(function(){
					// For Firefox it is necessary to delay revoking the ObjectURL
					window.URL.revokeObjectURL(data)
				}, 100)
			}
		},


		scrollTo(field, offset = 60) {
			return this.$vuetify.goTo(field, {
				offset: offset
			})
		},		

		goToNotifications(notification) {
			if(notification.organization_id != $store.getters.getCurrentOrganization.id) {
				let organization = $store.getters.getUserOrganizations.find((organization) => {
					return organization.id === notification.organization_id
				})
				if(organization) {
					$store.commit('SET_CURRENT_ORGANIZATION', organization)
				}
			}
			
			if(notification.data && notification.data.type) {
				switch (notification.data.type) {
				case 'USER_ACCEPTED':
					$router.push({ name: 'board' })
					break
				case 'USER_REJECTED':
					$router.push({ name: 'organizations' })
					break
				case 'NEW_USER_REQUEST':
					$router.push({ name: 'requests', params: { element: parseInt(notification.data.id)}})
					break
				case 'NEW_PRIVATE_POST':
					$router.push({ name: 'board', params: { element: parseInt(notification.data.id), tab: 1}})
					break
				case 'NEW_POST_COMMENT':
					$router.push({ name: 'board', params: { element: parseInt(notification.data.id), tab: notification.data.tab}})
					break
				case 'NEW_POST_REACTION':
					$router.push({ name: 'board', params: { element: parseInt(notification.data.id), tab: notification.data.tab}})
					break
				case 'NEW_USER_AVAILABILITY':
				case 'APPROVED_AVAILABILITY':
				case 'REJECTED_AVAILABILITY':
				case 'AVAILABILITY_CANCELED':
				case 'AVAILABILITY_REMOVED':
				case 'SHIFT_CHANGED':
				case 'SHIFT_REMEMBER':
				case 'NEW_PARTICIPABLE_SHIFT':
					$router.push({ name: 'calendar', params: { element: parseInt(notification.data.id), tab: notification.data.tab, started_at: notification.data.started_at}})
					break
				case 'LESSON_REMEMBER':
					$router.push({ name: 'courses', params: { element: parseInt(notification.data.id), course_id: notification.data.course_id, started_at: notification.data.started_at}})
					break
				case 'CHECKLIST_REMEMBER':
					$router.push({ name: 'compile_checklists'})
					break
				case 'CHECKLIST_NOTE':
				case 'CHECKLIST_EXPIRED':
				case 'CHECKLIST_INVALID':
				case 'CHECKLIST_VALID':
					$router.push({ name: 'checklists', params: { element: parseInt(notification.data.id)}})
					break
				case 'PATENT_EXPIRATION_REMEMBER':
					$router.push({ name: 'profile', params: { element: parseInt(notification.data.id), tab: 'patents'}})
					break
				case 'SUPERVISOR_PATENT_EXPIRATION_REMEMBER':
					$router.push({ name: 'people', params: { element: parseInt(notification.data.id), tab: 'patents'}})
					break
				case 'NEW_SHIFT_REQUEST':
					$router.push({ name: 'availability-request', params: { id: parseInt(notification.data.id)}})
					break
				case 'COURSE_SUBSCRIBED':
				case 'COURSE_UNSUBSCRIBED':
				case 'REQUEST_PARTECIPANT_RESPONSE':
					$router.push({ name: 'courses', params: { course_id: parseInt(notification.data.id)}})
					break
				case 'NEW_PARTECIPANT_REQUEST':
					$router.push({ name: 'courses', params: { course_id: parseInt(notification.data.id), subtab: 'participants'}})
					break
				default:
					break
				}
			}

		},

		changeNotificationStatus(notifications, status) {
			
			let notification_ids = []
			_.forEach(notifications, (value) => {
				notification_ids.push(value.id)
			})
			this.axios.put('notifications/', {status: status, notifications: notification_ids}).then(response => {
				// get body data
				_.forEach(notifications, (notification) => {
					if(status === 'read') {
						notification.read_at = new Date()
					} else if(status === 'unread') {
						notification.read_at = null
					}
				})
				this.$eventBusUtility.$emit('updateNotifications', true)
				this.$eventBusUtility.$emit('updateNotificationsCheckbox', true)

			}, response => {
				// error callback
			})
		},

		toggleFullScreen() {
			let doc = window.document
			let docEl = doc.documentElement

			let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
			let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

			if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
				requestFullScreen.call(docEl)
			}
			else {
			  cancelFullScreen.call(doc)
			}
		},

		getCSV(selected, modelDef) {
			this.axios.post(modelDef + '-csv', { selected: selected }).then(response => {
				
			}, response => {
				// error callback
			})
		},

		exportCSV(theaders, selected, title) {
			let csv = []
			let headers =[]
			let row = []
			// Take headers
			_.forEach(theaders, (header) => {
				headers.push(header.text)
			})
			// Write first row of headers
			csv.push(headers.join(';'))
			// Take all tr -> forEach tr take cell -> forEach cell check if is checked
			let rows = _.each(selected, (obj) => {
				row = []
				_.forEach(theaders, (header) => {
					let val = obj[header.text_value ? header.text_value : header.value]
					if(!isNaN(val) && val != undefined)
						val = val.toString().replace(/[.]/, ',')

					row.push(val)
				})
				csv.push(row.join(';'))
			})
			this.downloadCSV(csv.join('\n'), title)
		},

		downloadCSV(csv, filename) {
			let csvFile
			let downloadLink

			// CSV file
			csvFile = new Blob([csv], {type: 'text/csv'})
			// Download link
			downloadLink = document.createElement('a')
			// File name
			downloadLink.download = filename + '.csv'
			// Create a link to the file
			downloadLink.href = window.URL.createObjectURL(csvFile)
			// Hide download link
			downloadLink.style.display = 'none'
			// Add the link to DOM
			document.body.appendChild(downloadLink)
			// Click download link
			downloadLink.click()
			document.body.removeChild(downloadLink)
		},


		formatAddress(model, suffix = '') {
			if(model) {
				let address = ''
				suffix = suffix == '' ? suffix : suffix + '_' 
				if(model[suffix + 'address_street']) {
					address += model[suffix + 'address_street']
				}
				if(model[suffix + 'address_number']) {
					if(model[suffix + 'address_street']) {
						address += ', '
					}
					address += model[suffix + 'address_number']
				} else {
					//address += ','
				}
				if(model[suffix + 'address_zipcode']) {
					address += ' ' + model[suffix + 'address_zipcode']
				}
				if(model[suffix + 'address_city']) {
					address += ' ' + model[suffix + 'address_city']
				}
				if(model[suffix + 'address_province']) {
					address += ' ' + model[suffix + 'address_province']
				}
				
				return address
			}
		},

		formatDate(date){
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},

		formatMonth(date){
			if (!date) return null

			const [year, month] = date.split('-')
			return `${month}/${year}`
		},

		appendFormdata(FormData, data, name){
			name = name || ''
			if (typeof data === 'object' && !(data instanceof File)){
				_.forEach(data, (value, index) => {
					if (name == ''){
						this.appendFormdata(FormData, this.tryParseBoolean(value), index)
					} else {
						this.appendFormdata(FormData, this.tryParseBoolean(value), name + '['+index+']')
					}
				})
			} else {
				FormData.append(name, this.tryParseBoolean(data))
			}
		},

		tryParseBoolean(value) {
			if (value === true) {
				return 1
			}
			if (value === false) {
				return 0
			}
			return value
		},

		goToLink(url) {
			window.open(url, '_blank')
		},

		// custom filter function for FieldsGenerator autocomplete
		customFilter(item, queryText, itemText, searchKeys) {
			let result = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
			if(!searchKeys || result) {
				return result
			}
			
			_.forEach(searchKeys, (searchKey, k) => {
				if(item[searchKey] != null) {
					//result = item[searchKey].toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
					if($store.getters.checkSettings('product_code_search') && searchKey == 'code') {
						result = item[searchKey].toLocaleLowerCase() == queryText.toLocaleLowerCase()
					} else {
						result = item[searchKey].toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
					}
					return !result
				}
			})
			return result
		},


		setSnackbar(response, store) {
			let snackmessage = ''
			let	snackcolor = ''
			if(response.data.status == 'message'){
				snackmessage = response.data.message
				snackcolor = 'warning'
			} else {
				snackmessage = 'Elementi eliminati con successo.'
				snackcolor = 'success'
			}
			store.commit('setSnackbar', {timeout: 6000, text: snackmessage, value: true, color: snackcolor})
		},

		lightOrDark(color) {

			// Variables for red, green, blue values
			let r, g, b, hsp
			
			// Check the format of the color, HEX or RGB?
			if (color.match(/^rgb/)) {
		
				// If HEX --> store the red, green, blue values in separate variables
				color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
				
				r = color[1]
				g = color[2]
				b = color[3]
			} 
			else {
				
				// If RGB --> Convert it to HEX: http://gist.github.com/983661
				color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
		
				r = color >> 16
				g = color >> 8 & 255
				b = color & 255
			}
			
			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
		
			// Using the HSP value, determine whether the color is light or dark
			//if (hsp>127.5) {
			if (hsp>180) {
				return 'light'
			} else {
		
				return 'dark'
			}
		}
	}
})
