<template>
    <v-col class="col-12 pa-0 mx-0">
        <v-subheader>Impostazioni Timbrature</v-subheader>

        <v-card class="rounded-lg pa-0">
            <v-card-text>
                <validation-observer ref="fieldsSettings">
                    <v-row no-gutters>
                        <v-col class="col-12 py-0 px-lg-2 pl-xs-0">
                            <v-subheader>Minimo intervallo tra due timbrature in minuti</v-subheader>
                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                            <validation-provider name="Minimo intervallo timbrature" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    type="number"
                                    label="Minuti"
                                    v-model="settings['minimum_attendance_interval']"
                                    :error-messages="errors"
                                    outlined
                                    dense
                                    ref="minimum_attendance_interval"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
            
                        <v-col class="col-12 col-sm-12 py-0 px-lg-4 px-xs-0 px-sm-2">
                            <v-checkbox
                                label="Valida la disponibilità"
                                v-model="settings['valid_availability']"
                                color="primary"
                                :input-value="[true, false]"
                                ref="valid_availability"
                            ></v-checkbox>
                        </v-col>
		
                        <v-fade-transition>
                            <div v-show="settings['valid_availability']">
                                <v-col class="col-12 py-0 px-lg-2 pl-xs-0">
                                    <v-subheader>Intervallo massimo in minuti entro cui considerare una timbratura legata a un turno</v-subheader>
                                </v-col>                        
                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-lg-4 px-xs-0 px-sm-2 pt-4">
                                    <validation-provider name="Massimo giorni disponibilità" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            type="number"
                                            label="Minuti"
                                            v-model="settings['maximum_subscription_availability']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            ref="maximum_attendance_interval"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </div>
                        </v-fade-transition>

                    </v-row>
                </validation-observer>
            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center">
                <v-spacer></v-spacer>
                <v-btn color="success" min-width="100px" height="40" class="ml-4" @click="saveSettings()" :loading="loading">Salva</v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
</template>

<script>

import SettingsOptionsMixin from '@/mixins/SettingsOptionsMixin'

export default {
	name: 'attendance-general-settings',
	mixins: [SettingsOptionsMixin],
	data() {
		return {
			items: [],
			model: 'attendance',
			settings: {},
			loading: false
		}
	},

	methods: {

	},

	created() {
	},

	mounted() {
	},

	beforeDestroy() {
	},       
}
</script>

<style>

</style>