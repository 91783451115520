<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Sanzioni"
				:totalItems="totalItems"
				icon="mdi-book-check-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>


			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">

				<v-card :elevation="1" class="background" width="100%">

					<v-data-table class="background-2"
						:options.sync="pagination"
						:headers="headers"
						:items="violations"
						:server-items-length="totalItems"
						:loading="loading"
						:search="search"
						v-model="selected" 
						:hide-default-footer="!$vuetify.breakpoint.mdAndUp"
						show-select
						:mobile-breakpoint="0"
						ref="vDataTable"
						:footer-props="{
							'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
							showFirstLastPage: true
						}">
						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
						</template>

						<template v-slot:body.prepend>
							<tr>
								<td>
									<v-icon >mdi-magnify</v-icon>
								</td>
								<td>
									<v-tooltip top v-if="!isEmptyColumnsFilter">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
												<v-icon>mdi-progress-close</v-icon>
											</v-btn>
										</template>
										<span>Svuota Filtri</span>
									</v-tooltip>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Numero"
										hide-details
										v-model="columnsFilter['number']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-select
										:items="infoData.sections"
										v-model="columnsFilter['section_id']"
										placeholder="Sezione"
										item-value="id"
										item-text="name"
										hide-details
										clearable
										dense
										solo
										flat									
									></v-select>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Descrizione"
										hide-details
										v-model="columnsFilter['description']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Norme Violate"
										hide-details
										v-model="columnsFilter['broken_rules']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Sanzioni Previste"
										hide-details
										v-model="columnsFilter['penalties_provided']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Sanzione Ridotta"
										hide-details
										v-model="columnsFilter['reduced_penalty']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Note"
										hide-details
										v-model="columnsFilter['note']"
									></v-text-field>
								</td>
							</tr>                                        
						</template>

						<template v-slot:item="{item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
								</td>
								<td style="white-space: nowrap">
									<actions-menu
										:item="item"
										:actions="actions"
									></actions-menu>
								</td>								
								<td @click="show(item)">
									{{ item.number }}
								</td>
								<td @click="show(item)" style="max-width: 50px">
									{{ item.section.name }}
								</td>
								<td @click="show(item)" style="min-width: 250px">
									{{ item.description }}
								</td>
								<td @click="show(item)">
									{{ item.broken_rules }}
								</td>
								<td @click="show(item)">
									{{ item.penalties_provided }}
								</td>
								<td @click="show(item)">
									{{ item.reduced_penalty | currency }}
								</td>
								<td @click="show(item)" style="min-width: 250px">
									{{ item.note }}
								</td>
							</tr>
						</template>

						<template v-slot:footer.page-text="{ pageStart, pageStop }">
							<div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
							Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
						</template>

						<template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
							<div class="text-center d-flex background pa-4">
								<v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
									<v-icon>mdi-chevron-left</v-icon>
								</v-btn>
								<v-text-field
									hide-details
									rounded
									solo
									dense
									prefix="#"
									label="Pagina"
									type="number"
									:min="1"
									:max="props.pagination.pageCount"
									v-model="newPageToJump">
									<template v-slot:append-outer>
										<v-btn dark color="primary" @click="jumpToPage()">
											Vai
										</v-btn>						
									</template>
								</v-text-field>
								<v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
									<v-icon>mdi-chevron-right</v-icon>
								</v-btn>					
							</div>
							<div class="text-center caption pr-4">
								<div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
								Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
							</div>

						</template>
					</v-data-table>
					

					<v-speed-dial
						:class="{'mb-16': $vuetify.breakpoint.smAndDown}"
						fixed
						v-model="fab"
						bottom
						right
						:loading="loading"
						color="primary"
						direction="left"
						transition="slide-x-transition">
						<template v-slot:activator>
							<v-btn
								v-model="fab"
								color="primary"
								dark
								fab>
								<v-icon v-if="fab">mdi-close</v-icon>
								<v-icon v-else>mdi-book-check-outline</v-icon>
							</v-btn>
						</template>              
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									@click="create()"
									color="indigo">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							<span>Nuovo</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">                             
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									color="green"
									:depressed="disabled"
									:disabled="disabled"
									@click.native.stop="$eventBusUtility.getCSV(selectedIds, modelDef)">
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</template>
							<span>Esporta CSV</span>   
						</v-tooltip>
					</v-speed-dial>
				
				</v-card>

				<footer-logo></footer-logo>


			</v-container>

			<violation-crud
				:info="infoData"
				:selected-ids="selectedIds"
			></violation-crud>

		</v-col>
	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import DataTableMixin from '@/mixins/DataTableMixin'
import ViolationCrud from '@/components/modules/handbook/ViolationCrud'

export default {
	name: 'violations-crud',
	components: {
		HeaderCrud,
		ViolationCrud,
	},
	mixins: [DataTableMixin],
	props: {
	},

	data() {
		return {
			disabled: true,
			fab: false,
			infoData: {},
			selected: [],
			search: '',
			totalItems: 0,
			violations: [],
			loading: false,
			pagination: {
				'sortBy': ['number'],
				'sortDesc': [false],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
			apiTimeout: null,
			headers: [
				{
					'text': 'Azioni',
					'value': 'actions',
					'sortable': false
				},
				{
					'text': '#',
					'value': 'number'
				},
				{
					'text': 'Sezione',
					'value': 'section',
				},
				{
					'text': 'Descrizione',
					'value': 'description',
				},
				{
					'text': 'Norma Violata',
					'value': 'broken_rules',
				},
				{
					'text': 'Sanzioni Previste',
					'value': 'penalties_provided',
				},
				{
					'text': 'Sanzione Ridotta',
					'value': 'reduced_penalty',
				},
				{
					'text': 'Note',
					'value': 'note'
				},
			],
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editViolation'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyViolation',
				},
			]
		}
	},


	methods: {

		getData() {
			if(this.apiTimeout) {
				clearTimeout(this.apiTimeout)
			}

			this.apiTimeout = setTimeout(() => {

				this.loading = true
					
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					filter: this.search,
				}

				this.axios.get('violations/', {params: dataObject}).then(response => {
					this.loading = false
					this.violations = response.data.data
					this.totalItems = response.data.meta.total

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)
		},

		getInfo() {
			return this.axios.get('violations-info').then(response => {
				// get info data
				this.infoData = response.data.info
				this.$store.commit('END_LOADING')

			}, response => {
				// error callback
				this.loading = false
			})
		},

		show(item) {
			this.$eventBusUtility.$emit('showViolation', item)
		},

		create() {
			this.$eventBusUtility.$emit('createViolation')
		},


	},

	created() {
		this.getInfo().then(() => {
			this.getData()
		}) 	
	},

	mounted() {
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.getData()
		})
		this.$eventBusUtility.$on('refreshInfo', (modelDef) => {
			this.getInfo()
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
		this.$eventBusUtility.$off('refreshInfo')
	},
}

</script>