<template>
    <validation-observer ref="fields">

        <v-row class="pb-2 mx-0 pt-4">
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                <validation-provider name="Codice" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Codice"
                        v-model="model['code']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="code"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                <validation-provider name="Targa" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Targa*"
                        v-model="model['license_plate']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="license_plate"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-autocomplete class="font-weight-regular"
                    label="Tipo Veicolo"
                    v-model="model['vehicletype_id']"
                    item-value="id"
                    item-text="name"
                    clearable
                    small-chips
                    :items="info.vehicletypes"
                    dense
                    outlined>
                </v-autocomplete>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <v-autocomplete class="font-weight-regular"
                    label="Tipo Checklist"
                    v-model="model['checklisttype_id']"
                    item-value="id"
                    item-text="name"
                    clearable
                    small-chips
                    :items="info.checklisttypes"
                    dense
                    outlined>
                </v-autocomplete>
            </v-col>

            <v-col class="col-12 py-0 pt-3 px-6">
                <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                    <v-textarea
                        label="Descrizione"
                        name="description"
                        v-model="model['description']"
                        :error-messages="errors"
                        type="text"
                        outlined
                        dense
                        counter
                        ref="description"
                    ></v-textarea>
                </validation-provider>
            </v-col>
            
            <v-col class="col-12 py-0 px-6" v-if="model.id">
                <validation-observer ref="compartments">
                    <v-subheader>Scomparti
                        <v-spacer></v-spacer>
                        <v-btn fab dark class="mb-n8" style="z-index: 2" small color="primary" @click="create()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-subheader>
                    <template v-for="(compartment, c) in model.compartments">
                        <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" style="overflow: hidden;" :key="c">
                            <div class="pa-2 d-inline-flex" style="width: 100%">
                                <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                                    <div>
                                        <validation-provider name="Nome" rules="required|max:255" v-slot="{ errors }">
                                            <v-text-field
                                                type="text"
                                                label="Nome"
                                                v-model="compartment.name"
                                                :error-messages="errors"
                                                outlined
                                                dense
                                                ref="name"
                                                                        class="pt-4"
                                            ></v-text-field>
                                        </validation-provider>
                                        <v-combobox
                                            v-model="compartment.instruments"
                                            :items="info.instruments"
                                            item-value="id"
                                            item-text="name"
                                            chips
                                            clearable
                                            hide-details
                                            flat
                                                                label="Inserisci Strumenti"
                                            multiple
                                            prepend-icon="mdi-toolbox-outline"
                                            solo>
                                            <template v-slot:selection="{ attrs, item, select, selected }">
                                                <v-chip
                                                    v-bind="attrs"
                                                    :input-value="selected"
                                                    close
                                                    @click="select"
                                                    @click:close="removeChip(item, c)">
                                                    <strong>{{ item.name }}</strong>&nbsp;
                                                </v-chip>
                                            </template>
                                        </v-combobox>
                                        </div>
                                    </div>
                                    <div class="ma-auto">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn v-on="{ ...tooltip }" icon @click="removeCompartment(c)">
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Elimina</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                        </v-card>
                    </template>
                </validation-observer>
            </v-col>
      
        </v-row>
        
    </validation-observer>    
</template>

<script>
import Vue from 'vue'

export default {
	name: 'vehicle-fields',
	props: {
		model: Object,
		info: Object,
	},
        
	data() {
		return {
			dialog: false,
			dialogType: null,
			compartmentActions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editCompartment'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyCompartment'
				},
			],            
		}
	},

	methods: {
		create() {
			this.$refs.compartments.validate().then(result => {
				if (!result) {
					return false
				}
				this.model.compartments.push({})

			}).catch(() => {

			})            
		},

		removeChip(item, c) {
			const index = this.model.compartments[c].instruments.map((obj) => {return obj.id}).indexOf(item.id)
			if (index >= 0) this.model.compartments[c].instruments.splice(index, 1)
		},

		removeCompartment(c) {
			this.model.compartments.splice(c, 1)
		},        
	}

}
</script>

<style>

</style>