<template>
    <v-dialog v-model="open" max-width="800" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card class="rounded-lg pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">{{ title }}</div>                
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="px-0 pt-6 mx-0">
                    <v-row v-if="confirmDelete" class="mx-0 justify-center py-6">
                        <div class="caption" >Sei sicuro di voler eliminare questa Disponibilità?</div>

                        <v-col class="col-12 py-0 pt-3 px-6">
                            <v-btn color="error" outlined block height="40" @click="deleteAvailability()">Rimuovi disponibilità</v-btn>
                        </v-col>                        
                    </v-row>

                    <validation-observer ref="userAvailabilityFields" v-else-if="can">

                        <v-row class="pb-2 mx-0 pt-2">
                            <v-col class="col-12 py-0 pt-3 px-6">
                                <validation-provider name="Note" rules="max:255" v-slot="{ errors }">
                                    <v-textarea
                                        label="Note"
                                        name="note"
                                        v-model="model['note']"
                                        :error-messages="errors"
                                        type="text"
                                        outlined
                                        dense
                                        counter
                                        ref="note"
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>

                            <template v-if="$store.getters.checkSetting('calendar', 'overwrite_availability_schedule')">

                                <v-col class="col-12 py-0 pa-0 pl-2">
                                    <v-subheader>Sovrascrivi Orario</v-subheader>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Ora di Inizio" rules="is_time" v-slot="{ errors }">
                                        <v-text-field
                                            label="Ora di Inizio"
                                            v-model="model['started_at']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            v-mask="'##:##'"
                                            return-masked-value						
                                            ref="schedule"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Ora di Inizio" rules="is_time" v-slot="{ errors }">
                                        <v-text-field
                                            label="Ora di Fine"
                                            v-model="model['ended_at']"
                                            :error-messages="errors"
                                            outlined
                                            dense
                                            v-mask="'##:##'"
                                            return-masked-value						
                                            ref="schedule"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </template>

                            <v-col class="col-12 py-0 px-6 pt-3" v-if="typeof tasks !== 'undefined' && tasks.length > 0">
                                <v-btn block outlined :color="task.color" height="40" class="mb-3" v-for="(task, t) in tasks" :key="t" @click="selectTask(task)" :disable="loading">
                                    {{ task.name }}
                                    <v-icon right dark>
                                        {{ task.icon }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </validation-observer>

                    <v-row class="pb-2 mx-0 pt-2" v-else>
                        <v-col class="col-12 py-0 px-6 pt-3" v-html="message">
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
	name: 'user-availability-dialog',
	props: {
	},    
	data() {
		return {
			model: {},
			existingAvailability: {},
			open: false,
			fullScreen: false,
			loading: false,
			confirmDelete: false,
			message: '',
			tasks: [],
			can: false,
			shift: {}
		}
	},

	computed: {
		title () {
			if(this.confirmDelete) {
				return 'Elimina Disponibilità'
			} else if(this.model.id) {
				return 'Modifica Disponibilità'
			} else {
				return 'Aggiungi Disponibilità'
			}
		}
	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('manageUserAvailability', (shift) => {
			this.openCreateDialog(shift)
		})
	},

	methods: {
    
		openCreateDialog(shift) {
			this.shift = shift
			// this.existingAvailability = shift.availables.find(availability => availability.person_id === this.$store.getters.getUserPerson.id)

			// if(this.existingAvailability) {
			//     this.confirmDelete = true
			//     this.open = true
			// } else {
			this.axios.get('shifts/'+shift.id+'/my-tasks').then(response => {
				this.can = response.data.can
				if(response.data.can) {
					if(typeof response.data.existing !== 'undefined'){
						this.existingAvailability = response.data.existing
						this.confirmDelete = true
						this.open = true
					} else {
						this.tasks = response.data.tasks
					}
				} else {
					this.message = response.data.message
				}
				this.open = true
			})
			// }
		},

		closeDialog() {
			this.model = _.stubObject()
			this.shift = _.stubObject()
			this.can = false
			this.tasks = []
			this.message = ''
			this.open = false
			this.confirmDelete = false
		},

		selectTask(task) {
			this.loading = true
			this.$set(this.model, 'task_id', task.id)
			this.$refs.userAvailabilityFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.axios.post('availabilities/', {...this.model, 'shift_id': this.shift.id, type: 'available'}).then(response => {
					this.loading = false
					this.closeDialog()

				}, response => {

				})
                
			}).catch(() => {

			})
		},

		deleteAvailability() {
			this.loading = true
			this.axios.delete('/availabilities/' + this.existingAvailability.id).then(response => {
				this.loading = false
				this.closeDialog()

			}, response => {
				this.loading = false
			})
		}        

	},

	beforeDestroy() {
		this.$eventBusUtility.$off('manageUserAvailability')
	},

}
</script>
