

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import ChecklistsIndex from '@/components/modules/checklists/ChecklistsIndex'
import ChecklisttypeConditions from '@/components/modules/checklists/ChecklisttypeConditions'
import ChecklistEditor from '@/components/modules/checklists/ChecklistEditor'

export const checklists = [
	{
		path: '/checklists',
		name: 'checklists',
		component: ChecklistsIndex,
		beforeEnter: ifAuthenticated,
		props: true,
	},
	{
		path: '/compile_checklists',
		name: 'compile_checklists',
		component: ChecklistEditor,
		beforeEnter: ifAuthenticated
	},

	{
		path: '/checklist_type/:id/conditions',
		name: 'checklist_type_conditions',
		component: ChecklisttypeConditions,
		beforeEnter: ifAuthenticated,
		props: (route) => ({
			id: parseInt(route.params.id)
		}),
	},
]