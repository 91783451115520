<template>
    <div class="text-center pa-8 pb-offset" style="opacity: 0.7">
        <img src="img/volontapp-login.png" style="width: 250px" alt="VolontApp" class="pb-4">
    </div>
</template>

<script>
export default {
	name: 'footer-logo'
}
</script>

<style>

</style>