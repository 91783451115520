<template>
	<v-row no-gutters class="fill-height">
		<v-container fluid class="pa-6 body-page mt-4">

            <v-col class="d-flex justify-center">
                <v-alert outlined type="warning" prominent border="left" v-if="$store.getters.isSuperuser">
                    Il modulo <strong>{{ module | trans }}</strong> non è attivo. Per maggiori informazioni scrivici a <a href="mailto:supporto@volontapp.it">supporto@volontapp.it</a> per attivare la prova gratuita!
                </v-alert>
                <v-alert outlined type="warning" prominent border="left" v-else>
                    Il modulo <strong>{{ module | trans }}</strong> non è stato attivato dalla tua associazione. Per maggiori informazioni contatta l'amministratore della tua associazione!
                </v-alert>
            </v-col>

            <footer-logo></footer-logo>
        </v-container>

    </v-row>

</template>

<script>
export default {
	name: 'module-disabled',
	props: {
		module: {
			type: String,
			default: '',
		}
	},
}
</script>

<style>

</style>