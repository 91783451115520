export default {
	data() {
		return {
			from: null,
			to: null,
			dayWidth: 0,
			today: this.started_at ? moment(this.started_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			timeout: null,
			loading: false,
			events: {},
		}   
	},

	computed: {   
		daysToShow() {
			let width = this.$vuetify.breakpoint.width
			let days = 0

			if(this.type == 'day') {
				return 1
			}
			if(this.daysToShowPrint) {
				return 5
			}

			if (width > 0) {
				if (width < 600)
					days = 1
				else if (width < 1080)
					days = 3
				else if (width < 1250)
					days = 5
				else if (width < 1500)
					days = 6
				else
					days = 7
			}

			return days
		},

		formatCurrentDate() {
			let month = moment(this.today).format('ddd, D MMM')
			return month.charAt(0).toUpperCase() + month.slice(1)
		},

		printDateDay(n) {
			return (n) => {
				return moment(this.today).add(n, 'd').format('ddd, D MMM')
			}
		},

		setColorTypeDay(d) {
			return (d) => {
				let color = ''
				if(this.events[d]) {
					switch(this.events[d].day_type) {
					case 'holiday':
						color = 'red--text'
						break
					case 'preholiday':
						color = 'orange--text'
						break
					}
				}
				return color
			}
		},
		
		formatDateColumn(n) {
			return (n) => {
				return moment(n).format('ddd, D MMM')
			}
		},
	},

	watch: {
		'$vuetify.breakpoint.width': {
			handler() {
				this.calcDayWidth()
			}
		},

		daysToShow: {
			handler() {
				if(this.daysToShowPrint) {
				} else {
					this.getData()
				}
			}
		},

		type: {
			handler() {
				this.getData()
			}
		},

		today: {
			handler() {
				this.getData()
			}
		},
	},

	methods: {
		prev() {
			if(this.type == 'month') {
			    this.$refs.calendar.prev()
			} else {
				this.today = moment(this.today).subtract(this.daysToShow, 'd').format('YYYY-MM-DD HH:mm')
			}
		},

		next() {
			if(this.type == 'month') {
			    this.$refs.calendar.next()
			} else {
				this.today = moment(this.today).add(this.daysToShow, 'd').format('YYYY-MM-DD HH:mm')
			}
		},

		calcDayWidth() {
			this.dayWidth = (this.$refs.calendarDays ? this.$refs.calendarDays.clientWidth : 0) / this.daysToShow
		},      
	}
}