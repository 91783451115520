<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				:title="title"
				:totalItems="totalItems"
				icon="mdi-certificate-outline">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" :style="{'margin-top': onlyShow ? '170px' : '100px'}" style="height: 100%">
				<v-data-iterator
					:items="patentpersons"
					:search="search"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="100" style="overflow: hidden;" :class="item.id == element ? 'activeNotification' : ''" :key="o">
								<div class="pa-2 d-inline-flex" style="width: 100%">

									<!-- <div style="width: 50px; vertical-align: middle; margin: auto;">
										<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
											<v-icon></v-icon>
										</v-avatar>
									</div> -->


									<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
										<div>
											<div class="body-1">{{ item.patent.name }}</div>
											<div class="caption">Data inizio validità {{ moment(item.date).format('D/MM/Y')}}</div>
											<div class="caption">Data scadenza {{ moment(item.expiration_date).format('D/MM/Y')}}</div>
                                            <v-tooltip top v-for="(task, t) in item.patent.tasks" :key="item.id + '_' + t">
                                                <template v-slot:activator="{ on: tooltip }">
                                                    <v-icon v-on="{ ...tooltip }" :color="tasksById[task.id].color" :size="18" class="pr-1">
                                                        {{ tasksById[task.id].icon }}
                                                    </v-icon>
                                                </template>
                                                <span>{{ tasksById[task.id].name ? tasksById[task.id].name : '' }}</span>
                                            </v-tooltip>
                                        </div>
									</div>
									<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto" v-if="!onlyShow">
										<actions-menu
											:item="item"
											:actions="actions"
										></actions-menu>
									</div>

								</div>
							</v-card>
						</template>

					</template>
				</v-data-iterator>

			
				<v-speed-dial v-if="!onlyShow"
					class="mb-24"
					fixed
					v-model="fab"
					bottom
					right
					:loading="loading"
					color="primary"
					direction="left"
					transition="slide-x-transition">
					<template v-slot:activator>
						<v-btn
							v-model="fab"
							color="primary"
							dark
							fab>
							<v-icon v-if="fab">mdi-close</v-icon>
							<v-icon v-else>mdi-certificate-outline</v-icon>
						</v-btn>
					</template>              
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">
							<v-btn
								v-on="{ ...tooltip }"
								fab
								dark
								small
								@click.native.stop="create()"
								color="indigo">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Nuovo</span>
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on: tooltip }">                             
							<v-btn
								v-on="{ ...tooltip }"
								fab
								dark
								small
								color="green"
								@click.native.stop="$eventBusUtility.openFile($store, '/patentperson-bypeople-csv/', 'Brevetti ' + person.full_name + '.csv', 'POST', {'person_id': [person.id]})">
								<v-icon>mdi-download</v-icon>
							</v-btn>
						</template>
						<span>Esporta CSV</span>   
					</v-tooltip>
				</v-speed-dial>

				<footer-logo></footer-logo>

			</v-container>
			
            <v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters class="d-block">
							<template v-if="confirmDelete">
								<div class="pl-0 font-weight-bold">Rimuovi brevetto di {{person.full_name}}</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.patent.name }}</div>
							</template>
							<template v-else-if="model.id">
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Modifica brevetto di {{person.full_name}}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Aggiungi brevetto a {{person.full_name}}</div>
							</template>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
						<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                            <validation-observer ref="patentPersonFields">
                                <v-row class="pb-2 mx-0 pt-4">
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                        <v-autocomplete class="font-weight-regular"
                                            label="Brevetto"
                                            v-model="model['patent_id']"
                                            item-value="id"
                                            item-text="name"
                                            clearable
                                            small-chips
                                            :items="info.patents"
                                            dense
                                            outlined>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                        <v-menu
                                            ref="date"
                                            :close-on-content-click="false"
                                            v-model="datepicker_menu['date']"
                                            :return-value.sync="model['date']"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                            :nudge-right="40"
                                            style="width: 100%">
                                            <template v-slot:activator="{ on }">
                                                <validation-provider name="Data Assegnazione" rules="required" v-slot="{ errors }">
                                                    <v-text-field
                                                        v-on="on"
                                                        label="Data Assegnazione*"
                                                        :error-messages="errors"
                                                        append-icon="event"
                                                        readonly
                                                        :value="$eventBusUtility.formatDate(model['date'])"
                                                        outlined
                                                        @click:clear="model['date'] = ''"
                                                        dense
                                                    ></v-text-field>
                                                </validation-provider>
                                            </template>
                                            <v-date-picker 
                                                v-model="model['date']"
                                                color="primary"
                                                locale="It"
                                                scrollable
                                                :first-day-of-week="1"
                                                @input="$refs['date'].save(model['date'])">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </validation-observer>

						</v-container>

						<v-container v-else>
							<v-row class="mx-0 justify-center pt-6">
								<div class="caption">Sei sicuro di voler rimuove il brevetto a questa Persona?</div>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
						<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
						<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

		</v-col>
  	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'

export default {
	name: 'patent-person',
	components: {
		HeaderCrud,
	},
	props: {
		title: {
			type: String,
			default: 'Brevetti',
		},
		person: Object,
		onlyShow: {
			type: Boolean,
			default: false,
		},
		element: Number,
	},
	data() {
		return {
			fab: null,
			editorDialog: false,
			model: {},
			info: {},
			patentpersons: [],
			search: '',
			totalItems: 0,
			confirmDelete: false,
			datepicker_menu: {},
			dialog: false,
			loading: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editPersonPatent'
				},				
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyPersonPatent'
				},
			],
		}
	},

	watch: {},


	created() {
		this.axios.get('patentperson-info').then(response => {
			this.info = response.data.info
			this.loading = false
			this.getData()

		}, response => {
			this.loading = false
		})        
	},

	computed: {

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},
	},


	mounted() {
		this.$eventBusUtility.$on('editPersonPatent', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyPersonPatent', (item) => {
			this.confirmDestroy(item)
		})
	},


	methods: {
		
		create() {
			this.editorDialog = true
		},

		save() {
			this.$refs.patentPersonFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if (this.model.id) {
					this.axios.put('patentpersons/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				} else {
					this.model.person_id = this.person.id
					this.axios.post('patentpersons', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},

		edit(item) {
			this.editorDialog = true
			this.dialog = false
			this.model = {...item}
			this.loading = false
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		destroy() {
			this.loading = true
			this.axios.delete('/patentpersons/' +this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},		

		closeEditor() {
			if(this.$refs.patentPersonFields)
				this.$refs.patentPersonFields.reset()
			this.dialog = false
			this.editorDialog = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},		

		getData() {
			this.loading = true
			let dataObject = {
				columnsFilter: {'person_id': this.person.id},
			}

			this.axios.get('patentpersons/', { params: dataObject }).then(response => {
				this.patentpersons = response.data.data
				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('destroyPersonPatent')
		this.$eventBusUtility.$off('editPersonPatent')
	},
}
</script>