<template>
	<v-row no-gutters>
		<v-dialog 
			v-model="open"
			max-width="900px"
            hide-overlay
            transition="scroll-x-reverse-transition"
            
            style="overflow-x: hidden"
			:fullscreen="$vuetify.breakpoint.xsOnly">
            <v-card class="rounded-lg pa-0 elevation-0">
                <v-card-title class="pa-6">
                    <v-row no-gutters>
                        <v-col class="col-12">
                            <div class="pl-0 font-weight-bold" style="font-size: 30px">Registrazione</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 18px">Inserisci i dati richiesti per effettuare la registrazione</div>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text style="overflow-x: hidden; margin-top: 0px" class="body-page pa-0">
					<v-container class="px-0 justify-center" fluid>
						<validation-observer ref="register">
							<v-row class="pb-2 mx-0 pt-8">
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<validation-provider name="Email" :rules="{required: true, email: true, 'unique_email': [model]}" v-slot="{ errors }">
										<v-text-field
											label="Email*"
											type="email"
											v-model="model['email']"
											:error-messages="errors"
											outlined
											dense
											ref="email"
										></v-text-field>
									</validation-provider>
								</v-col>
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<v-text-field
										label="Telefono"
										type="text"
										v-model="model['primary_telephone']"
										outlined
										dense
										ref="primary_telephone"
									></v-text-field>
								</v-col>
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<validation-provider name="Password" rules="confirmed:repeat_password" v-slot="{ errors }">
										<v-text-field
											label="Password*"
											name="password"
											v-model="model['password']"
											:error-messages="errors"
											:append-icon="e1 ? 'visibility' : 'visibility_off'"
											@click:append="() => {e1 = !e1}"
											:type="!e1 ? 'password' : 'text'"
											ref="password"
											outlined
											dense
										></v-text-field>
									</validation-provider>
								</v-col>
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<validation-provider name="Ripeti Password" rules="required" v-slot="{ errors }" vid="repeat_password">
										<v-text-field
											label="Ripeti Password*"
											name="repeat_password"
											v-model="model['repeat_password']"
											:error-messages="errors"
											:append-icon="e2 ? 'visibility' : 'visibility_off'"
											@click:append="() => {e2 = !e2}"
											:type="!e2 ? 'password' : 'text'"
											outlined
											dense
											ref="repeat_password"
										></v-text-field>
									</validation-provider>
								</v-col>
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<validation-provider name="Nome" rules="required" v-slot="{ errors }">
										<v-text-field
											type="text"
											label="Nome*"
											v-model="model['name']"
											:error-messages="errors"
											outlined
											dense
											ref="name"
										></v-text-field>
									</validation-provider>
								</v-col>
								<v-col class="col-12 col-sm-6 col-md-6 col-lg-6 px-6 py-2">
									<validation-provider name="Cognome" rules="required" v-slot="{ errors }">
										<v-text-field
											type="text"
											label="Cognome*"
											v-model="model['surname']"
											:error-messages="errors"
											outlined
											dense
											ref="name"
										></v-text-field>
									</validation-provider>
								</v-col>
							</v-row>
						</validation-observer>
						<small class="pl-6">* Indica i campi obbligatori</small>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="pt-6 pb-10 justify-center elevation-5">
					<v-btn style="text-transform: initial" color="grey" outlined @click.native.stop="openPrivacy = true">Informativa Privacy</v-btn>
					<v-spacer></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>					
					<v-btn color="success" height="40" :loading="loading" @click.native="register()">Registrati</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog 
			v-model="openPrivacy"
			max-width="900px"
			scrollable style="overflow-y: hidden"
			:fullscreen="$vuetify.breakpoint.xsOnly">
			<v-card class="pa-0 rounded-lg">
                <v-card-title class="pa-6">
                    <v-row no-gutters>
                        <v-col class="col-12">
                            <div class="pl-0 font-weight-bold" style="font-size: 30px">Informativa Privacy</div>
                        </v-col>
                    </v-row>
                </v-card-title>				
				<v-divider></v-divider>
				<v-card-text style="overflow-x: hidden" class="pa-4">
					<div class="text-h5">Titolari e responsabili esterni del trattamento</div>
					<br>
					<p>
						Titolare del trattamento dati è la società Stiip S.r.l., con sede in Via G. Galilei, 95 - 51031 Agliana PT, C.F. e P.IVA 01854250477, contattabile ai seguenti recapiti: indirizzo e-mail info@volontapp.it; indirizzo pec stiip@pec.it; Tel. 0574/027026.
					</p>
					<p>
						Nel momento in cui effettuerai l’iscrizione ad una o più associazioni di volontariato, i tuoi dati verranno trattati mediante VolontApp anche da parte di queste associazioni, per finalità da loro determinate. Tali associazioni diverranno titolari autonomi dei trattamenti effettuati mediante VolontApp. Con riferimento a tali trattamenti, Stiip S.r.l. assumerà il ruolo di responsabile esterno del trattamento, in quanto tratterà i tuoi dati per conto delle associazioni a cui sei iscritto e seguendo le loro istruzioni.							
					</p>
					<br>
					<div class="text-h5">Dati oggetto del trattamento</div>
					<br>
					<p>
						Dopo aver scaricato l’applicazione VolontApp sul tuo dispositivo, per poterla utilizzare è richiesta l’iscrizione mediante creazione di un account personale. Per poter creare un account, dovrai compilare alcuni campi inserendo i tuoi dati personali. I dati obbligatori, poiché indispensabili per usufruire del servizio oggetto dell’applicazione, sono contrassegnati da un asterisco. Gli altri campi sono anch’essi necessari a permettere una migliore fornitura del servizio ma la loro compilazione è facoltativa. Ove questi campi non venissero riempiti, sarà comunque possibile procedere con l’iscrizione e usufruire della prestazione richiesta. Il campo Bio è a compilazione libera. Ti invitiamo a non utilizzarlo per inserire tuoi dati sensibili (che rivelino l’origine razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l’appartenenza sindacale, dati genetici, dati biometrici, dati relativi alla salute o alla vita sessuale o all’orientamento sessuale), né tantomeno dati riferiti a soggetti terzi, anche se trattasi di dati comuni.I dati relativi alla tua persona che verranno trattati sono solo quelli da te inseriti nel modulo di iscrizione. Potrai vederli e modificarli in ogni momento accedendo al tuo profilo personale.
					</p>
					<p>
						Potranno inoltre essere oggetto di trattamento i dati di Log-in e Log-out all’applicazione VolontApp.
					</p>
					<p>
						VolontApp fornisce alle associazioni e ai volontari un servizio di geolocalizzazione. Grazie a questo servizio, quando ti trovi nei pressi di un’associazione di volontariato alla quale sei iscritto, potrai registrare la tua presenza utilizzando il tuo dispositivo mobile dotato di GPS. Per rendere possibile questo servizio, dovremo trattare i tuoi dati di geolocalizzazione. Ad essere oggetto di trattamento sarà soltanto la tua posizione nel momento in cui utilizzerai la funzione per registrarti in associazione.
					</p>
					<br>						
					<div class="text-h5">Finalità e basi giuridiche del trattamento</div>
					<br>						
					<p>
						I dati da te rilasciati al momento dell’iscrizione a VolontApp vengono trattati al fine di fornirti i servizi oggetto dell’applicazione, ovvero di consentire all’associazione di volontariato a cui sei iscritto di gestire la tua posizione personale all’interno dell’organizzazione. Nel momento in cui scarichi VolontApp, stai chiedendo di poter usufruire di questo servizio. Base giuridica di questo trattamento è quella di cui all’art.6, par. 1 lett. b) Reg. UE 2016/679.
					</p>
					<p>
						I dati di log vengono trattati al solo fine di verificare quando non utilizzi più l’applicazione da molto tempo. Ciò serve al titolare al fine di definire un criterio di durata di conservazione dei dati e cancellare i tuoi dati se non stai più utilizzando l’applicazione. La base giuridica di questo trattamento è il legittimo interesse del titolare, ai sensi dell’art. 6, par. 1 lett. f) Reg. UE 2016/679. Questo trattamento non è lesivo per i diritti dell’interessato ed è anzi finalizzato a minimizzare i tempi di conservazione dei dati che non sono più necessari. Inoltre, i dati di log sono messi a tua conoscenza in un’apposita sezione, permettendoti di verificare gli accessi ed accorgerti di eventuali intrusioni sul tuo profilo personale.
					</p>
					<p>
						I dati di geolocalizzazione vengono trattati al fine di fornirti il servizio di registrazione delle presenze in associazione mediante l’utilizzo di un dispositivo mobile. Per poter utilizzare il servizio di registrazione delle presenze, ti verrà richiesta l’attivazione della funzione GPS sul tuo dispositivo mobile. L’attivazione di questa funzione equivale alla richiesta da parte tua di utilizzare il servizio. Ovviamente, potrai smettere in ogni momento di utilizzare il servizio, impedendo a VolontApp di utilizzare il GPS del dispositivo mobile. La base giuridica di questo trattamento è quella di cui all’art. 6, par. 1 lett. b) Reg. UE 2016/679.
					</p>
					<br>						
					<div class="text-h5">Modalità e durata del trattamento</div>
					<br>						
					<p>
						I dati verranno trattati in formato elettronico. Una volta raccolti, i dati verranno conservati in formato digitale presso dei server dedicati forniti da società terze.
					</p>
					<p>
						I dati verranno conservati sino a che perdura la finalità, ovvero fino a che continuerai ad utilizzare l’applicazione. Trascorsi 18 mesi dal tuo ultimo log-in, si presume che questa finalità sia venuta meno e pertanto i tuoi dati verranno cancellati.
					</p>
					<p>
						I dati concernenti le registrazioni delle tue presenze presso le associazioni di volontariato vengono conservati da Stiip S.r.l. soltanto in qualità di responsabile esterno, sulla base delle istruzioni fornite dalle associazioni stesse. La durata del trattamento è quindi determinata dalla necessità dell’associazione di volontariato, in qualità di titolare del trattamento, di conservare il registro delle presenze dei propri volontari. Nel caso venga meno il rapporto contrattuale fra un’associazione di volontariato e Stiip S.r.l., quest’ultima non conserverà le registrazioni delle presenze relative a quella associazione.							
					</p>
					<br>						
					<div class="text-h5">Categorie di destinatari</div>
					<br>						
					<p>
						I dati trattati mediante VolontApp verranno conservati presso dei server dedicati forniti da società terze e situati all’interno dell’Unione Europea. I dati potranno essere trattati da personale del titolare e del responsabile del trattamento, nonché da soggetti terzi, quali hosting provider e sviluppatori software. Titolare e responsabile avranno cura di assicurarsi che questi soggetti adottino misure adeguate di protezione dei dati personali.
					</p>
					<br>
					<div class="text-h5">Diritti degli interessati</div>
					<br>						
					<p>
						Oltre a queste informazioni, per garantirti un trattamento dei tuoi dati il più corretto e trasparente possibile, devi essere a conoscenza del fatto che:
					</p>
					<br>
						<ul>
							<li>
								hai il diritto di chiedere al titolare del trattamento l'accesso ai tuoi dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che ti riguarda o di opporti al trattamento;
							</li>
							<li>
								hai diritto di chiedere al titolare di fornirti i tuoi dati in un formato strutturato o di trasmetterli ad un diverso operatore;
							</li>
							<li>
								se hai fornito il consenso per una o più specifiche finalità, hai il diritto di revocare tale consenso in qualsiasi momento;- se ritieni che vi sia stata una violazione nel trattamento dei tuoi dati, potrai depositare un reclamo presso l’Autorità Garante per il trattamento dei dati personali.
							</li>
						</ul>
					<br>
					<p>
						Per approfondire la conoscenza e la portata dei tuoi diritti, puoi fare riferimento agli articoli dal 15 al 22 del Reg. UE 2016/679.
					</p>
					<br>
					<div class="text-h5">Comunicazioni</div>
					<br>
					<p>
						Per qualsiasi comunicazione o richiesta o per esercitare i tuoi diritti riguardanti il trattamento dei dati personali, puoi rivolgerti a Stiip S.r.l., con sede in Via G. Galilei, 95 - 51031 Agliana PT, C.F. e P.IVA 01854250477, contattabile ai seguenti recapiti: indirizzo e-mail info@volontapp.it; indirizzo pec stiip@pec.it; Tel. 0574/027026.
					</p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="px-2 pt-4 pb-10">
					<v-btn block text height="40" @click.native="openPrivacy = false">Chiudi</v-btn>
				</v-card-actions>						
			</v-card>
		</v-dialog>		
	</v-row>
</template>

<script>
export default {
	name: 'register',
	props: {
		open: Boolean,
	},

	data() {
		return {
			url: '',
			model: {},
			e1: false,
			e2: false,
			items: {},
			show: false,
			loading: false,
			openPrivacy: false,
			fieldsset: {'fields': []},
		}
	},
		
	methods: {

		closeDialog() {
			this.$emit('close')
		},

		register() {
			this.loading = true
			this.$refs.register.validate().then(result => {
				if (!result) {
					this.loading = false
					return false
				}

				this.$store.dispatch('authRegister', this.model).then(() => {
					this.closeDialog()
					this.loading = false
					this.$store.dispatch('redirectHome')
				})
					
			}).catch(() => {
				this.loading = false
			})
		},
	},

}
</script>

<style>

</style>