<template>
    <div>
        <v-dialog
            v-model="dialogShow"
            fullscreen
            hide-overlay persistent
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile v-if="model">
                <v-card-title class="pa-6">
                    <v-row no-gutters>

                        <v-col class="col-12 ma-auto ml-0">
                            <div class="pl-0 font-weight-bold">Corso</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                            <!-- <div class="pl-0 font-weight-bold">{{ model.description }}</div> -->
                        </v-col>
                    </v-row>
                 
                </v-card-title>
                <v-card-title class="pa-0 px-2">
                    <v-tabs
                        class="pt-4"
                        v-model="mutableTab"
                        background-color="white"
                        show-arrows
                        :centered="!$vuetify.breakpoint.mdAndUp">
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab href="#info">
                            Informazioni
                        </v-tab>

                        <v-tab href="#calendar">
                            Calendario
                        </v-tab>

                        <v-tab href="#participants" v-if="$store.getters.checkPermission('manage_presences')">
                            Partecipanti
                        </v-tab>

                    </v-tabs>   
                </v-card-title>

                <v-card-text style="overflow-x: hidden; margin-top: 20px" class="body-page" v-if="dialogShow">

                    <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                        <v-tabs-items v-model="mutableTab" class="transparent">                    
                            <v-tab-item value="info" class="fill-height">
                            <v-row no-gutters>
                                <v-col class="col-12 col-md-12 col-sm-12 col-lg-6">
                                    <v-row class="ma-auto d-flex pt-6" v-if="!subscriptionClosed">

                                        <v-btn v-if="model.subscription_status == 'subscribed'" color="primary" :block="$vuetify.breakpoint.smAndDown" @click="subscribe()">Disiscriviti</v-btn>

                                        <v-btn v-else-if="model.subscribe_require_approval && !$store.getters.checkPermission('manage_presences') && model.subscription_status == 'not_subscribed'" color="success" :block="$vuetify.breakpoint.smAndDown" @click="subscribe()">richiedi iscrizione</v-btn>

                                        <v-btn v-else-if="model.subscription_status == 'pending'" color="warning" :block="$vuetify.breakpoint.smAndDown" @click="subscribe()">
                                            <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            class="mr-2"
                                            size="25"
                                            ></v-progress-circular> <span class="ml-2">annulla richiesta</span>
                                        </v-btn>

                                        <v-btn v-else color="success" :block="$vuetify.breakpoint.smAndDown" @click="subscribe()">iscriviti</v-btn>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6">
                                        <v-icon>mdi-book-open-outline</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Descrizione</div>
                                            <div class="pl-2">{{ model.description }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6">
                                        <v-icon>mdi-account</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Totale Iscritti</div>
                                            <div class="pl-2">{{ model.subscriptions }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="$store.getters.checkPermission('manage_courses')">
                                        <v-icon>mdi-account</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">In Attesa</div>
                                            <div class="pl-2">{{ model.subscriptions_pending }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.first_lesson">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Data Inizio</div>
                                            <div class="pl-2">{{ model.first_lesson | date }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.last_lesson">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Data Fine</div>
                                            <div class="pl-2">{{ model.last_lesson | date }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6">
                                        <v-icon>mdi-book-open-outline</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Totale Lezioni</div>
                                            <div class="pl-2">{{ model.lessons }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6">
                                        <v-icon>mdi-clock-outline</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Durata complessiva</div>
                                            <div class="pl-2">{{ model.duration | duration }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.end_subscription_date">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Scadenza Iscrizioni</div>
                                            <div class="pl-2">{{ model.end_subscription_date | date }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.patent">
                                        <v-icon>mdi-certificate-outline</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Brevetto</div>
                                            <div class="pl-2">{{ model.patent ? model.patent.name : '' }}</div>
                                        </div>
                                    </v-row>
                                </v-col>
                                
                                <v-col class="col-12 col-md-12 col-sm-12 col-lg-6">
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.minimal_frequency">
                                        <v-icon>mdi-account-check-outline</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Frequenza Minima</div>
                                            <div class="pl-2">{{ model.minimal_frequency }}%</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Accesso libero</div>
                                            <div class="pl-2">{{ model.can_person_free_subscribe ? 'Si' : 'No' }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.max_subscription">
                                        <v-icon>mdi-account</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Numero massimo di iscrizioni</div>
                                            <div class="pl-2">{{ model.max_subscription }}</div>
                                        </div>
                                    </v-row>
                                    
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.training_hour">
                                        <v-icon>mdi-account</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Ore Tirocinio</div>
                                            <div class="pl-2">{{ model.training_hour }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.start_training_date">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Data Inizio Tirocinio</div>
                                            <div class="pl-2">{{ model.start_training_date | date }}</div>
                                        </div>
                                    </v-row>
                                    <v-row class="ma-auto d-flex pt-6" v-if="model.end_training_date">
                                        <v-icon>mdi-calendar</v-icon>
                                        <div class="d-block">
                                            <div class="pl-2 caption">Data Fine Tirocinio</div>
                                            <div class="pl-2">{{ model.end_training_date | date }}</div>
                                        </div>
                                    </v-row>           

                                    <template v-if="model.shiftTypes && model.shiftTypes.length > 0">
                                        <v-row class="ma-auto d-flex pt-6">
                                            <v-icon>mdi-clipboard-account-outline</v-icon>
                                            <div class="pl-2 caption">Turni validi per il tirocinio</div>
                                        </v-row>                                
                                        <div class="d-block ml-6">
                                            <v-chip small class="ma-1" v-for="(shift, t) in model.shiftTypes" :key="t">
                                                {{ shiftTypesById[shift.id].name }}
                                            </v-chip>
                                        </div>
                                    </template>                                                                                                 

                                    <template v-if="model.tasks && model.tasks.length > 0">
                                        <v-row class="ma-auto d-flex pt-6">
                                            <v-icon>mdi-clipboard-account-outline</v-icon>
                                            <div class="pl-2 caption">Mansioni per partecipare</div>
                                        </v-row>                                
                                        <div class="d-block ml-6">
                                            <v-chip small class="ma-1" v-for="(task, t) in model.tasks" :key="t">
                                                <v-avatar left>
                                                    <v-icon :color="tasksById[task].color">{{ tasksById[task].icon }}</v-icon>
                                                </v-avatar>
                                                {{ tasksById[task].name }}
                                            </v-chip>
                                        </div>
                                    </template>

                                    <template v-if="model.examTasks && model.examTasks.length > 0">
                                        <v-row class="ma-auto d-flex pt-6">
                                            <v-icon>mdi-clipboard-account-outline</v-icon>
                                            <div class="pl-2 caption">Mansioni per l'esame</div>
                                        </v-row>                                
                                        <div class="d-block ml-6">
                                            <v-chip small class="ma-1" v-for="(task, t) in model.examTasks" :key="t">
                                                <v-avatar left>
                                                    <v-icon :color="tasksById[task].color">{{ tasksById[task].icon }}</v-icon>
                                                </v-avatar>
                                                {{ tasksById[task].name }}
                                            </v-chip>
                                        </div>
                                    </template>                                    
                                </v-col>
                            </v-row>
                            </v-tab-item>



                            <v-tab-item value="calendar" class="fill-height">

                                <calendar-lessons
                                    :course="model"
                                ></calendar-lessons>


                                <lesson-editor
                                    :course="model"
				                    :info="info"                                
                                ></lesson-editor>

                                <v-btn
                                    v-if="$store.getters.checkPermission('manage_courses')"
                                    class="mb-24"
                                    color="primary"
                                    fixed
                                    fab
                                    bottom
                                    right
                                    @click.native.stop="createLesson()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-tab-item>



                            <v-tab-item value="participants" class="fill-height">

                                <subscribed-people
                                    :course="model"
                                ></subscribed-people>

                            </v-tab-item>


                        </v-tabs-items>
                    </v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <v-col class="text-center">
                                <p class="body-1 font-weight-bold">Sei sicuro di voler eliminare questo Corso?</p>
                                <p class="caption">Verranno eliminate tutte le lezioni e le presenze inserite.</p>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    
                    <template v-if="$store.getters.checkPermission('manage_other_people') || model.creator_id == $store.getters.getUserPerson.id">
                        <v-btn color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">
                            Elimina
                        </v-btn>
                        <v-btn color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model.id)">
                            Modifica
                        </v-btn>
                    </template>
                </v-card-actions>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="deleteCourse()">
                                Conferma
                            </v-btn>
                        </template>
                        Conferma
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogEditor"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card tile>
                <v-card-title class="pa-6" style="position: fixed">
                    <v-row no-gutters class="d-block">
                        <template v-if="confirmDelete">
                            <div class="pl-0 font-weight-bold">Elimina Corso</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold">Modifica Corso</div>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Corso</div>
                        </template>
                    </v-row>
                </v-card-title>
                <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                    <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                        <validation-observer ref="courseFields">
                            <v-row no-gutters>
                                <v-col class="col-12 col-sm-12 col-md-12 col-lg-6 py-0 px-3 pt-2">
                                    <v-row no-gutters>
                                        <v-subheader class="pl-3 title">Informazione Corso</v-subheader>
                                        <v-col class="col-12 py-0 px-3 pt-2">
                                            <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    type="text"
                                                    label="Nome*"
                                                    v-model="model['name']"
                                                    :error-messages="errors"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col class="col-12 py-0 px-3 pt-2">
                                            <validation-provider name="Descrizione" rules="required|max:255" v-slot="{ errors }">
                                                <v-textarea
                                                    type="text"
                                                    label="Descrizione*"
                                                    v-model="model['description']"
                                                    :error-messages="errors"
                                                    outlined
                                                    dense
                                                ></v-textarea>
                                            </validation-provider>
                                        </v-col>
                                        <v-col class="col-12 py-0 px-3 pt-2">
                                            <v-autocomplete
                                                label="Brevetto rilasciato"
                                                :items="info.patents"
                                                v-model="model['patent_id']"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                dense
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col class="col-12 py-0 px-3 pt-2">
                                            <v-autocomplete
                                                label="Responsabili Staff"
                                                :items="info.peoplestaff"
                                                v-model="model['staffs']"
                                                small-chips
                                                item-text="full_name"
                                                item-value="id"
                                                multiple
                                                outlined
                                                dense
                                                hint="Seleziona gli staff a cui notificare le richieste di partecipazione."
                                                persistent-hint
                                            ></v-autocomplete>
                                        </v-col>


                                        <v-subheader class="pl-3 title">Requisiti Iscrizione</v-subheader>                          
                                        <v-col class="col-12 py-0 px-3 pb-3">
                                            <v-checkbox
                                                label="Iscrizione aperta a tutti gli utenti"
                                                v-model="model['can_person_free_subscribe']"
                                                class="ma-auto"
                                                color="primary"
                                                :input-value="[true, false]"
                                                hide-details
                                                ref="can_person_free_subscribe"
                                            ></v-checkbox>
                                        </v-col>

                                        <v-slide-y-transition>
                                            <v-col class="col-12 py-0 px-3 pb-3" v-show="model['can_person_free_subscribe']">
                                                <v-checkbox
                                                    label="Iscrizione richiede l'approvazione dello staff"
                                                    v-model="model['subscribe_require_approval']"
                                                    color="primary"
                                                    class="ma-auto"
                                                    hide-details
                                                    :input-value="[true, false]"
                                                    ref="subscribe_require_approval"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-slide-y-transition>

                                        <v-col class="col-12 py-0 px-3 pt-3">
                                            <v-menu
                                                ref="end_subscription_date"
                                                :close-on-content-click="false"
                                                v-model="datepicker_menu['end_subscription_date']"
                                                :return-value.sync="model['end_subscription_date']"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                :nudge-right="40"
                                                style="width: 100%">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-on="on"
                                                        label="Data chiusura iscrizioni"
                                                        append-icon="event"
                                                        readonly
                                                        clearable
                                                        hint="Lasciare vuoto se le iscrizioni non hanno una data di chiusura"
                                                        persistent-hint
                                                        :value="$eventBusUtility.formatDate(model['end_subscription_date'])"
                                                        outlined
                                                        @click:clear="model['end_subscription_date'] = ''"
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                    v-model="model['end_subscription_date']"
                                                    color="primary"
                                                    locale="It"
                                                    scrollable
                                                    :first-day-of-week="1"
                                                    @input="$refs['end_subscription_date'].save(model['end_subscription_date'])">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col class="col-12 py-0 px-3 pt-3">
                                            <validation-provider name="Numero Massimo di Iscritti" rules="required|integer|min_value:0" v-slot="{ errors }">
                                                <v-text-field
                                                    label="Numero Massimo di Iscritti*"
                                                    v-model="model['max_subscription']"
                                                    hint="Lasciare 0 se non esiste un massimo."
                                                    persistent-hint
                                                    :error-messages="errors"
                                                    type="number"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>

                                        <span class="pl-3">Mansioni necessarie per l'iscrizione</span>
                                        <v-col class="col-12 py-0 px-3">
                                            <v-row class="mb-3" no-gutters>
                                                <v-col cols="3" class="pr-3" v-for="task in info.tasks" :key="task.id">
                                                    <v-checkbox
                                                        :label="task.name"
                                                        v-model="model.tasks"
                                                        :value="task.id"
                                                        class="ma-auto"
                                                        hide-details>
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-12 col-lg-6 py-0 px-3 pt-3">
                                    <v-row no-gutters>
                                        <v-subheader class="pl-3 title">Requisiti Esame</v-subheader>
                                        <v-col class="col-12 py-0 px-3 pt-2">
                                            <validation-provider name="Frequenza minima" rules="required|integer|min_value:0|max_value:100" v-slot="{ errors }">
                                                <v-text-field
                                                    label="Frequenza minima*"
                                                    v-model="model['minimal_frequency']"
                                                    :error-messages="errors"
                                                    type="number"
                                                    hint="Percentuale minima di partecipazione"
                                                    persisten-hint
                                                    prefix="%"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>    

                                        <span class="pl-3">Mansioni necessarie per l'esame</span>
                                        <v-col class="col-12 py-0 px-3">
                                            <v-row class="mb-3" no-gutters>
                                                <v-col cols="3" class="pr-3" v-for="task in info.tasks" :key="task.id">
                                                    <v-checkbox
                                                        :label="task.name"
                                                        v-model="model.examTasks"
                                                        :value="task.id"
                                                        class="ma-auto"
                                                        hide-details>
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>   

                                        <v-col class="col-12 py-0 pt-3 px-3">
                                            <validation-provider name="Ore Tirocinio necessarie per l'esame" rules="required|integer|min_value:0|max_value:100" v-slot="{ errors }">
                                                <v-text-field
                                                    label="Ore Tirocinio*"
                                                    hint="Lasciare 0 se non è necessario un tirocinio per l'esame"
                                                    persistent-hint
                                                    v-model="model['training_hour']"
                                                    :error-messages="errors"
                                                    type="number"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col class="col-12 col-lg-6 py-0 px-3 pt-3">
                                            <v-menu
                                                ref="start_training_date"
                                                :close-on-content-click="false"
                                                v-model="datepicker_menu['start_training_date']"
                                                :return-value.sync="model['start_training_date']"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                :nudge-right="40"
                                                style="width: 100%">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-on="on"
                                                        label="Data Inizio Tirocinio"
                                                        append-icon="event"
                                                        readonly
                                                        clearable
                                                        :value="$eventBusUtility.formatDate(model['start_training_date'])"
                                                        outlined
                                                        @click:clear="model['start_training_date'] = ''"
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                    v-model="model['start_training_date']"
                                                    color="primary"
                                                    locale="It"
                                                    scrollable
                                                    :first-day-of-week="1"
                                                    @input="$refs['start_training_date'].save(model['start_training_date'])">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <v-col class="col-12 col-lg-6 py-0 px-3 pt-3">
                                            <v-menu
                                                ref="end_training_date"
                                                :close-on-content-click="false"
                                                v-model="datepicker_menu['end_training_date']"
                                                :return-value.sync="model['end_training_date']"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                                :nudge-right="40"
                                                style="width: 100%">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-on="on"
                                                        label="Data Fino Tirocinio"
                                                        append-icon="event"
                                                        readonly
                                                        clearable
                                                        :value="$eventBusUtility.formatDate(model['end_training_date'])"
                                                        outlined
                                                        @click:clear="model['end_training_date'] = ''"
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker 
                                                    v-model="model['end_training_date']"
                                                    color="primary"
                                                    locale="It"
                                                    scrollable
                                                    :first-day-of-week="1"
                                                    @input="$refs['end_training_date'].save(model['end_training_date'])">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>

                                        <span class="pl-3">Tipi di turno validi per il tirocinio</span>
                                        <v-col class="col-12 py-0 px-3">
                                            <v-row class="mb-3" :no-gutters="true">
                                                <v-col cols="3" class="pr-3" v-for="shifttype in info.shifttypes" :key="shifttype.id">
                                                    <v-checkbox
                                                        :label="shifttype.name"
                                                        v-model="model.shifttypes"
                                                        :value="shifttype.id"
                                                        class="ma-auto"
                                                        hide-details>
                                                    </v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </validation-observer>

                    </v-container>

                    <v-container v-else>
                        <v-row class="mx-0 justify-center pt-6">
                            <v-col class="text-center">
                                <p class="body-1 font-weight-bold">Sei sicuro di voler eliminare questo Corso?</p>
                                <p class="caption">Verranno eliminate tutte le lezioni e le presenze inserite.</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                    <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                                <v-icon>mdi-keyboard-backspace</v-icon>
                            </v-btn>
                        </template>
                        <span>Chiudi</span>
                    </v-tooltip>
                    <v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
                    <v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
                    <v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import LessonEditor from '@/components/modules/courses/LessonEditor'
import CalendarLessons from '@/components/modules/courses/CalendarLessons'
import SubscribedPeople from '@/components/modules/courses/SubscribedPeople'

export default {
	name: 'courses-editor',
	components: {
		LessonEditor,
		CalendarLessons,
		SubscribedPeople,
	},
	props: {
		info: Object,
		element: Number,
		course_id: Number,
		started_at: String,
		subtab: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			mutableTab: 'info',
			model: {},
			loading: false,
			showDialog: false,
			dialogShow: false,
			datepicker_menu: {},
			dialogEditor: false,
			confirmDelete: false,
			defaultModel: {
				'tasks': [],
				'examTasks': [],
				'shifttypes': [],
				'training_hour': 0,
				'max_subscription': 0,
				'minimal_frequency': 0,
			}
		}
	},

	computed: {

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},
        
		shiftTypesById() {
			const initialValue = {}
			return this.info.shifttypes ? this.info.shifttypes.reduce((obj, item) => {
				return {
					...obj,
					[item['id']]: item,
				}
			}, initialValue) : []            
		},

		subscriptionClosed() {
			return (this.model.end_subscription_date && moment().isAfter(moment(this.model.end_subscription_date), 'day')) || !this.model.can_person_free_subscribe
		},

	},

	methods: {
		closeDialog() {
			this.dialogShow = false
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(id) {
			this.loading = true
			this.axios.get('courses/edit/' + id).then(response => {
                
				this.dialogShow = false
				this.dialogEditor = true
				this.model = {...response.data.data}
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		refreshShow(id) {
			this.loading = true
			this.axios.get('courses/' + id).then(response => {
                
				this.model = {...response.data.data}
				this.loading = false

			}, response => {
				this.loading = false
			})
		},


		show(id) {
			this.axios.get('courses/' + id).then(response => {
					
				this.dialogShow = true
				this.model = {...response.data.data}
				this.loading = false
				if(this.subtab) {
					this.mutableTab =  this.subtab
				} else if(this.element) {
					this.mutableTab = 'calendar'
				} else  {
					this.mutableTab = 'info'
				}
			}, response => {
				this.loading = false
			})
		},              

		create() {
			this.dialogEditor = true
			this.model = {...this.defaultModel}
		},

		createLesson() {
			this.$eventBusUtility.$emit('createLesson')
		},

		confirmDestroy() {
			this.dialogShow = true
			this.confirmDelete = true
		},        

		destroy() {
			this.closeDialog()
		},

		subscribe() {
			this.axios.get('courses/handleSubscription/' + this.model.id +'/' + this.$store.getters.getUserPerson.id).then(response => {
				this.loading = false
				this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: response.data.message, value: true, color: 'success'})
				this.reload()
			}, response => {
				this.loading = false
			})
		},

		reload() {
			this.refreshShow(this.model.id)
			this.$eventBusUtility.$emit('refreshTable', 'courses')
			this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
		},

		save() {
			this.$refs.courseFields.validate().then(result => {
				if (!result) {
					return false
				}
				if(this.model.id) {
					this.axios.put('courses/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'courses')
						this.closeDialog()
					}, response => {
						this.loading = false
					})

				} else {
					this.axios.post('courses', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'courses')
						this.closeDialog()
					}, response => {
						this.loading = false
					})
				}
			}).catch(() => {

			})
		},

		deleteCourse() {
			this.loading = true
			this.axios.delete('courses/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'courses')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},        
	},

	mounted() {
		this.$eventBusUtility.$on('showCourse', (item) => {
			this.show(item.id)
		}),

		this.$eventBusUtility.$on('editCourse', (item) => {
			this.edit(item.id)
		}),
               
		this.$eventBusUtility.$on('createCourse', () => {
			this.create()
		})

		this.$eventBusUtility.$on('refreshCourse', (course_id) => {
			// this.model.id = course_id
			this.reload()
		})
            
		this.$eventBusUtility.$on('destroyCourse', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	beforeDestroy() {
		this.$eventBusUtility.$off('showCourse')
		this.$eventBusUtility.$off('editCourse')
		this.$eventBusUtility.$off('createCourse')
		this.$eventBusUtility.$off('refreshCourse')
		this.$eventBusUtility.$off('destroyCourse')
	},    
}
</script>