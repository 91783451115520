<template>
    <validation-observer ref="fields">
        <v-row class="pb-2 mx-0 pt-3">
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome*"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Ordine" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Ordine*"
                        v-model="model['order']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="order"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 py-0 pt-3 px-6">
                <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                    <v-textarea
                        label="Descrizione"
                        name="description"
                        v-model="model['description']"
                        :error-messages="errors"
                        type="text"
                        outlined
                        dense
                        counter
                        ref="description"
                    ></v-textarea>
                </validation-provider>
            </v-col>            

        </v-row>

    </validation-observer>    
</template>

<script>

export default {
	name: 'calendar-fields',
	props: {
		create: {
			type: Boolean,
			default: false,
		},
		edit: {
			type: Boolean,
			default: false,
		},
		model: Object,
	},
        
	data() {
		return {
			dialog: false,
			dialogType: null,
		}
	},
       

	computed: {

	},
        
	methods: {

		openDialog(type) {
			this.dialog = true
			this.dialogType = type
		}

	}
}
</script>

<style>

</style>