<template>
    <v-card class="background-2 rounded-lg">
        <v-toolbar dark flat color="success">
            <div>
                <div class="font-weight-bold" style="font-size: 13px !important;">{{ formatEventDate(model)}} </div>
                <div class="font-weight-bold" style="font-size: 13px !important;">{{ model.name }}</div>
            </div>
            <v-spacer></v-spacer>

            <actions-menu v-if="$store.getters.checkPermission('manage_courses')"
                :item="model"
                :actions="actions"
                :showAction="false"
            ></actions-menu>

        </v-toolbar>

        <v-list dense class="pa-0">

            <template v-if="model.trainer">
                <v-list-item class="shift-task-header c-t-divider px-1" style="pointer-events: inherit !important;">
                    <v-list-item-content class="py-0">
                        <v-list-item-title style="font-size: 13px !important">Formatore
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div>
                    <v-list-item class="shift-task px-1">
                        <v-list-item-action class="pl-4">
                            <v-icon :size="20">mdi-account-outline</v-icon> 
                        </v-list-item-action>
                        <v-list-item-content class="pl-1 py-0">
                            <v-list-item-title :style="{'font-size': '13px !important'}">
                                {{ model.trainer }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </template>

            <template v-if="model.room">
                <v-list-item class="shift-task-header c-t-divider px-1" style="pointer-events: inherit !important;">
                    <v-list-item-content class="py-0">
                        <v-list-item-title style="font-size: 13px !important">Aula
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div>
                    <v-list-item class="shift-task px-1">
                        <v-list-item-action class="pl-4">
                            <v-icon :size="20">mdi-seat-outline</v-icon> 
                        </v-list-item-action>
                        <v-list-item-content class="pl-1 py-0">
                            <v-list-item-title :style="{'font-size': '13px !important'}">
                                {{ model.room ? model.room.name : '' }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </template>

            <v-list-item class="shift-task-header c-t-divider px-1" style="pointer-events: inherit !important;">
                <span class="py-0" style="font-size: 13px !important">{{ model.description }}
                </span>
            </v-list-item>

            <!-- <v-list-item class="shift-task-header c-t-divider px-1" style="pointer-events: inherit !important;">
                <v-list-item-content class="py-0">
                    <v-list-item-title style="font-size: 13px !important">Allegati
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <div>
                <v-list-item class="shift-task px-1">
                    <v-list-item-action class="pl-4">
                        <v-icon :size="20">mdi-paperclip</v-icon> 
                    </v-list-item-action>
                    <v-list-item-content class="pl-1 py-0">
                        <v-list-item-title :style="{'font-size': '13px !important'}">
                            
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>
            <div>
                <v-list-item class="shift-task px-1" @click="addAttachment()">
                    <v-list-item-content class="pl-1 py-0 text-center">
                        <v-list-item-title style="font-size: 13px !important">
                        <v-icon :size="20">mdi-plus</v-icon> 
                            Aggiungi allegato
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div> -->

            <v-list-item class="shift-task-header c-t-divider px-1" style="pointer-events: inherit !important;" :disabled="!$store.getters.checkPermission('manage_presences')" @click="showAttendances()">
                <v-list-item-content class="py-0 text-center red--text">
                    <v-list-item-title style="font-size: 13px !important">
                        <v-icon :size="20" color="red" class="pr-2">mdi-account-group-outline</v-icon> 
                        Presenze {{ model.subscriptions }} / {{ subscriptionsCourse }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>            

        </v-list>

    </v-card>
</template>

<script>
export default {
	name: 'lesson',
	props: {
		model: Object,
		subscriptionsCourse: Number,
	},
	data() {
		return {
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editLesson'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'deleteLesson'
				},
				{
					'icon': 'mdi-calendar-edit',
					'text': 'Foglio Firme',
					'event': 'downloadSignatures',
				},
				{
					'icon': 'mdi-account-group-outline',
					'text': 'Presenze',
					'event': 'showAttendances',
				},                
			],
		}
	},

	computed: {

		formatEventDate(event) {
			return (event) => {
				return moment(event.start).format('HH:mm') + ' - ' + moment(event.end).format('HH:mm')
			}
		},
	},

	mounted() {
		this.$eventBusUtility.$on('downloadSignatures', (lesson) => {
			if(lesson.id == this.model.id) {
				this.$eventBusUtility.openFile(this.$store, '/lessons/' + this.model.id + '/print', 'FoglioFirme_' + this.model.id)
			}
		})
		this.$eventBusUtility.$on('deleteLesson', (lesson) => {
			if(lesson.id == this.model.id) {
				this.deletePerson()
			}
		})
		this.$eventBusUtility.$on('showAttendances', (lesson) => {
			if(lesson.id == this.model.id) {
				this.showAttendances()
			}
		})        
	},

	methods: {

		addAttachment() {

		},

		showAttendances() {
			if(this.$store.getters.checkPermission('manage_presences'))
		        this.$eventBusUtility.$emit('openLessonAttendances', this.model)
		},

		deletePerson() {
			this.loading = true
			this.axios.delete('lessons/' + this.model.id).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshCalendar', 'lessons')
			}, response => {
				this.loading = false
			})
		},        
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('downloadSignatures')
		this.$eventBusUtility.$off('deleteLesson')
		this.$eventBusUtility.$off('showAttendances')
	},     
}
</script>

<style>
    .shift-task {
        min-height: 22px !important;
    }
    .shift-task-header {
        min-height: 30px !important;
    }
    .shift-task .v-list-item__action, .shift-task-header .v-list-item__action {
        margin: 0px !important
    }
</style>