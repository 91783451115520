<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Persone"
				:totalItems="totalItems"
				icon="mdi-account-supervisor-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-card :elevation="1" class="background" width="100%">
					<v-expand-transition>
						<v-alert v-show="showSelectAll" icon="mdi-check-box-multiple-outline" text prominent type="info">
							<template v-if="!selectAll">
								Tutte le <span class="font-weight-bold">{{ selected.length }}</span> persone in questa pagina sono state selezionate.
								<v-btn @click="setSelectAll()" color="info" dark text>Seleziona tutte</v-btn> le {{ totalItems }} persone esistenti
							</template>
							<template v-else>
								Tutte le <span class="font-weight-bold">{{ totalItems }}</span> persone sono state selezionate.
								<v-btn @click="cancelSelectAll()" color="info" dark text>Annulla la selezione</v-btn>
							</template>
						</v-alert>
					</v-expand-transition>
					<v-data-table class="background-2"
						:headers="headers"
						:items="filteredItems"
						:loading="loading"
						v-model="selected" 
						show-select
						:mobile-breakpoint="0"
						ref="vDataTable"
						:footer-props="{
							'items-per-page-options': [10, 25, 50],
							showFirstLastPage: true
						}">
						<template v-slot:header.data-table-select="{ on, props }">
							<v-simple-checkbox color="primary" v-bind="props"  @input="showSelectAll = $event" v-on="on"></v-simple-checkbox>
						</template>

						<template v-slot:body.prepend>
							<tr>
								<td>
									<v-icon >mdi-magnify</v-icon>
								</td>
								<td>
									<v-tooltip top v-if="!isEmptyColumnsFilter">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
												<v-icon>mdi-progress-close</v-icon>
											</v-btn>
										</template>
										<span>Svuota Filtri</span>
									</v-tooltip>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Cognome e Nome"
										hide-details
										v-model="columnsFilter['full_name']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-select
										:items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
										v-model="columnsFilter['employee']"
										placeholder="Dipendente"
										hide-details
										clearable
										dense
										solo
										flat									
									></v-select>
								</td>
								<td class="pl-0">
									<v-autocomplete
										:items="tasksFilter"
										v-model="columnsFilter['tasks']"
										item-value="id"
										item-text="name"
										placeholder="Mansioni"
										hide-details
										clearable
										dense
										solo
										flat>
										<template v-slot:item="data">
											<v-list-item-avatar :height="20" :min-width="20" :width="20">
												<v-icon small :color="data.item.color">{{ data.item.icon }}</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title v-html="data.item.name"></v-list-item-title>
											</v-list-item-content>
										</template>
										</v-autocomplete>
								</td>
								<td class="pl-0">
									<v-select
										:items="$store.getters.getGeneralTypes.person_status"
										v-model="columnsFilter['person_status']"
										item-value="value"
										item-text="text"
										placeholder="Stato"
										hide-details
										clearable
										dense
										solo
										flat									
									></v-select>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Utente"
										hide-details
										v-model="columnsFilter['user_email']"
									></v-text-field>
								</td>
							</tr>                                        
						</template>

						<template v-slot:item="{item, select, isSelected}">
							<tr class="cursor-pointer">
								<td>
									<v-simple-checkbox color="primary" :value="isSelected" @input="callSelect(select, $event)"></v-simple-checkbox>
								</td>
								<td style="white-space: nowrap">
									<actions-menu
										v-if="$store.getters.checkPermission('manage_other_people') || item.creator_id == $store.getters.getUserPerson.id"
										:item="item"
										:actions="actions"
									></actions-menu>
								</td>								
								<td @click="show(item)">
									{{ item.full_name }}
								</td>
								<td @click="show(item)">
									<div>
										<v-icon>{{ item.employee == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
									</div>
								</td>
								<td @click="show(item)">
									<v-tooltip top v-for="(task_id, t) in item.tasks" :key="item.id + '_' + t">
										<template v-slot:activator="{ on: tooltip }">
											<v-icon v-on="{ ...tooltip }" :color="tasksById[task_id].color" :size="18" class="pr-1">
												{{ tasksById[task_id].icon }}
											</v-icon>
										</template>
										<span>{{ tasksById[task_id].name ? tasksById[task_id].name : '' }}</span>
									</v-tooltip>
								</td>
								<td>
									<v-chip text-color="white" small :color="$eventBusUtility.personStatusColor(item.person_status)">{{ item.person_status_text }}</v-chip>
								</td>
								<td @click="show(item)">
									{{ item.user_email }} <span v-if="item.role != 'volunteer'" :class="item.role == 'staff' ? 'orange' : 'red'" class="person-role" >{{ item.role }}</span>
								</td>
							</tr>
						</template>

						<template v-slot:footer.page-text="{ pageStart, pageStop }">
							<div class="primary--text"><span>{{ selectedBE ? selectedBE : countSelected }}</span> Selezionati</div>
							Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
						</template>
						
					</v-data-table>
					

					<v-speed-dial
						:class="{'mb-16': $vuetify.breakpoint.smAndDown}"
						fixed
						v-model="fab"
						bottom
						right
						:loading="loading"
						color="primary"
						direction="left"
						transition="slide-x-transition">
						<template v-slot:activator>
							<v-btn
								v-model="fab"
								color="primary"
								dark
								fab>
								<v-icon v-if="fab">mdi-close</v-icon>
								<v-icon v-else>mdi-account-supervisor-outline</v-icon>
							</v-btn>
						</template>              
						<v-tooltip top v-if="$store.getters.checkPermission('create_person') && totalItems < $store.getters.getCurrentOrganization.users">
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									@click="create()"
									color="indigo">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							<span>Nuovo</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">                             
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									color="green"
									:depressed="disabled"
									:disabled="disabled"
									@click.native.stop="$eventBusUtility.openFile($store, '/people-csv/', 'people.csv', 'POST', {'selected': selectedIds})">
									<v-icon>mdi-download</v-icon>
								</v-btn>
							</template>
							<span>Esporta CSV</span>   
						</v-tooltip>
						<v-tooltip top v-if="$store.getters.checkPermission('manage_groups')">
							<template v-slot:activator="{ on: tooltip }">                             
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									color="orange"
									:depressed="disabled"
									:disabled="disabled"
									@click.native.stop="manage('group')">
									<v-icon>mdi-account-group</v-icon>
								</v-btn>
							</template>
							<span>Gestisci Gruppi</span>   
						</v-tooltip>
						<v-tooltip top v-if="$store.getters.checkPermission('manage_tasks')">
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									v-on="{ ...tooltip }"
									fab
									dark
									small
									color="teal"
									:depressed="disabled"
									:disabled="disabled"
									@click.native.stop="manage('task')">
									<v-icon>mdi-clipboard-account-outline</v-icon>
								</v-btn>
							</template>
							<span>Gestisci Mansioni</span>   
						</v-tooltip>
					</v-speed-dial>
				
				</v-card>

				<footer-logo></footer-logo>


			</v-container>

			<person-crud
				:info="info"
				:selected-ids="selectedIds"
				:element="element"
			></person-crud>

			<person-permissions-dialog
				:info="info"
			></person-permissions-dialog>

			<dissociates-person-dialog
			></dissociates-person-dialog>

		</v-col>
	</v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'
import PersonCrud from '@/components/modules/people/PersonCrud'
import PersonPermissionsDialog from '@/components/modules/people/PersonPermissionsDialog'
import DissociatesPersonDialog from '@/components/modules/people/DissociatesPersonDialog'

export default {
	name: 'people-crud',
	components: {
		PersonCrud,
		HeaderCrud,
		PersonPermissionsDialog,
		DissociatesPersonDialog
	},
	mixins: [DataTableMixin],
	props: {
		element: Number,
	},

	data() {
		return {
			disabled: true,
			fab: false,
			info: {},
			selected: [],
			search: '',
			totalItems: 0,
			people: [],
			loading: false,
			apiTimeout: null,
			pagination: null,
			headers: [
				{
					'text': 'Azioni',
					'value': 'actions',
					'sortable': false					
				},
				{
					'text': 'Cognome e Nome',
					'value': 'full_name',
					filter: (value) => this.customSearch(value, 'full_name')
				},
				{
					'text': 'Dipendente',
					'value': 'employee',
					filter: (value) => this.customSelectSearch(value, 'employee')
				},
				{
					'text': 'Mansioni',
					'value': 'tasks',
					'sortable': false,
					filter: (value) => this.customTasksSearch(value)
				},
				{
					'text': 'Stato',
					'value': 'person_status',
					filter: (value) => this.customSelectSearch(value, 'person_status')
				},
				{
					'text': 'Utente',
					'value': 'user_email',
					filter: (value) => this.customSearch(value, 'user_email')
				},
			],
		}
	},

	computed: {
		tasksFilter() {
			let filter = {name: 'Nessuna Mansione', id: 0}
			if(this.info.tasks) {
				return [filter, ...this.info.tasks]
			} else {
				return [filter]
			}
		},
		actions() {
			if(this.$store.getters.getUserPerson.role == 'superuser') {
				return [
					{
						'icon': 'mdi-pencil',
						'text': 'Modifica',
						'event': 'editPerson'
					},
					{
						'icon': 'mdi-delete-outline',
						'text': 'Elimina',
						'event': 'destroyPerson',
						'condition_key': 'role',
						'condition_operator': 'in',
						'condition_value': ['staff', 'volunteer']
					},
					{
						'icon': 'mdi-lock-check',
						'text': 'Cambia Permessi',
						'event': 'editPermissions',
						'condition_key': 'role',
						'condition_operator': 'in',
						'condition_value': ['staff']
					},
					{
						'icon': 'mdi-account-remove-outline',
						'text': 'Disassocia',
						'event': 'dissociatePerson',
						'condition_key': 'user_email',
						'condition_operator': '!=',
						'condition_value': null						
					},
				]
			} else {
				return [
					{
						'icon': 'mdi-pencil',
						'text': 'Modifica',
						'event': 'editPerson'
					},
					{
						'icon': 'mdi-delete-outline',
						'text': 'Elimina',
						'event': 'destroyPerson',
						'condition_key': 'role',
						'condition_operator': 'in',
						'condition_value': ['staff', 'volunteer']
					},
				]				
			}
		},

		tasksById() {
			return this.$eventBusUtility.tasksById(this.info.tasks)
		},

		filteredItems() {
			return this.people.filter((item) => {
            	return !item.full_name ? false : item.full_name.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1
			})
		}		
		
	},

	methods: {

		getData() {
			this.loading = true

			this.axios.get('people/').then(response => {
				this.loading = false
				this.people = response.data.data
				this.totalItems = response.data.data.length

			}, response => {
				// error callback
				this.loading = false
			})
		},

		show(item) {
			this.$eventBusUtility.$emit('showPeople', item)
		},

		create() {
			this.$eventBusUtility.$emit('createPerson')
		},

		manage(type) {
			this.$eventBusUtility.$emit('managePeople', type)
		},

		customSearch(value, key) {
			if (this.columnsFilter[key] == null) return true
			return value == null ? false : value.toLocaleLowerCase().indexOf(this.columnsFilter[key].toLocaleLowerCase()) > -1
		},
		
		customSelectSearch(value, key) {
			if (this.columnsFilter[key] == null) return true
			return value == null ? false : value == this.columnsFilter[key]
		},

		customTasksSearch(value) {
			if(this.columnsFilter['tasks'] == 0) {
				return value.length == 0
			}
			if (this.columnsFilter['tasks'] == null) return true
			return value == null ? false : value.includes(this.columnsFilter['tasks']) 
		},		

	},

	created() {

		// Get info data for model
		this.axios.get('people-info').then(response => {
			// get info data
			this.info = response.data.info
			this.getData()
			this.$store.commit('END_LOADING')
			
			// if from notification
			if(this.element) {
				this.show({id: this.element})
			}

		}, response => {
			// error callback
			this.loading = false
		})

	},

	mounted() {
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.$set(this, 'selected', [])
			this.getData()
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
	},
}

</script>