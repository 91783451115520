<template>
    <v-row no-gutters class="mx-0 px-2">
        <v-col cols="12" class="pa-0">
            <v-toolbar flat class="transparent">
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" @click="prev()" :disabled="page == 0" text small icon class="ml-4 mr-1">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Precedente</span>
                </v-tooltip>
                <!-- <v-menu
                    ref="menu"
                    :nudge-left="40"
                    transition="scroll-y-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <div v-ripple class="rounded-lg text-center cursor-pointer" style="width: 200px" v-on="on">
                            <v-icon color="primary">today</v-icon>
                            <span class="text-capitalize px-2 body-2">{{ formatCurrentDate }}</span>
                        </div>
                    </template>
                    <v-date-picker
                        v-model="today"
                        locale="It"
                        scrollable
                        color="primary"
                        :first-day-of-week="1"
                        @change="getData()"
                        @input="getData()">
                    </v-date-picker>
                </v-menu> -->
                <v-spacer></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" @click="next()" :disabled="page >= (Object.keys(events).length / daysToShow) -1" text small icon class="mr-4 ml-1">
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn>
                    </template>
                    <span>Successiva</span>
                </v-tooltip>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-col>


        <div class="mx-0 fill-height pt-4 body-page px-2" style="width: 100%; margin-top: 12px">

            <div class="d-flex align-start" style="width: 100%" ref="calendarDays">
                <template v-for="(day, d) in showEvents">
                    <div class="px-1" :key="d" :style="{'width': dayWidth + 'px'}">
                        <v-card class="rounded-lg mb-2" outlined style="width:100%">
                            <div dense dark flat class="text-center pa-2" :class="setColorTypeDay(d)">{{ formatDateColumn(d) }}</div>
                        </v-card>
                    </div>
                </template>
            </div>

            <div class="d-flex" style="width: 100%">
                <template v-for="(day, d) in showEvents">

                    <div class=" px-1"  :key="d" :style="{'width': dayWidth + 'px'}">
                        <v-col cols="12" class="pa-0 pt-1">
                            <template v-if="showEvents[d]">
                                <lesson v-for="lesson in showEvents[d]['data']"
                                    :model="lesson"
                                    :subscriptions-course="showEvents[d]['subscriptions_course']"
                                    :key="'shift_'+lesson.id"
                                    class="background-2 rounded-lg mb-2">
                                </lesson>
                            </template>

                        </v-col>
                    </div>
                </template>
            </div>            

        </div>

        <lesson-attendances

        ></lesson-attendances>

    </v-row>
</template>

<script>
import CalendarMixin from '@/mixins/CalendarMixin'
import Lesson from '@/components/modules/courses/Lesson'
import LessonAttendances from '@/components/modules/courses/LessonAttendances'

export default {
	name: 'calendar-lessons',
  	components: {
		Lesson,
		LessonAttendances
	},
	mixins: [CalendarMixin],
	props: {
		course: Object,
	},
	data() {
		return {
			page: 0,
			showEvents: [],
		}
	},


	methods: {
		getData() {
			if(this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {                
				this.loading = true

				let options = {
					course_id: this.course.id,
				}
				this.axios.get('lessons', {params: options}).then(response => {
					this.events = response.data.data.days

					// start from next lesson
					let index = Object.keys(this.events).findIndex((key) => {
						// search lesson after or equal today
						return moment(key).isSameOrAfter(moment().format('YYYY-MM-DD'))
					})
					if(index == -1)
						this.page = 0
					else
						this.page = Math.floor(index / this.daysToShow)

					this.goTo(this.page)
                    
					this.loading = false
				},
				response => {
					this.loading = false
				})
			}, 300)
		},

		prev() {
			if(this.page > 0){
				this.page -=1
				this.goTo(this.page)
			}
		},

		next() {
			if(this.page < Object.keys(this.events).length -1) {
				this.page +=1
				this.goTo(this.page)
			}
		},
        
		goTo(page) {
			this.showEvents = Object.keys(this.events).slice(this.page * this.daysToShow, (this.page + 1) * this.daysToShow).reduce((result, key) => {
				result[key] = this.events[key]
				return result
			}, {})
		}

      
	},

	mounted() {
		//window.addEventListener('resize', this.calcDayWidth)
		this.calcDayWidth()
        
		this.$eventBusUtility.$on('refreshCalendar', (modelDef) => {
			if(modelDef == 'lessons') {
				this.getData()
			}

		})        
		this.getData()
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshCalendar')
		window.removeEventListener('resize', this.calcDayWidth)
	},    
}
</script>

<style>

</style>