<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Aule"
				:totalItems="totalItems"
				icon="mdi-seat-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-data-iterator
					:items="rooms"
					:search="search"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o">
								<div class="pa-2 d-inline-flex" style="width: 100%">

									<div style="width: 50px; vertical-align: middle; margin: auto;">
										<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
											<v-icon>{{ item.icon }}</v-icon>
										</v-avatar>
									</div>


									<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
										<div>
											<div class="body-1">{{ item.name }}</div>
										</div>
									</div>
									<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto" v-if="$store.getters.checkPermission('manage_rooms')">
										<actions-menu
											:item="item"
											:actions="actions"
										></actions-menu>
									</div>

								</div>
							</v-card>
						</template>

					</template>
				</v-data-iterator>

				<v-btn
					v-if="$store.getters.checkPermission('manage_rooms')"
					:class="{'mb-16': $store.getters.showBottomNavigator}"
					color="primary"
					fixed
					fab
					bottom
					right
					@click.native.stop="create()">
					<v-icon>mdi-plus</v-icon>
				</v-btn>

				<footer-logo></footer-logo>

			</v-container>


			<room-editor

			></room-editor>

		</v-col>
	</v-row>
</template>
<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import RoomEditor from '@/components/modules/courses/RoomEditor'

export default {
	name: 'rooms-crud',
	components: {
		HeaderCrud,
		RoomEditor,
	},

	data() {
		return {
			fab: null,
			search: '',
			rooms: [],
			timeout: null,
			totalItems: 0,
			loading: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editRoom'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyRoom',
					'permission': 'manage_rooms',
				},
			],
		}
	},

	mounted() {
		this.getData()
		this.$eventBusUtility.$on('refreshTable', (modelDef) => {
			this.getData()
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('refreshTable')
	},

  	methods: {

		create() {
			this.$eventBusUtility.$emit('createRoom')
		},

		getData() {
			if(this.timeout) clearTimeout(this.timeout)

			this.timeout = setTimeout(() => {
				this.loading = true
				this.axios.get('rooms').then(response => {
					this.rooms = response.data.data
					this.totalItems = response.data.meta.total
					this.loading = false
				}).catch(() => {
					this.loading = false
				})
			}, 500)
		},
	},
}
</script>