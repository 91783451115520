<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Checklist"
				:totalItems="totalItems"
				icon="mdi-clipboard-text"
				:dates.sync="dates">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

            <v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">

                <v-card :elevation="1" class="background" width="100%">

                    <v-data-table class="background-2"
                        :options.sync="pagination"
                        :headers="headers"
                        :items="checklists"
                        :server-items-length="totalItems"
                        :loading="loading"
                        v-model="selected" 
                        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                        show-select
                        :mobile-breakpoint="0"
                        ref="vDataTable"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
                            showFirstLastPage: true
                        }">
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
                        </template>

                        <template v-slot:body.prepend>
                            <tr>
                                <td>
                                    <v-icon >mdi-magnify</v-icon>
                                </td>
                                <td>
                                    <v-tooltip top v-if="!isEmptyColumnsFilter">
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
                                                <v-icon>mdi-progress-close</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Svuota Filtri</span>
                                    </v-tooltip>
                                </td>
                                <td class="pl-0">
                                    <v-text-field
                                        dense
                                        solo
                                        flat
                                        placeholder="Turno"
                                        hide-details
                                        v-model="columnsFilter['shift']"
                                    ></v-text-field>
                                </td>
                                <td class="pl-0">
                                </td>
                                <td class="pl-0">
                                    <v-text-field
                                        dense
                                        solo
                                        flat
                                        placeholder="Persona"
                                        hide-details
                                        v-model="columnsFilter['person']"
                                    ></v-text-field>
                                </td>
                                <td class="pl-0">
                                    <v-select
                                        :items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
                                        v-model="columnsFilter['certified']"
                                        placeholder="Conforme"
                                        hide-details
                                        clearable
                                        dense
                                        solo
                                        flat									
                                    ></v-select>
                                </td>
                                <td class="pl-0">
                                    <v-select
                                        :items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
                                        v-model="columnsFilter['note']"
                                        placeholder="Note"
                                        hide-details
                                        clearable
                                        dense
                                        solo
                                        flat									
                                    ></v-select>
                                </td>
                                <td class="pl-0">
                                    <v-select
                                        :items="[{'text': 'Si', 'value': true}, {'text': 'No', 'value': false}]"
                                        v-model="columnsFilter['expired']"
                                        placeholder="Scaduta"
                                        hide-details
                                        clearable
                                        dense
                                        solo
                                        flat									
                                    ></v-select>
                                </td>
                            </tr>                                        
                        </template>

                        <template v-slot:item="{item, select, isSelected}">
                            <tr class="cursor-pointer">
                                <td>
                                    <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                                </td>
                                <td style="white-space: nowrap" @click="show(item.id)">
                                </td>								
                                <td @click="show(item.id)">
                                    {{ item.shift ? item.shift.full_name : '' }}
                                </td>
                                <td @click="show(item.id)">
                                    {{ item.certified_at ?  moment(item.certified_at).format('LLL') : '' }}
                                </td>
                                <td @click="show(item.id)">
                                    {{ item.person ? item.person.full_name : '' }}
                                </td>
                                <td @click="show(item.id)">
                                    <v-icon>{{ item.compliant ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                </td>
                                <td @click="show(item.id)">
                                    <v-icon>{{ item.note ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                </td>
                                <td @click="show(item.id)">
                                    <v-icon>{{ item.expired == 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:footer.page-text="{ pageStart, pageStop }">
                            <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                            Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
                        </template>

                        <template v-slot:footer="{ props }" v-if="$vuetify.breakpoint.smAndDown">
                            <div class="text-center d-flex background pa-4">
                                <v-btn fab small class="mr-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page == 1" @click="prevPage()">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-text-field
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    prefix="#"
                                    label="Pagina"
                                    type="number"
                                    :min="1"
                                    :max="props.pagination.pageCount"
                                    v-model="newPageToJump">
                                    <template v-slot:append-outer>
                                        <v-btn dark color="primary" @click="jumpToPage()">
                                            Vai
                                        </v-btn>						
                                    </template>
                                </v-text-field>
                                <v-btn fab small class="ml-4 ma-auto elevation-0" color="background-1" :disabled="pagination.page ==  props.pagination.pageCount" @click="nextPage()">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>					
                            </div>
                            <div class="text-center caption pr-4">
                                <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                                Da {{ props.pagination.pageStart }} a {{ props.pagination.pageStop }} di {{ totalItems }}
                            </div>

                        </template>
                    </v-data-table>
                    
                
                </v-card>

                <footer-logo></footer-logo>


            </v-container>
        </v-col>

        <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="scroll-x-reverse-transition"
            scrollable
            style="overflow-x: hidden">
            <v-card class="rounded-0 pa-0 elevation-0">
                <v-card-title class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn icon @click.native.stop="closeShow()"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title> 
                <v-card-text class="pa-0">
                    <checklist-editor v-if="currentId" :id="currentId"></checklist-editor>
                </v-card-text>
            </v-card>
        </v-dialog>
        
	</v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'
import HeaderCrud from '@/components/common/HeaderCrud'
import ChecklistEditor from '@/components/modules/checklists/ChecklistEditor'

export default {
	name: 'checklists-crud',
	components: {
		HeaderCrud,
		ChecklistEditor
	},
	mixins: [DataTableMixin],
	props: {
		element: {
			type: Number,
			default: null
		},
	},

	data() {
		return {
			currentId: null,
			dates: [moment().subtract(30, 'days').format('Y-MM-DD'), moment().format('Y-MM-DD')],
			fab: false,
			selected: [],            
			checklists: [],
			showDialog: false,
			totalItems: 0,
			apiTimeout: null,
			loading: false,
			pagination: {
				'sortBy': ['id'],
				'sortDesc': [false],
				'page': 1,
				'rowsPerPage': 10,
				'totalItems': 0,
			},
			headers: [
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Turno',
					'value': 'shift',
					'sortable': false
				},
				{
					'text': 'Data certificazione',
					'value': 'certified_at'
				},
				{
					'text': 'Utente Certificante',
					'value': 'user',
					'sortable': false
				},
				{
					'text': 'Risultato certificato',
					'value': 'compliant',
				},
				{
					'text': 'Note',
					'value': 'note'
				},
				{
					'text': 'Scaduta',
					'value': 'expired'
				},
			],            
		}
	},

	watch: {
		dates: {
			handler(newVal) {
				if(newVal[0] && newVal[1])
					this.getData()
			},
			deep: true,
		},
	},   

	methods: {

		closeShow() {
			this.currentId = null
			this.showDialog = false
		},

		getData() {
			if(this.apiTimeout) {
				clearTimeout(this.apiTimeout)
			}

			this.apiTimeout = setTimeout(() => {

				this.loading = true
					
				let dataObject = {
					page: this.pagination.page,
					per_page: this.pagination.itemsPerPage,
					sortBy: this.pagination.sortBy,
					sortDesc: this.pagination.sortDesc,
					columnsFilter: this.columnsFilter,
					dates: [],
				}

				if(moment(this.dates[1]).isBefore(this.dates[0])) {
					let d = this.dates[0]
					dataObject.dates[0] = this.dates[1]
					dataObject.dates[1] = d
				} else {
					dataObject.dates = this.dates
				}	

				this.axios.get('checklists', {params: dataObject}).then(response => {
					this.loading = false
					this.checklists = response.data.data
					this.totalItems = response.data.meta.total

				}, response => {
					// error callback
					this.loading = false
				})
			}, 500)
		},

		show(id) {
			this.currentId = id
			this.showDialog = true
		},

	},

	created() {
		this.getData()
        
		// if from notification
		if(this.element) {
			this.show(this.element)
		}
	},    
}
</script>