

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import AnalyticsIndex from '@/components/modules/analytics/AnalyticsIndex'

export const analytics = [
	{
		path: '/analytics',
		name: 'analytics',
		component: AnalyticsIndex,
		beforeEnter: ifAuthenticated
	},
]