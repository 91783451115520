<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">
		<v-container fluid class="pa-0" style="position: fixed;" :class="{'open-nav': $store.getters.getSidebar && $vuetify.breakpoint.mdAndUp}">

			<v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
				<v-card-title class="pa-0">
					<v-row no-gutters>
						<v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
							<div class="pl-0 font-weight-bold">Nuova Timbratura</div>
						</v-col>
                    </v-row>
                </v-card-title>
            </v-card>
		</v-container>
		<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 110px; height: 100%">
			<v-card :elevation="1" class="background" style="height: 100%">


                <div ref="map" class="attendances-map"></div>

                <div class="text-center">                    
                    <p><strong>Precisione:</strong> {{accuracyMessage}}</p>
                    <v-btn
                        color="primary"
                        large
                        class="mt-5"
                        :disabled="!enableSend"
                        @click="sendAttendance"
                    >INVIA TIMBRATURA</v-btn>
                </div>
            </v-card>
        </v-container>
        </v-col>

        <confirm-dialog
            v-model="confirmEnableLocation"
            mainTitle="Devi attivare GPS"
            secondTitle="Per poter effettuare una timbratura è necessario attivare la funzione GPS dalle impostazioni del tuo dispositivo."
            :atExit="() => $eventBusUtility.navigateTo('home')"
            @confirm="enableLocation"
        ></confirm-dialog>
    </v-row>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"

export default {
	name: 'attendances-create',
	data() {
		return {
			map: null,
			position: null,
			watcher: null,
			confirmEnableLocation: false,
			posOptions: {
				enableHighAccuracy: false,
				timeout: 30000,
				maximumAge: 30000
			},
			minAccuracy: 50
		}
	},

	mounted() {
		const loader = new Loader({
			apiKey: "AIzaSyDuAp4HjXaWQ5Yv0qDDiZ92M05EoUPXhBM",
			version: "weekly",
			// ...additionalOptions,
		});

		loader.load().then(async () => {
			const { Map } = await google.maps.importLibrary("maps");

			this.map = new Map(this.$refs.map, {
				// center: myLatlng,
				zoom: 16,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				fullscreenControl: false,
				mapTypeControl: false,
				panControl: false,
				streetViewControl: false
			})

			this.init()
		});
	},

	computed: {
		enableSend() {
			if(this.position) {
				return this.position.coords.accuracy <= this.minAccuracy
			}

			return false
		},

		accuracyMessage() {
			return this.enableSend ? Math.floor(this.position.coords.accuracy).toString() + ' metri' : 'Troppo grande (oltre ' + this.minAccuracy + ' metri)'
		}
	},

	beforeDestroy() {
		navigator.geolocation.clearWatch(this.watcher)
	},
    
	methods: {
		init() {
			if(this.$store.getters.isMobile) {
				cordova.plugins.diagnostic.getLocationAuthorizationStatus((status) => {
					switch(status){
						case cordova.plugins.diagnostic.permissionStatus.NOT_REQUESTED:
							cordova.plugins.diagnostic.requestLocationAuthorization((new_status) => {
								switch(new_status){
									case cordova.plugins.diagnostic.permissionStatus.GRANTED:
									case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
										window.cordova.plugins.diagnostic.isLocationEnabled(enabled => {
											if (!enabled) {
												this.confirmEnableLocation = true
											} else {
												this.getCurrentPosition()
											}
										})
										break
									}
									
							}, function(error){
								alert(error)
							}, cordova.plugins.diagnostic.locationAuthorizationMode.WHEN_IN_USE)
							break
						case cordova.plugins.diagnostic.permissionStatus.DENIED_ONCE:
						case cordova.plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
							navigator.notification.confirm('VolontApp non ha accesso alla tua posizione ed è necessario per poter utilizzare questa funzione. Vuoi accedere alla impostazioni dell\'app per abilitare l\'accesso?', 
								function (i) {
									if (i === 1) {
										cordova.plugins.diagnostic.switchToSettings()
									}
								}, 'Accesso negato alla posizione', ['Si', 'No'])
							break
						case cordova.plugins.diagnostic.permissionStatus.GRANTED:
						case cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
							this.getCurrentPosition()
							break 
					}
				}, function(error){
					//alert(error);
				})

			} else {
				this.getCurrentPosition()
			}
		},
		getCurrentPosition() {
			navigator.geolocation.getCurrentPosition(position => {
				this.position = position
				let myLatlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)

				let markerPos = new google.maps.Marker({
					position: myLatlng,
					map: this.map,
					opacity: 1,
					zIndex: 100
				})

				let circle = new google.maps.Circle({
					center: myLatlng,
					radius: position.coords.accuracy,
					map: this.map,
					fillColor: '#e75e5a',
					fillOpacity: 0.7,
					strokeColor: '#e65253',
					strokeOpacity: 1,
					zIndex: 10
				})                

				this.watcher = navigator.geolocation.watchPosition((position) => {
					this.position = position
					let newPos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
					this.map.setCenter(newPos)
					markerPos.setPosition(newPos)
					circle.setCenter(newPos)
					circle.setRadius(position.coords.accuracy)
				}, (error) => {
					// console.log(error)
				}, {
					timeout: 10 * 1000,
					enableHighAccuracy: true
				})
			}, error => {
				// console.log(error)
			}, this.posOptions)
		},

		sendAttendance() {
			let device_code = ''
			if (typeof window.cordova !== 'undefined' && typeof device !== 'undefined') {
				device_code = device.uuid
			}

			let data = {
				personal_device_code: device_code,
				latitude: this.position.coords.latitude.toFixed(8),
				longitude: this.position.coords.longitude.toFixed(8),
				precision: Math.floor(this.position.coords.accuracy),
				manual: false
			}
			this.axios.post('attendances', data).then(response => {
				if(response.data.status == 'success') {
					this.$eventBusUtility.navigateTo('attendances')
				} else {
					alert('error')
				}
			})
		},

		enableLocation() {
			window.cordova.plugins.diagnostic.switchToLocationSettings()
		}
	},
}
</script>

<style scoped>
.attendances-map {
    height: 50vh;
}
</style>