<template>
	<v-row no-gutters class="fill-height" v-if="!$store.getters.isLoading">
		<v-col class="mx-0">
            <v-container fluid class="pa-0" style="position: fixed;" :class="{'open-nav': $eventBusUtility.calcOpenNav($vuetify)}">
                <v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters>

                            <v-col class="col-10 col-sm-6 col-lg-4 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold">Notifiche</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 30px">{{ totalItems }}</div>
                            </v-col>

                            <v-col class="col-sm-6 col-lg-6 ma-auto mr-0 hidden-sm-and-down">
                                <v-text-field class="font-weight-regular rounded-search"
                                    prepend-inner-icon="mdi-magnify"
                                    label="Cerca"
                                    v-model="search"
                                    clearable
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    :loading="loading">
                                    <template v-slot:append-outer>
                                        <v-icon :size="35" color="black">mdi-bell-outline</v-icon>
                                    </template>
                                    <template v-slot:progress>
                                        <v-progress-linear 
                                            color="success"
                                            absolute 
                                            height="2"
                                        ></v-progress-linear>
                                    </template>							
                                </v-text-field>
                            </v-col>

                            <v-col class="col-2 col-lg-1 text-right ma-auto mr-0 hidden-md-and-up">
                                <v-icon :size="35" color="black">mdi-bell-outline</v-icon>
                            </v-col>

                        </v-row>
                    </v-card-title>

                    <v-card-title class="px-0 pt-3 py-6 hidden-sm-and-down">
                        <v-row no-gutters>
                            <v-col class="col-6 ma-auto pa-0">
                                <v-autocomplete class="font-weight-regular rounded-search"
                                    :items="organizations"
                                    item-text="name"
                                    item-value="id"
                                    label="Associazione"
                                    v-model="filters['organization_id']"
                                    clearable
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    :loading="loading">					
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6 ma-auto pl-2">
                                <v-autocomplete class="font-weight-regular rounded-search"
                                    :items="types"
                                    label="Tipo Notifica"
                                    v-model="filters['type']"
                                    clearable
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    :loading="loading">						
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-container class="pa-0 pt-3 hidden-md-and-up" fluid>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-text-field class="rounded-search"
                                    prepend-inner-icon="mdi-magnify"
                                    label="Cerca"
                                    v-model="search"
                                    clearable
                                    hide-details
                                    rounded
                                    solo
                                    background-color="#f5f7fa"
                                    dense
                                    :loading="loading">
                                    <template v-slot:append-outer v-if="$vuetify.breakpoint.mdAndUp">
                                        <v-icon :size="35" color="black">mdi-account-supervisor-outline</v-icon>
                                    </template>
                                    <template v-slot:progress>
                                        <v-progress-linear
                                            color="success"
                                            absolute 
                                            height="2"
                                        ></v-progress-linear>
                                    </template>							
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12">
                                <v-autocomplete class="font-weight-regular rounded-search"
                                    :items="organizations"
                                    item-text="name"
                                    item-value="id"
                                    label="Associazione"
                                    v-model="filters['organization_id']"
                                    clearable
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    :loading="loading">					
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="col-12">
                                <v-autocomplete class="font-weight-regular rounded-search"
                                    :items="types"
                                    label="Tipo Notifica"
                                    v-model="filters['type']"
                                    clearable
                                    background-color="background-1"
                                    hide-details
                                    rounded
                                    solo
                                    dense
                                    :loading="loading">						
                                </v-autocomplete>
                            </v-col>
                        </v-row>                    
                    </v-container>

                </v-card>
		    </v-container>

            <v-container fluid class="pa-6 body-page" style="height: 100%" :style="{'margin-top': $vuetify.breakpoint.mdAndUp ? '200px' : '270px' }">
                <v-row class="pa-4 pb-5">
                    <v-checkbox hide-details color="primary" class="margin-auto ml-2 mr-4" v-model="all"></v-checkbox>
                    <v-btn :disabled="areSelected" text color="primary" @click="markAs('read')" class="pl-0 ml-6 margin-auto">Segna come lette</v-btn>
                    <v-btn :disabled="areSelected" text color="primary" class="margin-auto" @click="markAs('unread')">Segna come da leggere</v-btn>
                </v-row>        
                <v-data-iterator
                    :options.sync="pagination"
                    :items="notifications"
                    :server-items-length="totalItems"
                    :loading="loading"
                    :search="search"
                    :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
                    :mobile-breakpoint="0"
                    :footer-props="{
                        'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
                        showFirstLastPage: true
                    }">                
                    <template v-slot:default="{ items }">

                        <template v-for="(item, o) in items">

                            <v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" style="overflow: hidden;" :key="item.id + '_' + o" @click.stop="$eventBusUtility.goToNotifications(item)">
                                <div class="pa-2 d-inline-flex" style="width: 100%">
                                    
                                    <div style="width: 30px; vertical-align: middle; margin: auto;">
                                        <v-checkbox v-model="selected" :value="item.id" class="ml-1 ma-auto" hide-details></v-checkbox>
                                    </div>
                                    <div style="width: 45px; vertical-align: middle; margin: auto;">
                                        <v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
                                            <img src="cropped-favicon-114x114.png" alt="VolontApp">
                                        </v-avatar>			
                                    </div>

                                    <div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
                                        <div>
                                            <div class="body-2">{{ item.data.subject }}</div>
                                            <div class="caption">{{ item.data.body }}</div>
                                        </div>
                                    </div>

                                    <div class="pl-4 pr-2 text-center" style="vertical-align: middle; margin: auto">

                                        <v-tooltip v-if="item.read_at === null" top class="fs12i">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="fs18i" :size="20" color="grey lighten-1">mdi-email-outline</v-icon>
                                            </template>
                                            <span class="fs11i">Da leggere</span>
                                        </v-tooltip>
                                        <v-tooltip v-else top class="fs12i">
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" class="fs18i" :size="20" color="grey lighten-1">mdi-email-open-outline</v-icon>
                                            </template>
                                            <span class="fs11i">Letta</span>
                                        </v-tooltip>
                                        <div class="caption" style="white-space: nowrap;">{{ moment(item.created_at).startOf('second').fromNow() }}</div>

                                    </div>

                                </div>

                            </v-card>

                        </template>

                    </template>

                    <template v-slot:footer.page-text="{ pageStart, pageStop }">
                        <div class="primary--text"><span>{{ countSelected }}</span> Selezionati</div>
                        Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
                    </template>
                </v-data-iterator>


                <footer-logo></footer-logo>
                
            </v-container>
        </v-col>
    </v-row>
</template>

<script>

import DataTableMixin from '@/mixins/DataTableMixin'

export default {
	name: 'notifications',
	    mixins: [DataTableMixin],
	props: {},
	data() {
		return {
			all: false,
			search: '',
			loading: false,
			totalItems: 0,
			notifications: [],
			selected: [],
			isSelected : false,
			filters: {},
			status: [{value: 'all', text:'Tutte'}, {value: 'read', text: 'Lette'}, {value: 'unread', text:'Da leggere'}],
			avatars: [],
			pagination: {
				'sortBy': ['id'],
				'sortDesc': [false],
				'page': 1,
				'itemsPerPage': 10,
				'totalItems': 0,
			},
			types: [
				{
					'text': 'Utente Accettato',
					'value': 'USER_ACCEPTED',
				},
				{
					'text': 'Utente Rifiutato',
					'value': 'USER_REJECTED',
				},
				{
					'text': 'Utente Dissociato',
					'value': 'USER_DISSOCIATED',
				},
				{
					'text': 'Nuova Richiesta',
					'value': 'NEW_USER_REQUEST',
				},
				{
					'text': 'Nuovo Post Privato',
					'value': 'NEW_PRIVATE_POST',
				},
				{
					'text': 'Nuovo Commento',
					'value': 'NEW_POST_COMMENT',
				},
				{
					'text': 'Nuova Reazione al Post',
					'value': 'NEW_POST_REACTION',
				},
				{
					'text': 'Nuova Disponibilità',
					'value': 'NEW_USER_AVAILABILITY',
				},
				{
					'text': 'Disponibilità Approvata',
					'value': 'APPROVED_AVAILABILITY',
				},
				{
					'text': 'Disponibilità Rifiutata',
					'value': 'REJECTED_AVAILABILITY',
				},
				{
					'text': 'Disponibilità Rimossa',
					'value': 'AVAILABILITY_REMOVED',
				},
				{
					'text': 'Richiesta di disponibilità',
					'value': 'AVAILABILITY_RESPONSE',
				},
				{
					'text': 'Promemoria Turno',
					'value': 'SHIFT_REMEMBER',
				},
				{
					'text': 'Turno Modificato',
					'value': 'SHIFT_CHANGED',
				},
				{
					'text': 'Variazione Disponibilità',
					'value': 'CHANGED_AVAILABILITY',
				},
				{
					'text': 'Promemoria Lezione',
					'value': 'LESSON_REMEMBER',
				},
				{
					'text': 'Promemoria Checklist',
					'value': 'CHECKLIST_REMEMBER',
				},
				{
					'text': 'Checklist Non Valida',
					'value': 'CHECKLIST_INVALID',
				},                    {
					'text': 'Checklist Valida',
					'value': 'CHECKLIST_VALID',
				},
				{
					'text': 'Checklist Scaduta',
					'value': 'CHECKLIST_EXPIRED',
				},
				{
					'text': 'Promemoria Scadenza Brevetto',
					'value': 'PATENT_EXPIRATION_REMEMBER',
				},
				{
					'text': 'Promemoria Scadenza Superiore Brevetto',
					'value': 'SUPERVISOR_PATENT_EXPIRATION_REMEMBER',
				},
				{
					'text': 'Richiesta partecipazione turno',
					'value': 'NEW_SHIFT_REQUEST',
				},
				{
					'text': 'Esito richiesta di Disponibilità',
					'value': 'AVAILABILITY_RESPONSE',
				},
				{
					'text': 'Nuovo turno disponibile',
					'value': 'NEW_PARTICIPABLE_SHIFT',
				},
				{
					'text': 'Richiesta partecipazione corso',
					'value': 'NEW_PARTECIPANT_REQUEST',
				},
				{
					'text': 'Esito partecipazione corso',
					'value': 'REQUEST_PARTECIPANT_RESPONSE',
				},
			]
		}
	},
        
	computed: {

		organizations() {
			return this.$store.getters.getUserOrganizations
		},

		areSelected() {
			return _.isEmpty(this.selected) ? true : false
		},            

	},

	watch: {
		pagination: {
			handler() {
				this.getData()
			},
			deep: true,
		},

		all: {
			handler(newVal, oldVal) {
				if(newVal) {
					_.forEach(this.notifications, (not) => {
						this.selected.push(not.id)
					})
				} else {
					this.$set(this, 'selected', [])
				}
			}
		},

		filters: {
			handler() {
				this.getData()
			},
			deep: true,
		}
	},

	methods: {

		markAs(status) {

			this.axios.put(this.$store.getters.getAuthUrl + '/notifications/', {status: status, notifications: this.selected}).then(response => {
				// get body data
				this.getData()
				this.all = false
				this.$eventBusUtility.$emit('updateNotifications')

			}, response => {
				// error callback
			})
		},

		areSelectedAll() {
			return this.selected.length > this.notifications.length ? true : false
		},

		formatNotificationTime(date) {
			if (date) {
				return moment(date).startOf('second').fromNow()
			} else {
				return ''
			}
		},

		getData() {

			this.loading = true
					
			let dataObject = {
				page: this.pagination.page,
				per_page: this.pagination.itemsPerPage,
				sortBy: this.pagination.sortBy,
				sortDesc: this.pagination.sortDesc,
				filters: this.filters,
				search: this.search
			}                
			this.axios.get(this.$store.getters.getAuthUrl + '/notifications/', {params: dataObject}).then(response => {
				this.notifications = response.data.data.data
				this.avatars = response.data.avatars
				this.totalItems = response.data.data.total
				this.loading = false

			}, response => {
				this.loading = false
				// error callback
			})
		},

	},

	created() {
		this.getData()
		this.$store.commit('END_LOADING')

	},

	mounted() {
		this.$eventBusUtility.$on('updateNotificationsCheckbox', () => {
			this.selected.splice(0, this.selected.length)
			this.all = false
		})
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('updateNotificationsCheckbox')
	}

}
</script>

<style >
    #list-item-notification {
        height: 54px;
    }  

    #list-item-notification .v-list-item__title {
        font-size: 12px !important;
    }  

    #list-item-header  {
        padding-left: 0px !important;
        height: 54px;

    }
    .v-list-item__action {
        min-width: 48px;
    }
    .v-list-item__avatar {
        min-width: 48px;
    }
</style>
