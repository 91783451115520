<template>
	<v-row no-gutters class="pa-0 fill-height">
		<v-container fluid class="pa-0">

            <v-tabs-items v-model="tab" touchless class="fill-height">
                <v-tab-item value="tab-1" class="fill-height">

                    <vehicles-crud>
                        <template slot="tabs">
                            <v-tabs
                                class="pt-4"
                                v-model="tab"
                                background-color="white"
                                show-arrows
                                :centered="!$vuetify.breakpoint.mdAndUp">
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab href="#tab-1">
                                    Veicoli
                                </v-tab>

                                <v-tab href="#tab-2">
                                    Tipi Veicoli
                                </v-tab>

                            </v-tabs>
                        </template>
                    </vehicles-crud>
                </v-tab-item>

                <v-tab-item value="tab-2" class="fill-height">
                    <vehicletypes-crud>
                        <template slot="tabs">
                            <v-tabs
                                class="pt-4"
                                v-model="tab"
                                background-color="white"
                                show-arrows
                                :centered="!$vuetify.breakpoint.mdAndUp">
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab href="#tab-1">
                                    Veicoli
                                </v-tab>

                                <v-tab href="#tab-2">
                                    Tipi Veicoli
                                </v-tab>

                            </v-tabs>
                        </template>
                    </vehicletypes-crud>
                </v-tab-item>

            </v-tabs-items>
        </v-container>
    </v-row>
</template>

<script>

import VehiclesCrud from '@/components/modules/vehicles/VehiclesCrud'
import VehicletypesCrud from '@/components/modules/vehicles/VehicletypesCrud'

export default {
	name: 'vehicles-index',
	components: {
		VehiclesCrud,
		VehicletypesCrud
	},
	data() {
		return {
			tab: 'tab-1',
		}
	},
}
</script>

<style>

</style>