<template>
	<v-dialog v-model="showDialog" max-width="600" persistent>
		<v-card class="background-2 round-card">
			<v-card-title primary-title class="subtitle-1 background-2">{{ mainTitle }}</v-card-title>
			<v-divider></v-divider>
			<v-card-text style="min-height: 100px;" class="pt-4 body-2">{{ secondTitle }}</v-card-text>
			<slot></slot>
			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text v-if="enableClose" :color="cancel.color" @click.native.stop="close" >{{ cancel.label }}
				</v-btn>
				<v-btn v-if="exit" text :color="exit.color ? exit.color : 'error'" @click.native.stop="atExit()" >{{ exit.label ? exit.label : 'Esci' }}
				</v-btn>
				<v-btn text :color="confirm.color" @click.native.stop="executeAtConfirm" >{{ confirm.label }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'confirm-dialog',
	props: {
		value: Boolean,
		data: {
			type: Object,
			default: () => {}
		},
		mainTitle: {
			default: 'Sei sicuro di procedere con questa azione?',
			type: String
		},
		secondTitle: {
			default: 'Questa azione è irreversibile',
			type: String,
		},
		cancel: {
			default: () => { 
				return {
					color: 'primary',
					label: 'Annulla',
				}
			},
			type: Object,
		},
		enableClose: {
			type: Boolean,
			default: true
		},
		exit: {
			default: null,
			type: Object,
		},
		confirm: {
			default: () => {
				return {
					color: 'info',
					label: 'Conferma',
				}
			},
			type: Object,
		},
		atConfirm: {
			default: () => {},
			type: Function
		},
		atExit: {
			default: null,
			type: Function
		},
	},

	data() {
		return {
		}
	},

	computed: {
		showDialog: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		}
	},

	methods: {
		executeAtConfirm() {
			this.$emit('confirm', this.data)
			this.showDialog = false
		},

		close() {
			this.showDialog = false
		}
	},
}
</script>
