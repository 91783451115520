<template>
    <v-dialog v-model="open" max-width="800" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Dissocia</div>                
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="pa-0 mx-0 fill-height">

                        <v-row no-gutters class="pa-0 mx-0 fill-height">
                            <v-alert icon="mdi-delete-outline" prominent text type="error" class="ma-0 d-flex">
                                Sei sicuro di voler disassociare DEFINITIVAMENTE l'utente selezionato? In questo modo rimarrà attiva l'anagrafica delle persona ma l'utente non sarà più in grado di utilizzarla.
                            </v-alert>
                        </v-row>

                </v-container>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
                <v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="confirm()">
                    Conferma
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
	name: 'dissociates-person-dialog',
	props: {
	},    
	data() {
		return {
			model: {},
			open: false,
			fullScreen: false,
			loading: false,
		}
	},

	computed: {

	},

	watch: {
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('dissociatePerson', (person) => {
			this.openCreateDialog(person)
		})
	},

	methods: {
    
		openCreateDialog(person) {
			this.model = {...person}
			this.open = true
		},

		closeDialog() {
			this.model = _.stubObject()
			this.open = false
		},

		confirm() {
			this.loading = true
			this.axios.get('dissociate-user/' + this.model.id).then(response => {
				this.loading = false
				this.closeDialog()
		        this.$eventBusUtility.$emit('refreshTable')

			}, response => {

			})
		},


	},

	beforeDestroy() {
		this.$eventBusUtility.$off('dissociatePerson')
	},

}
</script>

<style>

</style>