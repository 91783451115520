<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

            <v-container fluid class="pa-0" style="position: fixed;" :class="{'open-nav': $store.getters.getSidebar && $vuetify.breakpoint.mdAndUp}">
                <v-card class="rounded-lg pa-6 pb-2 elevation-0 transparent">
                    <v-card-title class="pa-0">
                        <v-row no-gutters>  
                            <v-col class="col-12 ma-auto ml-0">
                                <div class="pl-0 font-weight-bold">Associazione</div>
                                <div class="pl-0 font-weight-bold" style="font-size: 30px">{{ organization.name }}</div>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <slot name="tabs">


                    </slot>

                </v-card>
            </v-container>

            <v-container fluid class="pa-6 body-page pt-8" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 160px; height: 100%">
                <v-card :elevation="1" class="background" width="100%">
                    <validation-observer ref="fields">
                        <v-row class="pb-2 mx-0 pt-4">
                            <div class="ma-auto pt-4 text-center">
							<image-input v-model="organization.fileImage">
								<template v-slot:activator="{preview}">
									<v-avatar :size="$vuetify.breakpoint.mdAndUp ? 124 :100" v-ripple v-if="!organization.avatar && !preview" class="grey lighten-3 mb-3 cursor-pointer">
                                        <img src="cropped-favicon-114x114.png" alt="VolontApp">
									</v-avatar>
									<v-avatar v-else
										:size="$vuetify.breakpoint.mdAndUp ? 124 :100"
										v-ripple
										class="custom-avatar mb-3 cursor-pointer"
										:style="{'background-image': 'url(' + (preview ? preview : organization.avatar) + ')'}">
									</v-avatar>
								</template>
							</image-input>                                
                            </div>
                        </v-row>
                        <v-row class="pb-2 mx-0 pt-4">
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        type="text"
                                        label="Nome"
                                        v-model="organization['name']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        ref="name"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col class="col-12 py-0 pt-3 px-6">
                                <validation-provider name="Descrizione" rules="max:255" v-slot="{ errors }">
                                    <v-textarea
                                        label="Descrizione"
                                        name="description"
                                        v-model="organization['description']"
                                        :error-messages="errors"
                                        type="text"
                                        outlined
                                        dense
                                        counter
                                        ref="description"
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                    
                    </validation-observer>

                    <v-divider></v-divider>
                    <v-card-actions class="pa-6 pb-10 justify-center elevation-0">
                        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                        <v-btn color="success" min-width="100px" height="40" class="ml-4" @click="updateOrganization()">Salva</v-btn>
                    </v-card-actions>                
                </v-card>
            </v-container>

        </v-col>
      
    </v-row>

</template>

<script>
import ImageInput from '@/components/common/ImageInput'

export default {
	name: 'organization-editor',
	components: {
		ImageInput,
	},

	props: {

	},

	data() {
		return {
			tab: 'tab-1',
			avatar: null,
			organization: {...this.$store.getters.getCurrentOrganization},
		}
	},


	methods: {

		updateOrganization() {
			this.saving = true
			this.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}

				let formData = new FormData()
				for (let key in this.organization) {
                    
					if (key != 'fileImage') {
						formData.append(key, this.organization[key])
					} else {
						formData.append(key, this.organization[key] ? this.organization[key] : '')
					}
				}
				formData.append('_method', 'PUT')

				this.axios.post(this.$store.getters.getAuthUrl + '/organizations-lite/' + this.organization.id, formData).then((response => {
					this.saving = false
					this.$store.commit('SET_SNACKBAR', {timeout: 6000, text: 'Associazione aggiornata', value: true, color: 'success'})
				}))

			}).catch(() => {

			})
		},

		uploadImage() {
			this.saving = true
			this.$store.commit('SET_ORGANIZATION_AVATAR', this.avatar)
		},

	}
}
</script>
