<template>
    <v-dialog v-model="open" max-width="1100" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card class="rounded-lg pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Duplica Disponibilità</div>
                    <div class="pl-0 font-weight-bold" style="font-size: 24px">{{ shift.name ? shift.name : ''}} {{ shift.shifttype ? '['+ shift.shifttype.name +']' : '' }}</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="px-0 pt-6 mx-0">
                    <validation-observer ref="duplicateAvailabilityFields">

                            <v-row class="pb-2 mx-0 pt-2">
                                <v-col class="col-6 px-6 py-0">
                                    <v-subheader class="pl-0">Persone</v-subheader>
                                </v-col>
                                <v-col class="col-6 px-6 py-0">
                                    <v-checkbox
                                        label="Seleziona Tutti"
                                        v-model="selectAll"
                                        color="primary"
                                        class="pr-4 ma-auto mr-0 float-right"
                                        :input-value="[true, false]"
                                    ></v-checkbox>                                    
                                </v-col>
                                <v-col class="col-12 px-6 py-0 d-flex">
                                    <v-checkbox v-for="(available, p) in shift.availables" :key="p"
                                        :label="available.person.full_name"
                                        v-model="availabilities[available.id]"
                                        color="primary"
                                        class="pr-4 ma-0"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <v-menu
                                        ref="repeat_until"
                                        :close-on-content-click="false"
                                        v-model="datepicker_menu['repeat_until']"
                                        :return-value.sync="model['repeat_until']"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        :nudge-right="40"
                                        style="width: 100%">
                                        <template v-slot:activator="{ on }">
                                            <validation-provider name="Ripeti Fino" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-on="on"
                                                    label="Ripeti Fino*"
                                                    :error-messages="errors"
                                                    append-icon="event"
                                                    readonly
                                                    :value="$eventBusUtility.formatDate(model['repeat_until'])"
                                                    outlined
                                                    @click:clear="model['repeat_until'] = ''"
                                                    dense
                                                ></v-text-field>
                                            </validation-provider>
                                        </template>
                                        <v-date-picker 
                                            v-model="model['repeat_until']"
                                            color="primary"
                                            locale="It"
                                            scrollable
                                            :min="setMinRepeatUntil"
                                            :first-day-of-week="1"
                                            @input="$refs['repeat_until'].save(model['repeat_until'])">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                    <validation-provider name="Ripeti Ogni" rules="required|integer" v-slot="{ errors }">
                                        <v-text-field
                                            type="number"
                                            placeholder="Ripeti Ogni*"
                                            v-model="model['repeat_each']"
                                            :error-messages="errors"
                                            outlined
                                            suffix="Giorni"
                                            dense>
                                        </v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col class="col-12 px-6 py-0 d-flex">
                                    <v-checkbox
                                        label="Sovrascrivi disponibilità esistenti"
                                        v-model="model.overwrite_existing_availabilities"
                                        color="primary"
                                        class="ma-0"
                                        :input-value="[true, false]"
                                    ></v-checkbox>
                                </v-col>                                

                                <v-col class="col-12 px-6 py-0">
                                    <v-subheader class="pl-1">Ripetere se</v-subheader>
                                    <v-row class="mx-0">
                                        <v-checkbox
                                            label="Lunedì"
                                            v-model="repeat['monday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Martedì"
                                            v-model="repeat['tuesday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Mercoledì"
                                            v-model="repeat['wednesday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Giovedì"
                                            v-model="repeat['thursday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Venerdì"
                                            v-model="repeat['friday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Sabato"
                                            v-model="repeat['saturday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Domenica"
                                            v-model="repeat['sunday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Feriale"
                                            v-model="repeat['weekday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Pre-Festivo"
                                            v-model="repeat['preholiday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Festivo"
                                            v-model="repeat['holiday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Sempre"
                                            v-model="repeat['always']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                    </v-row>
                                </v-col>
                                <v-col class="col-12 px-6 py-0">
                                    <v-subheader class="pl-1">Non Ripetere se</v-subheader>
                                    <v-row class="mx-0 mb-6">
                                        <v-checkbox
                                            label="Lunedì"
                                            v-model="not_repeat['monday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Martedì"
                                            v-model="not_repeat['tuesday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Mercoledì"
                                            v-model="not_repeat['wednesday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Giovedì"
                                            v-model="not_repeat['thursday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Venerdì"
                                            v-model="not_repeat['friday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Sabato"
                                            v-model="not_repeat['saturday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Domenica"
                                            v-model="not_repeat['sunday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Feriale"
                                            v-model="not_repeat['weekday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Pre-Festivo"
                                            v-model="not_repeat['preholiday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                        <v-checkbox
                                            label="Festivo"
                                            v-model="not_repeat['holiday']"
                                            color="primary"
                                            class="pr-2"
                                            :input-value="[true, false]"
                                        ></v-checkbox>
                                    </v-row>
                                </v-col>

                            </v-row>

                    </validation-observer>

                </v-container>

            </v-card-text> 
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import Vue from 'vue'

export default {
	name: 'duplicate-availability-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			shift: {},
			model: {},
			open: false,
			fullScreen: false,
			loading: false,
			datepicker_menu: {},
			availabilities: {},
			repeat: {},
			not_repeat: {},
			selectAll: true,
		}
	},

	computed: {
		setMinRepeatUntil() {
			return moment(this.shift.started_at).format('YYYY-MM-DD')
		}
	},

	watch: {
		selectAll: {
			handler(newValue, oldValue) {
				_.forEach(this.shift.availables, (item, value) => {
					Vue.set(this.availabilities, item.id, newValue)
				})
			}
		}
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('duplicateAvailability', (data) => {
			this.openEditDialog(data)
		})
	},

	methods: {
    

		openEditDialog(data) {
			this.shift = {...data}
			Vue.set(this.model, 'repeat_each', 1)
			_.forEach(this.shift.availables, (item, value) => {
				Vue.set(this.availabilities, item.id, true)
			})
			this.open = true
		},

		closeDialog() {
			this.loading = false
			this.model = _.stubObject()
			this.shift = _.stubObject()
			this.repeat = _.stubObject()
			this.not_repeat = _.stubObject()
			this.availabilities = _.stubObject()
			this.open = false
		},

		save() {
			this.$refs.duplicateAvailabilityFields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true
				// is in edit
				let data = this.model
				data.shift_id = this.shift.id
				data.availabilities = this.availabilities
				data.repeat = this.repeat
				data.not_repeat = this.not_repeat
                
				this.axios.post('shifts/'+this.shift.id+'/duplicate-availabilities', data).then(response => {
					// get body data
					this.loading = false
					this.closeDialog()
					this.$store.commit('SET_SNACKBAR', {
						timeout: 6000,
						text: 'Disponibilità duplicate',
						value: true,
						color: 'success'
					})

				}, response => {
					this.loading = false
				})
			}).catch(() => {
				this.loading = false
			})
		},

	}

}
</script>

<style>

</style>