<template>
    <v-container fluid class="pa-0 fill-height d-block">


        <v-row no-gutters class="mx-0 px-2">
            <v-col cols="12" class="pa-0">
                <v-tabs
                    class="pt-4"
                    v-model="calendarTab"
                    background-color="white"
                    show-arrows
                    :centered="!$vuetify.breakpoint.mdAndUp">
                    <v-tabs-slider></v-tabs-slider>

                    <template v-for="(calendar, c) in filterCalendars">
                        <v-tab v-if="calendar.id" :href="'#'+calendar.id.toString()" :key="c" @click="selectCalendar(calendar)">
                            {{ calendar.name }}
                        </v-tab>
                    </template>
                </v-tabs>
                <div class="text-center caption pt-1" v-if="$vuetify.breakpoint.xs && $store.getters.checkSetting('calendar', 'calendar_group') && currentGroupCalendar.name">
                    {{ currentGroupCalendar.name }}
                </div>
            </v-col>
            <v-col cols="12" class="pa-0 d-print-none">

                <v-toolbar flat>
                    <template v-if="!$vuetify.breakpoint.smAndDown">
                        <v-btn depressed class="px-3" @click="showFilters = !showFilters">Filtri</v-btn>
                        <v-btn depressed class="px-3" @click="printCalendar()">Stampa</v-btn>
                        <v-btn depressed class="px-3" @click="getCsv()">Esporta</v-btn>
                        <v-spacer></v-spacer>
                    </template>
                    <v-spacer v-else></v-spacer>


                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" v-on="on" @click="prev()" text small icon class="ml-4 mr-1">
                                <v-icon>keyboard_arrow_left</v-icon>
                            </v-btn>
                        </template>
                        <span>Precedente</span>
                    </v-tooltip>

                    <v-menu
                        v-model="datepicker"
                        :nudge-left="40"
                        :close-on-content-click="false"
                        transition="scroll-y-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px">
                        <template v-slot:activator="{ on }">
                            <div v-ripple class="rounded-lg text-center cursor-pointer" style="width: 200px" v-on="on">
                                <v-icon color="primary">today</v-icon>
                                <span class="text-capitalize px-2 body-2">{{ formatCurrentDate }}</span>
                            </div>
                        </template>
                        <v-date-picker
                            v-model="today"
                            locale="It"
                            scrollable
                            color="primary"
                            :first-day-of-week="1"
                            @change="getData()"
                            @input="getData()">
                        </v-date-picker>
                    </v-menu>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" v-on="on" @click="next()" text small icon class="mr-4 ml-1">
                                <v-icon>keyboard_arrow_right</v-icon>
                            </v-btn>
                        </template>
                        <span>Successiva</span>
                    </v-tooltip>

                    <template v-if="$vuetify.breakpoint.xs">
                        <v-menu v-if="$store.getters.checkSetting('calendar', 'calendar_group')"
                            rounded
                            offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small color="primary" class="ml-1" v-on="on">
                                    <v-icon>mdi-calendar-blank-multiple</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item dense link v-for="(calendargroup, cg) in calendargroups" :key="cg" @click="setGroupCalendar(calendargroup)">
                                <v-list-item-title link>{{ calendargroup.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>                    
                    </template>

                    <template v-if="!$vuetify.breakpoint.smAndDown">
                        <v-spacer></v-spacer>
                        <v-progress-circular
                            v-show="calendarShiftLoaded"
                            size="30"
                            class="mr-2"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                     
                        <v-btn-toggle v-model="type" borderless dense>
                            <v-btn value="month" class="px-3">
                                <span class="hidden-sm-and-down">Mese</span>
                                <v-icon :right="!$vuetify.breakpoint.smAndDown" :size="20">
                                    mdi-table-large
                                </v-icon>
                            </v-btn>

                            <v-btn value="week" class="px-3">
                                <span class="hidden-sm-and-down">Settimana</span>
                                <v-icon :right="!$vuetify.breakpoint.smAndDown" :size="20">
                                    mdi-format-align-justify
                                </v-icon>
                            </v-btn>

                        </v-btn-toggle>

                        <v-menu v-if="$store.getters.checkSetting('calendar', 'calendar_group')"
                            rounded
                            offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn outlined color="red" class="white--text ml-2" v-on="on">
                                    {{ currentGroupCalendar.name ? currentGroupCalendar.name : 'Gruppi' }}
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item dense link v-for="(calendargroup, cg) in calendargroups" :key="cg" @click="setGroupCalendar(calendargroup)">
                                <v-list-item-title link>{{ calendargroup.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        
                    </template>
                    <v-spacer v-else></v-spacer>

                </v-toolbar>

            </v-col>

        </v-row>

        <v-slide-y-transition>
			<v-card-text class="pa-2 background-1 pb-10" v-if="showFilters">
                <v-subheader class="pl-6">Filtri Avanzati</v-subheader>
                <v-container fluid class="pa-0 px-6">
                    <v-row no-gutters>
                        <v-col class="col-12 col-sm-10 col-md-10 col-lg-10 pa-0 pt-0">

                            <v-subheader class="pl-1">Giorni</v-subheader>
                            <v-row no-gutters>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Lunedì"
                                        class="ma-0"
                                        hide-details
                                        :value="2"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Martedì"
                                        class="ma-0"
                                        hide-details
                                        :value="3"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Mercoledì"
                                        class="ma-0"
                                        hide-details
                                        :value="4"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Giovedì"
                                        class="ma-0"
                                        hide-details
                                        :value="5"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Venerdì"
                                        class="ma-0"
                                        hide-details
                                        :value="6"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Sabato"
                                        class="ma-0"
                                        hide-details
                                        :value="7"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-1 pt-0">
                                    <v-checkbox
                                        v-model="filters.days"
                                        label="Domenica"
                                        class="ma-0"
                                        hide-details
                                        :value="1"
                                    ></v-checkbox>
                                </v-col>

                            </v-row>
                        </v-col>

                        <v-col class="col-12 col-sm-2 col-md-2 col-lg-2 pa-0 pt-0">
                            <v-subheader></v-subheader>
                            <v-checkbox
                                v-model="allDays"
                                @click="selectAllDays()"
                                label="Seleziona Tutti"
                                class="ma-0"
                                hide-details
                            ></v-checkbox>
                        </v-col>

                        <v-col class="col-12 col-sm-10 col-md-10 col-lg-10 pt-0">
                            <v-subheader class="pl-1">Tipi Turno</v-subheader>
                            <v-row no-gutters>
                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0" v-for="(shifttype, s) in shifttypes" :key="s">
                                    <v-checkbox 
                                        v-model="filters.shifttypes"
                                        :label="shifttype.name"
                                        class="ma-0"
                                        hide-details
                                        :value="shifttype.id"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col class="col-12 col-sm-2 col-md-2 col-lg-2 pt-0">
                            <v-subheader></v-subheader>
                            <v-checkbox
                                v-model="allShifttypes"
                                @click="selectAllShifttypes()"
                                label="Seleziona Tutti"
                                class="ma-auto mr-0"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>

                </v-container>
            </v-card-text>
        </v-slide-y-transition>

        <v-row v-if="type == 'month'" class="mx-0 fill-height" style="height: calc(100% - 128px) !important;">
                
            <v-col cols="12" class="pa-0">
                <v-calendar 
                    ref="calendar"
                    :now="today"
                    v-model="today"
                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                    type="month"
                    color="primary"
                    locale="It"
                    :events="shiftsMonth"
                    event-end="ended_at"
                    event-start="started_at"
                    :event-color="colorShift"
                    :event-overlap-threshold="30"
                    @click:event="showEvent"
                ></v-calendar>
                <v-menu
                    min-width="400px"
                    max-width="400px"
                    @click.native.stop
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x>
                    <shift v-if="selectedElement"
                        :shift="selectedEvent"
                    ></shift>
                </v-menu>

            </v-col>
        </v-row>

        <div v-else class="mx-0 fill-height pt-4 body-page px-2" style="width: 100%; margin-top: 12px">

            <div class="d-flex align-start" style="width: 100%" ref="calendarDays" >
                <div class="px-1" v-for="(day, d) in events" :key="d" :style="{'width': dayWidth + 'px'}" >
                <!-- <div class="flex-grow-1" v-for="(day, d) in daysToShow" :key="d" > -->
                    <v-card class="rounded-lg mb-2" outlined style="width:100%">
                        <div dense dark flat class="text-center pa-2" :class="setColorTypeDay(d)">{{ formatDateColumn(d) }}</div>
                    </v-card>
                </div>
            </div>

			<centered-loader v-show="calendarShiftLoaded" :size="50" color="primary" ></centered-loader>

            <div class="long-shift"  v-if="calcLongShifts.length > 0">
                
                <div v-for="(row, r) in calcLongShifts" :key="'longShift_'+r" ref="row">

                    <shift v-for="(shift, s) in row.shifts" 
                        :shift="shift"
                        :key="'longShift_'+r+'_'+s"
                        class="background-2 rounded-lg"
                        :class="{'border-left': shift.border.left, 'border-right': shift.border.right, 'activeNotification': shift.id == element }"
                        style="position: absolute"
                        :style="{'left': shift.left+'px', 'width': shift.width+'px'}">
                    </shift>

                </div>
            </div>

            <div class="d-flex" style="width: 100%" >
                <div class="px-1" v-for="(day, d, index) in events" :key="d" :style="{'width': dayWidth + 'px'}">
                    
                    <v-col cols="12" class="pa-0 pt-1" :ref="'day_'+index">
                        <v-fade-transition group>

                            <template v-if="events[d]">
                                <shift v-for="shift in events[d]['data'].slice(0, showItems[index] ? showItems[index] : events[d]['data'].length )" :key="'shift_'+shift.id"
                                    :shift="shift"
                                    class="background-2 rounded-lg mb-2"
                                    :class="{'activeNotification': shift.id == element }"
                                    :day-type="events[d]['day_type']">
                                </shift>
                            </template>
                        </v-fade-transition>
                        <v-skeleton-loader v-if="$store.getters.isAuth && (typeof showItems[index] == 'undefined' || showItems[index]<events[d]['data'].length)"
                            type="card-heading, card"
                        ></v-skeleton-loader>
                    </v-col>
                </div>
            </div>
        </div>


        <v-btn
			v-if="$store.getters.checkPermission('create_shift')"
            :class="{'mb-16': $store.getters.showBottomNavigator}"
            class="d-print-none"
			color="primary"
            fixed
            fab
            bottom
            right
            @click.native.stop="openDialog()">
			<v-icon>mdi-plus</v-icon>
        </v-btn>


        <v-dialog v-model="dialog" max-width="1100" persistent class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
            <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
                <v-card-title class="pa-3">
					<v-col class="col-10 ma-auto ml-0 py-1">
                        <div v-if="confirmDelete" class="pl-0 font-weight-bold">Elimina</div>
                        <div v-else class="pl-0 font-weight-bold">{{ model.id ? 'Modifica' : 'Nuovo'  }}</div>
						<div v-if="model.id" class="pl-0 font-weight-bold" style="font-size: 24px">{{ model.name ? model.name : ''}} {{ '['+ model.shifttype.name +']' }}</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                </v-card-title>            
                <v-divider></v-divider>
                <v-card-text class="pa-0">

                    <v-container v-if="!confirmDelete" fluid class="px-0 pt-6 mx-0">
                        
                        <shift-fields v-if="dialog"
							ref="shiftEditor"
                            :model="model"
                            :info="info"
                        ></shift-fields>

                        <small class="px-4">* Indica i campi obbligatori</small>
                    </v-container>

                    <v-container v-else style="min-height: 200px">
                        <v-row class="mx-0 justify-center pt-6">
                            <div class="caption" >Sei sicuro di voler eliminare questo Turno?</div>
                        </v-row>
                        
                        <template v-if="model.recurrence_id">
                            <v-row class="mx-0 justify-center pt-6" >
                                <div class="caption" >Questo turno è incluso in una ricorrenza. Cosa vuoi eliminare?</div>
                            </v-row>
                            <v-row class="mx-0 justify-center pt-6">
                                <v-radio-group v-model="deleteType">
                                    <v-radio
                                        label="Solo questo"
                                        value="only_shift"
                                    ></v-radio>
                                    <v-radio
                                        label="Questo e le ricorrenze future"
                                        value="shift_and_future"
                                    ></v-radio>
                                    <v-radio
                                        label="Tutte le ricorrenze"
                                        value="all"
                                    ></v-radio>
                                </v-radio-group>
                            </v-row>
                        </template>
                    </v-container>
                </v-card-text> 
                <v-divider></v-divider>
				<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

                    <v-btn v-if="confirmDelete" color="background-1" fab small @click="closeDialog()">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>
                    <v-btn v-else color="background-1" fab small @click="closeDialog()">
                        <v-icon>mdi-keyboard-backspace</v-icon>
                    </v-btn>

					<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
					<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">{{ model.id ? 'Salva' : 'Crea' }}</v-btn>

				                    
                </v-card-actions>
            </v-card>
        </v-dialog>


        <availability-dialog
            :groups="groups"
        ></availability-dialog>

        <!-- ACTIONS -->

        <duplicate-shift-dialog
            :groups="groups"
        ></duplicate-shift-dialog>
        
        <edit-recurrence-dialog
            :info="info"
        ></edit-recurrence-dialog>

        <extend-recurrence-dialog
            :info="info"
        ></extend-recurrence-dialog>

        <duplicate-availability-dialog
            :info="info"
        ></duplicate-availability-dialog>

        <destination-dialog
            :info="info"
        ></destination-dialog>

        <user-availability-dialog
        ></user-availability-dialog>

    </v-container>
</template>

<script>

import Vue from 'vue'
import CalendarMixin from '@/mixins/CalendarMixin'
import DestinationDialog from './DestinationDialog'
import AvailabilityDialog from './AvailabilityDialog'
import Shift from '@/components/modules/calendar/Shift'
import DuplicateShiftDialog from './DuplicateShiftDialog'
import EditRecurrenceDialog from './EditRecurrenceDialog'
import UserAvailabilityDialog from './UserAvailabilityDialog'
import ExtendRecurrenceDialog from './ExtendRecurrenceDialog'
import ShiftFields from '@/components/modules/calendar/ShiftFields'
import DuplicateAvailabilityDialog from './DuplicateAvailabilityDialog'

export default {
	name: 'calendar',
  	components: {
		Shift,
		ShiftFields,
		DestinationDialog,
		AvailabilityDialog,
		DuplicateShiftDialog,
		EditRecurrenceDialog,
		UserAvailabilityDialog,        
		ExtendRecurrenceDialog,
		DuplicateAvailabilityDialog,
	},
	mixins: [CalendarMixin],
	props: {
		element: Number,
		started_at: String,
		tab: {
			type: Number,
			default: 0
		}
	},

	data() {
		return {
			today: this.started_at ? moment(this.started_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			fab: null,
			model: {},
			info: {},
			shiftsMonth: [],
			groups: [],
			longShifts: [],
			type: 'week',
			dialog: false,
			calendars: [],
			calendargroups: [],
			currentCalendar: {},
			currentGroupCalendar: {},
			fullScreen: false,
			showFilters: false,
			filters: {
				'days': [1, 2, 3, 4, 5, 6, 7],
				'shifttypes': []
			},
			showItems: [],
			scrollTimeout: null,
			allDays: true,
			allShifttypes: true,
			selectedEvent: {},
			selectedOpen: false,
			confirmDelete: false,
			calendarShiftLoaded: false,
			selectedElement: null,
			deleteType: 'only_shift',
			calendarTab: this.tab.toString(),
			daysToShowPrint: null,
			switchTimeout: null,
			datepicker: false
		}
	},

	computed: {

		filterCalendars() {
			if(!this.$store.getters.checkSetting('calendar', 'calendar_group')) {
				return this.calendars
			} else {
				return this.calendars.filter((calendar) => calendar.calendargroup_id == this.currentGroupCalendar.id )
			}
		},

		calcLongShifts() {
			//larghezza del giorno
			let dayMinutes = 24 * 60
			let px_min =  this.dayWidth / dayMinutes

			let rows = []
			let last_row = 1

			_.each(this.longShifts, (longShift) => {
				if(moment(longShift.started_at).isBefore(moment(this.from).add(this.daysToShow, 'd'))) {

					let same_row = false

					let start, end = null
					longShift.border = {left: false, right: false}

					if(moment(longShift['started_at']).isBefore(moment(this.from))) {
						start = moment(this.from)
						longShift.border.left = true
					} else {
						start = moment(longShift['started_at'])
					}


					if(moment(longShift['ended_at']).isAfter(moment(this.from).add(this.daysToShow, 'd'))) {
						end = moment(this.from).add(this.daysToShow, 'd')
						longShift.border.right = true
					} else {
						end = moment(longShift['ended_at'])
					}

					longShift.left = start.clone().diff(this.from, 'minutes') * px_min + 8
					longShift.width = end.clone().diff(start, 'minutes') * px_min - 16

					_.each(rows, (row) => {
						// let force_new_row = !(row['started_at'] < this.to || moment(longShift.ended_at) > this.from)
						//nel caso di turno parziale forzo una nuova riga
						if (moment(longShift.started_at).isAfter(row.ended_at)) {

							row['ended_at'] = moment(longShift.ended_at)
							row['shifts'].push(longShift)
							same_row = true
							return false
						}
					})

					if (!same_row) {
						last_row++
						rows.push({'n': last_row, 'ended_at': end, 'started_at': start, 'shifts': [longShift]})
					}
				}
			})

			return rows

		},        
        
	},

	watch: {

		'filters.days': {
			handler(newValue, oldValue) {
				newValue.length == 7 ? this.allDays = true : this.allDays = false
				this.getData()
			}
		},

		'filters.shifttypes': {
			handler(newValue, oldValue) {
				newValue.length == this.shifttypes.length ? this.allShifttypes = true : this.allShifttypes = false
				this.getData()
			}
		},


	},

	created() {
		this.$store.commit('SET_SIDEBAR', false)
		this.getCalendarInfo()
	},

	mounted() {

		// WS Listeners
		this.$echo.private(`organization.${this.$store.getters.getCurrentOrganization.id}.calendar`)
			.listen('.ShiftUpdated', (event) => {
				let shift = event.shift
				if(this.currentCalendar.id == shift.calendar_id && moment(shift.started_at).isBetween(this.from, this.to)) {
					if(shift.long_shift) {
						this.getData()
					} else {
						let date = moment(shift.started_at).format('YYYY-MM-DD')
						if(typeof this.events[date] == 'undefined') return

						let index = this.events[date].data.findIndex((elem) => {
							return elem.id == shift.id
						})

						if(index !== -1) {
							this.events[date].data.splice(index, 1, shift)
						} else {
							this.getData()
						}
					}
				}
			})
			.listen('.ShiftDeleted', (event) => {
				let shift = event.shift
				if(this.currentCalendar.id == shift.calendar_id && moment(shift.started_at).isBetween(this.from, this.to)) {
					if(shift.long_shift) {
						this.getData()
					} else {
						let date = moment(shift.started_at).format('YYYY-MM-DD')
						let index = this.events[date].data.findIndex((elem) => {
							return elem.id == shift.id
						})

						if(index !== -1) {
							this.events[date].data.splice(index, 1)
						} else {
							this.getData()
						}
					}
				}
			})

		window.onscroll = () => {
			this.loadMore()
		}

		window.addEventListener('onafterprint', this.closePrintCalendar)

		window.addEventListener('resize', this.calcDayWidth)
		this.calcDayWidth()

		this.$eventBusUtility.$on('editShift', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('deleteShift', (item) => {
			this.confirmDestroy(item)
		})

		this.$eventBusUtility.$on('refreshCalendar', () => {
			this.getData()
		})

		// auto switch calendar
		if(!this.$store.getters.isAuth && this.$store.getters.isTouchDevice && !this.$store.getters.getDisableSectionRotation) {
			this.setSwitchTimeout()
			window.addEventListener('click', this.setSwitchTimeout)
			window.addEventListener('keypress', this.setSwitchTimeout)
			window.addEventListener('scroll', this.setSwitchTimeout)
			window.addEventListener('mousemove', this.setSwitchTimeout)
		}
	},

	beforeDestroy() {
		// Unlisten WS
		this.$echo.leave(`organization.${this.$store.getters.getCurrentOrganization.id}.calendar`)

		this.$eventBusUtility.$off('editShift')
		this.$eventBusUtility.$off('deleteShift')
		this.$eventBusUtility.$off('refreshCalendar')

		window.removeEventListener('resize', this.calcDayWidth)
		window.removeEventListener('click', this.setSwitchTimeout)
		window.removeEventListener('keypress', this.setSwitchTimeout)
		window.removeEventListener('scroll', this.setSwitchTimeout)
		window.removeEventListener('mousemove', this.setSwitchTimeout)
		window.removeEventListener('onafterprint', this.closePrintCalendar)

		clearTimeout(this.switchTimeout)
		clearTimeout(this.timeout)
	},

	updated() {
		this.$nextTick(() => {
			let maxHeight = 0

			_.each(this.$refs.row, (r, i) => {
				_.each(r.children, (child) => {
					if(child.clientHeight > maxHeight) {
						maxHeight = child.clientHeight
					}
				})
				this.$refs.row[i].style.height = maxHeight + 8 + 'px'
			})
		})
	},

    
	methods: {

		loadMore() {
			// return if no auth (touch device)
			if(!this.$store.getters.isAuth) return

			// delete old timer
			if(this.scrollTimeout) clearTimeout(this.scrollTimeout)
            
			// start new timer
			this.scrollTimeout = setTimeout(() => {
				// for all columns
				for(let i=0;i<this.daysToShow;i++) {
					// init showItems
					if(!this.showItems[i]) this.$set(this.showItems, i, 0)
					// check if refs exists
					if(typeof this.$refs['day_'+i] == 'undefined' || typeof Object.values(this.events)[i] == 'undefined') continue

					// if there are other shifts and height of columnd day is less than what i'd scrolled
					if(this.showItems[i] < Object.values(this.events)[i].data.length && this.$refs['day_'+i][0].clientHeight - 150 < document.documentElement.scrollTop + window.innerHeight) {
						// incresa shift's counter
						this.$set(this.showItems, i, this.showItems[i]+5)
						// when dom is rendered, check again
						this.$nextTick(() => {
							if(this.$refs['day_'+i][0].clientHeight - 150 < document.documentElement.scrollTop + window.innerHeight) {
								this.loadMore()
							}
						})
					}
				}
			}, 100)
		},

		setGroupCalendar(groupcalendar) {
			this.currentGroupCalendar = groupcalendar
			localStorage.setItem('currentGroupCalendar', JSON.stringify(groupcalendar))
			let calendar = this.calendars.filter((calendar) => calendar.calendargroup_id == this.currentGroupCalendar.id )
			if(calendar[0]) {
				this.selectCalendar(calendar[0])
			}

		},

		printCalendar() {
			this.daysToShowPrint = true
			this.$store.commit('SET_SIDEBAR', false)
			this.getDataPromise().then(() => {
				setTimeout(() => {
					window.print()
					this.daysToShowPrint = null
				}, 1500)
			}) 
		},

		closePrintCalendar() {
			this.daysToShowPrint = null
			this.print = false            
		},

		colorShift(shift) {
			if(shift.covered.shift) {
				return 'success'
			} else if(shift.availables.length > 0) {
				return 'warning'
			}
			return 'error'
		},

		setSwitchTimeout() {
			if(this.switchTimeout) {
				clearTimeout(this.switchTimeout)
			}
			this.switchTimeout = setTimeout(() => {
				let next_index = this.calendars.findIndex((item) => {
					return item.id == this.currentCalendar.id
				})

				next_index++

				if(this.calendars.length == next_index) {
					this.$eventBusUtility.navigateTo('home')
				} else {
					this.selectCalendar(this.calendars[next_index])
					this.setSwitchTimeout()
				}
			}, 10000)
		},

		confirmDestroy(item = null) {
			if(item) {
				this.dialog = true
				this.model = item
			} 
			this.confirmDelete = true
		},

		destroy() {
			this.loading = true
			this.axios.delete('shifts/'+this.model.id, {params: {deleteType: this.deleteType}}).then(response => {
				this.closeDialog()
				this.$store.commit('SET_SNACKBAR', {
					timeout: 6000,
					text: 'Turno eliminato',
					value: true,
					color: 'success'
				})
				this.loading = false
			})
		},

		selectAllDays() {
			this.allDays == true ? Vue.set(this.filters, 'days', [1, 2, 3, 4, 5, 6, 7]) : Vue.set(this.filters, 'days', [])
		},

		selectAllShifttypes() {
			this.allShifttypes == true ? Vue.set(this.filters, 'shifttypes', this.shifttypes.map(shifttype => shifttype.id )) : Vue.set(this.filters, 'shifttypes', [])
		},

		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event
				this.selectedElement = nativeEvent.target
				setTimeout(() => {
					this.selectedOpen = true
				}, 10)
			}

			if (this.selectedOpen) {
				this.selectedOpen = false
				setTimeout(open, 10)
			} else {
				open()
			}

			nativeEvent.stopPropagation()
		},

		selectCalendar(calendar) {
			this.calendarTab = calendar.id.toString()
			this.currentCalendar = {...calendar}
			this.getData()
		},

		getCalendarInfo() {

			this.loading = true

			this.axios.get('/calendars-info').then(response => {
				this.calendars = response.data.data.calendars
				this.calendargroups = response.data.data.calendargroups
				this.shifttypes = response.data.data.shifttypes
				this.groups = response.data.data.groups
                
				this.info = response.data.data

				// if active calendar group
				if(this.$store.getters.checkSetting('calendar', 'calendar_group')) {
					// if arrive to notification
					if(this.tab) {
						let tabCalendar = this.calendars.find((calendar) => calendar.id == this.tab)
						this.currentGroupCalendar = this.calendargroups.find((calendargroup) => calendargroup.id == tabCalendar.calendargroup_id)
						this.currentCalendar = {id: this.tab}
					} else {
						// if is set groupCalendar in localStorage
						if(localStorage.getItem('currentGroupCalendar')) {
							let localStorageGroupCalendar = JSON.parse(localStorage.getItem('currentGroupCalendar'))
							let gc = this.calendars.find((calendar) => calendar.calendargroup_id == localStorageGroupCalendar.id)
							if(gc) {
								this.currentGroupCalendar = localStorageGroupCalendar
							} else {
								this.currentGroupCalendar = response.data.data.calendargroups[0]
							}
						} else {
							this.currentGroupCalendar = response.data.data.calendargroups[0]
						}
						let availableCalendar = this.calendars.find((calendar) => calendar.calendargroup_id == this.currentGroupCalendar.id)
						if(availableCalendar) {
							this.currentCalendar = availableCalendar
						} else {
							this.calendars.filter((calendar) => calendar.calendargroup_id == this.currentGroupCalendar.id)
							this.currentCalendar = this.tab ? {id: this.tab} : response.data.data.calendars[0]
						}

					}

					// if not active calendar group
				} else {
					this.currentCalendar = this.tab ? {id: this.tab} : response.data.data.calendars[0]
				}


				if(this.currentCalendar) {
					this.getData()
				}
				_.forEach(this.shifttypes, (value) => {
					this.filters.shifttypes.push(value.id)
				})
				this.loading = false

			}, response => {
				this.loading = false
			})
		},

		getDataPromise() {
			return new Promise((resolve, reject) => {
				if(this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {   
					this.calendarShiftLoaded = true
					this.calcIntervalDate()

					let options = {
						from: this.from,
						calendar_id: this.currentCalendar.id,
						filters: this.filters
					}
					if(this.type == 'week') {
						options.days = this.daysToShow
					}
					this.axios.get('shifts', {params: options}).then(response => {
						if(this.type == 'month') {
							this.shiftsMonth = response.data.data.days
						} else {
							this.events = response.data.data.days
							this.longShifts = response.data.data.long_shifts
						}
						this.calendarShiftLoaded = false
						resolve()
					},
					response => {
						this.calendarShiftLoaded = false
						resolve()
					})
				}, 300)
			})
		},

		getData() {
			this.datepicker = false

			if(this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				this.calendarShiftLoaded = true
				this.events = {}
				this.showItems = []
				this.calcIntervalDate()

				let options = {
					from: this.from,
					calendar_id: this.currentCalendar.id,
					filters: this.filters
				}
				if(this.type == 'week') {
					options.days = this.daysToShow
				}
				this.axios.get('shifts', {params: options}).then(response => {
					if(this.type == 'month') {
						this.shiftsMonth = response.data.data.days
					} else {
						this.events = response.data.data.days
						this.longShifts = response.data.data.long_shifts
					}
                    
					this.loadMore()
					this.calendarShiftLoaded = false

					this.$nextTick(() => {
						if(!this.$store.getters.isAuth && this.$store.getters.isTouchDevice && !this.$store.getters.getDisableSectionRotation) {
							let body = document.body,
								html = document.documentElement

							let height = Math.max( body.scrollHeight, body.offsetHeight, 
								html.clientHeight, html.scrollHeight, html.offsetHeight )
							this.$vuetify.goTo(0).then(() => {
								this.$vuetify.goTo(height, {
									easing: 'linear',
									duration: height*9
								})
							})
						}
					})
				},
				response => {
					this.calendarShiftLoaded = false
				})
			}, 300)
		},

		getCsv() {
			let dataObject = {
				from: this.from,
				calendar_id: this.currentCalendar.id,
				filters: this.filters
			}
			if(this.type == 'week') {
				dataObject.days = this.daysToShow
			}

			this.$eventBusUtility.openFile(this.$store, '/shifts/getCsvForAnalytics/', 'Turni ' + this.currentCalendar.name + '.csv', 'POST', {...dataObject})
		},

		calcIntervalDate() {
      		switch (this.type) {
			case 'month':
				this.from = moment(this.today).startOf('month').subtract(6, 'd').format('YYYY-MM-DD HH:mm')
				this.to = moment(this.today).endOf('month').add(6, 'd').format('YYYY-MM-DD HH:mm')
				break

			case 'week':
				this.from = moment(this.today).format('YYYY-MM-DD HH:mm')
				this.to = moment(this.today).add(this.daysToShow, 'd').format('YYYY-MM-DD HH:mm')
				break
      		}
		},
    
        
		closeDialog() {
			this.model = _.stubObject()
			this.dialog = false
			this.confirmDelete = false
		},

		edit(shift) {
			this.axios.get('shifts/' + shift.id).then(response => {
				// get body data
				this.model = response.data.data
				this.openDialog()

			}, response => {

			})
		},

		openDialog() {
			if(!this.model.id) {
				Vue.set(this.model, 'calendar_id', this.currentCalendar.id)
			} else {
				Vue.set(this.model, 'start_time', moment(this.model.started_at).format('HH:mm'))
				Vue.set(this.model, 'started_at', moment(this.model.started_at).format('YYYY-MM-DD'))
				Vue.set(this.model, 'end_time', moment(this.model.ended_at).format('HH:mm'))
				Vue.set(this.model, 'ended_at', moment(this.model.ended_at).format('YYYY-MM-DD'))                        
			}
			this.dialog = true
		},

		save() {
			Vue.nextTick(() => {
				this.$refs.shiftEditor.$refs.fields.validate().then(result => {
					if (!result) {
						return false
					}
					this.loading = true
					if(!this.model.id) {
                        
						let data = {...this.model}
						data.started_at = this.model.started_at + ' ' + this.model.start_time + ':00'
						data.ended_at = this.model.ended_at + ' ' + this.model.end_time + ':00'
                        
						this.axios.post('shifts', data).then(response => {
							// get body data
							this.closeDialog()
							this.loading = false
							this.getData()

						}, response => {
							this.loading = false
						})
					} else {
						// is in edit
						let data = {...this.model}
						data.started_at = this.model.started_at + ' ' + this.model.start_time + ':00'
						data.ended_at = this.model.ended_at + ' ' + this.model.end_time + ':00'
                        
						this.axios.put('shifts/' + this.model.id, data).then(response => {
							// get body data
							this.closeDialog()
							this.loading = false
							this.getData()

						}, response => {
							this.loading = false
						})
					}
				}).catch(() => {

				})

			})
		},
	},

}
</script>

<style>

    .v-date-picker-header__value  button {
		text-transform: capitalize !important;
	}
    .v-calendar-weekly__day-label  button {
		text-transform: capitalize !important;
	}
    .long-shift {
        display: block;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid #ddd;
        margin-bottom: 3px;
        position: relative;
	}
    .border-left {
        border-left: 5px dotted grey !important;
    }
    .border-right {
        border-right: 5px dotted grey !important;
    }
</style>