<template>
    <v-dialog v-model="open" max-width="1600" class="round-card" :fullscreen="fullScreen || $vuetify.breakpoint.smAndDown" scrollable>
        <v-card :class="fullScreen || $vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-lg'" class=" pa-0 elevation-0">
            <v-card-title class="pa-3">
                <v-col class="col-10 ma-auto ml-0 py-1">
                    <div class="pl-0 font-weight-bold">Gestione Permessi</div>                
                </v-col>
                <v-spacer></v-spacer>
                <v-btn v-if="$vuetify.breakpoint.smAndUp" icon @click.native.stop="fullScreen = !fullScreen"><v-icon>mdi-fullscreen</v-icon></v-btn>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>                
            </v-card-title>            
            <v-divider></v-divider>
            <v-card-text class="pa-0">

                <v-container fluid class="pa-0 pt-1 mx-0">
                    <v-card class="rounded-lg px-2 pb-0 elevation-0 transparent">

                            <v-tabs 
                                v-model="tab"
                                class="px-4"
                                background-color="white"
                                show-arrows
                                :centered="!$vuetify.breakpoint.mdAndUp">
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab key="board" v-if="$store.getters.checkModulePermissions('board')">
                                    Bacheca
                                </v-tab>

                                <v-tab key="calendar">
                                    Calendario
                                </v-tab>

                                <v-tab key="courses" v-if="$store.getters.checkModulePermissions('courses')">
                                    Corsi
                                </v-tab>

                                <v-tab key="attendances" v-if="$store.getters.checkModulePermissions('attendances')">
                                    Timbrature
                                </v-tab>

                                <v-tab key="personal">
                                    Persone
                                </v-tab>

                                <v-tab key="vehicles" v-if="$store.getters.checkModulePermissions('vehicles')">
                                    Mezzi
                                </v-tab>

                                <v-tab key="checklists" v-if="$store.getters.checkModulePermissions('checklists')">
                                    Checklist
                                </v-tab>

                            </v-tabs>
                    </v-card>
                </v-container>
                    
                <v-container class="body-page px-6 pt-6 pb-0" style="margin-top: 0px;">
            
                    <v-tabs-items v-model="tab" touchless class="pt-2" style="height: 100%">

                        <v-tab-item key="board" class="rounded-lg" v-if="$store.getters.checkModulePermissions('board')">
                            <v-card class="rounded-lg" flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può creare un Post"
                                        v-model="model['create_public_post']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può modificare/eliminare Post creati da altri utenti"
                                        v-model="model['manage_other_posts']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può modificare/eliminare i Commenti di altri utenti"
                                        v-model="model['manage_other_comments']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può inviare un Post Privato"
                                        v-model="model['create_private_post']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card flat>
                                <v-container fluid class="pa-0 px-6">

                                    <v-row no-gutters>

                                        <v-col class="col-12 py-0">
                                            <v-checkbox
                                                label="Può creare un Turno"
                                                v-model="model['create_shift']"
                                            ></v-checkbox>
                                        </v-col>

                                        <v-col class="col-12 py-0">
                                            <v-checkbox
                                                label="Può modificare/eliminare Turni createi da altri utenti"
                                                v-model="model['manage_other_shifts']"
                                            ></v-checkbox>
                                        </v-col>

                                        <v-col class="col-12 col-sm-10 col-md-10 col-lg-10 pt-0">
                                            <v-subheader class="pl-0">Calendari</v-subheader>
										    <v-row no-gutters>
											    <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0" v-for="(calendar, c) in info.calendars" :key="c">
                                                    <v-checkbox 
                                                        :label="calendar.name"
                                                        v-model="calendars"
                                                        :value="calendar.id"
                                                        class="ma-0"
                                                        hide-details                                                        
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col class="col-12 col-sm-2 col-md-2 col-lg-2 pt-0">
                                            <v-subheader></v-subheader>
                                            <v-checkbox
                                                v-model="allcalendars"
                                                @click="selectAll('calendars')"
                                                label="Seleziona Tutti"
                                                class="ma-0 ma-auto mr-0"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>      

                                        <v-col class="col-12 pt-0">
                                            <div class="caption pt-2">Può gestire disponibilità in questi calendari</div>
                                        </v-col>

                                        <v-col class="col-12 col-sm-10 col-md-10 col-lg-10 pt-0">
                                            <v-subheader class="pl-0">Tipi Turno</v-subheader>
                                            <v-row no-gutters>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0" v-for="(shifttype, s) in info.shifttypes" :key="s" >
                                                    <v-checkbox 
                                                        :label="shifttype.name"
                                                        v-model="shifttypes"
                                                        :value="shifttype.id"
                                                        class="ma-0"
                                                        hide-details                                                
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col class="col-12 col-sm-2 col-md-2 col-lg-2 pt-0">
                                            <v-subheader></v-subheader>
                                            <v-checkbox
                                                v-model="allshifttypes"
                                                @click="selectAll('shifttypes')"
                                                label="Seleziona Tutti"
                                                class="ma-0 ma-auto mr-0"
                                                hide-details
                                            ></v-checkbox>
                                        </v-col>    

                                        <v-col class="col-12 pt-0">
                                            <div class="caption pt-2">Può gestire disponibilità su questi tipi di turno</div>
                                        </v-col>

                                        <v-col class="col-10 pt-0">
                                            <v-subheader class="pl-0">Giorni</v-subheader>
                                            <v-row no-gutters>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Lunedì"
                                                        value="monday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Martedì"
                                                        value="tuesday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Mercoledì"
                                                        value="wednesday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Giovedì"
                                                        value="thursday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Venerdì"
                                                        value="friday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Sabato"
                                                        value="saturday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Domenica"
                                                        value="sunday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Feriale"
                                                        value="workday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Pre-Festivo"
                                                        value="preholiday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Festivo"
                                                        value="holiday"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                                <v-col class="col-6 col-sm-4 col-md-4 col-lg-3 pt-0">
                                                    <v-checkbox
                                                        label="Sempre"
                                                        value="all"
                                                        v-model="days"
                                                        class="ma-0 ma-auto mr-0"
                                                        hide-details
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col class="col-12 pt-0">
                                            
                                            <v-row class="pb-2 mx-0 pt-4">
                                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 pa-0 pt-4" :class="$vuetify.breakpoint.smAndDown ? 'pr-0' : 'pr-6'">
                                                    <validation-provider name="Dalle ore" rules="is_time" v-slot="{ errors }">
                                                        <v-text-field
                                                            label="Dalle ore"
                                                            v-model="model['start_time']"
                                                            :error-messages="errors"
                                                            outlined
                                                            dense
                                                            v-mask="'##:##'"
                                                            return-masked-value
                                                            ref="start_time"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>

                                                <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 pa-0 pt-4" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : 'pl-6'">
                                                    <validation-provider name="Fino alle ore" rules="is_time" v-slot="{ errors }">
                                                        <v-text-field
                                                            label="Fino alle ore"
                                                            v-model="model['end_time']"
                                                            :error-messages="errors"
                                                            outlined
                                                            dense
                                                            v-mask="'##:##'"
                                                            return-masked-value						
                                                            ref="end_time"
                                                        ></v-text-field>
                                                    </validation-provider>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col class="col-12 pt-0">
                                            <v-checkbox
                                                label="Può modificare turni passati"
                                                class="pr-4"
                                                v-model="model['edit_past_shift']"
                                            ></v-checkbox>
                                            <v-checkbox
                                                label="Può sbloccare persone occupate"
                                                class="pr-4"
                                                v-model="model['unlock_busy_people']"
                                            ></v-checkbox>
                                            <v-checkbox
                                                label="Può duplicare le disponibilità"
                                                class="pr-4"
                                                v-model="model['duplicate_availability']"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item v-if="$store.getters.checkModulePermissions('courses')">
                            <v-card flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può gestire i Corsi"
                                        v-model="model['manage_courses']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire le Iscrizioni"
                                        v-model="model['manage_registrations']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire le Presenza"
                                        v-model="model['manage_presences']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire gli Esami"
                                        v-model="model['manage_exams']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire i Brevetti"
                                        v-model="model['manage_patents']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire le Aule"
                                        v-model="model['manage_rooms']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item v-if="$store.getters.checkModulePermissions('attendances')">
                            <v-card flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può inserire Timbrature manuali"
                                        v-model="model['manual_attendances']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item>
                            <v-card flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può visualizzare le Persone"
                                        v-model="model['show_people']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può creare una Persona"
                                        v-model="model['create_person']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può modificare Persone create da altri Utenti"
                                        v-model="model['manage_other_people']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire i Gruppi"
                                        v-model="model['manage_groups']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire le Mansioni"
                                        v-model="model['manage_tasks']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item v-if="$store.getters.checkModulePermissions('vehicles')">
                            <v-card flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può visualizzare i Veicoli"
                                        v-model="model['show_vehicles']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire i Veicoli"
                                        v-model="model['manage_vehicles']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>


                        <v-tab-item v-if="$store.getters.checkModulePermissions('checklists')">
                            <v-card flat>
                                <v-col class="col-12 py-0 px-6">
                                    <v-checkbox
                                        label="Può visualizzare le Checklist"
                                        v-model="model['show_checklists']"
                                    ></v-checkbox>
                                    <v-checkbox
                                        label="Può gestire le Checklist"
                                        v-model="model['manage_checklists']"
                                    ></v-checkbox>
                                </v-col>
                            </v-card>
                        </v-tab-item>


                    </v-tabs-items>
                </v-container>


            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-btn color="background-1" fab small @click="closeDialog()">
                    <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn>
				<v-btn color="success" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


export default {
	name: 'person-permissions-dialog',
	props: {
		info: Object
	},    
	data() {
		return {
			model: {
				'start_time': '00:00',
				'end_time': '00:00'
			},
			days: [],
			shifttypes: [],
			calendars: [],
			person: {},

			open: false,
			tab: null,
			fullScreen: false,
			loading: false,
			allshifttypes: false,
			allcalendars: false,
		}
	},

	computed: {

	},

	watch: {
		calendars: {
			handler(newValue, oldValue) {
				newValue.length == this.info.calendars.length ? this.allcalendars = true : this.allcalendars = false
			}
		},
        
		shifttypes: {
			handler(newValue, oldValue) {
				newValue.length == this.info.shifttypes.length ? this.allshifttypes = true : this.allshifttypes = false
			}
		},        
	},    

	created() {
	},

	mounted() {
		this.$eventBusUtility.$on('editPermissions', (person) => {
			this.openPermissionsDialog(person)
		})
	},

	methods: {
    
		selectAll(type) {
			let all = 'all' + type
			all = this[all]
			if(all == true) {
				this.info[type].forEach((val, index) => {
					this[type].push(val.id)
				})
			} else {
				this.info[type].forEach((val, index) => {
					this[type] = []
				})
			}
		},

		openPermissionsDialog(person) {
			this.person = {...person}
			this.axios.get('person-permissions/' + this.person.id).then(response => {
				this.loading = false
				this.model = response.data.data
				this.calendars = response.data.data.calendars ?  response.data.data.calendars : []
				this.shifttypes = response.data.data.shifttypes ? response.data.data.shifttypes : []
				this.days = response.data.data.days ? response.data.data.days : []
				this.open = true

			}, response => {

			})
		},


		closeDialog() {
			this.model = _.stubObject()
			this.tab = null
			this.open = false
		},

		save() {
			let data = {...this.model}
			data.days = this.days
			data.shifttypes = this.shifttypes
			data.calendars = this.calendars
			this.axios.put('person-permissions/' + this.person.id, data).then(response => {
				this.loading = false
				this.closeDialog()

			}, response => {

			})

		},


	},

	beforeDestroy() {
		this.$eventBusUtility.$off('editPermissions')
	},    

}
</script>

<style>
    .body-page .v-window {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
</style> 