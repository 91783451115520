<template>
    <validation-observer ref="fields">

        <v-row class="pb-2 mx-0 pt-4">
            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Nome" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="text"
                        label="Nome"
                        v-model="model['name']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="name"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Latitudine" rules="required|double" v-slot="{ errors }">
                    <v-text-field
                        type="number"
                        label="Latitudine"
                        v-model="model['latitude']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="latitude"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Longitudine" rules="required|double" v-slot="{ errors }">
                    <v-text-field
                        type="number"
                        label="Longitudine"
                        v-model="model['longitude']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="longitude"
                    ></v-text-field>
                </validation-provider>
            </v-col>

            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                <validation-provider name="Range" rules="required" v-slot="{ errors }">
                    <v-text-field
                        type="number"
                        label="Range"
                        v-model="model['range']"
                        :error-messages="errors"
                        outlined
                        dense
                        ref="range"
                    ></v-text-field>
                </validation-provider>
            </v-col>

        </v-row>
        
    </validation-observer>    
</template>

<script>

export default {
	name: 'place-fields',
	props: {
		model: Object,
		info: Object,
	},

}
</script>
