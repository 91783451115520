<template>
	<v-row no-gutters class="fill-height">
		<v-container fluid class="pa-0">
            <v-tabs-items v-model="tab" touchless class="fill-height">
                <template v-for="(t, i) in tabs">
                    <v-tab-item :value="'tab-'+i" :key="'tab-'+i" class="fill-height">
                        <calendar-settings v-if="t.modules == 'calendar'">
                            <template slot="tabs">
                                <v-tabs
                                    class="pt-4"
                                    v-model="tab"
                                    background-color="white"
                                    show-arrows
                                    :centered="!$vuetify.breakpoint.mdAndUp">
                                    <v-tabs-slider></v-tabs-slider>

                                    <template v-for="(tab, index) in tabs">
                                        <v-tab :key="'tab_'+index" :href="'#tab-'+index">
                                            {{ tab.name }}
                                        </v-tab>
                                    </template>

                                </v-tabs>
                            </template>
                        </calendar-settings>

                        <checklist-settings v-if="t.modules == 'checklists'">
                            <template slot="tabs">
                                <v-tabs
                                    class="pt-4"
                                    v-model="tab"
                                    background-color="white"
                                    show-arrows
                                    :centered="!$vuetify.breakpoint.mdAndUp">
                                    <v-tabs-slider></v-tabs-slider>

                                    <template v-for="(tab, index) in tabs">
                                        <v-tab :key="'tab_'+index" :href="'#tab-'+index">
                                            {{ tab.name }}
                                        </v-tab>
                                    </template>

                                </v-tabs>
                            </template>
                        </checklist-settings>

                        <attendance-settings v-if="t.modules == 'attendances'">
                            <template slot="tabs">
                                <v-tabs
                                    class="pt-4"
                                    v-model="tab"
                                    background-color="white"
                                    show-arrows
                                    :centered="!$vuetify.breakpoint.mdAndUp">
                                    <v-tabs-slider></v-tabs-slider>

                                    <template v-for="(tab, index) in tabs">
                                        <v-tab :key="'tab_'+index" :href="'#tab-'+index">
                                            {{ tab.name }}
                                        </v-tab>
                                    </template>

                                </v-tabs>
                            </template>
                        </attendance-settings>
                    </v-tab-item>
                </template>

            </v-tabs-items>
        </v-container>
    </v-row>
</template>

<script>

import CalendarSettings from '@/components/modules/settings/CalendarSettings'
import ChecklistSettings from '@/components/modules/settings/ChecklistSettings'
import AttendanceSettings from '@/components/modules/settings/AttendanceSettings'

export default {
	name: 'settings-index',
	components: {
		ChecklistSettings,
		CalendarSettings,
		AttendanceSettings
	},
	data() {
		return {
			tab: 'tab-0',
		}
	},

	computed: {
		tabs() {
			return [
				{
					name: 'Calendario',
					modules: 'calendar',
				},
				{
					name: 'Checklist',
					modules: 'checklists',
				},
				{
					name: 'Timbrature',
					modules: 'attendances',
				}
			].filter((tab) => {
				if(this.$store.getters.checkModulePermissions(tab.modules)) {
					return tab 
				}
			})
		}
	}
}
</script>

<style>

</style>