<template>
    <v-container class="fill-height" fluid>
        <v-slide-y-transition mode="out-in">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="10" md="6" lg="5" xl="4" class="text-center">
                    <img v-if="$store.getters.getCurrentOrganization.avatar" :src="$store.getters.getCurrentOrganization.avatar" style="width: 300px" alt="VolontApp" class="pb-4">
                    <img v-else src="img/volontapp-login.png" style="width: 300px" alt="VolontApp" class="pb-4">
                    <v-spacer></v-spacer>
                        <div class="text-h3 my-12" style="text-transform: capitalize">{{ time }}</div>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>

                    <div style="font-size: 18px">Sede:</div>
                    <div class="mb-6 text-h4">{{ placeName }}</div>

                    <v-text-field
                        v-model="badge"
                        name="Badge"
                        prepend-icon="person"
                        ref="badge"
                        label="Badge"
                        @keyup.enter="submit"
                    ></v-text-field>

                    <v-row class="pt-10" align="center" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-btn
                                x-large
                                block
                                rounded
                                ripple
                                color="primary"
                                :loading="loading"
                                @click="submit" 
                                :disabled="!badge">
                                Timbra
                            </v-btn>
                        </v-col>

                    </v-row>

                    <v-alert v-if="attendance.type == 'in'" class="mt-6" icon="mdi-arrow-up-bold-outline" prominent text type="success" style="font-size: 20px">
                        Benvenuto/a <span style="font-weight: bold !important">{{ personName }}</span>, entrata segnata alle ore <span style="font-weight: bold !important">{{ formatDate }}</span>
                    </v-alert>
                    <v-alert v-else-if="attendance.type == 'out'" class="mt-6" icon="mdi-arrow-down-bold-outline" prominent text type="error" style="font-size: 20px">
                        Arrivederci <span style="font-weight: bold !important">{{ personName }}</span>, uscita segnata alle ore <span style="font-weight: bold !important">{{ formatDate }}</span>
                    </v-alert>

                </v-col>
            </v-row>
        </v-slide-y-transition>
    </v-container>
</template>

<script>

export default {
	name: 'local-attendance',
	data() {
		return {
			badge: '',
			loading: false,
			place: {},
			attendance: {},
			intervalTime: null,
			time: null,
			intervalFocus: null,
			clearTimeout: null
		}
	},

	computed:{
		formatDate() {
			if(this.attendance.date)
				return moment(this.attendance.date).format('H:mm')
			return null
		},
		personName() {
			if(this.attendance.person)
				return this.attendance.person.full_name
			return null
		},
		placeName() {
			if(this.place)
				return this.place.name
			return null
		},
	},

	methods: {

		submit() {
			this.loading = true

			this.axios.post('local-attendance', {place_id: this.place.id, badge: this.badge}).then(response => {
				// get info data
				this.attendance = response.data.data
				this.badge = ''
		        this.$refs.badge.focus()
				this.loading = false
			}, response => {
				// error callback
				this.badge = ''                 
				this.loading = false
			})
		},

		setClearTimeout() {
			if(this.clearTimeout) {
				clearTimeout(this.clearTimeout)
			}
			this.clearTimeout = setTimeout(() => {
				this.badge = ''
				this.attendance = {}
				this.$refs.badge.focus()
			}, 10000)
		},

	},

	created() {
		this.intervalTime = setInterval(() => {
			// Concise way to format time according to system locale.
			// In my case this returns "3:48:00 am"
			this.time = Intl.DateTimeFormat(navigator.language, {
				year: 'numeric', month: 'long', day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric'
			}).format()
		}, 1000)

		this.setClearTimeout()
		window.addEventListener('click', this.setClearTimeout)
		window.addEventListener('keypress', this.setClearTimeout)
		window.addEventListener('mousemove', this.setClearTimeout)
		window.addEventListener('scroll', this.setClearTimeout)
	},

	mounted() {
		if(this.$store.getters.getPlaceAttendance) {
			this.axios.get('places/' + this.$store.getters.getPlaceAttendance).then(response => {
				// get info data
				this.place = response.data.data
				this.$store.commit('END_LOADING')
			}, response => {
				// error callback
				this.loading = false
			})        
		} else {
			this.$router.push('/')
		}
		this.$refs.badge.focus()
	},

	beforeDestroy() {
		clearInterval(this.intervalTime)
		clearInterval(this.intervalFocus)

		window.removeEventListener('click', this.setClearTimeout)
		window.removeEventListener('keypress', this.setClearTimeout)
		window.removeEventListener('mousemove', this.setClearTimeout)
		window.removeEventListener('scroll', this.setClearTimeout)

		clearTimeout(this.clearTimeout)
	},    
}
</script>