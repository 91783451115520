

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import PeopleIndex from '@/components/modules/people/PeopleIndex'

export const people = [
	{
		path: '/people',
		name: 'people',
		component: PeopleIndex,
		beforeEnter: ifAuthenticated
	},
]