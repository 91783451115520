<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Veicoli"
				:totalItems="totalItems"
				icon="mdi-truck-outline"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-data-iterator
					:items="vehicles"
					:search="search"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o" @click="show(item)">
								<div class="pa-2 d-inline-flex" style="width: 100%">

									<div style="width: 50px; vertical-align: middle; margin: auto;">
										<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
											<v-icon :style="{'color': item.color}">{{ item.icon }}</v-icon>
										</v-avatar>
									</div>


									<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
										<div>
											<div class="body-1">{{ item.code }}</div>
											<div class="body-1">{{ item.license_plate }}</div>
											<div class="caption" v-if="item.vehicletype">{{ item.vehicletype.name }}</div>
										</div>
									</div>
									<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto">
										<actions-menu
											:item="item"
											:actions="actions"
										></actions-menu>
									</div>

								</div>
							</v-card>
						</template>

					</template>
				</v-data-iterator>

				<v-btn
					:class="{'mb-16': $store.getters.showBottomNavigator}"
					color="primary"
					fixed
					fab
					bottom
					right
					@click.native.stop="create()">
					<v-icon>mdi-plus</v-icon>
				</v-btn>				

				<footer-logo></footer-logo>

			</v-container>
			
			<v-dialog
				v-model="dialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile v-if="model">
					<v-card-title class="pa-6" style="position: fixed">
					<v-row no-gutters>

						<v-col class="col-12 ma-auto ml-0">
						<div class="pl-0 font-weight-bold">Veicolo {{model.code}}</div>
						<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ model.license_plate }}</div>
						</v-col>
					</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 115px" class="body-page">
						<v-container class="px-0 justify-center" fluid>

							<v-row class="ma-auto d-flex pt-6">
								<v-icon>mdi-car-cog</v-icon>
								<div class="d-block">
									<div class="pl-2 caption">Tipo</div>
									<div class="pl-2" v-if="model.vehicletype">{{ model.vehicletype.name }}</div>
								</div>
							</v-row>
							<v-row class="ma-auto d-flex py-6">
								<v-icon>mdi-clipboard-text</v-icon>
								<div class="d-block">
									<div class="pl-2 caption">Tipo Checklist</div>
									<div class="pl-2" v-if="model.checklisttype">{{ model.checklisttype.name }}</div>
								</div>
							</v-row>

							<v-row class="ma-auto d-flex py-6" v-if="model.description">
								<v-icon>mdi-clipboard-text</v-icon>
								<div class="d-block">
									<div class="pl-2 caption">Descrizione</div>
									<div class="pl-2" style="white-space: pre;" v-html="model.description"></div>
								</div>
							</v-row>

							<v-subheader v-if="model.compartments && model.compartments.length > 0">Scomparti</v-subheader>
							<template v-for="(compartment, c) in model.compartments">
								<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="c">
									<div class="pa-2 d-inline-flex" style="width: 100%">
										<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
											<div>{{ compartment.name }}</div>
											<div class="d-flex">
												<v-icon>mdi-toolbox-outline</v-icon>
												<template v-if="compartment.instruments && compartment.instruments.length > 0">
													<v-chip v-for="(instrument, i) in compartment.instruments" :key="i" class="ml-2">
														<strong>{{ instrument.name }}</strong>&nbsp;
													</v-chip>
												</template>
												<div v-else class="pl-2">Nessuno</div>
											</div>
										</div>
									</div>
								</v-card>
							</template>
						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
							<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
								<v-icon>mdi-keyboard-backspace</v-icon>
							</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn color="error" outlined height="40" class="ml-4" @click="confirmDestroy(model)">
							Elimina
						</v-btn>
						<v-btn color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model)">
							Modifica
						</v-btn>
					</v-card-actions>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="confirmDelete = false">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="destroy()">Conferma</v-btn>
							</template>
							<span>Conferma</span>
						</v-tooltip>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters class="d-block">
							<template v-if="confirmDelete">
								<div class="pl-0 font-weight-bold">Elimina Veicolo</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else-if="model.id">
								<div class="pl-0 font-weight-bold">Modifica Veicolo</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Veicolo</div>
							</template>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 80px" class="body-page pa-0">
						<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
							<vehicle-fields
								ref="vehicleEditor"
								:model="model"
								:info="info"
							></vehicle-fields>
						</v-container>

						<v-container v-else>
							<v-row class="mx-0 justify-center pt-6">
								<div class="caption">Sei sicuro di voler eliminare questo Veicolo?</div>
							</v-row>
						</v-container>
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
						<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
						<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-col>
  	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import VehicleFields from '@/components/modules/vehicles/VehicleFields'

export default {
	name: 'vehicles-crud',
	components: {
		HeaderCrud,
		VehicleFields
	},
	data() {
		return {
			info: {},
			fab: null,
			model: {},
			vehicles: [],
			search: '',
			totalItems: 0,
			dialog: false,
			loading: false,
			editorDialog: false,
			confirmDelete: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editVehicle'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyVehicle'
				},
			],
		}
	},

	watch: {},


	created() {
		this.loading = true
		this.getData()

		this.axios.get('vehicles-info').then(response => {
			this.info = response.data.info
			this.loading = false

		}, response => {
			this.loading = false
		})
	},


	mounted() {
		this.$eventBusUtility.$on('editVehicle', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyVehicle', (item) => {
			this.confirmDestroy(item)
		})
	},


	methods: {

		closeEditor() {
			this.dialog = false
			this.editorDialog = false
			this.confirmDelete = false
			this.model = _.stubObject()
			this.$refs.vehicleEditor ? this.$refs.vehicleEditor.$refs.fields.reset() : null
		},

		show(item) {
			this.model = {...item}
			this.dialog = true
			this.loading = false
		},

		create() {
			this.editorDialog = true
		},

		save() {
			this.$refs.vehicleEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if (this.model.id) {
					this.axios.put('vehicles/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				} else {
					this.axios.post('vehicles', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},

		confirmDestroy(item) {
			this.model = {...item}
			this.confirmDelete = true
			this.editorDialog = true
		},

		edit(item) {
			this.editorDialog = true
			this.dialog = false
			this.model = {...item}
			this.loading = false
		},

		destroy() {
			this.loading = true
			this.axios.delete('/vehicles/' +this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},


		getData() {
			this.loading = true
			this.axios.get('vehicles').then(response => {
				this.vehicles = response.data.data
				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},
	},

	beforeDestroy() {
		this.$eventBusUtility.$off('editVehicle')
		this.$eventBusUtility.$off('destroyVehicle')
	},
}
</script>