

import { ifAuthenticated, ifNotAuthenticated, ifAuthenticatedAndSuperuser } from '@/router/middleware/basic'
import SettingsIndex from '@/components/modules/settings/SettingsIndex'

export const settings = [
	{
		path: '/settings',
		name: 'settings',
		component: SettingsIndex,
		beforeEnter: ifAuthenticatedAndSuperuser
	},
]