<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">


			<header-crud
				title="Gruppi"
				:totalItems="totalItems"
				icon="mdi-account-group"
				:search.sync="search">
				<template slot="header-tab">
					<slot name="tabs"></slot>
				</template>
			</header-crud>

			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="margin-top: 210px; height: 100%">
				<v-data-iterator
					:items="groups"
					:search="search"
					:loading="loading"
					disable-pagination
					hide-default-footer>
					<template v-slot:default="{ items }">

						<template v-for="(item, o) in items">

							<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o" @click="show(item)">
								<div class="pa-2 d-inline-flex" style="width: 100%">
									
									<div style="width: 50px; vertical-align: middle; margin: auto;">
										<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
											<v-icon :style="{'color': item.color}">{{ item.icon }}</v-icon>
										</v-avatar>			
									</div>


									<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
										<div>
											<div class="body-1">{{ item.name }}</div>
											<div class="caption">{{ item.description }}</div>
										</div>
									</div>

									<div class="pl-4 pr-2" style="vertical-align: middle; margin: auto">

										<actions-menu v-if="$store.getters.checkPermission('manage_groups')"
											:item="item"
											:actions="actions"
										></actions-menu>

									</div>								

								</div>

							</v-card>

						</template>

					</template>
				</v-data-iterator>

				<v-btn
					v-if="$store.getters.checkPermission('manage_groups')"
					:class="{'mb-16': $store.getters.showBottomNavigator}"
					color="primary"
					fixed
					fab
					bottom
					right
					@click.native.stop="create()">
					<v-icon>mdi-plus</v-icon>
				</v-btn>

				<footer-logo></footer-logo>
				
			</v-container>

			<v-dialog
				v-model="dialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile v-if="model">
					<v-card-title class="pa-6" style="position: fixed">
						<v-row no-gutters>

							<v-col class="col-12 ma-auto ml-0">
								<div class="pl-0 font-weight-bold">Gruppo</div>
								<div class="pl-0 font-weight-bold" style="font-size: 30px">{{ model.name }}</div>
								<div class="pl-0 font-weight-bold">{{ model.description }}</div>
							</v-col>
						</v-row>
					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 115px" class="body-page">
						<v-container class="px-0 justify-center" fluid>
							
								<v-row class="ma-auto d-flex pt-6">
									<v-icon>{{ model.exclude_statistics ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
									<div class="pl-2">Escludi da Statistiche</div>
								</v-row>
								<div class="pt-2 pl-1 caption">Le disponibilita per questo gruppo {{ model.exclude_statistics ? '' : 'non' }} saranno escluse dalle statistiche</div>						
								
								<v-row no-gutters class="mt-8 hidden-sm-and-down">
									<v-col classw="col-3">
										<v-subheader class="pl-0">Persone</v-subheader>
									</v-col>
									<v-col classw="col-9 ma-auto">
										<v-text-field class="font-weight-regular rounded-search"
											prepend-inner-icon="mdi-magnify"
											label="Cerca"
											v-model="searchPeople"
											clearable
											background-color="background-1"
											hide-details
											rounded
											solo
											dense
											loading>
											<template v-slot:append-outer>
												<v-icon :size="35" color="black">mdi-account-group</v-icon>
											</template>
											<template v-slot:progress>
												<v-progress-linear
													color="success"
													absolute 
													:indeterminate="loading"
													height="2"
												></v-progress-linear>
											</template>							
										</v-text-field>
									</v-col>
								</v-row>

								<v-row no-gutters class="mt-8 hidden-md-and-up">
									<v-col classw="col-12 d-block">
										<v-subheader class="pl-0">Persone</v-subheader>
									</v-col>
								</v-row>

								<v-row no-gutters class="pb-4 hidden-md-and-up">
									<v-col classw="col-12 pl-0">
										<v-text-field class="font-weight-regular rounded-search"
											prepend-inner-icon="mdi-magnify"
											label="Cerca"
											v-model="searchPeople"
											clearable
											background-color="background-1"
											hide-details
											rounded
											solo
											dense
											loading>
											<template v-slot:append-outer>
												<v-icon :size="35" color="black">mdi-account-group</v-icon>
											</template>
											<template v-slot:progress>
												<v-progress-linear
													color="success"
													absolute 
													:indeterminate="loading"
													height="2"
												></v-progress-linear>
											</template>							
										</v-text-field>
									</v-col>
								</v-row>

								<v-data-iterator
									:items="model.people"
									:search="searchPeople">
									<template v-slot:default="{ items }">

										<template v-for="(item, o) in items">
											<v-card class="rounded-lg pa-0 ma-auto d-flex mb-2" height="80" style="overflow: hidden;" :key="o">
												<div class="pa-2 d-inline-flex" style="width: 100%">
													<div style="width: 50px; vertical-align: middle; margin: auto;">
														<v-avatar size="36px" class="grey lighten-3 cursor-pointer ml-2">
														</v-avatar>			
													</div>

													<div class="pl-4 pr-2" style="width: 100%; vertical-align: middle; margin: auto">
														<div>
															<div class="body-1">{{ item.full_name }}</div>
															<div class="caption">{{ item.description }}</div>
														</div>
													</div>
							
												</div>
											</v-card>
										</template>

									</template>
								</v-data-iterator>



						</v-container>

					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-if="!confirmDelete">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>

						<template v-if="$store.getters.checkPermission('manage_groups')">
							<v-btn color="error" outlined  height="40" class="ml-4" @click="confirmDestroy()">
								Elimina
							</v-btn>
							<v-btn  color="secondary" min-width="100px" height="40" outlined class="ml-4" @click="edit(model)">
								Modifica
							</v-btn>
						</template>
						
					</v-card-actions>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5" v-else>
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="confirmDelete = false">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="secondary" :loading="loading" min-width="100px" height="40" outlined class="ml-4" @click="confirmDestroy()">
									Conferma
								</v-btn>
							</template>
							Conferma
						</v-tooltip>
					</v-card-actions>

				</v-card>
			</v-dialog>

			<v-dialog
				v-model="editorDialog"
				fullscreen
				hide-overlay
				transition="scroll-x-reverse-transition"
				scrollable
				style="overflow-x: hidden">
				<v-card tile>
					<v-card-title class="pa-6" style="position: fixed">

						<v-row no-gutters class="d-block">

							<template v-if="confirmDelete">
								<div class="pl-0 font-weight-bold">Elimina Gruppo</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else-if="model.id">
								<div class="pl-0 font-weight-bold">Modifica Gruppo</div>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">{{ model.name }}</div>
							</template>
							<template v-else>
								<div class="pl-0 font-weight-bold" style="font-size: 27px">Nuovo Gruppo</div>
							</template>

						</v-row>

					</v-card-title>
					<v-card-text style="overflow-x: hidden; margin-top: 95px" class="body-page pa-0">
						<v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
						
							<group-fields
								ref="groupEditor"
								:model="model"
								:info="info"
							></group-fields>

						</v-container>

						<v-container v-else>
							<v-row class="mx-0 justify-center pt-6">
								<div class="caption">Sei sicuro di voler eliminare questo Gruppo?</div>
							</v-row>
						</v-container>					
					</v-card-text>

					<v-card-actions class="pa-6 pb-10 justify-center elevation-5">
						<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
						<v-tooltip top>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeEditor()">
									<v-icon>mdi-keyboard-backspace</v-icon>
								</v-btn>
							</template>
							<span>Chiudi</span>
						</v-tooltip>
						<v-btn v-if="confirmDelete" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="destroy()">Conferma</v-btn>
						<v-btn v-else-if="model.id" color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Salva</v-btn>
						<v-btn v-else color="success" :loading="loading" min-width="100px" height="40" class="ml-4" @click="save()">Crea</v-btn>				
					</v-card-actions>
				</v-card>
			
			
			</v-dialog>

		</v-col>
	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'
import GroupFields from '@/components/modules/people/GroupFields'

export default {
	name: 'groups-crud',
  	components: {
		HeaderCrud,
		GroupFields
	},
	data() {
		return {
			info: {},
			fab: null,
			model: {},
			groups: [],
			search: '',
			searchPeople: '',
			totalItems: 0,
			dialog: false,
			loading: false,
			editorDialog: false,
			confirmDelete: false,
			actions: [
				{
					'icon': 'mdi-pencil',
					'text': 'Modifica',
					'event': 'editGroup'
				},
				{
					'icon': 'mdi-delete-outline',
					'text': 'Elimina',
					'event': 'destroyGroup'
				},
			],			
		}
	},

	watch: {

	},
	

	created() {
		this.getData()
	},


	mounted() {
		this.$eventBusUtility.$on('editGroup', (item) => {
			this.edit(item)
		})

		this.$eventBusUtility.$on('destroyGroup', (item) => {
			this.confirmDestroy()
			this.$set(this, 'model', item)
		})
	},


	methods: {

		closeEditor() {
			this.dialog = false
			this.editorDialog = false
			this.confirmDelete = false
			this.model = _.stubObject()
			this.$refs.groupEditor ? this.$refs.groupEditor.$refs.fields.reset() : null
		},

		show(group) {
			
			this.axios.get('groups/' + group.id).then(response => {
				
				this.dialog = true
				this.model = response.data.data
				this.loading = false

			}, response => {
				this.loading = false
			})				
		},

		create() {
			this.editorDialog = true	
			this.loading = false
		},

		save() {
			this.$refs.groupEditor.$refs.fields.validate().then(result => {
				if (!result) {
					return false
				}
				this.loading = true

				if(this.model.id) {
					this.axios.put('groups/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('groups', this.model).then(response => {
						this.loading = false
						this.getData()
						this.closeEditor()

					}, response => {
						this.loading = false
					})
				}


			}).catch(() => {

			})
		},

		confirmDestroy() {
			this.confirmDelete = true
			this.editorDialog = true
		},

		edit(item) {
			this.editorDialog = true
			this.dialog = false
			this.model = {...item}
			this.loading = false
		},

		destroy() {
			this.loading = true
			this.axios.delete('groups/' + this.model.id).then(response => {
				this.loading = false
				this.getData()
				this.closeEditor()

			}, response => {
				this.loading = false
			})
		},


		getData() {

			this.loading = true

			this.axios.get('groups').then(response => {
				this.groups = response.data.data
				this.totalItems = response.data.data.length
				this.loading = false

			}, response => {
				this.loading = false
			})
		},



	}
}
</script>

<style>

</style>