<template>
    <v-dialog
        v-model="dialogEditor"
        fullscreen
        hide-overlay
        transition="scroll-x-reverse-transition"
        scrollable
        style="overflow-x: hidden">
        <v-card tile v-if="model">
            <v-card-title class="pa-6" style="position: fixed">
                <v-row no-gutters>

                    <v-col class="col-12 ma-auto ml-0">
                        <template v-if="confirmDelete">
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Elimina Timbratura</div>
                        </template>
                        <template v-else-if="model.id">
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Modifica Timbratura</div>
                        </template>
                        <template v-else>
                            <div class="pl-0 font-weight-bold" style="font-size: 27px">Nuova Timbratura</div>
                        </template>
                    </v-col>
                </v-row>
            </v-card-title>
            
            <v-card-text style="overflow-x: hidden; margin-top: 100px" class="body-page pa-0">
                <v-container class="px-0 justify-center" fluid v-if="!confirmDelete">
                    
                    <validation-observer ref="fieldsAttendance">
                        <v-row class="pb-2 mx-0 pt-4">
                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-menu
                                    ref="date"
                                    :close-on-content-click="false"
                                    v-model="datepicker_menu['date']"
                                    :return-value.sync="model['date']"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                    :nudge-right="40"
                                    style="width: 100%">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-on="on"
                                            label="Data*"
                                            append-icon="mdi-event"
                                            readonly
                                            :value="$eventBusUtility.formatDate(model['date'])"
                                            outlined
                                            :clearable="true"
                                            @click:clear="model['date'] = ''"
                                            dense
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="model['date']"
                                        color="primary"
                                        locale="It"
                                        scrollable
                                        :first-day-of-week="1"
                                        @input="$refs['date'].save(model['date'])">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <validation-provider name="Orario" rules="required|is_time" v-slot="{ errors }">
                                    <v-text-field
                                        label="Orario*"
                                        v-model="model['schedule']"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        v-mask="'##:##'"
                                        return-masked-value						
                                        ref="schedule"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>


                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <validation-provider name="Persone" rules="required" v-slot="{ errors }">
                                    <v-autocomplete class="font-weight-regular"
                                        label="Persone*"
                                        v-model="model['person_id']"
                                        item-value="id"
                                        item-text="full_name"
                                        :error-messages="errors"
                                        clearable
                                        small-chips
                                        :items="info.people"
                                        dense
                                        outlined>
                                    </v-autocomplete>
                                </validation-provider>
                            </v-col>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-4">
                                <validation-provider name="Tipo" rules="required" v-slot="{ errors }">                                    
                                    <v-select
                                        label="Tipo*"
                                        v-model="model['type']"
                                        :items="[{'text': 'Ingresso', 'value': 'in'}, {'text': 'Uscita', 'value': 'out'}]"
                                        :error-messages="errors"
                                        outlined
                                        dense
                                        ref="type"
                                    ></v-select>
                                </validation-provider>
                            </v-col>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-autocomplete class="font-weight-regular"
                                    label="Luogo"
                                    v-model="model['place_id']"
                                    :items="info.places"
                                    item-value="id"
                                    item-text="name"
                                    clearable
                                    small-chips
                                    dense
                                    outlined>
                                </v-autocomplete>
                            </v-col>

                            <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 py-0 px-6 pt-3">
                                <v-switch class="font-weight-regular"
                                    label="Completa"
                                    v-model="model['completed']"
                                    >
                                </v-switch>
                            </v-col>
                        </v-row>
                    </validation-observer>
                </v-container>

                <v-container v-else>
                    <v-row class="mx-0 justify-center pt-6">
                        <div class="caption" >Sei sicuro di voler eliminare questa Timbratura?</div>
                    </v-row>
                </v-container>

            </v-card-text>

            <v-card-actions class="pa-6 pb-10 justify-center elevation-5">
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn v-on="{ ...tooltip }" color="background-1" fab small @click="closeDialog()">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                        </v-btn>
                    </template>
                    <span>Chiudi</span>
                </v-tooltip>
                <v-btn v-if="model.id" color="success" min-width="100px" height="40" class="ml-4" @click="storeAttendance()">Salva</v-btn>
                <v-btn v-else color="success" min-width="100px" height="40" class="ml-4" @click="storeAttendance()">Crea</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'attendance-crud',
	props: {
		info: Object,
	},
        
	data() {
		return {
			model: {},
			loading: false,
			datepicker_menu: {},
			dialogEditor: false,
			confirmDelete: false,
		}
	},

	computed: {

	},

	methods: {

		closeDialog() {
			this.dialogEditor = false
			this.confirmDelete = false
			this.model = _.stubObject()
		},

		edit(item) {
			this.dialogEditor = true
			this.model = {...item}
			Vue.set(this.model, 'date', moment(item.date).format('YYYY-MM-DD'))
			Vue.set(this.model, 'schedule', moment(item.date).format('HH:mm'))
			this.loading = false
		},

		create() {
			this.dialogEditor = true
		},
            
		storeAttendance() {
			this.$refs.fieldsAttendance.validate().then(result => {
				if (!result) {
					return false
				}

				if(this.model.id) {
					this.axios.put('attendances/' + this.model.id, this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'attendances')
						this.closeDialog()
					}, response => {
						this.loading = false
					})					
				} else {
					this.axios.post('attendances', this.model).then(response => {
						this.loading = false
						this.$eventBusUtility.$emit('refreshTable', 'attendances')
						this.closeDialog()

					}, response => {
						this.loading = false
					})
				}

			}).catch(() => {

			})                
		},

		deleteAttendance() {
			this.axios.post('attendances/delete', {ids: [this.model.id]}).then(response => {
				this.loading = false
				this.$eventBusUtility.$emit('refreshTable', 'attendances')
				this.closeDialog()
			}, response => {
				this.loading = false
			})
		},

		confirmDestroy() {
			this.confirmDelete = true
			this.dialogEditor = true
		},

	},

	mounted() {

		this.$eventBusUtility.$on('editAttendance', (item) => {
			this.edit(item)
		}),
               
		this.$eventBusUtility.$on('createManualAttendance', () => {
			this.create()
			Vue.set(this.model, 'manual', true)
		})
            
		this.$eventBusUtility.$on('destroyAttendance', (item) => {
			this.confirmDestroy()
			Vue.set(this, 'model', item)
		})

	},


	beforeDestroy() {
		this.$eventBusUtility.$off('editAttendance')
		this.$eventBusUtility.$off('createManualAttendance')
		this.$eventBusUtility.$off('destroyAttendance')
	},        

}
</script>

<style>

</style>