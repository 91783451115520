<template>
	<v-row no-gutters class="fill-height">
		<v-col class="mx-0">

			<header-crud
				title="Statistiche"
				:totalItems="totalItems"
				icon="mdi-equalizer-outline"
				:dates.sync="dates">
				<template slot="header-tab">
					<slot name="tabs"></slot>

					<div class="py-4">
						<v-btn depressed class="px-3" @click="showFilters = !showFilters">Filtri</v-btn>
						<v-spacer></v-spacer>
					</div>
				</template>
			</header-crud>
			<v-container fluid class="pa-6 body-page" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-2'" style="height: 100%" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 265px' : 'margin-top: 220px'">


				<v-card :elevation="1" class="background" width="100%">


					<v-slide-y-transition>
						<v-card-text class="pa-0 background-1 pb-10" v-if="showFilters">
							<v-container fluid class="pa-0">
								<v-subheader class="pl-1">Filtri Avanzati</v-subheader>
								<v-row no-gutters>

									<v-col class="col-12 col-sm-10 col-md-10 col-lg-10 pt-0">
										<v-subheader class="pl-1">Calendari</v-subheader>
										<v-row no-gutters>
											<v-col class="col-6 col-sm-4 col-md-4 col-lg-3" v-for="(calendar, s) in info.calendars" :key="s">
												<v-checkbox
													v-model="filters.calendars"
													:label="calendar.name"
													class="ma-0"
													hide-details
													:value="calendar.id"
												></v-checkbox>
											</v-col>
										</v-row>
									</v-col>
									<v-col class="col-12 col-sm-2 col-md-2 col-lg-2 pt-0">
										<v-subheader></v-subheader>
										<v-checkbox
											v-model="allcalendars"
                            				@click="selectAll('calendars')"
											label="Seleziona Tutti"
											class="ma-0 ma-auto mr-0"
											hide-details
										></v-checkbox>
									</v-col>

									<v-col class="col-10 pt-0">
										<v-subheader class="pl-1">Tipi Turno</v-subheader>
										<v-row no-gutters>
											<v-col class="col-6 col-sm-4 col-md-4 col-lg-3" v-for="(shifttype, s) in info.shifttypes" :key="s">
												<v-checkbox
													v-model="filters.shifttypes"
													:label="shifttype.name"
													class="ma-0"
													hide-details
													:value="shifttype.id"
												></v-checkbox>
											</v-col>
										</v-row>
									</v-col>

									<v-col class="col-2 pt-0">
										<v-subheader></v-subheader>
										<v-checkbox
											v-model="allshifttypes"
                            				@click="selectAll('shifttypes')"
											label="Seleziona Tutti"
											class="ma-0 ma-auto mr-0"
											hide-details
										></v-checkbox>
									</v-col>

									<v-col class="col-10 pt-0">
										<v-subheader class="pl-1">Mansioni</v-subheader>
										<v-row no-gutters>
											<v-col class="col-6 col-sm-4 col-md-4 col-lg-3" v-for="(task, s) in info.tasks" :key="s">
												<v-checkbox
													v-model="filters.tasks"
													:label="task.name"
													class="ma-0"
													hide-details
													:value="task.id"
												></v-checkbox>
											</v-col>
										</v-row>
									</v-col>
									<v-col class="col-2 pt-0">
										<v-subheader></v-subheader>
										<v-checkbox
											v-model="alltasks"
                            				@click="selectAll('tasks')"
											label="Seleziona Tutti"
											class="ma-0 ma-auto mr-0"
											hide-details
										></v-checkbox>
									</v-col>

									<v-col class="col-10 pt-0">
										<v-subheader class="pl-1">Gruppi</v-subheader>
										<v-row no-gutters>
											<v-col class="col-6 col-sm-4 col-md-4 col-lg-3" v-for="(group, s) in info.groups" :key="s">
												<v-checkbox
													v-model="filters.groups"
													:label="group.name"
													class="ma-0"
													hide-details
													:value="group.id"
												></v-checkbox>
											</v-col>
										</v-row>
									</v-col>
									<v-col class="col-2 pt-0">
										<v-subheader></v-subheader>
										<v-checkbox
											v-model="allgroups"
                            				@click="selectAll('groups')"
											label="Seleziona Tutti"
											class="ma-0 ma-auto mr-0"
											hide-details
										></v-checkbox>
									</v-col>

								</v-row>
							</v-container>
						</v-card-text>
					</v-slide-y-transition>

					<v-data-table class="background-2"
						:headers="headers"
						:items="people"
						:loading="loading"
						:options.sync="options"
						:mobile-breakpoint="0"
						ref="vDataTable"
						:footer-props="{
							'items-per-page-options': [10, 25, 50,  { text: 'Tutti', value: -1 }],
							showFirstLastPage: true
						}">

						<template v-slot:body.prepend>
							<tr>
								<td>
									<v-icon >mdi-magnify</v-icon>
								</td>
								<td>
									<v-tooltip top v-if="!isEmptyColumnsFilter">
										<template v-slot:activator="{ on: tooltip }">
											<v-btn v-on="{ ...tooltip }" icon @click="emptyColumnsFilter()">
												<v-icon>mdi-progress-close</v-icon>
											</v-btn>
										</template>
										<span>Svuota Filtri</span>
									</v-tooltip>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Cognome e Nome"
										hide-details
										v-model="columnsFilter['full_name']"
									></v-text-field>
								</td>
								<td class="pl-0">
									<v-text-field
										dense
										solo
										flat
										placeholder="Valore"
										hide-details
										v-model="columnsFilter[type]"
									></v-text-field>
								</td>
							</tr>
						</template>

						<template v-slot:item="{ item, index }">
							<tr class="cursor-pointer">
								<td>
								</td>
								<td>
									{{ index + 1 + ((options.page-1) * options.itemsPerPage) }}
								</td>
								<td>
									{{ item.full_name }}
								</td>
								<td>
									{{ item[type] }}
								</td>
							</tr>
						</template>

						<template v-slot:footer.page-text="{ pageStart, pageStop }">
							Da {{ pageStart }} a {{ pageStop }} di {{ totalItems }}
						</template>
					</v-data-table>

				</v-card>

				<footer-logo></footer-logo>


			</v-container>

		</v-col>

        <v-btn
            :class="{'mb-16': $store.getters.showBottomNavigator}"
			color="green"
            fixed
            fab
			dark
            bottom
            right
			@click.native.stop="getCsv()">
			<v-icon>mdi-download</v-icon>
        </v-btn>

	</v-row>
</template>

<script>

import HeaderCrud from '@/components/common/HeaderCrud'

export default {
	name: 'analytic-table',
	components: {
		HeaderCrud,
	},
	props: {
		type: String,
		start: String,
		end: String,
		info: Object
	},
	data() {
		return {
			people: [],
			loading: false,
			timeout: null,
			columnsFilter: {},
			showFilters: false,
			allcalendars: true,
			allshifttypes: true,
			alltasks: true,
			allgroups: true,
			filters: {
				'tasks': [],
				'groups': [],
				'calendars': [],
				'shifttypes': [],
			},
			options: {},
			dates: [moment().subtract(30, 'days').format('Y-MM-DD'), moment().format('Y-MM-DD')],
		}
	},

	computed: {

		totalItems() {
			return this.people.length
		},

		headers() {
			return [
				{
					'text': '',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Posizione',
					'value': '',
					'sortable': false
				},
				{
					'text': 'Cognome e Nome',
					'value': 'full_name',
					filter: (value) => this.customSearch(value, 'full_name'),
					'sortable': false
				},
				{
					'text': 'Valore',
					'value': this.type,
					filter: (value) => this.customSearch(value, this.type),
					'sortable': false
				},
			]
		},

		isEmptyColumnsFilter() {
			return _.isEmpty(this.columnsFilter)
		},
	},

	watch: {

		dates: {
			handler(newVal) {
				if(newVal[0] && newVal[1])
					this.getData()
			},
			deep: true,
		},

		'filters.calendars': {
			handler(newValue, oldValue) {
				newValue.length == this.info.calendars.length ? this.allcalendars = true : this.allcalendars = false
				this.getData()
			}
		},

		'filters.shifttypes': {
			handler(newValue, oldValue) {
				newValue.length == this.info.shifttypes.length ? this.allshifttypes = true : this.allshifttypes = false
				this.getData()
			}
		},

		'filters.tasks': {
			handler(newValue, oldValue) {
				newValue.length == this.info.tasks.length ? this.alltasks = true : this.alltasks = false
				this.getData()
			}
		},

		'filters.groups': {
			handler(newValue, oldValue) {
				newValue.length == this.info.groups.length ? this.allgroups = true : this.allgroups = false
				this.getData()
			}
		},
	},

	methods: {

		selectAll(type) {
			let all = 'all' + type
			all = this[all]
			if(all == true) {
				this.info[type].forEach((val, index) => {
					this.filters[type].push(val.id)
				})
			} else {
				this.info[type].forEach((val, index) => {
					this.filters[type] = []
				})
			}
		},

		emptyColumnsFilter() {
			this.columnsFilter = _.stubObject()
		},

		customSearch(value, key) {

			if (!this.columnsFilter[key]) return true
			return !value ? false : (typeof value == 'number' ? value == this.columnsFilter[key] : value.toLocaleLowerCase().indexOf(this.columnsFilter[key].toLocaleLowerCase()) > -1)
		},

		getData() {
			if(this.timeout) {
				clearTimeout(this.timeout)
			}

			this.timeout = setTimeout(() => {

				this.loading = true

				let dataObject = {
					dates: [],
					filters: this.filters,
					type: this.type,
				}

				if(moment(this.dates[1]).isBefore(this.dates[0])) {
					let d = this.dates[0]
					dataObject.dates[0] = this.dates[1]
					dataObject.dates[1] = d
				} else {
					dataObject.dates = this.dates
				}

				this.axios.get('analytics/', {params: dataObject}).then(response => {
					this.loading = false
					this.people = response.data.data
            		// this.$store.commit('END_LOADING')

				}, response => {
					// error callback
					this.loading = false
				})
			}, 800)
		},

		getCsv() {
			let dataObject = {
				dates: [],
				filters: this.filters,
				type: this.type,
			}

			if(moment(this.dates[1]).isBefore(this.dates[0])) {
				let d = this.dates[0]
				dataObject.dates[0] = this.dates[1]
				dataObject.dates[1] = d
			} else {
				dataObject.dates = this.dates
			}

			this.$eventBusUtility.openFile(this.$store, '/analytics-csv/', 'Statistiche.csv', 'POST', {...dataObject})
		}
	},

	mounted() {
		this.info.calendars.every(calendar => this.filters.calendars.push(calendar.id))
		this.info.shifttypes.every(shifttype => this.filters.shifttypes.push(shifttype.id))
		this.info.tasks.every(task => this.filters.tasks.push(task.id))
		this.info.groups.every(group => this.filters.groups.push(group.id))
		this.getData()
	},
}
</script>