

import { ifAuthenticated, ifNotAuthenticated } from '@/router/middleware/basic'
import CoursesIndex from '@/components/modules/courses/CourseIndex'

export const courses = [
	{
		path: '/courses',
		name: 'courses',
		component: CoursesIndex,
		beforeEnter: ifAuthenticated,
		props: true
	},
]