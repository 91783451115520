import Vue from 'vue'
import { eventBusUtility } from '@/eventBusUtility.js'

function initialState() {
	return {
		token: localStorage.getItem('user-token') || '',
		touchToken: localStorage.getItem('touch-token') || '',
		authUrl: process.env.VUE_APP_AUTH_URL + '/api/v1',
		baseHostname: process.env.VUE_APP_AUTH_URL,
		touchTimer: null,
		bottomNavigator: false,
		disableSectionRotation: localStorage.getItem('disable_section_rotation') == undefined ? false : JSON.parse(localStorage.getItem('disable_section_rotation')),
		placeAttendance: localStorage.getItem('place_attendance') == undefined ? false : localStorage.getItem('place_attendance')
	}
}

const state = initialState

const getters = {

	isAuth: state => !!state.token,

	isTouchDevice: state => !!state.touchToken,

	getAuthToken: state => state.token,

	getAuthUrl: state => state.authUrl,

	getHostname: state => state.baseHostname,

	getDisableSectionRotation: state => state.disableSectionRotation,

	getPlaceAttendance: state => state.placeAttendance,


}


const mutations = {

	SET_AUTH_DEFAULT: (state) =>  {
		const s = initialState()
		Object.keys(s).forEach(key => {
			state[key] = s[key]
		})
	},


	SET_AUTH_DATA: (state, response) => {
		localStorage.setItem('user-token', response.token)
		Vue.axios.defaults.headers = {
			'X-FE-Version': localStorage.version,
			'Content-Type': 'application/json',
			'Accept':'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Authorization': 'Bearer ' + response.token
		}     
		state.token = response.token
	},
	
	
	REMOVE_AUTH_DATA: (state) => {
		if(!state.touchToken) {
			state.token = ''
			localStorage.removeItem('user')
			localStorage.removeItem('user-token')
			localStorage.removeItem('fqdn')
			Vue.axios.defaults.headers = {
				'X-FE-Version': localStorage.version,
				'Content-Type': 'application/json',
				'Accept':'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': ''
			}
			eventBusUtility.navigateTo('login')
		} else {
			state.token = ''
			localStorage.removeItem('user-token')
			localStorage.removeItem('user')
			Vue.axios.defaults.headers = {
				'X-FE-Version': localStorage.version,
				'Content-Type': 'application/json',
				'Accept':'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'Authorization': 'Bearer ' + state.touchToken
			}
			eventBusUtility.navigateTo('home')
		}
	},

	DISABLE_TOUCH_DEVICE: (state) => {
		localStorage.removeItem('touch-token')
		localStorage.removeItem('disable_section_rotation')
		localStorage.removeItem('place_attendance')
		state.disableSectionRotation = false
		state.placeAttendance = null
		state.touchToken = ''
	},

	ENABLE_TOUCH_DEVICE: (state, touchToken) => {

		Vue.axios.defaults.headers = {
			'X-FE-Version': localStorage.version,
			'Content-Type': 'application/json',
			'Accept':'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'Authorization': 'Bearer ' + touchToken
		}
		state.touchToken = touchToken
	},

	SET_TOUCH_TIMER: (state, interval) => {
		state.touchTimer = interval
	}

}


const actions = {

	authLogin: ({commit, state, dispatch, rootGetters}, user) => {
		return new Promise((resolve, reject) => {
			Vue.axios.post(state.authUrl + '/login', user).then(response => {

				commit('SET_AUTH_DATA', response.data)
				
				dispatch('fetchUser').then(() => {
					resolve(response.data)
				})

			}).catch(error => {
				localStorage.removeItem('user-token')
				reject(error)
			})
		})
	},

	authLogout: ({commit, state, getters, dispatch}) => {
		return new Promise((resolve, reject) => {
			Vue.axios.get(state.authUrl + '/logout').then((response) => {
				dispatch('clearAuthData')
				resolve(true)
			}).catch((error) => {
				reject(error)
			}) 
		})
	},

	clearAuthData: ({commit, state, getters, dispatch}) => {
		return new Promise((resolve, reject) => {
			commit('REMOVE_AUTH_DATA')
			if(getters.isTouchDevice) {
				dispatch('fetchUser').then(() => {
					resolve(true)
				})
			} else {
				dispatch('resetStore')
				resolve(true)
			}		
		})
	},

	authRegister: ({commit, state, dispatch}, user) => {
		return new Promise((resolve, reject) => {
			Vue.axios.post(state.authUrl + '/register', user).then((response) => {

				commit('SET_AUTH_DATA', response.data)
				dispatch('fetchUser')
				resolve(response.data)
				
			}).catch((error) => {
				reject(error)
			}) 
		})
	},

	enableTouchDevice: ({commit, dispatch}, data,) => {
		return new Promise((resolve, reject) => {
			Vue.axios({url: '/touch-device', method: 'POST' }).then(response => {
				localStorage.setItem('touch-token', response.data.token)
				localStorage.setItem('disable_section_rotation', !!data.disableSectionRotation)
				if(data.placeAttendance)
					localStorage.setItem('place_attendance', data.placeAttendance)
				else
					localStorage.removeItem('place_attendance')
				dispatch('authLogout').then(() => {
					commit('ENABLE_TOUCH_DEVICE', response.data.token)
					dispatch('fetchUser').then(() => {
						resolve(response.data)
					})
				})

			}).catch(error => {
				commit('DISABLE_TOUCH_DEVICE')
				reject(error)
			})
		})
	},

}



export default {
	state,
	getters,
	actions,
	mutations,
}